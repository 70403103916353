import { Platform, StyleSheet } from "react-native";
import { PickerStyle } from "react-native-picker-select";
import { colors } from "./Colors";
import { cornerRadius, spacings } from "./Constants";
import { fontFamilies, fontSizes } from "./Fonts";

export const largeInputStyle = StyleSheet.create({
  container: {
    alignSelf: "center",
    backgroundColor: colors.background.light,
    borderRadius: cornerRadius.md,
    paddingHorizontal: spacings.lg,
    paddingVertical: spacings.sm,
    height: 46
  },
  input: {
    fontFamily: fontFamilies.default,
    color: colors.text.primary,
    ...(Platform.OS === "web" && {
      outlineStyle: "none"
    }),
    minWidth: 1
  },
  inputContainer: {
    borderBottomWidth: cornerRadius.none,
    height: "100%",
    width: "100%"
  }
});

const largePickerInputStyle = StyleSheet.flatten([
  largeInputStyle.inputContainer,
  largeInputStyle.input,
  {
    borderWidth: cornerRadius.none,
    backgroundColor: colors.background.transparent,
    fontSize: fontSizes.lg
  }
]);

export const largePickerContainerStyle = StyleSheet.flatten([
  largeInputStyle.container,
  { width: "100%" }
]);

export const largePickerSelectStyle: PickerStyle = {
  inputAndroid: largePickerInputStyle,
  inputIOS: largePickerInputStyle,
  inputWeb: largePickerInputStyle,
  viewContainer: largePickerContainerStyle,
  inputAndroidContainer: largePickerContainerStyle,
  iconContainer: {
    right: Platform.OS === "android" ? spacings.lg : spacings.none,
    top: Platform.OS === "android" ? spacings.lg : spacings.md
  },
  placeholder: {
    color: colors.text.placeholder
  }
};

export const searchBarStyle = {
  container: StyleSheet.flatten([
    largeInputStyle.container,
    {
      paddingVertical: spacings.none,
      borderTopWidth: spacings.none,
      borderBottomWidth: spacings.none,
      width: "100%"
    }
  ]),
  input: largeInputStyle.input,
  inputContainer: StyleSheet.flatten([
    largeInputStyle.inputContainer,
    {
      backgroundColor: colors.background.transparent
    }
  ])
};
