import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { RootStackParamsList } from "../navigation";
import { AccountDetail, useAccounts } from "../services/AccountsService";
import { virtualizedListItemStyle } from "../styles/Lists";
import {
  colors,
  containerStyle,
  cornerRadius,
  spacings,
  typographies
} from "../styles/Styles";

export default function GeneralTermsScreen() {
  const { t } = useTranslation();
  const accountsQuery = useAccounts();
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();

  if (accountsQuery.isInitialLoading) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return (
    <ScrollViewComponent>
      {accountsQuery.data && accountsQuery.data.length > 0 && (
        <>
          <Text
            style={[
              typographies.title,
              { textAlign: "left", marginBottom: spacings.sm }
            ]}>
            {t(
              "GeneralTermsScreenAffiliationGeneralTermsTitle",
              "Affiliations' general terms"
            )}
          </Text>
          <View
            style={{
              width: "100%",
              borderRadius: cornerRadius.md,
              marginBottom: spacings.lg,
              overflow: "hidden"
            }}>
            {accountsQuery.data?.map((item, index) => {
              return (
                <RenderItem
                  key={index}
                  index={index}
                  accountDetail={item}></RenderItem>
              );
            })}
          </View>
        </>
      )}

      <Text
        style={[
          typographies.title,
          {
            textAlign: "left",
            marginBottom: spacings.sm
          }
        ]}>
        {t(
          "GeneralTermsScreenSecandaGeneralTermsTitle",
          "SECANDA AG general terms"
        )}
      </Text>
      <View
        style={{
          width: "100%",
          flex: 1,
          borderRadius: cornerRadius.md,
          overflow: "hidden"
        }}>
        <ListItem
          style={virtualizedListItemStyle.middleItem}
          onPress={() => {
            navigation.navigate("termsAndConditions");
          }}>
          <ListItem.Content>
            <Text style={typographies.body}>
              {t(
                "GeneralTermsScreenTermsAndConditionsTitle",
                "Terms & Conditions"
              )}
            </Text>
          </ListItem.Content>
          <ListItem.Chevron />
        </ListItem>

        <ListItem
          onPress={() => {
            navigation.navigate("privacyPolicy");
          }}>
          <ListItem.Content>
            <Text style={typographies.body}>
              {t("GeneralTermsScreenPrivacyPolicyTitle", "Privacy Policy")}
            </Text>
          </ListItem.Content>
          <ListItem.Chevron />
        </ListItem>
      </View>
    </ScrollViewComponent>
  );

  function RenderItem({
    accountDetail,
    index
  }: {
    accountDetail: AccountDetail;
    index: number;
  }) {
    return (
      <ListItem
        style={
          accountsQuery.data &&
          index !== accountsQuery.data.length - 1 &&
          virtualizedListItemStyle.middleItem
        }
        onPress={() => {
          navigation.navigate("termsAndConditions", {
            identifierId: accountDetail.identifier.identifierId,
            affiliationId: accountDetail.affiliation.affiliationId
          });
        }}>
        <ListItem.Content>
          <Text style={typographies.body}>
            {accountDetail.affiliation.name}
          </Text>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
    );
  }
}
