import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { getDaysInMonth, isValid } from "date-fns";
import { range, rangeRight } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import RNPickerSelect, { Item } from "react-native-picker-select";
import { containerMaxWidth } from "../styles/Containers";
import { largePickerSelectStyle } from "../styles/Inputs";
import { colors, iconSizes, spacings } from "../styles/Styles";

export default function DatePickerComponent({
  date,
  onValueChange
}: {
  date: Date | null | undefined;
  onValueChange: (date?: Date) => void;
}) {
  const { t } = useTranslation();

  const [days, setDays] = useState<Item[]>([]);
  const [months, setMonths] = useState<Item[]>([]);
  const [years, setYears] = useState<Item[]>([]);

  const [day, setDay] = useState<number | undefined>(date?.getDate());
  const [month, setMonth] = useState<number | undefined>(date?.getMonth());
  const [year, setYear] = useState<number | undefined>(date?.getFullYear());

  useEffect(() => {
    setMonths([
      { label: t("Month.January", "January"), value: 0 },
      { label: t("Month.February", "February"), value: 1 },
      { label: t("Month.March", "March"), value: 2 },
      { label: t("Month.April", "April"), value: 3 },
      { label: t("Month.May", "May"), value: 4 },
      { label: t("Month.June", "June"), value: 5 },
      { label: t("Month.July", "July"), value: 6 },
      { label: t("Month.August", "August"), value: 7 },
      { label: t("Month.September", "September"), value: 8 },
      { label: t("Month.October", "October"), value: 9 },
      { label: t("Month.November", "November"), value: 10 },
      { label: t("Month.December", "December"), value: 11 }
    ]);

    setYears(
      rangeRight(
        new Date().getFullYear() - 100,
        new Date().getFullYear() + 1
      ).map((d): Item => {
        return { label: d.toString(), value: d };
      })
    );
  }, []);

  useEffect(() => {
    let currentDate = new Date(year ?? 2000, month ?? 0);
    if (!isValid(currentDate)) {
      currentDate = new Date(2000, 0);
    }
    var daysInMonth = getDaysInMonth(currentDate);
    var items = range(1, daysInMonth + 1).map((d): Item => {
      return { label: d.toString(), value: d };
    });
    setDays(items);
  }, [month, year]);

  useEffect(() => {
    if (day !== undefined && month !== undefined && year !== undefined) {
      onValueChange(new Date(Date.UTC(year, month, day)));
    } else {
      onValueChange(undefined);
    }
  }, [day, month, year]);

  return (
    <View
      style={{
        flexDirection: "row",
        maxWidth: containerMaxWidth,
        marginBottom: spacings.xs
      }}>
      <View style={{ flex: 0.2, marginRight: spacings.xs }}>
        <RNPickerSelect
          placeholder={{
            label: t("DaySelectionPlaceholder", "Day..."),
            value: undefined
          }}
          fixAndroidTouchableBug={true}
          useNativeAndroidPickerStyle={false}
          style={largePickerSelectStyle}
          onValueChange={(value: number | undefined) => {
            if (value !== undefined) {
              setDay(value);
            }
          }}
          Icon={() => {
            if (Platform.OS !== "web") {
              return (
                <FontAwesomeIcon
                  size={iconSizes.md}
                  color={colors.primary}
                  icon={faChevronDown}></FontAwesomeIcon>
              );
            }
            return null;
          }}
          items={days}
          value={day}
        />
      </View>
      <View style={{ flex: 0.5, marginRight: spacings.xs }}>
        <RNPickerSelect
          placeholder={{
            label: t("MonthSelectionPlaceholder", "Month..."),
            value: undefined
          }}
          fixAndroidTouchableBug={true}
          useNativeAndroidPickerStyle={false}
          style={largePickerSelectStyle}
          onValueChange={(value: number | undefined) => {
            if (value !== undefined) {
              setMonth(value);
            }
          }}
          Icon={() => {
            if (Platform.OS !== "web") {
              return (
                <FontAwesomeIcon
                  size={iconSizes.md}
                  color={colors.primary}
                  icon={faChevronDown}></FontAwesomeIcon>
              );
            }
            return null;
          }}
          items={months}
          value={month}
        />
      </View>
      <View style={{ flex: 0.3 }}>
        <RNPickerSelect
          placeholder={{
            label: t("YearSelectionPlaceholder", "Year..."),
            value: undefined
          }}
          fixAndroidTouchableBug={true}
          useNativeAndroidPickerStyle={false}
          style={largePickerSelectStyle}
          onValueChange={(value: number | undefined) => {
            if (value !== undefined) {
              setYear(value);
            }
          }}
          Icon={() => {
            if (Platform.OS !== "web") {
              return (
                <FontAwesomeIcon
                  size={iconSizes.md}
                  color={colors.primary}
                  icon={faChevronDown}></FontAwesomeIcon>
              );
            }
            return null;
          }}
          items={years}
          value={year}
        />
      </View>
    </View>
  );
}
