import AsyncStorage from "@react-native-async-storage/async-storage";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import {
  InvalidateQueryFilters,
  onlineManager,
  QueryClient
} from "@tanstack/react-query";
import { default as i18n } from "i18next";
import { Keyboard, Platform } from "react-native";
import { NotAllowedOfflineError } from "../errors/NotAllowedOfflineError";
import { IAccountKey, IPersonKey } from "./AccountsService";
import { IBeneficiaryKey } from "./BeneficiariesService";
import { jsonReviver } from "./ParserService";

const defaultCacheTime = 1000 * 60 * 60 * 24 * 7;
const defaultStaleTime = 60 * 1000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: defaultCacheTime,
      staleTime: defaultStaleTime
    },
    mutations: {
      onMutate: () => {
        Keyboard.dismiss();

        if (!onlineManager.isOnline()) {
          throw new NotAllowedOfflineError(
            i18n.t("NotAllowedOfflineErrorTitle", "Network error!"),
            i18n.t(
              "NotAllowedOfflineErrorMessage",
              "Please check your network connection"
            )
          );
        }
      }
    }
  }
});

export const queryClientPersisterKey = "QUERY_CACHED_DATA";
export const queryClientPersister = createAsyncStoragePersister({
  key: queryClientPersisterKey,
  storage: Platform.OS === "web" ? undefined : AsyncStorage,
  deserialize: (cachedString) => {
    return JSON.parse(cachedString, jsonReviver);
  }
});

export const accountsQueryKey: string = "accounts";
export const affiliationsQueryKey: string = "affiliations";
export const affiliationQueryKey: string = "affiliation";
export const affiliationContactDataQueryKey: string = "affiliationContactData";
export const dependentAffiliationsQueryKey: string = "dependentAffiliations";
export const identifiersQueryKey: string = "identifiers";
export const identifierQueryKey: string = "identifier";
export const transactionsQueryKey: string = "transactions";
export const transactionQueryKey: string = "transaction";
export const transactionStatusQueryKey: string = "transactionStatus";
export const profileQueryKey: string = "profile";
export const serverStatusQueryKey: string = "serverStatus";
export const beneficiaryQueryKey: string = "beneficiary";
export const beneficiariesQueryKey: string = "beneficiaries";
export const allBeneficiariesQueryKey: string = "allBeneficiaries";
export const paymentModesQueryKey: string = "paymentModes";
export const affiliationLogoQueryKey: string = "affiliationLogo";
export const feesQueryKey: string = "fees";
export const feeQueryKey: string = "fee";
export const affiliationCardLayoutQueryKey: string = "affiliationCardLayout";
export const termsAndConditionsQueryKey: string = "termsAndConditions";
export const qrBillQrCodeQueryKey: string = "qrBillQrCode";
export const paymentSlipQueryKey: string = "paymentSlip";
export const creditCardsQueryKey: string = "creditCards";
export const creditCardQueryKey: string = "creditCard";
export const refundInfoQueryKey: string = "refundInfo";
export const userInfoQueryKey: string = "userInfo";
export const legalTermsQueryKey: string = "legalTerms";
export const externalSitesQueryKey: string = "externalSites";
export const externalSiteImageQueryKey: string = "externalSiteImage";
export const updateAppInfoQueryKey: string = "updateAppInfo";

export const refreshTokenMutationKey: string = "refreshToken";

export function invalidateAccounts(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([accountsQueryKey], filters);
}

export function invalidateAccount(
  _: IAccountKey,
  filters?: InvalidateQueryFilters
): Promise<void> {
  return invalidateAccounts(filters);
}

export function invalidateTransactions(
  accountKey?: IAccountKey,
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([transactionsQueryKey], filters);
}

export function invalidateBeneficiaries(
  affiliationId?: number,
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries(
    [beneficiariesQueryKey, affiliationId],
    filters
  );
}

export function invalidateBeneficiary(
  beneficiaryKey: IBeneficiaryKey,
  filters?: InvalidateQueryFilters
) {
  return queryClient.invalidateQueries(
    [beneficiaryQueryKey, beneficiaryKey],
    filters
  );
}

export function invalidateAllBeneficiaries(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([allBeneficiariesQueryKey], filters);
}

export function invalidatePaymentModes(
  accountKey?: IAccountKey,
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries(
    [paymentModesQueryKey, accountKey?.affiliationId],
    filters
  );
}

export function invalidateProfile(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([profileQueryKey], filters);
}

export function invalidateIdentifiers(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([identifiersQueryKey], filters);
}

export function invalidateAffiliationCardLayout(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries(
    [affiliationCardLayoutQueryKey],
    filters
  );
}

export function invalidateCreditCards(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([creditCardsQueryKey], filters);
}

export function invalidateRefundInfo(
  personKey?: IPersonKey,
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries(
    [refundInfoQueryKey, personKey],
    filters
  );
}

export function invalidateUserInfo(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([userInfoQueryKey], filters);
}

export function invalidateLegalTerms(
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries([legalTermsQueryKey], filters);
}

export function invalidateExternalSites(
  affiliationId?: number,
  filters?: InvalidateQueryFilters
): Promise<void> {
  return queryClient.invalidateQueries(
    [externalSitesQueryKey, affiliationId],
    filters
  );
}

export function forceUpdateQueries() {
  const queriesData = queryClient.getQueriesData({ predicate: () => true });
  queriesData.forEach((qd) => {
    queryClient.setQueryData(qd[0], qd[1]);
  });
}
