import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { StackActions } from "@react-navigation/native";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { Card, Text } from "react-native-elements";
import { isManagedException } from "../errors/ApplicationBaseError";
import { UserStackParamsList } from "../navigation/UserStackNavigation";
import { useAuth } from "../providers/AuthenticationProvider";
import { useValidateEmail } from "../services/IdentifiersService";
import { toastError, toastSuccess } from "../services/ToastService";
import { containerStyle } from "../styles/Containers";
import { colors, cornerRadius, spacings, typographies } from "../styles/Styles";

export default function ValidateEmailScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route = useRoute<RouteProp<UserStackParamsList, "validateEmail">>();
  const { grantAccess } = useAuth();

  const validateEmailMutation = useValidateEmail();

  const { userId, identifierId, token } = route.params ?? {
    userId: 0,
    identifierId: 0,
    token: ""
  };

  useEffect(() => {
    validateEmail();
  }, []);

  async function validateEmail() {
    try {
      await validateEmailMutation.mutateAsync({ userId, identifierId, token });

      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "ValidateEmailScreenSuccess",
          "Great! You've successfully validated your email"
        )
      );
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      switch (e?.status) {
        case 401:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "ValidateEmailScreenLinkNotValid",
              "The link to validate this email is not valid."
            )
          );
          break;

        case 404:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "ValidateEmailScreenNoUserFound",
              "We couldn't find your user, please create an account."
            )
          );
          break;

        case 498:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "ValidateEmailScreenLinkExpired",
              "The link to validate this email has expired."
            )
          );
          break;

        case 499:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "ValidateEmailScreenEmailLinkExpired",
              "Email validation link expired."
            )
          );
          break;

        default:
          toastError(
            t("AlertErrorTitle", "Error"),
            t("ValidateEmailScreenUnknownError", "Internal server error")
          );
          break;
      }
    } finally {
      navigation.dispatch(StackActions.replace(grantAccess ? "root" : "auth"));
    }
  }

  return (
    <View style={containerStyle.containerCenter}>
      <Card
        containerStyle={{
          borderRadius: cornerRadius.md,
          padding: spacings.xxxl
        }}>
        <Card.Image
          containerStyle={{ alignSelf: "center" }}
          source={require("../assets/img/icon.png")}
          style={{
            width: 100,
            height: 100,
            justifyContent: "center"
          }}></Card.Image>
        <Card.Title style={typographies.body}>
          {t("ValidateEmailScreenValidatingEmail", "Validating email")}
        </Card.Title>
        <Text
          style={[
            typographies.body,
            { textAlign: "center", marginBottom: spacings.lg }
          ]}>
          {t(
            "ValidateEmailScreenWaitValidation",
            "Please, wait until the validation is complete."
          )}
        </Text>
        <ActivityIndicator
          size="large"
          color={colors.primary}></ActivityIndicator>
      </Card>
    </View>
  );
}
