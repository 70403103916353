import { faPaperPlane } from "@fortawesome/pro-light-svg-icons/faPaperPlane";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import { AuthenticationStackParamsList } from "../navigation/AuthenticationStackNavigation";
import { fontSizes } from "../styles/Fonts";
import { colors } from "../styles/Styles";

export default function ForgotPasswordResultScreen() {
  const route =
    useRoute<
      RouteProp<AuthenticationStackParamsList, "forgotPasswordResult">
    >();
  const navigation =
    useNavigation<StackNavigationProp<AuthenticationStackParamsList>>();
  const { t } = useTranslation();
  const isSuccess = route.params.type == ResultType.Success;

  return (
    <ActionResultComponent
      icon={isSuccess ? faPaperPlane : undefined}
      iconStyle={isSuccess && { color: colors.text.primary }}
      title={
        isSuccess
          ? t("ForgotPasswordResultScreenSuccessTitle", "Password reset")
          : t("ForgotPasswordResultScreenErrorTitle", "Oops...")
      }
      titleStyle={{ fontSize: fontSizes.lg }}
      description={route.params.description}
      descriptionStyle={{ fontSize: fontSizes.sm }}
      resultType={route.params.type}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
      buttonTitle={t("ForgotPasswordResultScreen", "Go to login")}
      buttonCallback={() =>
        navigation.navigate("login")
      }></ActionResultComponent>
  );
}
