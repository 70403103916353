import { useState } from "react";
import { Platform, StyleSheet } from "react-native";
import * as DropdownMenu from "zeego/dropdown-menu";
import {
  MenuCheckboxItemProps,
  MenuContentProps,
  MenuItemTitleProps,
  MenuSubContentProps
} from "zeego/lib/typescript/menu";
import { colors } from "../styles/Colors";
import { cornerRadius, spacings } from "../styles/Constants";
import { elevations } from "../styles/Elevations";

type ItemProps = React.ComponentProps<(typeof DropdownMenu)["Item"]>;
type SubTriggerProps = React.ComponentProps<
  (typeof DropdownMenu)["SubTrigger"]
>;

export default function DropdownMenuComponent() {
  const DropdownMenuRoot = DropdownMenu.Root;
  const DropdownMenuTrigger = DropdownMenu.Trigger;

  const DropdownMenuContent = DropdownMenu.create((props: MenuContentProps) => {
    return (
      <DropdownMenu.Content
        align="end"
        {...props}
        style={[
          styles.content,
          props.style,
          Platform.OS === "web" && elevations.sm
        ]}></DropdownMenu.Content>
    );
  }, "Content");

  const DropdownMenuSub = DropdownMenu.Sub;

  const DropdownMenuSubContent = DropdownMenu.create(
    (props: MenuSubContentProps) => {
      return (
        <DropdownMenu.SubContent
          {...props}
          style={[
            styles.content,
            props.style,
            Platform.OS === "web" && elevations.sm
          ]}></DropdownMenu.SubContent>
      );
    },
    "SubContent"
  );

  const DropdownMenuSubTrigger = DropdownMenu.create(
    (props: SubTriggerProps) => {
      const [isFocused, setIsFocused] = useState(false);

      return (
        <DropdownMenu.SubTrigger
          {...props}
          style={[
            styles.subTrigger,
            props.style,
            isFocused && styles.itemFocused
          ]}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}></DropdownMenu.SubTrigger>
      );
    },
    "SubTrigger"
  );

  const DropdownMenuItem = DropdownMenu.create((props: ItemProps) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <DropdownMenu.Item
        {...props}
        style={[styles.item, props.style, isFocused && styles.itemFocused]}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}></DropdownMenu.Item>
    );
  }, "Item");

  const DropdownMenuItemTitle = DropdownMenu.create(
    (props: MenuItemTitleProps) => {
      return (
        <DropdownMenu.ItemTitle
          {...props}
          style={[styles.itemTitle, props.style]}></DropdownMenu.ItemTitle>
      );
    },
    "ItemTitle"
  );

  const DropdownMenuCheckboxItem = DropdownMenu.create(
    (props: MenuCheckboxItemProps) => {
      const [isFocused, setIsFocused] = useState(false);

      return (
        <DropdownMenu.CheckboxItem
          {...props}
          style={[styles.item, props.style, isFocused && styles.itemFocused]}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}></DropdownMenu.CheckboxItem>
      );
    },
    "CheckboxItem"
  );

  return {
    DropdownMenuRoot,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuItemTitle,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
    DropdownMenuCheckboxItem
  };
}

const styles = StyleSheet.create({
  content: {
    minWidth: 200,
    backgroundColor: colors.background.light,
    padding: spacings.md,
    borderRadius: cornerRadius.md
  },
  item: {
    padding: spacings.sm
  },
  itemTitle: {
    flex: 1
  },
  itemFocused: {
    borderRadius: cornerRadius.md,
    backgroundColor: colors.background.hover
  },
  subTrigger: {
    flexDirection: "row",
    padding: spacings.sm
  }
});
