import { Asset } from "expo-asset";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import WebViewComponent from "../components/WebViewComponent";
import i18n from "../providers/i18n";
import { colors, containerStyle } from "../styles/Styles";

export default function PrivacyPolicyScreen() {
  const [path, setPath] = useState("");

  useEffect(() => {
    async function loadFileAsync() {
      const languageCode = i18n.language;
      const assetsFiles = Asset.fromModule(
        languageCode.includes("fr")
          ? require("../assets/files/PrivacyPolicy-fr.pdf")
          : languageCode.includes("de")
          ? require("../assets/files/PrivacyPolicy-de.pdf")
          : require("../assets/files/PrivacyPolicy-en.pdf")
      );

      let fileData = assetsFiles.uri;

      if (Platform.OS === "android") {
        fileData = (await assetsFiles.downloadAsync()).localUri!;
      }

      setPath(fileData);
    }

    loadFileAsync();
  }, []);

  if (!path) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return <WebViewComponent path={path}></WebViewComponent>;
}
