import React from "react";
import { ActivityIndicator, View } from "react-native";
import { colors, containerStyle } from "../styles/Styles";

export default function LoadingScreen() {
  return (
    <View style={containerStyle.containerCenter}>
      <ActivityIndicator
        color={colors.primary}
        size="large"></ActivityIndicator>
    </View>
  );
}
