import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import { RootStackParamsList } from "../navigation";

export default function EnrollPersonalDataResultScreen() {
  const route =
    useRoute<RouteProp<RootStackParamsList, "enrollPersonalDataResult">>();
  const navigation =
    useNavigation<
      StackNavigationProp<RootStackParamsList, "enrollPersonalDataResult">
    >();
  const homeNavigation = useResetHomeNavigation();
  const { t } = useTranslation();
  const isSuccess = route.params.type === ResultType.Success;

  return (
    <ActionResultComponent
      title={
        isSuccess
          ? t(
              "EnrollPersonalDataResultScreenSuccessTitle",
              "Registration successful"
            )
          : t("EnrollPersonalDataResultScreenErrorTitle", "Oops...")
      }
      description={route.params.description}
      resultType={route.params.type}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
      buttonTitle={
        !route.params.personId || !route.params.selfActivationAllowed
          ? t("EnrollPersonalDataResultScreenButtonHomeTitle", "Go home")
          : t(
              "EnrollPersonalDataResultScreenButtonActivationTitle",
              "Go to activation"
            )
      }
      buttonCallback={
        !route.params.personId || !route.params.selfActivationAllowed
          ? () => homeNavigation.reset()
          : () =>
              navigation.replace("activationSelectAffiliationStack", {
                screen: "selectAffiliationActivation",
                params: {
                  identifierId: route.params.identifierId!,
                  affiliationId: route.params.affiliationId!,
                  personId: route.params.personId!,
                  skipVisible: true
                }
              })
      }></ActionResultComponent>
  );
}
