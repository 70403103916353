import { faEye } from "@fortawesome/pro-light-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/pro-light-svg-icons/faEyeSlash";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import { Pressable } from "react-native";
import { colors, iconSizes, pressableStyle } from "../styles/Styles";

export default function PasswordToggleEyeComponent({
  isSecure,
  onPress
}: {
  isSecure: boolean;
  onPress: () => void;
}) {
  return (
    <Pressable
      style={({ pressed }) => pressed && pressableStyle.pressed}
      onPress={onPress}>
      <FontAwesomeIcon
        icon={isSecure ? faEye : faEyeSlash}
        color={colors.primary50}
        size={iconSizes.lg}></FontAwesomeIcon>
    </Pressable>
  );
}
