import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { orderBy, uniqBy } from "lodash";
import { fetchAccounts } from "./AccountsService";
import { IAffiliationKey } from "./AffiliationsService";
import {
  Beneficiary,
  IAffiliation,
  IBeneficiary
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";
import {
  allBeneficiariesQueryKey,
  beneficiariesQueryKey,
  beneficiaryQueryKey,
  invalidateAllBeneficiaries,
  invalidateBeneficiaries,
  invalidateBeneficiary
} from "./QueryService";

export type BeneficiaryDetails = {
  affiliationKey: IAffiliationKey;
  affiliation: IAffiliation;
  beneficiary: IBeneficiary;
};

export interface IBeneficiaryKey extends IAffiliationKey {
  beneficiaryId: number;
}

export function useBeneficiaries(affiliationKey: IAffiliationKey) {
  return useQuery([beneficiariesQueryKey, affiliationKey.affiliationId], () =>
    getBeneficiaries(affiliationKey)
  );
}

export function useDeleteBeneficiary() {
  return useMutation(
    ({ beneficiaryKey }: { beneficiaryKey: IBeneficiaryKey }) =>
      deleteBeneficiary(beneficiaryKey)
  );
}

export function useAllBeneficiaries() {
  return useQuery([allBeneficiariesQueryKey], getAllBeneficiaries);
}

export function useBeneficiary(
  beneficiaryKey: IBeneficiaryKey,
  options?: UseQueryOptions<
    IBeneficiary,
    unknown,
    IBeneficiary,
    (string | IBeneficiaryKey)[]
  >
) {
  return useQuery(
    [beneficiaryQueryKey, beneficiaryKey],
    () => getBeneficiary(beneficiaryKey),
    {
      ...options
    }
  );
}

export function useAddBeneficiary() {
  return useMutation(
    ({
      affiliationKey,
      beneficiary
    }: {
      affiliationKey: IAffiliationKey;
      beneficiary: IBeneficiary;
    }) => addBeneficiary(affiliationKey, beneficiary)
  );
}

export function useUpdateBeneficiary() {
  return useMutation(
    ({
      beneficiaryKey,
      beneficiary
    }: {
      beneficiaryKey: IBeneficiaryKey;
      beneficiary: IBeneficiary;
    }) => updateBeneficiary(beneficiaryKey, beneficiary)
  );
}

async function getBeneficiaries(
  affiliationKey: IAffiliationKey
): Promise<IBeneficiary[]> {
  const platformClient = platformClientFactory.create();
  let beneficiaries = await platformClient.getRegisteredBeneficiaries(
    affiliationKey.identifierId,
    affiliationKey.affiliationId
  );
  beneficiaries = orderBy(
    beneficiaries,
    [(b) => b.name, (b) => b.value],
    ["asc", "asc"]
  );

  return beneficiaries;
}

async function deleteBeneficiary(beneficiaryKey: IBeneficiaryKey) {
  const platformClient = platformClientFactory.create();

  await platformClient.deleteBeneficiary(
    beneficiaryKey.identifierId,
    beneficiaryKey.affiliationId,
    beneficiaryKey.beneficiaryId
  );

  await Promise.all([
    invalidateBeneficiaries(beneficiaryKey.affiliationId, {
      refetchType: "all"
    }),
    invalidateAllBeneficiaries({ refetchType: "all" })
  ]);
}

async function getAllBeneficiaries(): Promise<BeneficiaryDetails[]> {
  let accounts = await fetchAccounts();
  const platformClient = platformClientFactory.create();
  const beneficiariesDetails: BeneficiaryDetails[] = [];

  accounts = uniqBy(accounts, (a) => a.affiliation.affiliationId);

  await Promise.all(
    accounts.map(async (accountDetail) => {
      const affiliationKey: IAffiliationKey = {
        affiliationId: accountDetail.affiliation.affiliationId,
        identifierId: accountDetail.identifier.identifierId
      };
      const beneficiaries = await platformClient.getRegisteredBeneficiaries(
        accountDetail.identifier.identifierId,
        accountDetail.affiliation.affiliationId
      );
      beneficiaries.map((b) => {
        beneficiariesDetails.push({
          affiliationKey: affiliationKey,
          affiliation: accountDetail.affiliation,
          beneficiary: b
        });
      });
    })
  );

  return beneficiariesDetails;
}

async function getBeneficiary(
  beneficiaryKey: IBeneficiaryKey
): Promise<IBeneficiary> {
  const platformClient = platformClientFactory.create();
  const beneficiary = await platformClient.getRegisteredBeneficiary(
    beneficiaryKey.identifierId,
    beneficiaryKey.affiliationId,
    beneficiaryKey.beneficiaryId
  );

  return beneficiary;
}

async function addBeneficiary(
  affiliationKey: IAffiliationKey,
  beneficiary: IBeneficiary
) {
  const platformClient = platformClientFactory.create();
  await platformClient.addBeneficiary(
    affiliationKey.identifierId,
    affiliationKey.affiliationId,
    new Beneficiary(beneficiary)
  );

  await Promise.all([
    invalidateBeneficiaries(affiliationKey.affiliationId, {
      refetchType: "all"
    }),
    invalidateAllBeneficiaries({ refetchType: "all" })
  ]);
}

async function updateBeneficiary(
  beneficiaryKey: IBeneficiaryKey,
  beneficiary: IBeneficiary
) {
  const platformClient = platformClientFactory.create();
  await platformClient.updateBeneficiary(
    beneficiaryKey.identifierId,
    beneficiaryKey.affiliationId,
    beneficiaryKey.beneficiaryId,
    new Beneficiary(beneficiary)
  );

  await Promise.all([
    invalidateBeneficiaries(beneficiaryKey.affiliationId, {
      refetchType: "all"
    }),
    invalidateAllBeneficiaries({ refetchType: "all" }),
    invalidateBeneficiary(beneficiaryKey, { refetchType: "all" })
  ]);
}
