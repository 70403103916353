import * as FileSystem from "expo-file-system";
import { shareAsync } from "expo-sharing";
import { Platform } from "react-native";

export function getDataUrl(blob: Blob): Promise<string | null> {
  return new Promise((resolve, _) => {
    const temporaryFileReader = new FileReader();
    temporaryFileReader.onloadend = () => {
      resolve(temporaryFileReader.result as string | null);
    };
    temporaryFileReader.readAsDataURL(blob);
  });
}

// NSwag bug tracking the problem of the client with the blob content type https://github.com/RicoSuter/NSwag/issues/3950 (Change was made on NSwag v13.14.5)

export async function downloadFile(
  title: string,
  blob: Blob,
  mimeType: string
): Promise<void> {
  if (Platform.OS === "web") {
    await downloadFileWeb(title, blob);
  } else {
    await downloadFileMobile(title, blob, mimeType);
  }
}

async function downloadFileWeb(title: string, blob: Blob): Promise<void> {
  const url = await getDataUrl(blob);
  if (!url) {
    return;
  }

  const anchor = document.createElement("a");
  anchor.style.display = "none";
  anchor.href = url;
  anchor.download = title;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

async function downloadFileMobile(
  title: string,
  blob: Blob,
  mimeType: string
): Promise<void> {
  return new Promise(async (resolve, reject) => {
    let reader = new FileReader();
    reader.onload = async () => {
      const fileUri = `${FileSystem.documentDirectory}/${title}`;
      const url = reader.result as string;

      try {
        await FileSystem.writeAsStringAsync(fileUri, url.split(",")[1], {
          encoding: FileSystem.EncodingType.Base64
        });
        resolve(
          await shareAsync(fileUri, { mimeType: mimeType, UTI: "public.item" })
        );
      } catch (e) {
        reject(e);
      }
    };

    reader.readAsDataURL(blob);
  });
}
