import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { default as React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { Text } from "react-native-elements";
import AccountListItemComponent from "../components/AccountListItemComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import {
  ContactAffiliationScreenProps,
  ContactSelectAffiliationStackParamsList
} from "../navigation/ProfileStackNavigation";
import { fetchAccounts, useAccounts } from "../services/AccountsService";
import {
  colors,
  containerStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function SelectAccountContactScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<
      StackNavigationProp<ContactSelectAffiliationStackParamsList>
    >();
  const accountsQuery = useAccounts();

  useEffect(() => {
    async function skipScreen() {
      const accounts = await fetchAccounts();
      if (!navigation.isFocused()) {
        return;
      }

      if (accounts.length === 0) {
        navigation.replace("contact");
      }

      if (accounts.length === 1) {
        const accountDetail = accounts[0];

        if (accountDetail.person && accountDetail.account) {
          const params: ContactAffiliationScreenProps = {
            affiliationId: accountDetail.affiliation.affiliationId
          };

          navigation.replace("contact", params);
        }
      }
    }

    skipScreen();
  }, []);

  if (accountsQuery.isInitialLoading) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.sm,
            marginBottom: spacings.md,
            textAlign: "center"
          }
        ]}>
        {t(
          "SelectAccountContactScreenSelectAccount",
          "Select the account to contact or skip to the next step"
        )}
      </Text>
      {accountsQuery.data?.map((item, index) => {
        return (
          <AccountListItemComponent
            key={index}
            account={item}
            disabled={false}
            onPress={() => {
              const params: ContactAffiliationScreenProps = {
                affiliationId: item.affiliation.affiliationId
              };
              navigation.navigate("contact", params);
            }}></AccountListItemComponent>
        );
      })}
    </ScrollViewComponent>
  );
}
