import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import differenceInDays from "date-fns/differenceInDays";
import isFuture from "date-fns/isFuture";
import startOfToday from "date-fns/startOfToday";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Button, Card, ListItem, Text } from "react-native-elements";
import AccountListItemComponent from "../components/AccountListItemComponent";
import { ResultType } from "../components/ActionResultComponent";
import HyperlinkedTextComponent from "../components/HyperlinkedTextComponent";
import { RefundAccountBalanceConfirmationSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { RootStackParamsList } from "../navigation";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";
import { ContactAffiliationScreenProps } from "../navigation/ProfileStackNavigation";
import {
  IAccountKey,
  IPersonKey,
  useAccount
} from "../services/AccountsService";
import { useRefund, useRefundInfo } from "../services/CreditCardsService";
import { humanizeCurrency } from "../services/HumanizerService";
import { AffiliationStatus } from "../services/clients/PlatformClient";
import {
  colors,
  cornerRadius,
  iconSizes,
  largePrimaryRoundedButtonStyle,
  mediumCardStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function RefundAccountBalanceConfirmationScreen() {
  const { t } = useTranslation();
  const route =
    useRoute<RouteProp<HomeStackParamsList, "refundAccountBalance">>();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<HomeStackParamsList, "refundAccountBalance">,
        StackNavigationProp<RootStackParamsList>
      >
    >();

  const { identifierId, affiliationId, personId, accountId } = route.params;

  const personKey: IPersonKey = {
    identifierId,
    affiliationId,
    personId
  };
  const accountKey: IAccountKey = {
    ...personKey,
    accountId
  };

  const refundMutation = useRefund();
  const accountQuery = useAccount(accountKey);
  const refundInfoQuery = useRefundInfo(personKey);

  const balanceExceedRefundAmount = useMemo(() => {
    const balance = accountQuery.data?.account?.balance ?? 0;
    const maxRefundAmount =
      refundInfoQuery.data?.maxRefundAmount ?? Number.POSITIVE_INFINITY;
    return balance > maxRefundAmount;
  }, [accountQuery.data, refundInfoQuery.data]);

  const daysUntilRefund = useMemo(() => {
    if (
      !refundInfoQuery.data ||
      !isFuture(refundInfoQuery.data.nextAvailableDate)
    ) {
      return 0;
    }
    return Math.floor(
      differenceInDays(refundInfoQuery.data.nextAvailableDate, startOfToday())
    );
  }, [refundInfoQuery.data]);

  const isRefundAllowed = useMemo(
    () =>
      !balanceExceedRefundAmount &&
      daysUntilRefund <= 0 &&
      !!refundInfoQuery.data?.refundableAmount,
    [balanceExceedRefundAmount, daysUntilRefund, refundInfoQuery.data]
  );

  const nonRefundableAmount = useMemo(() => {
    if (!refundInfoQuery.data) return 0;
    return isRefundAllowed
      ? refundInfoQuery.data.pendingAmount
      : refundInfoQuery.data.pendingAmount +
          refundInfoQuery.data.refundableAmount;
  }, [refundInfoQuery.data, isRefundAllowed]);

  const refundableAmount = useMemo(() => {
    return isRefundAllowed ? refundInfoQuery.data?.refundableAmount ?? 0 : 0;
  }, [refundInfoQuery.data, isRefundAllowed]);

  if (refundInfoQuery.isInitialLoading || accountQuery.isInitialLoading) {
    return <RefundAccountBalanceConfirmationSkeleton />;
  }

  async function refundAccountBalance() {
    try {
      const refundResponse = await refundMutation.mutateAsync({
        personKey: accountKey
      });

      if (refundResponse.pendingAmount) {
        navigation.navigate("refundBalanceResult", {
          type: ResultType.Info,
          pendingAmount: humanizeCurrency(
            refundResponse.pendingAmount,
            accountQuery.data?.account?.currency!
          ),
          amountRefunded: humanizeCurrency(
            refundResponse.refundedAmount,
            accountQuery.data?.account?.currency!
          )
        });
      } else {
        navigation.navigate("refundBalanceResult", {
          type: ResultType.Success
        });
      }
    } catch (e: any) {
      navigation.navigate("refundBalanceResult", {
        type: ResultType.Error
      });
    }
  }

  return (
    <ScrollViewComponent>
      <AccountListItemComponent
        account={accountQuery.data!}
        disabled={
          accountQuery.data?.affiliation.status === AffiliationStatus.Offline
        }
      />

      <Text
        style={[
          typographies.title,
          {
            textAlign: "left",
            marginTop: spacings.md,
            marginBottom: spacings.sm
          }
        ]}>
        {t("RefundAccountBalanceConfirmationScreenRefundTitle", "Refund")}
      </Text>

      <Card
        containerStyle={[
          mediumCardStyle.container,
          {
            padding: spacings.xl,
            justifyContent: "center",
            alignItems: "center"
          }
        ]}>
        {!!refundableAmount && (
          <>
            <Text style={[typographies.small, { textAlign: "center" }]}>
              {t(
                "RefundAccountBalanceConfirmationScreenRefundableAmount",
                "Refundable amount"
              )}
            </Text>
            <Text
              style={[
                typographies.heading,
                { textAlign: "center", color: colors.success }
              ]}>
              {humanizeCurrency(
                refundableAmount,
                accountQuery.data?.account?.currency!
              )}
            </Text>
          </>
        )}
        {!!refundableAmount && !!nonRefundableAmount && (
          <View style={{ height: spacings.md }}></View>
        )}
        {!!nonRefundableAmount && (
          <>
            <Text style={[typographies.small, { textAlign: "center" }]}>
              {t(
                "RefundAccountBalanceConfirmationScreenNonrefundableAmount",
                "Nonrefundable amount"
              )}
            </Text>

            <Text
              style={[
                typographies.heading,
                { textAlign: "center", color: colors.danger }
              ]}>
              {humanizeCurrency(
                nonRefundableAmount,
                accountQuery.data?.account?.currency!
              )}
            </Text>
          </>
        )}
      </Card>

      <Text
        style={[
          typographies.title,
          {
            textAlign: "left",
            marginTop: spacings.xl,
            marginBottom: spacings.sm
          }
        ]}>
        {t(
          "RefundAccountBalanceConfirmationScreenRestrictions",
          "Restrictions"
        )}
      </Text>

      <View
        style={{
          overflow: "hidden",
          borderRadius: cornerRadius.md,
          marginBottom: spacings.md
        }}>
        {!!!refundableAmount && (
          <ListItem
            style={{
              marginBottom: spacings.xxs
            }}
            containerStyle={{
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: spacings.lg,
              paddingRight: spacings.xl
            }}>
            <HyperlinkedTextComponent
              containerStyle={{
                flex: 1
              }}
              firstText={t(
                "RefundAccountBalanceConfirmationScreenUnavailableRefundAmount",
                "Your account balance is not eligible for a refund. To get a refund of your account balance. Please",
                {
                  maxRefundAmount: humanizeCurrency(
                    refundInfoQuery.data?.maxRefundAmount!,
                    accountQuery.data?.account?.currency!
                  )
                }
              )}
              firstHyperlinkText={t(
                "RefundAccountBalanceConfirmationScreenAffiliationLink",
                "get in touch with your affiliation."
              )}
              onFirstHyperlinkCallback={() => {
                const params: ContactAffiliationScreenProps = {
                  identifierId: accountKey.identifierId,
                  affiliationId: accountKey.affiliationId,
                  personId: personKey.personId,
                  accountId: accountKey.accountId
                };
                navigation.navigate("root", {
                  screen: "profileStack",
                  params: {
                    screen: "helpCenterProfileStack",
                    initial: false,
                    params: {
                      screen: "contactSelectAffiliation",
                      initial: false,
                      params: {
                        screen: "contact",
                        initial: false,
                        params: params
                      }
                    }
                  }
                });
              }}
              textStyle={{
                ...typographies.small,
                textAlign: "left"
              }}
              linkTextStyle={{
                ...typographies.small,
                color: colors.text.link
              }}
            />
            <FontAwesomeIcon
              style={{
                color: colors.danger
              }}
              icon={faExclamationCircle}
              size={iconSizes.lg}></FontAwesomeIcon>
          </ListItem>
        )}

        {balanceExceedRefundAmount && (
          <ListItem
            style={{
              marginBottom: spacings.xxs
            }}
            containerStyle={{
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: spacings.lg,
              paddingRight: spacings.xl
            }}>
            <Text style={[typographies.small, { flex: 1 }]}>
              {t(
                "RefundAccountBalanceConfirmationScreenBalanceExceedsMaxAmount",
                "You account balance exceeds the limit of {{maxRefundAmount}}.",
                {
                  maxRefundAmount: humanizeCurrency(
                    refundInfoQuery.data?.maxRefundAmount!,
                    accountQuery.data?.account?.currency!
                  )
                }
              )}
            </Text>
            <FontAwesomeIcon
              style={{
                color: colors.danger
              }}
              icon={faExclamationCircle}
              size={iconSizes.lg}></FontAwesomeIcon>
          </ListItem>
        )}

        {daysUntilRefund > 0 && (
          <ListItem
            style={{
              marginBottom: spacings.xxs
            }}
            containerStyle={{
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: spacings.lg,
              paddingRight: spacings.xl
            }}>
            <Text style={[typographies.small, { flex: 1 }]}>
              {t(
                "RefundAccountBalanceConfirmationScreenBalanceDateNotAvailable",
                "There are {{count}} days left to be eligible for a refund.",
                {
                  count: daysUntilRefund
                }
              )}
            </Text>
            <FontAwesomeIcon
              style={{
                color: colors.danger
              }}
              icon={faExclamationCircle}
              size={iconSizes.lg}></FontAwesomeIcon>
          </ListItem>
        )}

        {isRefundAllowed && !!nonRefundableAmount && (
          <ListItem
            style={{
              marginBottom: spacings.xxs
            }}
            containerStyle={{
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: spacings.lg,
              paddingRight: spacings.xl
            }}>
            <Text style={[typographies.small, { flex: 1 }]}>
              {t(
                "RefundAccountBalanceConfirmationScreenPartOfYourMoneyNotRefunded",
                "Part of your money cannot be refunded, please contact your affiliation."
              )}
            </Text>
            <FontAwesomeIcon
              style={{
                color: colors.warning
              }}
              icon={faExclamationCircle}
              size={iconSizes.lg}></FontAwesomeIcon>
          </ListItem>
        )}

        {isRefundAllowed && (
          <ListItem
            style={{
              marginBottom: spacings.xxs
            }}
            containerStyle={{
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: spacings.lg,
              paddingRight: spacings.xl
            }}>
            <Text style={[typographies.small, { flex: 1 }]}>
              {t(
                "RefundAccountBalanceConfirmationScreenAdvertisementSentLastPayment",
                "The refundable balance will be sent to the last payment methods used."
              )}
            </Text>
            <FontAwesomeIcon
              style={{
                color: colors.warning
              }}
              icon={faExclamationCircle}
              size={iconSizes.lg}></FontAwesomeIcon>
          </ListItem>
        )}

        {isRefundAllowed && !!refundInfoQuery.data?.daysBetweenRefunds && (
          <ListItem
            style={{
              marginBottom: spacings.xxs
            }}
            containerStyle={{
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: spacings.lg,
              paddingRight: spacings.xl
            }}>
            <Text style={[typographies.small, { flex: 1 }]}>
              {t(
                "RefundAccountBalanceConfirmationScreenWarningDaysBetweenRefunds",
                "You can only make a refund every {{count}} days.",
                {
                  count: refundInfoQuery.data?.daysBetweenRefunds
                }
              )}
            </Text>
            <FontAwesomeIcon
              style={{
                color: colors.warning
              }}
              icon={faExclamationCircle}
              size={iconSizes.lg}></FontAwesomeIcon>
          </ListItem>
        )}
      </View>

      <HyperlinkedTextComponent
        containerStyle={{
          marginTop: spacings.lg,
          marginRight: spacings.none,
          marginLeft: spacings.sm
        }}
        firstText={t(
          "RefundAccountBalanceConfirmationScreenAgreementTextRefund",
          "By pressing Refund, you accept the"
        )}
        firstHyperlinkText={t(
          "RefundAccountBalanceConfirmationScreenTermsConditions",
          "Terms & Conditions"
        )}
        onFirstHyperlinkCallback={() =>
          navigation.navigate("termsAndConditions", {
            identifierId: accountKey.identifierId,
            affiliationId: accountKey.affiliationId
          })
        }
        secondText={t(
          "RefundAccountBalanceConfirmationScreenComponentOfLabel",
          "of {{affiliationName}}",
          {
            affiliationName: accountQuery.data?.affiliation.name
          }
        )}
      />

      <Button
        title={t(
          "RefundAccountBalanceConfirmationScreenRefundButton",
          "Refund {{amount}}",
          {
            amount: humanizeCurrency(
              refundableAmount,
              accountQuery.data?.account?.currency!
            )
          }
        )}
        loading={refundMutation.isLoading}
        disabled={refundMutation.isLoading || !isRefundAllowed}
        onPress={refundAccountBalance}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.md
          }
        ]}
      />
    </ScrollViewComponent>
  );
}
