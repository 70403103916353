import {
  LinkingOptions,
  PathConfig,
  PathConfigMap
} from "@react-navigation/native";
import Constants from "expo-constants";
import * as Linking from "expo-linking";
import {
  ActivationSelectAffiliationStackParamsList,
  EnrollIdentifiersStackParamsList,
  EnrollSelectAffiliationStackParamsList,
  HelpCenterStackParamsList,
  RootStackParamsList
} from ".";
import { AuthenticationStackParamsList } from "./AuthenticationStackNavigation";
import { BottomTabNavigatorParamsList } from "./BottomTabMenuNavigation";
import {
  HomeStackParamsList,
  TransactionsStackParamsList
} from "./HomeStackNavigation";
import {
  LoadAmountSelectionStackParamsList,
  LoadSelectAccountStackParamsList,
  LoadStackParamsList
} from "./LoadStackNavigation";
import {
  BeneficiariesStackParamsList,
  BeneficiarySelectAffiliationStackParamsList,
  ContactSelectAffiliationStackParamsList,
  CreditCardsStackParamsList,
  HelpCenterProfileStackParamsList,
  IdentifiersStackParamsList,
  ProfileStackParamsList,
  SecurityStackParamsList
} from "./ProfileStackNavigation";
import {
  SendSelectAccountStackParamsList,
  SendStackParamsList
} from "./SendStackNavigation";
import { DrawerNavigatorParamsList } from "./SidebarMenuNavigation";
import { UserStackParamsList } from "./UserStackNavigation";

const transactionsStack: PathConfig<TransactionsStackParamsList> = {
  initialRouteName: "transactions",
  screens: {
    transactions: "transactions",
    transactionDetail: {
      path: "transactions/:transactionId",
      parse: {
        transactionId: Number,
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number
      }
    },
    exportTransactions: "transactions/export"
  }
};

const homeStack: PathConfig<HomeStackParamsList> = {
  initialRouteName: "home",
  screens: {
    home: "home",
    accountDetails: {
      path: "accounts/:accountId",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number
      }
    },
    requestDeleteAccount: {
      path: "accounts/:accountId/delete",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number
      }
    },
    deleteAccountConfirmation: {
      path: "account/delete",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        token: String
      }
    },
    refundAccountBalance: {
      path: "account/identifiers/:identifierId/affiliations/:affiliationId/persons/:personId/accounts/:accountId/refund",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number
      }
    },
    transactionsStack: transactionsStack
  }
};

const loadAmountStack: PathConfig<LoadAmountSelectionStackParamsList> = {
  screens: {
    amountSelection: {
      path: "load/accounts/:accountId",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number
      }
    },
    paymentSlipPayment: {
      path: "load/qr-bill",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number,
        paymentSlipId: Number
      }
    }
  }
};
const loadSelectAccountStack: PathConfig<LoadSelectAccountStackParamsList> = {
  initialRouteName: "load",
  screens: {
    load: "load",
    loadAmountStack: loadAmountStack
  }
};

const loadStack: PathConfig<LoadStackParamsList> = {
  initialRouteName: "selectAccountStack",
  screens: {
    selectAccountStack: loadSelectAccountStack
  }
};

const sendSelectAccountStack: PathConfig<SendSelectAccountStackParamsList> = {
  initialRouteName: "send",
  screens: {
    send: "transfer",
    amountSelection: {
      path: "transfer/accounts/:accountId",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number
      }
    }
  }
};

const sendStack: PathConfig<SendStackParamsList> = {
  initialRouteName: "selectAccountStack",
  screens: {
    selectAccountStack: sendSelectAccountStack
  }
};

const identifiersStack: PathConfig<IdentifiersStackParamsList> = {
  initialRouteName: "identifiers",
  screens: {
    identifiers: "profile/identifiers",
    addIdentifier: "profile/identifiers/add",
    identifierDetail: {
      path: "profile/identifiers/:identifierId",
      parse: {
        identifierId: Number
      }
    }
  }
};

const creditCardsStack: PathConfig<CreditCardsStackParamsList> = {
  initialRouteName: "creditCards",
  screens: {
    creditCards: "profile/credit-cards",
    creditCard: {
      path: "profile/credit-cards/:creditCardId",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        personId: Number,
        accountId: Number,
        creditCardId: Number
      }
    }
  }
};

const beneficiarySelectAffiliationStack: PathConfig<BeneficiarySelectAffiliationStackParamsList> =
  {
    initialRouteName: "selectAffiliationBeneficiary",
    screens: {
      selectAffiliationBeneficiary: "profile/beneficiaries/affiliations",
      addBeneficiary: {
        path: "profile/beneficiaries/add",
        parse: {
          identifierId: Number,
          affiliationId: Number
        }
      }
    }
  };

const beneficiariesStack: PathConfig<BeneficiariesStackParamsList> = {
  initialRouteName: "beneficiaries",
  screens: {
    beneficiaries: "profile/beneficiaries",
    beneficiary: {
      path: "profile/beneficiaries/:beneficiaryId",
      parse: {
        identifierId: Number,
        affiliationId: Number,
        beneficiaryId: Number
      }
    },
    selectAffiliationStack: beneficiarySelectAffiliationStack
  }
};

const securityStack: PathConfig<SecurityStackParamsList> = {
  initialRouteName: "securitySettings",
  screens: {
    securitySettings: "profile/security",
    changePassword: "profile/security/password"
  }
};

const helpCenterStack: PathConfig<HelpCenterStackParamsList> = {
  initialRouteName: "helpCenter",
  screens: {
    helpCenter: "help-center",
    help: "help-center/help",
    contact: "help-center/contact"
  }
};

const contactSelectAffiliationStack: PathConfig<ContactSelectAffiliationStackParamsList> =
  {
    initialRouteName: "selectAccountContact",
    screens: {
      selectAccountContact: "profile/help-center/contact/affiliations",
      contact: {
        path: "profile/help-center/contact",
        parse: {
          identifierId: Number,
          affiliationId: Number,
          personId: Number,
          accountId: Number
        }
      }
    }
  };

const helpCenterProfileStack: PathConfig<HelpCenterProfileStackParamsList> = {
  initialRouteName: "helpCenterProfile",
  screens: {
    helpCenterProfile: "profile/help-center",
    help: "profile/help-center/help",
    contactSelectAffiliation: contactSelectAffiliationStack
  }
};

const profileStack: PathConfig<ProfileStackParamsList> = {
  initialRouteName: "profile",
  screens: {
    profile: "profile",
    personalData: "profile/edit",
    identifiersStack: identifiersStack,
    generalTerms: "profile/general-terms",
    confirmDeleteUser: "profile/delete",
    languageSelection: "profile/language",
    creditCardsStack: creditCardsStack,
    beneficiariesStack: beneficiariesStack,
    securityStack: securityStack,
    helpCenterProfileStack: helpCenterProfileStack
  }
};

const rootStack: PathConfig<
  BottomTabNavigatorParamsList | DrawerNavigatorParamsList
> = {
  screens: {
    homeStack: homeStack,
    loadStack: loadStack,
    sendStack: sendStack,
    profileStack: profileStack
  }
};

const authStack: PathConfig<AuthenticationStackParamsList> = {
  initialRouteName: "login",
  screens: {
    login: "login",
    register: "register",
    forgotPassword: {
      path: "reset-password",
      parse: {
        email: String
      }
    }
  }
};

const userStack: PathConfig<UserStackParamsList> = {
  path: "user",
  screens: {
    validateEmail: {
      path: "register/validate",
      parse: {
        userId: Number,
        identifierId: Number,
        token: String
      }
    },
    resetPassword: {
      path: "reset-password",
      parse: {
        userId: Number,
        token: String
      }
    }
  }
};

const enrollIdentifiersStack: PathConfig<EnrollIdentifiersStackParamsList> = {
  initialRouteName: "identifierNotFound",
  screens: {
    identifierNotFound: {
      path: "affiliations/:affiliationId?/assistance",
      parse: {
        affiliationId: Number
      }
    },
    identifierDetail: {
      path: "identifiers/:identifierId",
      parse: {
        identifierId: Number
      }
    },
    identifiers: "identifiers"
  }
};

const activationSelectAffiliationStack: PathConfig<ActivationSelectAffiliationStackParamsList> =
  {
    screens: {
      selectAffiliationActivation: {
        path: "affiliations/:affiliationId/activate",
        parse: {
          affiliationId: Number,
          identifierId: Number,
          personId: Number
        }
      },
      activationCode: {
        path: "affiliations/:affiliationId/activate/code",
        parse: {
          affiliationId: Number,
          identifierId: Number,
          personId: Number
        }
      }
    }
  };

const enrollSelectAffiliationStack: PathConfig<EnrollSelectAffiliationStackParamsList> =
  {
    initialRouteName: "selectAffiliationToEnroll",
    screens: {
      selectAffiliationToEnroll: "register/affiliations",
      enrollPersonalData: {
        path: "register/affiliations/:affiliationId",
        parse: {
          affiliationId: Number
        }
      },
      enrollIdentifiersStack: enrollIdentifiersStack
    }
  };

const screens: PathConfigMap<RootStackParamsList> = {
  root: rootStack,
  auth: authStack,
  user: userStack,
  publicHelpCenter: helpCenterStack,
  enrollSelectAffiliationStack: enrollSelectAffiliationStack,
  activationSelectAffiliationStack: activationSelectAffiliationStack,
  cardDetails: {
    path: "identifiers/:identifierId/affiliations/:affiliationId/persons/:personId/medium",
    parse: {
      identifierId: Number,
      affiliationId: Number,
      personId: Number
    }
  },
  creditCardPayment: {
    path: "load/credit-card",
    parse: {
      identifierId: Number,
      affiliationId: Number,
      personId: Number,
      accountId: Number,
      transactionId: String,
      brand: String,
      paymentMode: String,
      amount: Number,
      pspStartUrl: String,
      successUrlCallback: String,
      cancelUrlCallback: String,
      errorUrlCallback: String
    }
  },
  loadAmountResult: {
    path: "load/result",
    parse: {
      type: String,
      amount: Number,
      currency: String,
      identifierId: Number,
      affiliationId: Number,
      personId: Number,
      accountId: Number,
      paymentMode: Number
    }
  },
  termsAndConditions: {
    path: "terms-and-conditions/:identifierId?/:affiliationId?",
    parse: {
      identifierId: Number,
      affiliationId: Number
    }
  },
  privacyPolicy: "privacy-policy"
};

export function buildLinkingOptions(
  initialRouteName?: keyof RootStackParamsList
): LinkingOptions<ReactNavigation.RootParamList> {
  const config: {
    initialRouteName?: any;
    screens: PathConfigMap<ReactNavigation.RootParamList>;
  } = {
    initialRouteName: initialRouteName,
    screens: screens
  };

  const options: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: [
      ...Constants.expoConfig?.extra?.linkingPrefixes,
      Linking.createURL("/")
    ],
    config: config
  };

  return options;
}
