import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { t } from "i18next";
import React from "react";
import { View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import { FontAwesomeCustomKitIcon } from "../assets/fonts/FontAwesomeCustomKitIcons";
import {
  PaymentMode,
  PaymentModeDetails
} from "../services/AffiliationsService";
import {
  CreditCardType,
  ICreditCard
} from "../services/clients/PlatformClient";
import { listItemHeights } from "../styles/Constants";
import { colors, iconSizes, spacings, typographies } from "../styles/Styles";
import FontAwesomeCustomKitIconComponent from "./FontAwesomeCustomKitIconComponent";

export function PaymentModesListItemComponent({
  item,
  selected,
  onPress
}: {
  item: PaymentModeDetails;
  selected?: boolean;
  onPress?: () => void;
}) {
  return (
    <ListItem
      containerStyle={{
        height: listItemHeights.lg,
        paddingHorizontal: spacings.xl,
        paddingVertical: spacings.none
      }}
      onPress={() => onPress && onPress()}>
      <ListItem.Content>
        <PaymentModeItemContentComponent item={item} selected={selected} />
      </ListItem.Content>
    </ListItem>
  );
}

export function PaymentModeItemContentComponent({
  item,
  selected
}: {
  item: PaymentModeDetails;
  selected?: boolean;
}) {
  if (item.registeredCreditCard) {
    return (
      <CreditCardItemContentComponent
        item={item.registeredCreditCard}
        selected={selected}
      />
    );
  }

  const { icon, brand } = mapCardBrandIcon(item.paymentMode);
  const color = selected ? colors.text.tertiary : colors.text.primary;

  return (
    <View
      style={{
        alignSelf: "stretch",
        flexDirection: "row",
        alignItems: "center"
      }}>
      <FontAwesomeCustomKitIconComponent
        icon={icon}
        color={color}
        style={{ marginRight: spacings.md }}
        size={iconSizes.xl}
      />

      <View
        style={{
          flex: 1
        }}>
        <Text style={[typographies.body, { color: color }]}>{brand}</Text>
      </View>

      {selected && (
        <FontAwesomeIcon icon={faCheck} color={color} size={iconSizes.xl} />
      )}
    </View>
  );
}

export function CreditCardListItemComponent({
  item,
  selected,
  onPress
}: {
  item: ICreditCard;
  selected?: boolean;
  onPress?: () => void;
}) {
  return (
    <ListItem
      containerStyle={{
        height: listItemHeights.lg,
        paddingHorizontal: spacings.xl,
        paddingVertical: spacings.none
      }}
      onPress={() => onPress && onPress()}>
      <ListItem.Content>
        <CreditCardItemContentComponent item={item} selected={selected} />
      </ListItem.Content>
    </ListItem>
  );
}

export function CreditCardItemContentComponent({
  item,
  selected
}: {
  item: ICreditCard;
  selected?: boolean;
}) {
  const { icon, brand } = mapCardBrandIcon(item.type);
  const color = selected ? colors.text.tertiary : colors.text.primary;

  return (
    <View
      style={{
        alignSelf: "stretch",
        flexDirection: "row",
        alignItems: "center"
      }}>
      <FontAwesomeCustomKitIconComponent
        icon={icon}
        color={color}
        style={{ marginRight: spacings.md }}
        size={iconSizes.xl}
      />

      <View
        style={{
          flex: 1
        }}>
        <Text style={[typographies.body, { color: color }]}>{brand}</Text>

        {item.truncatedPan && (
          <Text style={[typographies.caption, { color: color }]}>
            {item.truncatedPan}
          </Text>
        )}
      </View>

      {selected && (
        <FontAwesomeIcon icon={faCheck} color={color} size={iconSizes.xl} />
      )}
    </View>
  );
}

function mapCardBrandIcon(type?: CreditCardType | PaymentMode) {
  let icon: FontAwesomeCustomKitIcon = FontAwesomeCustomKitIcon.CreditCard;
  let brand: string = t("PaymentMode.NewCreditCard", "New payment mean");

  switch (type) {
    case CreditCardType.MasterCard:
      icon = FontAwesomeCustomKitIcon.MastercardPay;
      brand = t("PaymentMode.MasterCard", "Mastercard");
      break;
    case CreditCardType.AmericanExpress:
      icon = FontAwesomeCustomKitIcon.AmexPay;
      brand = t("PaymentMode.AmericanExpress", "American Express");
      break;
    case CreditCardType.Visa:
      icon = FontAwesomeCustomKitIcon.VisaPay;
      brand = t("PaymentMode.Visa", "Visa");
      break;
    case CreditCardType.PostCard:
      icon = FontAwesomeCustomKitIcon.PostfinanceIconCardPay;
      brand = t("PaymentMode.PostCard", "PostFinance");
      break;
    case CreditCardType.Paypal:
      icon = FontAwesomeCustomKitIcon.PaypalPay;
      brand = t("PaymentMode.Paypal", "Paypal");
      break;
    case CreditCardType.Twint:
      icon = FontAwesomeCustomKitIcon.TwintPay;
      brand = t("PaymentMode.Twint", "Twint");
      break;
    case CreditCardType.Sepa:
      icon = FontAwesomeCustomKitIcon.SepaDirectDebitPay;
      brand = t("PaymentMode.Sepa", "SEPA");
      break;
    case CreditCardType.Jcb:
      icon = FontAwesomeCustomKitIcon.JcbPay;
      brand = t("PaymentMode.JCB", "JCB");
      break;
    case CreditCardType.Klarna:
      icon = FontAwesomeCustomKitIcon.KlarnaPay;
      brand = t("PaymentMode.Klarna", "Klarna");
      break;
    case CreditCardType.Ideal:
      icon = FontAwesomeCustomKitIcon.IdealPay;
      brand = t("PaymentMode.Ideal", "Ideal");
      break;
    case CreditCardType.Paydirekt:
      icon = FontAwesomeCustomKitIcon.DirektPay;
      brand = t("PaymentMode.Paydirekt", "Paydirekt");
      break;
    case CreditCardType.Sofort:
      icon = FontAwesomeCustomKitIcon.SofortPay;
      brand = t("PaymentMode.Sofort", "Sofort");
      break;
    case CreditCardType.Others:
      brand = t("PaymentMode.CreditCard", "Credit card");
      break;
    case CreditCardType.AccountToAccount:
      icon = FontAwesomeCustomKitIcon.CcBankTransferPay;
      brand = t("PaymentMode.BankTransfer", "Bank transfer");
      break;
    case PaymentMode.Twint:
      icon = FontAwesomeCustomKitIcon.TwintIconPay;
      brand = t("PaymentMode.Twint", "Twint");
      break;
    case PaymentMode.PaymentSlip:
      icon = FontAwesomeCustomKitIcon.QrBillIconPay;
      brand = t("PaymentMode.PaymentSlip", "QR-bill");
      break;
    default:
      break;
  }

  return { icon, brand };
}
