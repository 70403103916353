import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { default as React, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { default as Accordion } from "react-native-collapsible/Accordion";
import { Text } from "react-native-elements";
import ScrollViewComponent from "../components/ScrollViewComponent";
import {
  colors,
  cornerRadius,
  iconSizes,
  spacings,
  typographies
} from "../styles/Styles";

type FaqData = {
  question: string;
  answer: string;
};

export default function HelpSectionScreen() {
  const [activeSections, setActiveSections] = useState<number[]>([]);
  const { t } = useTranslation();

  const faqDatas: FaqData[] = [
    {
      question: t(
        "HelpSectionFaqData.SecandaAccountQuestion",
        "What is a SECANDA account?"
      ),
      answer: t(
        "HelpSectionFaqData.SecandaAccountAnswer",
        "This is the personal account associated to the card you received from your affiliation. This account can be safely used at different points of sales and services of your work place."
      )
    },
    {
      question: t(
        "HelpSectionFaqData.IdentifierQuestion",
        "What is an identifier?"
      ),
      answer: t(
        "HelpSectionFaqData.IdentifierAnswer",
        "An identifier is the key that allows, to link your account on SECANDA and SECANDA Systems in which your account is active. The identifier is present in the form of an email address. The email address used to log into the site SECANDA, does not have to match those given by your school or company"
      )
    },
    {
      question: t(
        "HelpSectionFaqData.ManageChildAccountQuestion",
        "How can I manage the account for my child?"
      ),
      answer: t(
        "HelpSectionFaqData.ManageChildAccountAnswer",
        "Two steps are necessary to manage the SECANDA account of your child.\n 1. You must first register as a legal representative of your child at the administration of the school. Do not forget to provide your email address, as it will be used as identifier in SECANDA.\n 2. Once connected to SECANDA, go to manage identifiers (top right menu) and add the email address provided at school if necessary."
      )
    },
    {
      question: t(
        "HelpSectionFaqData.SecandaAccountMandatoryQuestion",
        "Is it mandatory to have a SECANDA wallet to use the SECANDA app?"
      ),
      answer: t(
        "HelpSectionFaqData.SecandaAccountMandatoryAnswer",
        "No. The SECANDA app is completely independent of the SECANDA system. You can manage your child's SECANDA account without having one yourself."
      )
    },
    {
      question: t(
        "HelpSectionFaqData.TargetPersonTransferQuestion",
        "To whom can I transfer money from my SECANDA account?"
      ),
      answer: t(
        "HelpSectionFaqData.TargetPersonTransferAnswer",
        "It is allowed to transfer money from your account to any other person in your affiliation or to people whose account you manage. (For example your children)"
      )
    },
    {
      question: t(
        "HelpSectionFaqData.MoneyManagementQuestion",
        "Who manages the money on my SECANDA account?"
      ),
      answer: t(
        "HelpSectionFaqData.MoneyManagementAnswer",
        "The SECANDA account is managed by the affiliation (company or school) that offers the SECANDA services."
      )
    },
    {
      question: t(
        "HelpSectionFaqData.PersonalDataQuestion",
        "What personal information is saved into SECANDA?"
      ),
      answer: t(
        "HelpSectionFaqData.PersonalDataAnswer",
        "Only data given during the registration process are stored into the SECANDA."
      )
    },
    {
      question: t(
        "HelpSectionFaqData.PaymentSlipProcessTimeQuestion",
        "How long does it take for a QR-bill payment to reach my account?"
      ),
      answer: t(
        "HelpSectionFaqData.PaymentSlipProcessTimeAnswer",
        "Account loading through QR-bill depends on PostFinance payment processing time. Once the payment has been processed and the account of your affiliation was credited, the amount will be available on your SECANDA account. The full process lasts between 2 and 5 working days"
      )
    },
    {
      question: t(
        "HelpSectionFaqData.CreditCardProcessTimeQuestion",
        "Are payments made by credit or debit card processed instantly?"
      ),
      answer: t(
        "HelpSectionFaqData.CreditCardProcessTimeAnswer",
        "Almost. SECANDA receives the payment confirmation within seconds after the payment was executed. So you can immediately use the paid amount on your SECANDA account."
      )
    },
    {
      question: t(
        "HelpSectionFaqData.InstallAppQuestion",
        "How can I install SECANDA on my smartphone?"
      ),
      answer: t(
        "HelpSectionFaqData.InstallAppAnswer",
        "SECANDA is a also a mobile application. You can download it from the different Stores (Apple Store, Google Play, Microsoft Store). To access it, simply navigate to the web site www.secanda.app on your mobile. It is also possible to directly go to your Store and search for 'SECANDA'."
      )
    },
    {
      question: t(
        "HelpSectionFaqData.PaymentSlipLoadQuestion",
        "Is it possible to use the same reference number several times to load my card by QR-bill?"
      ),
      answer: t(
        "HelpSectionFaqData.PaymentSlipLoadAnswer",
        "Yes, it is possible"
      )
    },
    {
      question: t(
        "HelpSectionFaqData.MoneyBackQuestion",
        "How can I get the money back from my SECANDA account?"
      ),
      answer: t(
        "HelpSectionFaqData.MoneyBackAnswer",
        "The balance of the SECANDA account is managed by your affiliation. For any reimbursement, please contact your institution's administration."
      )
    }
  ];

  function RenderSectionHeader(
    section: FaqData,
    index: number,
    isActive: boolean
  ) {
    return (
      <View
        style={{
          padding: spacings.xl,
          flexDirection: "row",
          borderTopColor: colors.background.neutral,
          borderTopWidth: index !== 0 ? cornerRadius.xxs : cornerRadius.none
        }}>
        <Text
          style={[
            typographies.body,
            {
              flex: 1,
              color: isActive ? colors.text.dark : colors.text.primary
            }
          ]}>
          {section.question}
        </Text>

        <FontAwesomeIcon
          icon={!isActive ? faPlus : faMinus}
          size={iconSizes.md}
          color={
            isActive ? colors.text.dark : colors.text.primary
          }></FontAwesomeIcon>
      </View>
    );
  }

  function RenderSectionContent(section: FaqData) {
    return (
      <View
        style={{
          paddingHorizontal: spacings.xl,
          paddingVertical: spacings.md
        }}>
        <Text style={[typographies.body]}>{section.answer}</Text>
      </View>
    );
  }

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.sm,
            marginBottom: spacings.md,
            alignSelf: "center"
          }
        ]}>
        {t(
          "HelpSectionScreenFaqTitle",
          "Below you can find the frequently asked questions."
        )}
      </Text>

      <Accordion
        containerStyle={{
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md,
          overflow: "hidden"
        }}
        underlayColor={colors.background.selected}
        activeSections={activeSections}
        sections={faqDatas}
        renderHeader={(section, index, isActive) =>
          RenderSectionHeader(section, index, isActive)
        }
        renderContent={RenderSectionContent}
        onChange={(activeSections) =>
          setActiveSections(activeSections)
        }></Accordion>
    </ScrollViewComponent>
  );
}
