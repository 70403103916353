import { MutationKey, useIsMutating } from "@tanstack/react-query";
import { isEqual } from "lodash";
import React from "react";
import { View } from "react-native";
import { useBackHandler } from "../hooks/BackHandlerHook";
import { refreshTokenMutationKey } from "../services/QueryService";
import { colors } from "../styles/Colors";

export default function BusyIndicatoryOverlayComponent() {
  const excludedMutationKeys: MutationKey[] = [[refreshTokenMutationKey]];
  const isMutating =
    useIsMutating({
      predicate: (mutation) =>
        !mutation.options.mutationKey ||
        !excludedMutationKeys.some((key) =>
          isEqual(key, mutation.options.mutationKey)
        )
    }) !== 0;
  useBackHandler(() => isMutating);

  return (
    <>
      {isMutating && (
        <View
          style={{
            backgroundColor: colors.background.light,
            opacity: 0.3,
            position: "absolute",
            height: "100%",
            width: "100%"
          }}></View>
      )}
    </>
  );
}
