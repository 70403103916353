import { faCircleCheck } from "@fortawesome/pro-light-svg-icons/faCircleCheck";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { ImageBackground } from "expo-image";
import { default as React, useEffect, useState } from "react";
import { View } from "react-native";
import useScreenSize from "../hooks/ScreenSizeHook";
import { IPersonKey } from "../services/AccountsService";
import { computeImageSize } from "../services/ImageService";
import { useAffiliationCardLayout } from "../services/MediaService";
import { LayoutFace, LayoutType } from "../services/clients/PlatformClient";
import { colors } from "../styles/Colors";
import { cornerRadius, iconSizes, spacings } from "../styles/Constants";
import { elevations } from "../styles/Elevations";
import { AffiliationCardLayoutSkeleton } from "./LoadingSkeletonComponent";

export default function CardLayoutComponent({
  personKey,
  face,
  displaySuccessOverlay,
  isLoading,
  loaded
}: {
  personKey: IPersonKey;
  face?: LayoutFace;
  displaySuccessOverlay?: boolean;
  isLoading?: boolean;
  loaded?: () => void;
}) {
  return face ? (
    <CardLayout
      face={face}
      displaySuccessCheck={true}
      displaySuccessOverlay={displaySuccessOverlay}
      personKey={personKey}
      loaded={loaded}
      isLoading={isLoading}
    />
  ) : (
    <CompleteCardLayout
      displaySuccessOverlay={displaySuccessOverlay}
      personKey={personKey}
      loaded={loaded}
    />
  );
}

function CompleteCardLayout({
  personKey,
  displaySuccessOverlay,
  loaded
}: {
  personKey: IPersonKey;
  displaySuccessOverlay?: boolean;
  loaded?: () => void;
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        gap: spacings.md
      }}>
      <CardLayout
        personKey={personKey}
        face={LayoutFace.Front}
        displaySuccessCheck={true}
        displaySuccessOverlay={displaySuccessOverlay}
        loaded={loaded}
      />
      <CardLayout
        personKey={personKey}
        face={LayoutFace.Back}
        displaySuccessOverlay={displaySuccessOverlay}
        loaded={loaded}
      />
    </View>
  );
}

function CardLayout({
  personKey,
  face,
  displaySuccessOverlay,
  displaySuccessCheck,
  isLoading,
  loaded
}: {
  personKey: IPersonKey;
  face: LayoutFace;
  displaySuccessOverlay?: boolean;
  displaySuccessCheck?: boolean;
  isLoading?: boolean;
  loaded?: () => void;
}) {
  const cardLayoutQuery = useAffiliationCardLayout(
    personKey,
    face,
    LayoutType.Preview
  );

  const screenSize = useScreenSize();

  const maxLayoutSize = {
    width: screenSize.large ? 295 : 320,
    height: screenSize.large ? 193 : 210
  };
  const [layoutSize, setLayoutSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const maxSize = Math.max(maxLayoutSize.width, maxLayoutSize.height);
    const size = computeImageSize(
      cardLayoutQuery.data?.width ?? maxLayoutSize.width,
      cardLayoutQuery.data?.height ?? maxLayoutSize.height,
      maxSize,
      maxSize
    );

    setLayoutSize(size);
  }, [cardLayoutQuery.data]);

  useEffect(() => {
    if (cardLayoutQuery.isSuccess && loaded) {
      loaded();
    }
  }, [cardLayoutQuery.isSuccess]);

  if (cardLayoutQuery.isInitialLoading || isLoading) {
    return (
      <View
        style={{
          width: layoutSize.width,
          height: layoutSize.height,
          borderRadius: cornerRadius.lg,
          backgroundColor: colors.background.light,
          justifyContent: "center",
          overflow: "hidden"
        }}>
        <AffiliationCardLayoutSkeleton
          layoutWidth={layoutSize.width}
          layoutHeight={layoutSize.height}
          face={face}
        />
      </View>
    );
  }

  return (
    <View>
      <ImageBackground
        source={{
          uri: cardLayoutQuery.data?.uri
        }}
        imageStyle={{
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.lg
        }}
        style={[
          elevations.sm,
          {
            backgroundColor: colors.background.neutral,
            width: layoutSize.width,
            height: layoutSize.height,
            borderRadius: cornerRadius.lg
          }
        ]}
        contentFit="cover"
      />

      {displaySuccessOverlay && (
        <View
          style={{
            position: "absolute",
            width: layoutSize.width,
            height: layoutSize.height,
            zIndex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}>
          <View
            style={{
              backgroundColor: colors.success,
              opacity: 0.4,
              borderRadius: cornerRadius.lg,
              width: "100%",
              height: "100%"
            }}
          />
          {displaySuccessCheck && (
            <FontAwesomeIcon
              style={{ position: "absolute" }}
              icon={faCircleCheck}
              color={colors.success}
              size={iconSizes.xxxxl}></FontAwesomeIcon>
          )}
        </View>
      )}
    </View>
  );
}
