import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { default as React } from "react";
import { Linking, View } from "react-native";
import { Button, Card, Divider, Text } from "react-native-elements";
import {
  IAffiliation,
  IAffiliationContact
} from "../services/clients/PlatformClient";
import {
  colors,
  iconSizes,
  mediumCardStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function AffiliationContactDataSummary({
  affiliationContact,
  affiliation
}: {
  affiliationContact: IAffiliationContact;
  affiliation: IAffiliation;
}) {
  return (
    <>
      <Card
        containerStyle={[
          mediumCardStyle.container,
          {
            backgroundColor: colors.background.light
          }
        ]}>
        <Text style={[typographies.body, { alignSelf: "center" }]}>
          {affiliation.name}
          {"\n"}
          {affiliationContact?.address}
          {"\n"}
          {affiliationContact?.countryCode.toUpperCase()}
          {"-"}
          {affiliationContact?.postalCode} {affiliationContact?.city}
        </Text>
        <Divider
          orientation="vertical"
          color={colors.background.neutral}
          style={{ marginVertical: spacings.lg }}></Divider>

        <View style={{ alignSelf: "center" }}>
          <Button
            type="clear"
            buttonStyle={{ justifyContent: "flex-start" }}
            icon={
              <FontAwesomeIcon
                style={{ marginRight: spacings.md }}
                icon={faPhone}
                color={colors.text.primary}
                size={iconSizes.lg}
              />
            }
            title={affiliationContact?.phoneNumber}
            titleStyle={[typographies.body, { textAlign: "center" }]}
            onPress={() =>
              Linking.openURL(`tel:${affiliationContact?.phoneNumber}`)
            }
          />
          <Button
            type="clear"
            buttonStyle={{ justifyContent: "flex-start" }}
            icon={
              <FontAwesomeIcon
                style={{ marginRight: spacings.md }}
                icon={faEnvelope}
                color={colors.text.primary}
                size={iconSizes.lg}
              />
            }
            title={affiliationContact?.email}
            titleStyle={[typographies.body, { textAlign: "center" }]}
            onPress={() =>
              Linking.openURL(`mailto:${affiliationContact?.email}`)
            }
          />
        </View>
      </Card>
    </>
  );
}
