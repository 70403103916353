import { faMessageArrowUpRight } from "@fortawesome/pro-light-svg-icons/faMessageArrowUpRight";
import { faMessageQuestion } from "@fortawesome/pro-light-svg-icons/faMessageQuestion";
import {
  CompositeNavigationProp,
  useNavigation
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { HelpCenterStackParamsList } from "../navigation";
import { HelpCenterProfileStackParamsList } from "../navigation/ProfileStackNavigation";
import { useAuth } from "../providers/AuthenticationProvider";
import { ProfileItemDetail } from "./ProfileScreen";

export default function HelpCenterScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<HelpCenterStackParamsList>,
        StackNavigationProp<HelpCenterProfileStackParamsList>
      >
    >();
  const { grantAccess } = useAuth();

  return (
    <ScrollViewComponent>
      <ProfileItemDetail
        firstItem
        title={t("HelpCenterScreenHelpTitle", "Help")}
        description={t(
          "HelpCenterScreenHelpDescription",
          "Consult frequent asked questions"
        )}
        iconLeft={faMessageQuestion}
        onPress={() => navigation.navigate("help")}
      />

      <ProfileItemDetail
        lastItem
        title={t("HelpCenterScreenContactTitle", "Contact support")}
        description={t(
          "HelpCenterScreenContactDescription",
          "Contact support for additional assistance"
        )}
        iconLeft={faMessageArrowUpRight}
        onPress={() =>
          grantAccess
            ? navigation.navigate("contactSelectAffiliation", {
                screen: "selectAccountContact"
              })
            : navigation.navigate("contact")
        }
      />
    </ScrollViewComponent>
  );
}
