import { faBan } from "@fortawesome/pro-light-svg-icons/faBan";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle";
import { faChildReaching } from "@fortawesome/pro-light-svg-icons/faChildReaching";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons/faEllipsis";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { faHandHoldingDollar } from "@fortawesome/pro-light-svg-icons/faHandHoldingDollar";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { faWarning } from "@fortawesome/pro-light-svg-icons/faWarning";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  useNavigation,
  useRoute
} from "@react-navigation/core";
import {
  CompositeNavigationProp,
  RouteProp,
  useFocusEffect
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { endOfDay, format, isPast } from "date-fns";
import { Image } from "expo-image";
import * as WebBrowser from "expo-web-browser";
import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  FlatList,
  LayoutChangeEvent,
  Platform,
  Pressable,
  RefreshControl,
  View
} from "react-native";
import { Card, ListItem, Text } from "react-native-elements";
import * as DropdownMenu from "zeego/dropdown-menu";
import { ResultType } from "../components/ActionResultComponent";
import AffiliationLogoComponent from "../components/AffiliationLogoComponent";
import CardLayoutComponent from "../components/CardLayoutComponent";
import DropdownMenuComponent from "../components/DropdownMenuComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import {
  AccountListItemSkeleton,
  ExternalSitesSkeleton,
  LogoSkeleton
} from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import TransactionsListSummaryComponent from "../components/TransactionsListSummaryComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { useAction } from "../hooks/ActionHook";
import usePreselectedAccount from "../hooks/PreselectedAccountHook";
import useScreenSize from "../hooks/ScreenSizeHook";
import { CardDetailsScreenProps, RootStackParamsList } from "../navigation";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";
import {
  AccountDetail,
  IAccountKey,
  IPersonKey,
  useAccount,
  useAccounts
} from "../services/AccountsService";
import { useDependentAffiliations } from "../services/AffiliationsService";
import { alert } from "../services/DialogService";
import {
  useExternalSiteImage,
  useExternalSites
} from "../services/ExternalSitesService";
import { humanizeCurrency } from "../services/HumanizerService";
import { IMediumKey, useBlockMedium } from "../services/MediaService";
import { parseDate } from "../services/ParserService";
import {
  invalidateAccount,
  invalidateExternalSites,
  invalidateTransactions
} from "../services/QueryService";
import { toastError } from "../services/ToastService";
import {
  AffiliationStatus,
  ExternalSite,
  IAffiliation,
  LayoutFace,
  MediumStatus,
  PersonType
} from "../services/clients/PlatformClient";
import {
  colors,
  cornerRadius,
  iconSizes,
  mediumCardStyle,
  pressableStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function AccountDetailsScreen() {
  const route = useRoute<RouteProp<HomeStackParamsList, "accountDetails">>();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<HomeStackParamsList, "accountDetails">,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const refreshAction = useAction(refresh);
  const { t } = useTranslation();

  const { identifierId, affiliationId, personId, accountId } = route.params;

  const accountQuery = useAccount({
    identifierId,
    affiliationId,
    personId,
    accountId
  });
  const dependentAffiliationsQuery = useDependentAffiliations(affiliationId);
  const externalSitesQuery = useExternalSites(affiliationId);
  const { preselectAccount } = usePreselectedAccount();

  useFocusEffect(
    React.useCallback(() => {
      preselectAccount({
        identifierId: identifierId,
        affiliationId: affiliationId,
        personId: personId,
        accountId: accountId
      });
    }, [route.params])
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <AccountDetailDropdownMenu
          accountKey={{
            identifierId: identifierId,
            affiliationId: affiliationId,
            personId: personId,
            accountId: accountId
          }}
        />
      )
    });
  }, []);

  async function refresh() {
    const accountKey: IAccountKey = {
      identifierId,
      affiliationId,
      personId,
      accountId
    };
    await Promise.all([
      invalidateAccount(accountKey),
      invalidateTransactions(accountKey),
      invalidateExternalSites(affiliationId)
    ]);
  }

  if (!accountQuery.isInitialLoading && !accountQuery.data) {
    return (
      <EmptyContentComponent
        message={t("AccountDetailNoAccountFound", "Account data not found.")}
        refreshControl={
          <RefreshControl
            refreshing={refreshAction.isBusy}
            onRefresh={refreshAction.execute}
          />
        }></EmptyContentComponent>
    );
  }

  return (
    <ScrollViewComponent
      refreshControl={
        <RefreshControl
          refreshing={refreshAction.isBusy}
          onRefresh={refreshAction.execute}
        />
      }>
      {accountQuery.isInitialLoading ||
      dependentAffiliationsQuery.isInitialLoading ||
      externalSitesQuery.isInitialLoading ? (
        <AccountListItemSkeleton />
      ) : accountQuery.data ? (
        <Card
          containerStyle={[
            mediumCardStyle.container,
            { padding: spacings.none }
          ]}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              padding: spacings.lg
            }}>
            <AccountDetailSummary
              accountDetail={accountQuery.data}
              showCardStatus={true}
            />
            <AffiliationLogoComponent affiliationId={affiliationId} />
          </View>

          <AccountValiditySummary
            accountDetail={accountQuery.data}
            dependentAffiliations={dependentAffiliationsQuery.data}
          />
        </Card>
      ) : null}

      {accountQuery.data?.affiliation.configuration.isVirtualCardAllowed && (
        <View style={{ marginTop: spacings.md }}>
          <FlatList
            horizontal={true}
            data={accountQuery.data?.media}
            alwaysBounceHorizontal={false}
            ListEmptyComponent={
              <MediumDetail
                identifierId={identifierId}
                affiliationId={affiliationId}
                personId={personId}
                accountId={accountId}
              />
            }
            contentContainerStyle={{
              flex: 1,
              justifyContent: "center",
              paddingVertical: spacings.md
            }}
            renderItem={(item) => {
              return (
                <MediumDetail
                  key={item.item.mediumId}
                  identifierId={identifierId}
                  affiliationId={affiliationId}
                  personId={personId}
                  accountId={accountId}
                />
              );
            }}
            keyExtractor={(_, index) => index.toString()}></FlatList>
        </View>
      )}

      <ExternalSitesComponent
        identifierId={identifierId}
        affiliationId={affiliationId}
        personId={personId}
        accountId={accountId}
      />

      <View
        style={{
          marginTop: accountQuery.data?.affiliation.configuration
            .isVirtualCardAllowed
            ? spacings.md
            : spacings.xl
        }}>
        <TransactionsListSummaryComponent
          take={4}
          identifierId={accountQuery.data?.identifier.identifierId}
          affiliationId={accountQuery.data?.affiliation.affiliationId}
          personId={accountQuery.data?.person?.personId}
          accountId={
            accountQuery.data?.account?.accountId
          }></TransactionsListSummaryComponent>
      </View>
    </ScrollViewComponent>
  );
}

function getAccountValidityData(
  accountDetail?: AccountDetail,
  dependentAffiliations?: IAffiliation[]
) {
  const selfActivationAllowed =
    accountDetail?.affiliation.configuration.selfActivationAllowed ||
    dependentAffiliations?.some((d) => d.configuration.selfActivationAllowed);
  const categoryName: string | undefined = selfActivationAllowed
    ? accountDetail?.person?.metadata?.CategoryName
    : undefined;
  const validityEndDate = accountDetail?.person?.metadata?.ValidityEndDate
    ? endOfDay(parseDate(accountDetail.person?.metadata?.ValidityEndDate))
    : undefined;
  const validityEndDateExpired = validityEndDate && isPast(validityEndDate);
  const selfActivationAvailable =
    selfActivationAllowed &&
    (accountDetail?.media.length === 0 || validityEndDateExpired);

  return {
    categoryName: categoryName,
    validityEndDate: validityEndDate,
    selfActivationAllowed: selfActivationAllowed,
    selfActivationAvailable: selfActivationAvailable,
    validityEndDateExpired: validityEndDateExpired
  };
}

function AccountValiditySummary({
  accountDetail,
  dependentAffiliations
}: {
  accountDetail: AccountDetail;
  dependentAffiliations?: IAffiliation[];
}) {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();

  const {
    categoryName,
    selfActivationAvailable,
    validityEndDate,
    validityEndDateExpired
  } = useMemo(
    () => getAccountValidityData(accountDetail, dependentAffiliations),
    [accountDetail, dependentAffiliations]
  );

  if (!selfActivationAvailable && !categoryName && !validityEndDate) {
    return null;
  }

  return (
    <Pressable
      style={({ pressed }) => pressed && pressableStyle.pressed}
      onPress={() => {
        if (selfActivationAvailable && accountDetail.person?.personId) {
          navigation.navigate("activationSelectAffiliationStack", {
            screen: "selectAffiliationActivation",
            params: {
              identifierId: accountDetail.identifier.identifierId,
              affiliationId: accountDetail.affiliation.affiliationId,
              personId: accountDetail.person.personId
            }
          });
        }
      }}
      disabled={!selfActivationAvailable || !accountDetail.person?.personId}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderTopColor: colors.background.neutral,
          borderTopWidth: cornerRadius.xxs,
          paddingHorizontal: spacings.lg,
          paddingVertical: spacings.md
        }}>
        <View style={{ justifyContent: "center", flexGrow: 1, flexShrink: 1 }}>
          {categoryName && (
            <Text style={typographies.small} numberOfLines={1}>
              {categoryName}
            </Text>
          )}

          {validityEndDate && !validityEndDateExpired && (
            <Text style={typographies.small} numberOfLines={1}>
              {t(
                "AccountDetailScreenValidityEndDate",
                "Valid until {{validityEndDate}}",
                {
                  validityEndDate: format(validityEndDate, "P")
                }
              )}
            </Text>
          )}

          {validityEndDate && validityEndDateExpired && (
            <Text
              style={[typographies.small, { color: colors.danger }]}
              numberOfLines={1}>
              {t(
                "AccountDetailScreenValidityEndDateExpired",
                "Expired on {{validityEndDate}}",
                {
                  validityEndDate: format(validityEndDate, "P")
                }
              )}
            </Text>
          )}
        </View>

        {selfActivationAvailable && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size={iconSizes.lg}
            color={colors.danger}></FontAwesomeIcon>
        )}
      </View>
    </Pressable>
  );
}

export function AccountDetailSummary({
  accountDetail,
  showCardStatus
}: {
  accountDetail: AccountDetail;
  showCardStatus?: boolean;
}) {
  const hasManyAffiliationAccounts = useAccounts({
    select: (accounts) =>
      accounts.filter(
        (a) =>
          a.affiliation.affiliationId ===
          accountDetail.affiliation.affiliationId
      ).length > 1
  });
  const { t } = useTranslation();
  const displayFormattedName =
    hasManyAffiliationAccounts.data ||
    accountDetail.person?.type === PersonType.Represented;

  return (
    <View
      style={{
        flexGrow: 1,
        flexShrink: 1,
        paddingRight: spacings.md
      }}>
      <Text style={typographies.body} numberOfLines={1}>
        {accountDetail.affiliation.name}
      </Text>

      {displayFormattedName ? (
        <View style={{ flexDirection: "row", marginTop: spacings.xs }}>
          {accountDetail.person?.type === PersonType.Represented ? (
            <FontAwesomeIcon
              size={iconSizes.md}
              icon={faChildReaching}
              color={colors.secondary}
              style={{ marginRight: spacings.xs }}
            />
          ) : null}

          <Text
            style={[typographies.body, { color: colors.secondary }]}
            numberOfLines={1}>
            {accountDetail.person?.formattedName}
          </Text>
        </View>
      ) : null}

      {accountDetail.affiliation.status === AffiliationStatus.Offline ? (
        <Text
          style={[typographies.caption, { color: colors.danger }]}
          numberOfLines={1}>
          {t("AccountDetailTemporarilyUnavailable", "Temporarily unavailable")}
        </Text>
      ) : null}

      {accountDetail.account ? (
        <Text
          style={[typographies.title, { color: colors.text.dark }]}
          numberOfLines={1}>
          {humanizeCurrency(
            accountDetail.account.balance,
            accountDetail.account.currency
          )}
        </Text>
      ) : null}

      {showCardStatus && (
        <View
          style={{
            marginTop: spacings.sm,
            alignSelf: "flex-start",
            flexDirection: "row",
            alignItems: "center"
          }}>
          {accountDetail.media.some(
            (m) => m.status === MediumStatus.Blocked
          ) ? (
            <>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size={iconSizes.sm}
                color={colors.danger}></FontAwesomeIcon>
              <Text
                style={[
                  typographies.caption,
                  { color: colors.danger, marginLeft: spacings.sm }
                ]}>
                {t("AccountDetailsScreenCardBlockedTitle", "Card blocked")}
              </Text>
            </>
          ) : accountDetail.media.some(
              (m) => m.status === MediumStatus.ToValidate
            ) ? (
            <>
              <FontAwesomeIcon
                icon={faWarning}
                size={iconSizes.sm}
                color={colors.warning}></FontAwesomeIcon>
              <Text
                style={[
                  typographies.caption,
                  { color: colors.warning, marginLeft: spacings.sm }
                ]}>
                {t(
                  "AccountDetailsScreenCardToValidateTitle",
                  "Card to validate"
                )}
              </Text>
            </>
          ) : accountDetail.media.some(
              (m) => m.status === MediumStatus.Active
            ) ? (
            <>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size={iconSizes.sm}
                color={colors.success}></FontAwesomeIcon>
              <Text
                style={[
                  typographies.caption,
                  { color: colors.success, marginLeft: spacings.sm }
                ]}>
                {t("AccountDetailsScreenCardActiveTitle", "Active card")}
              </Text>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size={iconSizes.sm}
                color={colors.text.secondary}></FontAwesomeIcon>
              <Text
                style={[
                  typographies.caption,
                  { color: colors.text.secondary, marginLeft: spacings.sm }
                ]}>
                {t("AccountDetailsScreenNoCardTitle", "No card")}
              </Text>
            </>
          )}
        </View>
      )}
    </View>
  );
}

function MediumDetail({
  identifierId,
  affiliationId,
  personId,
  accountId
}: {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
}) {
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  const screenSize = useScreenSize();

  const [isCardLoaded, setIsCardLoaded] = useState(false);

  const personKey: IPersonKey = {
    identifierId: identifierId,
    affiliationId: affiliationId,
    personId: personId
  };

  return (
    <ListItem
      containerStyle={{
        padding: spacings.sm,
        justifyContent: "center",
        backgroundColor: colors.background.transparent
      }}>
      <View>
        <Pressable
          style={({ pressed }) => pressed && pressableStyle.pressed}
          disabled={!isCardLoaded}
          onPress={() => {
            const params: CardDetailsScreenProps = {
              identifierId: identifierId,
              affiliationId: affiliationId,
              personId: personId,
              accountId: accountId
            };

            navigation.navigate("cardDetails", params);
          }}>
          <CardLayoutComponent
            personKey={personKey}
            face={screenSize.large ? undefined : LayoutFace.Front}
            loaded={() => setIsCardLoaded(true)}
          />
        </Pressable>
      </View>
    </ListItem>
  );
}

function AccountDetailDropdownMenu({
  accountKey
}: {
  accountKey: IAccountKey;
}) {
  const { t } = useTranslation();
  const accountsQuery = useAccounts();
  const accountQuery = useAccount(accountKey);
  const dependentAffiliationsQuery = useDependentAffiliations(
    accountKey.affiliationId
  );
  const blockMediumMutation = useBlockMedium();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<HomeStackParamsList, "accountDetails">,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const {
    DropdownMenuRoot,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuItemTitle
  } = DropdownMenuComponent();

  const addAvailable = accountsQuery.data?.length === 1;

  const blockCardEnable =
    accountQuery.data?.affiliation?.configuration?.isBlockCardAllowed;

  const availableActiveMediums = accountQuery.data?.media.some(
    (m) => m.status === MediumStatus.Active
  );

  const blockAvailable = blockCardEnable && availableActiveMediums;

  const deleteAvailable =
    !!accountQuery.data?.affiliation?.configuration?.isDeletePersonAllowed;
  const refundBalanceAvailable =
    (!!accountQuery.data?.affiliation?.configuration?.refundByCreditCard ||
      !!accountQuery.data?.affiliation?.configuration?.refundByTwint) &&
    !!accountQuery.data.account?.balance;

  const { selfActivationAllowed } = useMemo(
    () =>
      getAccountValidityData(
        accountQuery.data,
        dependentAffiliationsQuery.data
      ),
    [accountQuery.data, dependentAffiliationsQuery.data]
  );

  async function blockMedium() {
    if (!accountQuery.data?.media?.length) return;

    for (let index = 0; index < accountQuery.data.media.length; index++) {
      const medium = accountQuery.data.media[index];
      const mediumKey: IMediumKey = {
        identifierId: accountKey.identifierId,
        affiliationId: accountKey.affiliationId,
        personId: accountKey.personId,
        mediumId: medium.mediumId
      };

      try {
        await blockMediumMutation.mutateAsync(mediumKey);

        if (index === accountQuery.data.media.length - 1) {
          navigation.navigate("blockCardResult", {
            type: ResultType.Success,
            description: t(
              "BlockCardResultScreenDescription",
              "The card was blocked successfully"
            ),
            identifierId: mediumKey.identifierId,
            affiliationId: mediumKey.affiliationId,
            personId: mediumKey.personId,
            mediumId: mediumKey.mediumId
          });
          return;
        }
      } catch (e: any) {
        const managedException = isManagedException(e);
        if (managedException) {
          navigation.navigate("blockCardResult", {
            type: ResultType.Error,
            description: managedException.message
          });
          return;
        }

        navigation.navigate("blockCardResult", {
          type: ResultType.Error,
          description: t(
            "AccountDetailsScreenCardBlockError",
            "An error ocurred when blocking the card"
          )
        });
        return;
      }
    }
  }

  function deletePerson() {
    if (accountQuery.data?.person?.type === PersonType.Represented) {
      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "AccountDetailsScreenDeleteNotOwnerError",
          "You cannot delete the account because you are not the owner of the account."
        )
      );
      return;
    }

    navigation.navigate("requestDeleteAccount", accountKey);
  }

  return (
    <View style={{ position: "absolute", right: 0 }}>
      <DropdownMenuRoot>
        <DropdownMenuTrigger>
          <Pressable style={({ pressed }) => pressed && pressableStyle.pressed}>
            <FontAwesomeIcon
              icon={faEllipsis}
              color={colors.primary}
              style={{
                marginRight: spacings.md
              }}
              size={iconSizes.xl}
            />
          </Pressable>
        </DropdownMenuTrigger>

        <DropdownMenuContent style={{ marginRight: spacings.md }}>
          {addAvailable && (
            <DropdownMenuItem
              key="add"
              textValue={t("AccountDetailsAddAccountButtonTitle", "Add")}
              onSelect={() =>
                navigation.navigate("enrollSelectAffiliationStack", {
                  screen: "selectAffiliationToEnroll"
                })
              }>
              <DropdownMenu.ItemIcon ios={{ name: "plus" }} />
              <DropdownMenuItemTitle>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color={colors.text.primary}
                    style={{ marginRight: spacings.md }}
                    size={iconSizes.md}
                  />
                  <Text style={typographies.body}>
                    {t("AccountDetailsAddAccountButtonTitle", "Add")}
                  </Text>
                </View>
              </DropdownMenuItemTitle>
            </DropdownMenuItem>
          )}

          {selfActivationAllowed && (
            <DropdownMenuItem
              key="activation"
              textValue={t(
                "AccountDetailsActivateAccountButtonTitle",
                "Validate account"
              )}
              onSelect={() => {
                if (accountQuery.data?.person?.personId) {
                  navigation.navigate("activationSelectAffiliationStack", {
                    screen: "selectAffiliationActivation",
                    params: {
                      identifierId: accountQuery.data?.identifier.identifierId,
                      affiliationId:
                        accountQuery.data?.affiliation.affiliationId,
                      personId: accountQuery.data?.person?.personId
                    }
                  });
                }
              }}>
              <DropdownMenu.ItemIcon ios={{ name: "checkmark.circle" }} />
              <DropdownMenuItemTitle>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={colors.text.primary}
                    style={{ marginRight: spacings.md }}
                    size={iconSizes.md}
                  />
                  <Text style={typographies.body}>
                    {t(
                      "AccountDetailsActivateAccountButtonTitle",
                      "Validate account"
                    )}
                  </Text>
                </View>
              </DropdownMenuItemTitle>
            </DropdownMenuItem>
          )}

          {blockAvailable && (
            <DropdownMenuItem
              key="block"
              textValue={t("AccountDetailsScreenBlockCardTitle", "Block card")}
              onSelect={() =>
                alert(
                  t("AccountDetailsScreenBlockCardTitle", "Block card"),
                  t(
                    "AccountDetailsScreenBlockCardMessage",
                    "Are you sure you want to block the card?"
                  ),
                  [
                    {
                      text: t("AlertConfirm", "Confirm"),
                      onPress: blockMedium,
                      style: "destructive"
                    },
                    {
                      text: t("AlertCancel", "Cancel"),
                      style: "cancel"
                    }
                  ]
                )
              }>
              <DropdownMenu.ItemIcon ios={{ name: "nosign" }} />
              <DropdownMenuItemTitle>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faBan}
                    color={colors.text.primary}
                    style={{ marginRight: spacings.md }}
                    size={iconSizes.md}
                  />
                  <Text style={typographies.body}>
                    {t("AccountDetailsScreenBlockCardTitle", "Block card")}
                  </Text>
                </View>
              </DropdownMenuItemTitle>
            </DropdownMenuItem>
          )}

          {refundBalanceAvailable && (
            <DropdownMenuItem
              key="refund"
              textValue={t(
                "AccountDetailRefundAccountBalance",
                "Refund account"
              )}
              onSelect={() => {
                navigation.navigate("refundAccountBalance", {
                  identifierId: accountKey.identifierId,
                  affiliationId: accountKey.affiliationId,
                  accountId: accountKey.accountId,
                  personId: accountKey.personId
                });
              }}>
              <DropdownMenu.ItemIcon ios={{ name: "dollarsign.square" }} />
              <DropdownMenuItemTitle>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faHandHoldingDollar}
                    color={colors.text.primary}
                    style={{ marginRight: spacings.md }}
                    size={iconSizes.md}></FontAwesomeIcon>

                  <Text style={typographies.body}>
                    {t("AccountDetailRefundAccountBalance", "Refund account")}
                  </Text>
                </View>
              </DropdownMenuItemTitle>
            </DropdownMenuItem>
          )}

          {deleteAvailable && (
            <DropdownMenuItem
              key="delete"
              textValue={t("AccountDetailDeleteAccount", "Delete account")}
              onSelect={deletePerson}>
              <DropdownMenu.ItemIcon ios={{ name: "trash" }} />
              <DropdownMenuItemTitle>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    color={colors.text.primary}
                    style={{ marginRight: spacings.md }}
                    size={iconSizes.md}
                  />
                  <Text style={typographies.body}>
                    {t("AccountDetailDeleteAccount", "Delete account")}
                  </Text>
                </View>
              </DropdownMenuItemTitle>
            </DropdownMenuItem>
          )}

          <DropdownMenuItem
            key="contact"
            textValue={t(
              "AccountDetailContactAffiliation",
              "Contact affiliation"
            )}
            onSelect={() =>
              navigation.navigate("root", {
                screen: "profileStack",
                params: {
                  screen: "helpCenterProfileStack",
                  initial: false,
                  params: {
                    screen: "contactSelectAffiliation",
                    initial: false,
                    params: {
                      screen: "contact",
                      initial: false,
                      params: {
                        identifierId: accountKey.identifierId,
                        affiliationId: accountKey.affiliationId,
                        personId: accountKey.personId,
                        accountId: accountKey.accountId
                      }
                    }
                  }
                }
              })
            }>
            <DropdownMenu.ItemIcon ios={{ name: "phone" }} />
            <DropdownMenuItemTitle>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faPhone}
                  color={colors.text.primary}
                  style={{ marginRight: spacings.md }}
                  size={iconSizes.md}
                />
                <Text style={typographies.body}>
                  {t("AccountDetailContactAffiliation", "Contact affiliation")}
                </Text>
              </View>
            </DropdownMenuItemTitle>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </View>
  );
}

function ExternalSitesComponent({
  identifierId,
  affiliationId,
  personId,
  accountId
}: {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
}) {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const [containerWidth, setContainerWidth] = useState<number>();
  const externalSitesQuery = useExternalSites(affiliationId);
  const accountQuery = useAccount({
    identifierId,
    affiliationId,
    personId,
    accountId
  });

  const onContentLayout = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  const containerMargin = screenSize.small ? spacings.lg : spacings.none;
  const itemGap = spacings.sm;
  const itemSize = useMemo(() => {
    const minItemSize = 80;
    const maxItemSize = 100;
    if (!containerWidth || !externalSitesQuery.data) return minItemSize;

    const itemsCount = externalSitesQuery.data.length;
    const itemsGap = itemGap * (itemsCount - 1);
    const size = (containerWidth - itemsGap) / itemsCount;

    if (size < minItemSize) return minItemSize;
    if (size > maxItemSize) return maxItemSize;
    return size;
  }, [containerWidth, containerMargin, externalSitesQuery.data]);

  if (externalSitesQuery.isInitialLoading || accountQuery.isInitialLoading) {
    return (
      <View style={{ marginTop: spacings.md }}>
        <ExternalSitesSkeleton />
      </View>
    );
  }

  if (!externalSitesQuery.data || !externalSitesQuery.data.length) {
    return null;
  }

  return (
    <View
      style={{
        marginTop: accountQuery.data?.affiliation.configuration
          .isVirtualCardAllowed
          ? spacings.md
          : spacings.xl
      }}>
      <Text
        style={[
          typographies.title,
          { textAlign: "left", marginBottom: spacings.sm }
        ]}>
        {t("AccountDetailsScreenServicesTitle", "Services")}
      </Text>

      <View onLayout={onContentLayout}>
        <FlatList
          horizontal={true}
          alwaysBounceHorizontal={false}
          data={externalSitesQuery.data}
          style={{
            marginHorizontal: -containerMargin
          }}
          contentContainerStyle={{
            paddingHorizontal: containerMargin,
            paddingBottom: spacings.sm
          }}
          renderItem={(item) => (
            <ExternalSiteItemComponent
              externalSite={item.item}
              affiliationId={affiliationId}
              itemSize={itemSize}
            />
          )}
          ItemSeparatorComponent={() => (
            <View
              style={{
                backgroundColor: colors.background.neutral,
                width: itemGap
              }}
            />
          )}
          keyExtractor={(_, index) => index.toString()}></FlatList>
      </View>
    </View>
  );
}

function ExternalSiteItemComponent({
  affiliationId,
  externalSite,
  itemSize
}: {
  affiliationId: number;
  externalSite: ExternalSite;
  itemSize: number;
}) {
  const externalSiteImageQuery = useExternalSiteImage({
    affiliationId: affiliationId,
    externalSiteId: externalSite.externalSiteId
  });

  async function openExternalSite() {
    if (Platform.OS === "web") {
      window.open(externalSite.url, "_blank");
    } else {
      await WebBrowser.openBrowserAsync(externalSite.url);
    }
  }

  return (
    <Pressable
      style={{
        flex: 1,
        flexDirection: "column",
        borderRadius: cornerRadius.md
      }}
      onPress={openExternalSite}>
      <View
        style={{
          width: itemSize,
          height: itemSize,
          backgroundColor: colors.background.light,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: cornerRadius.md,
          marginBottom: spacings.sm,
          padding: spacings.md
        }}>
        {externalSiteImageQuery.isInitialLoading && (
          <LogoSkeleton
            height={itemSize - spacings.md * 2}
            width={itemSize - spacings.md * 2}></LogoSkeleton>
        )}

        {externalSiteImageQuery.data && (
          <Image
            style={{ width: "100%", height: "100%" }}
            transition={1000}
            contentFit="contain"
            source={{
              uri: externalSiteImageQuery.data
            }}
          />
        )}
      </View>
      <Text
        style={[
          typographies.caption,
          {
            textAlign: "center",
            alignSelf: "stretch",
            width: itemSize
          }
        ]}
        numberOfLines={1}>
        {externalSite.title}
      </Text>
    </Pressable>
  );
}
