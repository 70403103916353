import { NavigatorScreenParams } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import usePreselectedAccount from "../hooks/PreselectedAccountHook";
import LoadAmountSelectionScreen from "../screens/LoadAmountSelectionScreen";
import LoadScreen from "../screens/LoadScreen";
import PaymentSlipPaymentScreen from "../screens/PaymentSlipPaymentScreen";
import { useAccounts } from "../services/AccountsService";
import { headerStyle } from "../styles/Styles";

export type LoadAmountSelectionScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};
export type PaymentSlipPaymentScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
  paymentSlipId: number;
};
export type LoadAmountSelectionStackParamsList = {
  amountSelection: LoadAmountSelectionScreenProps;
  paymentSlipPayment: PaymentSlipPaymentScreenProps;
};
export type LoadSelectAccountStackParamsList = {
  loadAmountStack: NavigatorScreenParams<LoadAmountSelectionStackParamsList>;
  load: undefined;
};
export type LoadStackParamsList = {
  selectAccountStack: NavigatorScreenParams<LoadSelectAccountStackParamsList>;
};
const LoadStack = createStackNavigator<LoadStackParamsList>();

export default function LoadStackScreen() {
  const { t } = useTranslation();

  return (
    <LoadStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <LoadStack.Screen
        name="selectAccountStack"
        component={SelectAccountScreen}
        options={{
          headerShown: false
        }}></LoadStack.Screen>
    </LoadStack.Navigator>
  );
}

const SelectAccountScreenStack =
  createStackNavigator<LoadSelectAccountStackParamsList>();

function SelectAccountScreen() {
  const { t } = useTranslation();
  const accountsQuery = useAccounts();
  const { preselectedAccount } = usePreselectedAccount();

  return (
    <SelectAccountScreenStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <SelectAccountScreenStack.Screen
        name="load"
        component={LoadScreen}
        options={{
          title: t("LoadScreenTitle", "Load wallet")
        }}
      />
      <SelectAccountScreenStack.Screen
        name="loadAmountStack"
        component={SendAmountSelectionScreen}
        options={{
          animationEnabled:
            (accountsQuery.data?.length ?? 0) > 1 &&
            Platform.OS !== "web" &&
            !!!preselectedAccount,
          headerShown: false
        }}
      />
    </SelectAccountScreenStack.Navigator>
  );
}

const LoadAmountSelectionStack =
  createStackNavigator<LoadAmountSelectionStackParamsList>();

function SendAmountSelectionScreen() {
  const { t } = useTranslation();

  return (
    <LoadAmountSelectionStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <LoadAmountSelectionStack.Screen
        name="amountSelection"
        component={LoadAmountSelectionScreen}
        options={{
          title: t("LoadAmountSelectionScreenTitle", "Load wallet")
        }}></LoadAmountSelectionStack.Screen>
      <LoadAmountSelectionStack.Screen
        name="paymentSlipPayment"
        component={PaymentSlipPaymentScreen}
        options={{
          title: t("PaymentSlipPaymentScreenTitle", "QR-bill")
        }}
      />
    </LoadAmountSelectionStack.Navigator>
  );
}
