import {
  faAngleRight,
  faBuildingCircleExclamation
} from "@fortawesome/pro-light-svg-icons";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";
import { Button, ListItem, SearchBar, Text } from "react-native-elements";
import AffiliationListItemComponent from "../components/AffiliationListItemComponent";
import { SelectAffiliationToEnrollSkeleton } from "../components/LoadingSkeletonComponent";
import { EnrollSelectAffiliationStackParamsList } from "../navigation";
import { useAccounts } from "../services/AccountsService";
import { useAllAffiliations } from "../services/AffiliationsService";
import {
  AffiliationStatus,
  IAffiliation
} from "../services/clients/PlatformClient";
import { mediumActionRoundedButtonStyle } from "../styles/Buttons";
import { listItemHeights } from "../styles/Constants";
import { searchBarStyle } from "../styles/Inputs";
import { virtualizedListItemStyle } from "../styles/Lists";
import {
  colors,
  containerStyle,
  iconSizes,
  spacings,
  typographies
} from "../styles/Styles";

export default function SelectAffiliationToEnrollScreen() {
  const { t } = useTranslation();
  const affiliationsQuery = useAllAffiliations();
  const accountsQuery = useAccounts();
  const navigation =
    useNavigation<
      StackNavigationProp<EnrollSelectAffiliationStackParamsList>
    >();
  const [searchBarValue, setSearchBarValue] = useState("");

  const availableAffiliations = React.useMemo((): IAffiliation[] => {
    if (affiliationsQuery.isInitialLoading || accountsQuery.isInitialLoading)
      return [];
    if (!affiliationsQuery.data) return [];

    const assignedAffiliationIds =
      accountsQuery.data?.map((a) => a.affiliation.affiliationId) ?? [];
    return affiliationsQuery.data.filter(
      (a) => !assignedAffiliationIds.includes(a.affiliationId)
    );
  }, [affiliationsQuery.data, accountsQuery.data]);

  const filteredAffiliations = React.useMemo((): IAffiliation[] => {
    if (!searchBarValue) return availableAffiliations;

    return availableAffiliations.filter((a) =>
      a.name.toLowerCase().includes(searchBarValue.toLowerCase())
    );
  }, [availableAffiliations, searchBarValue]);

  if (affiliationsQuery.isInitialLoading || accountsQuery.isInitialLoading) {
    return <SelectAffiliationToEnrollSkeleton />;
  }

  if (!availableAffiliations.length) {
    return (
      <View style={containerStyle.containerCenter}>
        <AffiliationNotListed
          message={t(
            "SelectAffiliationToEnrollScreenEmptyList",
            "There are no affiliations to enroll"
          )}
        />
      </View>
    );
  }

  return (
    <>
      <FlatList
        contentContainerStyle={containerStyle.container}
        ListHeaderComponent={
          <SearchBar
            inputStyle={searchBarStyle.input}
            inputContainerStyle={searchBarStyle.inputContainer}
            containerStyle={[
              searchBarStyle.container,
              { marginBottom: spacings.lg }
            ]}
            placeholder={t(
              "SelectAffiliationToEnrollSearchBarPlaceholder",
              "Search"
            )}
            placeholderTextColor={colors.text.placeholder}
            value={searchBarValue}
            searchIcon={() => {
              return (
                <FontAwesomeIcon
                  icon={faSearch}
                  size={iconSizes.md}
                  color={colors.secondary}
                />
              );
            }}
            // TODO: Known typescript check issue: https://github.com/react-native-elements/react-native-elements/issues/3089
            onChangeText={(value) => {
              setSearchBarValue(value);
            }}></SearchBar>
        }
        ListFooterComponent={
          !!filteredAffiliations.length ? (
            <View
              style={[
                virtualizedListItemStyle.baseItem,
                virtualizedListItemStyle.lastItem
              ]}>
              <ListItem
                containerStyle={{
                  height: listItemHeights.md,
                  justifyContent: "space-between"
                }}
                onPress={() => {
                  navigation.navigate("enrollIdentifiersStack", {
                    screen: "identifierNotFound",
                    params: {}
                  });
                }}>
                <View
                  style={{
                    flexShrink: 1
                  }}>
                  <Text style={typographies.body}>
                    {t("SelectAffiliationToEnrollScreenOtherItemList", "Other")}
                  </Text>
                </View>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  color={colors.text.primary}
                  size={iconSizes.lg}
                />
              </ListItem>
            </View>
          ) : undefined
        }
        ListEmptyComponent={
          <AffiliationNotListed
            message={t(
              "SelectAffiliationToEnrollScreenNoResultList",
              "Didn't find your affiliation?"
            )}
          />
        }
        data={filteredAffiliations}
        renderItem={({ item, index }) => {
          return (
            <View
              key={index}
              style={[
                virtualizedListItemStyle.baseItem,
                virtualizedListItemStyle.middleItem,
                index === 0 ? virtualizedListItemStyle.firstItem : null
              ]}>
              <AffiliationListItemComponent
                affiliation={item}
                onPress={() => affiliationSelected(item)}
                disabled={item.status === AffiliationStatus.Offline}
              />
            </View>
          );
        }}
        keyExtractor={(_, index) => index.toString()}></FlatList>
    </>
  );

  function affiliationSelected(affiliation: IAffiliation): void {
    if (affiliation.configuration.selfRegistrationAllowed) {
      navigation.navigate("enrollPersonalData", {
        affiliationId: affiliation.affiliationId
      });
    } else {
      navigation.navigate("enrollIdentifiersStack", {
        screen: "identifierNotFound",
        params: {
          affiliationId: affiliation.affiliationId
        }
      });
    }
  }
}

function AffiliationNotListed({ message }: { message: string }) {
  const { t } = useTranslation();
  const navigation =
    useNavigation<
      StackNavigationProp<EnrollSelectAffiliationStackParamsList>
    >();

  return (
    <View style={containerStyle.containerCenter}>
      <FontAwesomeIcon
        icon={faBuildingCircleExclamation}
        color={colors.primary30}
        size={iconSizes.xxxl}
      />
      <Text
        style={[
          typographies.body,
          {
            textAlign: "center",
            marginBottom: spacings.lg,
            color: colors.primary30
          }
        ]}>
        {message}
      </Text>
      <Button
        buttonStyle={[mediumActionRoundedButtonStyle.button, { minWidth: 235 }]}
        containerStyle={[mediumActionRoundedButtonStyle.container]}
        titleStyle={mediumActionRoundedButtonStyle.title}
        title={t(
          "SelectAffiliationToEnrollNotFoundGetAssistanceButton",
          "Get assistance"
        )}
        onPress={() => {
          navigation.navigate("enrollIdentifiersStack", {
            screen: "identifierNotFound",
            params: {}
          });
        }}></Button>
    </View>
  );
}
