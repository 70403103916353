import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, TextInput, View } from "react-native";
import { Input } from "react-native-elements";
import validator from "validator";
import { colors } from "../styles/Colors";
import { cornerRadius, spacings } from "../styles/Constants";
import { largeInputStyle } from "../styles/Inputs";

export default function NameDataComponent({
  defaultFirstName,
  defaultLastName,
  onFirstNameChanged,
  onLastNameChanged,
  onSubmitCallback
}: {
  defaultFirstName?: string;
  defaultLastName?: string;
  onFirstNameChanged: (firstName?: string) => void;
  onLastNameChanged: (lastName?: string) => void;
  onSubmitCallback?: () => void;
}) {
  const [firstName, setFirstName] = useState(defaultFirstName ?? "");
  const [lastName, setLastName] = useState(defaultLastName ?? "");
  const [isFormValid, setIsFormValid] = useState({
    isValidFirstName: true,
    isValidLastName: true
  });

  const { t } = useTranslation();

  const firstNameRef = useRef<TextInput>(null);
  const lastNameRef = useRef<TextInput>(null);

  return (
    <View>
      <Input
        ref={firstNameRef}
        autoFocus={Platform.OS !== "web" && !firstName}
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          firstNameContainerStyle,
          {
            borderWidth: !isFormValid.isValidFirstName
              ? cornerRadius.xxs
              : undefined,
            borderColor: !isFormValid.isValidFirstName
              ? colors.danger
              : undefined
          }
        ]}
        returnKeyType="next"
        onSubmitEditing={() => lastNameRef.current?.focus()}
        inputStyle={largeInputStyle.input}
        placeholder={t("NameDataComponentNamePlaceholder", "First name *")}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        onChangeText={(value) => onFirstNameChangeText(value)}
        value={firstName}></Input>
      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          lastNameContainerStyle,
          {
            marginBottom:
              !isFormValid.isValidFirstName || !isFormValid.isValidLastName
                ? spacings.lg
                : spacings.none,
            borderWidth: !isFormValid.isValidLastName
              ? cornerRadius.xxs
              : undefined,
            borderColor: !isFormValid.isValidLastName
              ? colors.danger
              : undefined
          }
        ]}
        ref={lastNameRef}
        returnKeyType="next"
        onSubmitEditing={onSubmitCallback}
        inputStyle={largeInputStyle.input}
        placeholder={t("NameDataComponentLastNamePlaceholder", "Last name *")}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        onChangeText={(value) => onLastNameChangeText(value)}
        errorMessage={
          !isFormValid.isValidFirstName
            ? t("NameDataComponentFirstNameNotValid", "First name is not valid")
            : !isFormValid.isValidLastName
            ? t("NameDataComponentLastNameNotValid", "Lastname is not valid")
            : undefined
        }
        value={lastName}></Input>
    </View>
  );

  function onFirstNameChangeText(value: string) {
    setFirstName(value);

    const isFirstNameValid = !validator.isEmpty(value);

    onFirstNameChanged(isFirstNameValid ? value : undefined);
    setIsFormValid({
      ...isFormValid,
      isValidFirstName: isFirstNameValid
    });
  }

  function onLastNameChangeText(value: string) {
    setLastName(value);

    const isLastNameValid = !validator.isEmpty(value);

    onLastNameChanged(isLastNameValid ? value : undefined);
    setIsFormValid({
      ...isFormValid,
      isValidLastName: isLastNameValid
    });
  }
}

const firstNameContainerStyle = StyleSheet.flatten([
  largeInputStyle.container,
  {
    borderRadius: undefined,
    borderTopLeftRadius: cornerRadius.md,
    borderTopRightRadius: cornerRadius.md,
    marginBottom: spacings.xxs
  }
]);

const lastNameContainerStyle = StyleSheet.flatten([
  largeInputStyle.container,
  {
    borderRadius: undefined,
    borderBottomLeftRadius: cornerRadius.md,
    borderBottomRightRadius: cornerRadius.md
  }
]);
