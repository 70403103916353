import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Platform } from "expo-modules-core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "react-native";
import { Button, Input } from "react-native-elements";
import validator from "validator";
import { ResultType } from "../components/ActionResultComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useReCaptchaToken from "../hooks/ReCaptchaHook";
import { RootStackParamsList } from "../navigation";
import { IdentifiersStackParamsList } from "../navigation/ProfileStackNavigation";
import { useAddIdentifier } from "../services/IdentifiersService";
import {
  colors,
  cornerRadius,
  largeInputStyle,
  largePrimaryRoundedButtonStyle,
  spacings
} from "../styles/Styles";

export default function AddIdentifierScreen() {
  const { t } = useTranslation();
  const route =
    useRoute<RouteProp<IdentifiersStackParamsList, "addIdentifier">>();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const addIdentifierMutation = useAddIdentifier();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<IdentifiersStackParamsList, "identifiers">,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const { executeRecaptcha } = useReCaptchaToken("add_identifier");

  const emailRef = useRef<TextInput>(null);

  useEffect(() => {
    if (route.params?.email) {
      setEmail(route.params.email);
    }
  }, [route.params?.email]);

  const isFormValid = email && isEmailValid;

  async function registerIdentifier() {
    if (!isFormValid) {
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha();
      await addIdentifierMutation.mutateAsync({
        identifier: email,
        recaptchaToken: recaptchaToken
      });
      navigation.navigate("addIdentifierResult", {
        type: ResultType.Success,
        description: t(
          "AddIdentifierResultScreenDescription",
          "Please check your mailbox and click on the activation link to validate your email"
        )
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("addIdentifierResult", {
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      switch (e?.status) {
        case 409:
          errorDescription = t(
            "IdentifiersScreenRegisterIdentifierAlreadyExistsError",
            "The specified email is already associated to another user."
          );
          break;
        default:
          errorDescription = t(
            "AddIdentifierErrorMessage",
            "An error occurred when creating the identifier"
          );
          break;
      }

      navigation.navigate("addIdentifierResult", {
        type: ResultType.Error,
        description: errorDescription
      });
    }
  }

  return (
    <ScrollViewComponent>
      <Input
        ref={emailRef}
        autoFocus={Platform.OS !== "web"}
        inputMode="email"
        inputContainerStyle={largeInputStyle.inputContainer}
        autoCapitalize="none"
        containerStyle={[
          largeInputStyle.container,
          {
            paddingHorizontal: spacings.md,
            borderColor: !isEmailValid ? colors.danger : undefined,
            borderWidth: !isEmailValid ? cornerRadius.xxs : undefined
          }
        ]}
        inputStyle={largeInputStyle.input}
        placeholder={t(
          "AddIdentifierScreenEmailPlaceholder",
          "user@example.com"
        )}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        onBlur={() => setIsEmailValid(validator.isEmail(email))}
        onSubmitEditing={registerIdentifier}
        onChangeText={setEmail}
        value={email}
        numberOfLines={1}
        errorMessage={
          !isEmailValid
            ? t(
                "AddIdentifierScreenEmailFormatNotValid",
                "Email format is not valid"
              )
            : undefined
        }></Input>

      <Button
        title={t("AddIdentifierScreenButtonAdd", "Add")}
        disabled={!isFormValid || addIdentifierMutation.isLoading}
        onPress={registerIdentifier}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.xxl
          }
        ]}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        loading={addIdentifierMutation.isLoading}></Button>
    </ScrollViewComponent>
  );
}
