import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, SafeAreaView, TextInput, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import validator from "validator";
import { ResultType } from "../components/ActionResultComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useReCaptchaToken from "../hooks/ReCaptchaHook";
import { AuthenticationStackParamsList } from "../navigation/AuthenticationStackNavigation";
import { useRecoverPassword } from "../services/AuthenticationService";
import {
  colors,
  containerStyle,
  cornerRadius,
  largeInputStyle,
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function ForgotPasswordScreen() {
  const { t } = useTranslation();
  const { executeRecaptcha } = useReCaptchaToken("forgot_password");
  const route =
    useRoute<RouteProp<AuthenticationStackParamsList, "forgotPassword">>();
  const navigation =
    useNavigation<NavigationProp<AuthenticationStackParamsList>>();
  const recoverPasswordMutation = useRecoverPassword();

  const [email, setEmail] = useState(route.params?.email ?? "");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const emailRef = useRef<TextInput>(null);

  const isFormValid = email && isEmailValid;

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={[
          {
            paddingVertical: spacings.xl,
            flex: 1
          },
          containerStyle.container
        ]}>
        <Text
          style={[
            typographies.body,
            {
              marginHorizontal: spacings.xl,
              marginTop: spacings.xl,
              marginBottom: spacings.md,
              textAlign: "center"
            }
          ]}>
          {t(
            "ForgotPasswordScreenEnterEmailMessage",
            "Please enter your email address, we will send you a link to reset your password"
          )}
        </Text>

        <Input
          ref={emailRef}
          autoFocus={Platform.OS !== "web"}
          inputMode="email"
          inputContainerStyle={largeInputStyle.inputContainer}
          containerStyle={[
            largeInputStyle.container,
            {
              marginBottom: spacings.lg,
              borderColor: !isEmailValid ? colors.danger : undefined,
              borderWidth: !isEmailValid ? cornerRadius.xxs : undefined
            }
          ]}
          inputStyle={largeInputStyle.input}
          placeholder={t(
            "ForgotPasswordScreenEmailPlaceholder",
            "Email address"
          )}
          autoCorrect={false}
          autoCapitalize="none"
          placeholderTextColor={colors.text.placeholder}
          errorMessage={
            !isEmailValid
              ? t("ForgotPasswordScreenEmailNotValid", "Email is not valid")
              : undefined
          }
          onBlur={validate}
          onSubmitEditing={recoverPasswordAsync}
          onChangeText={setEmail}
          value={email}></Input>

        <Button
          title={t(
            "ForgotPasswordScreenSendResetPasswordLinkButtonTitle",
            "Send reset password link"
          )}
          disabled={!isFormValid || recoverPasswordMutation.isLoading}
          titleStyle={largePrimaryRoundedButtonStyle.title}
          containerStyle={[
            largePrimaryRoundedButtonStyle.container,
            { marginTop: spacings.xl }
          ]}
          loading={recoverPasswordMutation.isLoading}
          onPress={recoverPasswordAsync}
          loadingStyle={largePrimaryRoundedButtonStyle.loading}
          buttonStyle={largePrimaryRoundedButtonStyle.button}
          disabledStyle={largePrimaryRoundedButtonStyle.disabled}></Button>

        <View
          style={{
            marginHorizontal: spacings.lg,
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end"
          }}>
          <Text
            style={[
              typographies.body,
              { textAlign: "center", marginBottom: spacings.sm }
            ]}>
            {t(
              "ForgotPasswordScreenDontHaveAccountMessage",
              "Don't have an account?"
            )}
          </Text>

          <Button
            title={t("ForgotPasswordScreenSignUpButtonTitle", "Sign up")}
            titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
            containerStyle={{ marginBottom: spacings.md }}
            onPress={() => navigation.navigate("register")}
            buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}></Button>
        </View>
      </View>
    </SafeAreaView>
  );

  function validate() {
    setIsEmailValid(validator.isEmail(email));
  }

  async function recoverPasswordAsync() {
    if (!isFormValid) {
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha();
      await recoverPasswordMutation.mutateAsync({
        email,
        recaptchaToken
      });
      navigation.navigate("forgotPasswordResult", {
        type: ResultType.Success,
        description: t(
          "ForgotPasswordScreenPasswordRecoveredMessage",
          "Please click on the link sent by email to reset your password."
        )
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("forgotPasswordResult", {
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      switch (e?.status) {
        case 404:
          errorDescription = t(
            "ForgotPasswordScreenRecoverPasswordError",
            "No account found with the specified email address."
          );
          break;

        default:
          errorDescription = t(
            "ForgotPasswordScreenInternalServerErrorMessage",
            "Internal server error"
          );
          break;
      }

      navigation.navigate("forgotPasswordResult", {
        type: ResultType.Error,
        description: errorDescription
      });
    }
  }
}
