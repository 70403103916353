import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import { REMEMBER_ME } from "../providers/IdentityConstants";

export async function deleteItemAsync(
  key: string,
  options?: SecureStore.SecureStoreOptions
): Promise<void> {
  if (isWeb()) {
    if (await rememberMe()) {
      await AsyncStorage.removeItem(key);
    } else {
      window.sessionStorage.removeItem(key);
    }
    return;
  }

  await SecureStore.deleteItemAsync(key, options);
}

export async function getItemAsync(
  key: string,
  options?: SecureStore.SecureStoreOptions
): Promise<string | null> {
  try {
    if (isWeb()) {
      if (await rememberMe()) {
        return await AsyncStorage.getItem(key);
      } else {
        return window.sessionStorage.getItem(key);
      }
    }

    return await SecureStore.getItemAsync(key, options);
  } catch (e) {
    // We delete it because of bugs. Tracking it in:
    // https://github.com/expo/expo/issues/19018
    // https://github.com/expo/expo/issues/1459
    // https://expo.canny.io/feature-requests/p/securestore-fix-could-not-decrypt-the-item-in-securestore
    await deleteItemAsync(key);
    return null;
  }
}

export async function setItemAsync(
  key: string,
  value: string,
  options?: SecureStore.SecureStoreOptions
): Promise<void> {
  if (isWeb()) {
    if (key === REMEMBER_ME || (await rememberMe())) {
      await AsyncStorage.setItem(key, value);
    } else {
      window.sessionStorage.setItem(key, value);
    }
    return;
  }

  await SecureStore.setItemAsync(key, value, options);
}

function isWeb(): boolean {
  return Platform.OS === "web";
}

async function rememberMe(): Promise<boolean> {
  const rm = await AsyncStorage.getItem(REMEMBER_ME);
  return rm ? rm === "true" : false;
}
