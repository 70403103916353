import { useState } from "react";

export function useAction(action: () => Promise<void>) {
  const [isBusy, setIsBusy] = useState(false);

  async function execute() {
    if (isBusy) {
      return;
    }

    try {
      setIsBusy(true);
      await action();
    } finally {
      setIsBusy(false);
    }
  }

  return { isBusy, execute };
}
