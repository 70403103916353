import { useMutation, useQuery } from "@tanstack/react-query";
import { serverStatusQueryKey } from "./QueryService";
import { ContactInfo, IContactInfo } from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

const refetchInterval = 10000;

async function contactPlatform(
  contactInfoModel: IContactInfo,
  recaptchaToken?: string
) {
  const platformClient = platformClientFactory.create();
  const contactInfo = new ContactInfo(contactInfoModel);

  await platformClient.contact(contactInfo, recaptchaToken);
}

export function useContactPlatform() {
  return useMutation(
    ({
      contactInfoModel,
      recaptchaToken
    }: {
      contactInfoModel: IContactInfo;
      recaptchaToken?: string;
    }) => contactPlatform(contactInfoModel, recaptchaToken)
  );
}

export function getServerStatus(): Promise<void> {
  const platformClient = platformClientFactory.create();
  return platformClient.getStatus();
}

export function useServerStatus() {
  return useQuery([serverStatusQueryKey], getServerStatus, {
    refetchInterval: refetchInterval
  });
}
