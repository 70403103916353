import { Camera, CameraProps } from "expo-camera";
import { minBy } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { Platform } from "react-native";

export function CameraViewComponent(props: CameraProps) {
  const cameraRef = useRef<Camera | null>(null);
  const [size, setSize] = useState<{ height: number; width: number }>();
  const [supportedRatios, setSupportedRatios] =
    useState<{ ratio: string; value: number }[]>();

  const ratio = useMemo(() => {
    if (!supportedRatios || !size) return undefined;
    const cameraViewRatio =
      Math.max(size.height, size.width) / Math.min(size.height, size.width);
    const ratioValue = minBy(supportedRatios, (o) =>
      Math.abs(o.value - cameraViewRatio)
    );
    return ratioValue?.ratio;
  }, [size, supportedRatios]);

  const refreshSupportedRatios = useCallback(async () => {
    if (!cameraRef?.current) return;
    if (Platform.OS !== "android") return;

    const ratios = await cameraRef.current.getSupportedRatiosAsync();
    const ratioValues = ratios.map((r) => {
      const parts = r.split(":");
      const width = parseInt(parts[0]);
      const height = parseInt(parts[1]);

      return {
        ratio: r,
        value: Math.max(height, width) / Math.min(height, width)
      };
    });

    setSupportedRatios(ratioValues);
  }, [cameraRef?.current]);

  return (
    <Camera
      {...props}
      ref={(ref) => {
        cameraRef.current = ref;
      }}
      onLayout={(event) => {
        setSize({
          height: event.nativeEvent.layout.height,
          width: event.nativeEvent.layout.width
        });
      }}
      onCameraReady={refreshSupportedRatios}
      ratio={ratio}></Camera>
  );
}
