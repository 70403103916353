import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, Text, View } from "react-native";
import { Chip, ListItem } from "react-native-elements";
import { IdentifiersListSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { useAction } from "../hooks/ActionHook";
import { EnrollIdentifiersStackParamsList } from "../navigation";
import { useAuth } from "../providers/AuthenticationProvider";
import { useUserInfo } from "../services/AuthenticationService";
import { useIdentifiers } from "../services/IdentifiersService";
import { invalidateIdentifiers } from "../services/QueryService";
import {
  IIdentifier,
  IdentifierStatus
} from "../services/clients/PlatformClient";
import { listItemHeights } from "../styles/Constants";
import {
  colors,
  cornerRadius,
  iconSizes,
  spacings,
  typographies
} from "../styles/Styles";

export default function IdentifiersScreen() {
  const identifiersQuery = useIdentifiers();
  const { accessToken } = useAuth();
  const userInfoQuery = useUserInfo(accessToken!);
  const refreshAction = useAction(refresh);

  async function refresh(): Promise<void> {
    await invalidateIdentifiers();
  }

  if (identifiersQuery.isInitialLoading || userInfoQuery.isInitialLoading) {
    return <IdentifiersListSkeleton />;
  }

  return (
    <ScrollViewComponent
      refreshControl={
        <RefreshControl
          refreshing={refreshAction.isBusy}
          onRefresh={refreshAction.execute}
        />
      }>
      <View
        style={{
          borderWidth: cornerRadius.none,
          borderRadius: cornerRadius.md,
          overflow: "hidden"
        }}>
        {identifiersQuery.data?.map((item, index) => (
          <IdentifierItem
            identifier={item}
            isPrimary={
              item.value.toUpperCase() ===
              userInfoQuery.data?.preferred_username.toUpperCase()
            }
            key={index}
          />
        ))}
      </View>
    </ScrollViewComponent>
  );
}

export function IdentifierItem({
  identifier,
  isPrimary,
  showStatusIcon = true
}: {
  identifier: IIdentifier;
  isPrimary: boolean;
  showStatusIcon?: boolean;
}) {
  const { t } = useTranslation();
  const navigation =
    useNavigation<NavigationProp<EnrollIdentifiersStackParamsList>>();

  const isValidated = identifier.status === IdentifierStatus.Validated;

  return (
    <ListItem
      style={{
        marginBottom: cornerRadius.xxs,
        borderWidth: cornerRadius.none
      }}
      containerStyle={{
        height: listItemHeights.md
      }}
      onPress={() =>
        navigation.navigate("identifierDetail", {
          identifierId: identifier.identifierId
        })
      }>
      <ListItem.Content
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row"
        }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            marginRight: spacings.md
          }}>
          <View style={{ flexShrink: 1, marginRight: spacings.md }}>
            <Text style={typographies.body} numberOfLines={1}>
              {identifier.value}
            </Text>
            {!isValidated ? (
              <Text
                style={[
                  typographies.caption,
                  {
                    color: colors.danger,
                    marginTop: spacings.sm
                  }
                ]}>
                {t(
                  "AddIdentifierScreenEmailNotValidated",
                  "Email not validated"
                )}
              </Text>
            ) : null}
          </View>

          {isPrimary && (
            <Chip
              title={t("IdentifiersScreenPrimaryBadge", "Primary")}
              buttonStyle={{
                borderRadius: cornerRadius.sm,
                backgroundColor: colors.chip.primary,
                paddingHorizontal: spacings.lg,
                paddingVertical: spacings.xs
              }}
              titleStyle={[
                typographies.caption,
                { color: colors.text.light }
              ]}></Chip>
          )}
        </View>

        {showStatusIcon && (
          <FontAwesomeIcon
            style={{
              color: isValidated ? colors.primary : colors.danger
            }}
            icon={isValidated ? faCheck : faExclamationCircle}
            size={iconSizes.lg}></FontAwesomeIcon>
        )}
      </ListItem.Content>
    </ListItem>
  );
}
