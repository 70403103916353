import { useMutation, useQuery } from "@tanstack/react-query";
import { IPersonKey } from "./AccountsService";
import { getDataUrl } from "./BlobService";
import { getImageSize } from "./ImageService";
import {
  affiliationCardLayoutQueryKey,
  invalidateAccounts,
  queryClient
} from "./QueryService";
import { ApiException, LayoutFace, LayoutType } from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

const cardLayoutStaleTime: number = 60 * 60 * 1000;

export interface IMediumKey extends IPersonKey {
  mediumId: number;
}

async function getCardLayout(
  personKey: IPersonKey,
  face: LayoutFace,
  type: LayoutType
): Promise<{ uri: string; width: number; height: number } | null> {
  try {
    const platformClient = platformClientFactory.create();
    var fileResponse = await platformClient.getVirtualLayout(
      personKey.identifierId,
      personKey.affiliationId,
      personKey.personId,
      face,
      type
    );
  } catch (e) {
    if (
      (e instanceof ApiException && e.status <= 400) ||
      (e instanceof ApiException && e.status === 403)
    ) {
      // No card layout defined or virtual card not allowed
      return null;
    }

    throw e;
  }

  const uri = await getDataUrl(fileResponse.data);
  if (!uri) {
    return null;
  }

  const size = await getImageSize(uri);
  return { uri: uri, width: size.width, height: size.height };
}

export function useBlockMedium() {
  return useMutation(
    ({
      identifierId,
      affiliationId,
      personId,
      mediumId
    }: {
      identifierId: number;
      affiliationId: number;
      personId: number;
      mediumId: number;
    }) => blockMedium(identifierId, affiliationId, personId, mediumId)
  );
}

async function blockMedium(
  identifierId: number,
  affiliationId: number,
  personId: number,
  mediumId: number
): Promise<void> {
  const platformClient = platformClientFactory.create();
  await platformClient.blockMedium(
    identifierId,
    affiliationId,
    personId,
    mediumId
  );

  await invalidateAccounts({
    refetchType: "all"
  });
}

export function useAffiliationCardLayout(
  personKey: IPersonKey,
  face: LayoutFace,
  type: LayoutType
) {
  return useQuery(
    [affiliationCardLayoutQueryKey, personKey, face, type],
    () => getCardLayout(personKey, face, type),
    { staleTime: cardLayoutStaleTime }
  );
}

export function prefetchAffiliationCardLayout(
  personKey: IPersonKey,
  face: LayoutFace,
  type: LayoutType
) {
  return queryClient.prefetchQuery(
    [affiliationCardLayoutQueryKey, personKey, face, type],
    () => getCardLayout(personKey, face, type),
    { staleTime: cardLayoutStaleTime }
  );
}
