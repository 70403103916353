import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAddressBook } from "@fortawesome/pro-light-svg-icons/faAddressBook";
import { faAt } from "@fortawesome/pro-light-svg-icons/faAt";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { faFileContract } from "@fortawesome/pro-light-svg-icons/faFileContract";
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faLock } from "@fortawesome/pro-light-svg-icons/faLock";
import { faMessagesQuestion } from "@fortawesome/pro-light-svg-icons/faMessagesQuestion";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  CompositeNavigationProp,
  useNavigation
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, SafeAreaView, View } from "react-native";
import { Text } from "react-native-elements";
import { ProfileSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { RootStackParamsList } from "../navigation";
import { ProfileStackParamsList } from "../navigation/ProfileStackNavigation";
import { getApplicationInfo } from "../services/AboutService";
import { useIdentifiers } from "../services/IdentifiersService";
import { useProfile } from "../services/ProfileService";
import { pressableStyle } from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { iconSizes, spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";
import { virtualizedListItemStyle } from "../styles/Lists";

export const itemHeight = 80;

export default function ProfileScreen() {
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<ProfileStackParamsList, "profile">,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const identifiersQuery = useIdentifiers();
  const profileQuery = useProfile();
  const { t } = useTranslation();

  if (identifiersQuery.isInitialLoading || profileQuery.isInitialLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <SafeAreaView
      style={{
        flex: 1
      }}>
      <ScrollViewComponent>
        <ProfileItemDetail
          title={t("ProfileScreenPersonalData", "Personal data")}
          description={t(
            "ProfileScreenPersonalDataDescription",
            "Edit your account information like your address or phone number"
          )}
          firstItem
          iconLeft={faUser}
          onPress={() => navigation.navigate("personalData")}
        />

        <ProfileItemDetail
          title={t("ProfileScreenEmailTitle", "Emails")}
          description={t(
            "ProfileScreenEmailDescription",
            "Manage your email addresses"
          )}
          iconLeft={faAt}
          onPress={() =>
            navigation.navigate("identifiersStack", { screen: "identifiers" })
          }
        />

        <ProfileItemDetail
          title={t("ProfileScreenCreditCardsTitle", "Credit cards")}
          description={t(
            "ProfileScreenCreditCardsDescription",
            "Manage credit cards and payment methods"
          )}
          iconLeft={faCreditCard}
          onPress={() =>
            navigation.navigate("creditCardsStack", { screen: "creditCards" })
          }
        />

        <ProfileItemDetail
          title={t("ProfileScreenBeneficiariesTitle", "Beneficiaries")}
          description={t(
            "ProfileScreenBeneficiariesDescription",
            "Manage saved beneficiaries"
          )}
          iconLeft={faAddressBook}
          onPress={() =>
            navigation.navigate("beneficiariesStack", {
              screen: "beneficiaries"
            })
          }
        />

        <ProfileItemDetail
          title={t("ProfileScreenLanguage", "Language")}
          description={t(
            "ProfileScreenLanguageDescription",
            "Specify your preferred language for communications and application"
          )}
          iconLeft={faGlobe}
          onPress={() => navigation.navigate("languageSelection")}
        />

        <ProfileItemDetail
          title={t("ProfileScreenSecurityTitle", "Security")}
          description={t(
            "ProfileScreenSecurityDescription",
            "Change your password or other security options"
          )}
          iconLeft={faLock}
          onPress={() =>
            navigation.navigate("securityStack", { screen: "securitySettings" })
          }
        />

        <ProfileItemDetail
          title={t("ProfileScreenHelpCenterTitle", "Help Center")}
          description={t(
            "ProfileScreenHelpCenterDescription",
            "Consult frequent questions and contact support for additional assistance"
          )}
          iconLeft={faMessagesQuestion}
          onPress={() =>
            navigation.navigate("helpCenterProfileStack", {
              screen: "helpCenterProfile"
            })
          }
        />

        <ProfileItemDetail
          title={t("ProfileScreenTermsConditions", "Terms & conditions")}
          description={t(
            "ProfileScreenTermsConditionsDescription",
            "Consult & download documents"
          )}
          iconLeft={faFileContract}
          onPress={() => navigation.navigate("generalTerms")}
        />

        <ProfileItemDetail
          lastItem
          title={t("ProfileScreenDeleteUser", "Delete user")}
          description={t(
            "ProfileScreenDeleteUserDescription",
            "Delete your user account and all related user data"
          )}
          iconLeft={faTrash}
          onPress={() => navigation.navigate("confirmDeleteUser")}
        />

        <View style={{ marginTop: spacings.lg }}>
          <Text style={[typographies.small, { textAlign: "center" }]}>
            {t("AboutScreenVersion", "Version {{version}}", {
              version: getApplicationInfo().version
            })}
          </Text>
          <Text
            style={[
              typographies.small,
              { textAlign: "center", marginBottom: spacings.sm }
            ]}>
            {t("AboutScreenCopyright", "© SECANDA AG. All rights reserved")}
          </Text>
        </View>
      </ScrollViewComponent>
    </SafeAreaView>
  );
}

export function ProfileItemDetail({
  title,
  description,
  iconLeft,
  iconRight,
  firstItem,
  lastItem,
  onPress
}: {
  title: string | undefined;
  description: string;
  iconLeft: IconProp;
  iconRight?: IconProp;
  firstItem?: boolean;
  lastItem?: boolean;
  onPress: () => void;
}) {
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        pressed && pressableStyle.pressed,
        firstItem && virtualizedListItemStyle.firstItem,
        lastItem
          ? virtualizedListItemStyle.lastItem
          : virtualizedListItemStyle.middleItem,
        {
          backgroundColor: colors.background.light,
          flexDirection: "row",
          height: itemHeight,
          paddingHorizontal: spacings.xl,
          paddingVertical: spacings.lg,
          alignItems: "center"
        }
      ]}>
      <FontAwesomeIcon
        icon={iconLeft}
        size={iconSizes.xl}
        color={colors.text.primary}></FontAwesomeIcon>

      <View
        style={{
          flexDirection: "column",
          marginHorizontal: spacings.lg,
          flex: 1
        }}>
        <Text style={typographies.body}>{title}</Text>
        <Text style={typographies.caption}>{description}</Text>
      </View>

      <FontAwesomeIcon
        icon={iconRight ?? faChevronRight}
        size={iconSizes.md}
        color={colors.text.primary}></FontAwesomeIcon>
    </Pressable>
  );
}
