import { RouteProp, useRoute } from "@react-navigation/native";
import { endOfDay, startOfDay } from "date-fns";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import { default as i18n } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Text } from "react-native-elements";
import DatePickerComponent from "../components/DatePickerComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { TransactionsStackParamsList } from "../navigation/HomeStackNavigation";
import { IAccountKey } from "../services/AccountsService";
import { toastError } from "../services/ToastService";
import { useExportTransactions } from "../services/TransactionsService";
import { FileType } from "../services/clients/PlatformClient";
import {
  largePrimaryRoundedButtonStyle,
  largeRoundedButtonGroupStyle
} from "../styles/Buttons";
import { spacings, typographies } from "../styles/Styles";

export default function ExportTransactionsScreen() {
  const route =
    useRoute<RouteProp<TransactionsStackParamsList, "exportTransactions">>();

  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(startOfMonth(Date.now()));
  const [endDate, setEndDate] = useState<Date>(endOfMonth(Date.now()));
  const [datesInRange, setDatesInRange] = useState(true);

  const exportTransactionsMutation = useExportTransactions();

  useEffect(() => {
    setDatesInRange(
      endDate && startDate && startDate.getTime() <= endDate.getTime()
    );
  }, [startDate, endDate]);

  const fileTypes: { label: string; value: FileType }[] = [
    { label: t("ExportTransactionsCsv", "CSV"), value: FileType.Csv },
    { label: t("ExportTransactionsPdf", "PDF"), value: FileType.Pdf }
  ];
  const [selectedFileTypeIndex, setSelectedFileTypeIndex] = useState<number>(
    fileTypes.findIndex((f) => f.value === FileType.Pdf)
  );

  return (
    <ScrollViewComponent>
      <ButtonGroup
        buttons={fileTypes.flatMap((f) => f.label)}
        selectedIndex={selectedFileTypeIndex}
        innerBorderStyle={largeRoundedButtonGroupStyle.innerBorder}
        containerStyle={largeRoundedButtonGroupStyle.container}
        selectedButtonStyle={largeRoundedButtonGroupStyle.selectedButton}
        textStyle={largeRoundedButtonGroupStyle.text}
        onPress={setSelectedFileTypeIndex}
      />

      <Text
        style={[
          typographies.title,
          {
            marginBottom: spacings.sm,
            marginTop: spacings.xl
          }
        ]}>
        {t("ExportTransactionsScreenStartDate", "Start Date")}
      </Text>
      <DatePickerComponent date={startDate} onValueChange={onStartDateChange} />

      <Text
        style={[
          typographies.title,
          {
            marginVertical: spacings.sm
          }
        ]}>
        {t("ExportTransactionsScreenEndDate", "End Date")}
      </Text>
      <DatePickerComponent date={endDate} onValueChange={onEndDateChange} />

      <Button
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        title={t("ExportTransactionsScreenExportButton", "Export")}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        loading={exportTransactionsMutation.isLoading}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabled={exportTransactionsMutation.isLoading || !datesInRange}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        onPress={exportAsync}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.xxl
          }
        ]}></Button>
    </ScrollViewComponent>
  );

  function onStartDateChange(value: Date | undefined) {
    if (value) {
      setStartDate(value);
    }
  }

  function onEndDateChange(value: Date | undefined) {
    if (value) {
      setEndDate(value);
    }
  }

  async function exportAsync() {
    try {
      let accountKey: IAccountKey | undefined;
      if (route.params) {
        accountKey = {
          identifierId: route.params.identifierId,
          affiliationId: route.params.affiliationId,
          personId: route.params.personId,
          accountId: route.params.accountId
        };
      }

      await exportTransactionsMutation.mutateAsync({
        accountKey: accountKey,
        fileType: fileTypes[selectedFileTypeIndex].value,
        startDate: startOfDay(startDate),
        endDate: endOfDay(endDate),
        languageCode: i18n.language
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "ExportTransactionsScreenError",
          "An error ocurred when exporting the transactions."
        )
      );
    }
  }
}
