import { StyleSheet } from "react-native";
import { cornerRadius, spacings } from "./Constants";

export const mediumCardStyle = StyleSheet.create({
  container: {
    borderRadius: cornerRadius.md,
    borderWidth: cornerRadius.none,
    elevation: 0,
    margin: spacings.none,
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    shadowRadius: cornerRadius.none
  }
});
