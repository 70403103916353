import { faTools } from "@fortawesome/pro-light-svg-icons/faTools";
import { faWifiSlash } from "@fortawesome/pro-light-svg-icons/faWifiSlash";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import useScreenSize from "../hooks/ScreenSizeHook";
import { useServerStatus } from "../services/PlatformService";
import {
  colors,
  containerStyle,
  largePrimaryOutlineRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export enum ErrorType {
  NoInternet,
  ServerInMaintenance
}

export default function NoConnectionScreen({
  errorType
}: {
  errorType: ErrorType;
}) {
  const serverStatus = useServerStatus();
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const isServerInMaintenance = errorType === ErrorType.ServerInMaintenance;

  async function getServerStatusAsync() {
    serverStatus.refetch();
  }

  return (
    <View
      style={[
        containerStyle.container,
        { justifyContent: "center", alignItems: "center", flex: 1 }
      ]}>
      <FontAwesomeIcon
        size={screenSize.large ? 180 : 100}
        color={colors.primary}
        icon={isServerInMaintenance ? faTools : faWifiSlash}></FontAwesomeIcon>

      <Text
        style={[
          typographies.heading,
          {
            alignSelf: "center",
            textAlign: "center",
            marginVertical: spacings.md
          }
        ]}>
        {t("Oops", "Oops!")}
      </Text>

      <Text
        style={[
          typographies.body,
          {
            alignSelf: "center",
            textAlign: "center"
          }
        ]}>
        {isServerInMaintenance
          ? t(
              "ServerInMaintenanceMode",
              "We are currently down for maintenance. We expect to be back soon. Thank you for you patience."
            )
          : t(
              "NoInternetConnection",
              "There is no Internet connection. Please check your Internet connection."
            )}
      </Text>

      {isServerInMaintenance && (
        <Button
          title={t("RetryButton", "Retry")}
          onPress={getServerStatusAsync}
          buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}
          titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
          containerStyle={{
            marginTop: spacings.lg,
            alignSelf: "stretch"
          }}></Button>
      )}
    </View>
  );
}
