import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamsList } from "../navigation";

export default function useResetHomeNavigation() {
  const navigation = useNavigation<StackNavigationProp<RootStackParamsList>>();

  function reset() {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: "root",
          state: {
            routes: [{ name: "homeStack" }]
          }
        }
      ]
    });
  }

  return { reset };
}
