import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import countries from "i18n-iso-countries";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, TextInput, View } from "react-native";
import { Input, Text } from "react-native-elements";
import validator from "validator";
import i18n from "../providers/i18n";
import {
  colors,
  cornerRadius,
  largeInputStyle,
  pressableStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function AddressDataComponent({
  defaultAddress,
  defaultCity,
  defaultPostalCode,
  defaultCountryCode,
  addressInputReference,
  onAddressChanged,
  onCityChanged,
  onPostalCodeChanged,
  onCountrySelection
}: {
  defaultAddress?: string;
  defaultCity?: string;
  defaultPostalCode?: string;
  defaultCountryCode?: string;
  addressInputReference?: RefObject<TextInput>;
  onAddressChanged: (address?: string) => void;
  onCityChanged: (city?: string) => void;
  onPostalCodeChanged: (postalCode?: string) => void;
  onCountrySelection: () => void;
}) {
  const [address, setAddress] = useState(defaultAddress ?? "");
  const [city, setCity] = useState(defaultCity ?? "");
  const [postalCode, setPostalCode] = useState(defaultPostalCode ?? "");

  const [isFormValid, setIsFormValid] = useState({
    isValidAddress: true,
    isValidCity: true,
    isValidPostalCode: true,
    isValidCountryCode: true
  });

  const { t } = useTranslation();

  const postalCodeRef = useRef<TextInput>(null);
  const cityRef = useRef<TextInput>(null);

  useEffect(() => {
    onCountryChangeCode(defaultCountryCode ?? "");
  }, [defaultCountryCode]);

  const countryName = useMemo(() => {
    if (!defaultCountryCode) return undefined;

    const language = i18n.language.substring(0, 2);
    return countries.getName(defaultCountryCode, language);
  }, [defaultCountryCode, i18n.language]);

  return (
    <View>
      <Input
        containerStyle={[
          largeInputStyle.container,
          {
            marginTop: spacings.lg,
            marginBottom: !isFormValid.isValidAddress
              ? cornerRadius.lg
              : undefined,
            borderWidth: !isFormValid.isValidAddress
              ? cornerRadius.xxs
              : undefined,
            borderColor: !isFormValid.isValidAddress ? colors.danger : undefined
          }
        ]}
        ref={addressInputReference}
        returnKeyType="next"
        onSubmitEditing={() => {
          postalCodeRef.current?.focus();
        }}
        inputContainerStyle={largeInputStyle.inputContainer}
        inputStyle={largeInputStyle.input}
        placeholder={t("AddressPlaceholder", "Address *")}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        value={address}
        errorMessage={
          !isFormValid.isValidAddress
            ? t("AddressDataComponentAddressNotValid", "Address is not valid")
            : undefined
        }
        onChangeText={(value) => onAddressChangeText(value)}></Input>
      <View
        style={{
          flexDirection: "row",
          marginTop: spacings.xs,
          marginBottom:
            !isFormValid.isValidPostalCode || !isFormValid.isValidCity
              ? cornerRadius.lg
              : undefined
        }}>
        <Input
          inputMode="text"
          containerStyle={[
            largeInputStyle.container,
            {
              flex: 1,
              marginRight: spacings.sm,
              borderWidth: !isFormValid.isValidPostalCode
                ? cornerRadius.xxs
                : undefined,
              borderColor: !isFormValid.isValidPostalCode
                ? colors.danger
                : undefined
            }
          ]}
          ref={postalCodeRef}
          returnKeyType="next"
          onSubmitEditing={() => {
            cityRef.current?.focus();
          }}
          inputContainerStyle={largeInputStyle.inputContainer}
          inputStyle={largeInputStyle.input}
          placeholder={t("PostalCodePlaceholder", "Postal code *")}
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          value={postalCode}
          errorMessage={
            !isFormValid.isValidPostalCode
              ? t(
                  "AddressDataComponentPostalCodeNotValid",
                  "Postal code is not valid"
                )
              : undefined
          }
          onChangeText={(value) => onPostalCodeChangeText(value)}></Input>
        <Input
          inputMode="text"
          containerStyle={[
            largeInputStyle.container,
            {
              flex: 2,
              borderWidth: !isFormValid.isValidCity
                ? cornerRadius.xxs
                : undefined,
              borderColor: !isFormValid.isValidCity ? colors.danger : undefined
            }
          ]}
          ref={cityRef}
          returnKeyType="next"
          onSubmitEditing={() => onCountrySelection()}
          inputContainerStyle={largeInputStyle.inputContainer}
          inputStyle={largeInputStyle.input}
          placeholder={t("CityPlaceholder", "City *")}
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          value={city}
          errorMessage={
            !isFormValid.isValidCity
              ? t("AddressDataComponentCityNotValid", "City is not valid")
              : undefined
          }
          onChangeText={(value) => onCityChangeText(value)}></Input>
      </View>

      <Pressable
        style={({ pressed }) => [
          pressed && pressableStyle.pressed,
          {
            backgroundColor: colors.background.light,
            height: largeInputStyle.container.height,
            borderRadius: cornerRadius.md,
            paddingHorizontal: spacings.lg,
            paddingVertical: spacings.sm,
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            marginTop: spacings.xs,
            marginBottom: spacings.lg
          }
        ]}
        onPress={() => onCountrySelection()}>
        <Text
          style={[
            typographies.subtitle,
            {
              flex: 1,
              ...(!defaultCountryCode && {
                color: colors.text.placeholder
              })
            }
          ]}>
          {countryName ?? t("CountryPlaceholder", "Select your country *")}
        </Text>

        <FontAwesomeIcon
          icon={faChevronRight}
          color={
            defaultCountryCode ? colors.text.primary : colors.text.placeholder
          }
        />
      </Pressable>
    </View>
  );

  function onAddressChangeText(value: string) {
    setAddress(value);

    const isAddressValid = !validator.isEmpty(value);

    onAddressChanged(isAddressValid ? value : undefined);
    setIsFormValid({
      ...isFormValid,
      isValidAddress: isAddressValid
    });
  }

  function onPostalCodeChangeText(value: string) {
    setPostalCode(value);

    const isPostalCodeValid = !validator.isEmpty(value);

    onPostalCodeChanged(isPostalCodeValid ? value : undefined);
    setIsFormValid({
      ...isFormValid,
      isValidPostalCode: isPostalCodeValid
    });
  }

  function onCityChangeText(value: string) {
    setCity(value);

    const isCityValid = !validator.isEmpty(value);

    onCityChanged(isCityValid ? value : undefined);
    setIsFormValid({
      ...isFormValid,
      isValidCity: isCityValid
    });
  }

  function onCountryChangeCode(value: string) {
    const isCountryCodeValid = !validator.isEmpty(value);

    setIsFormValid({
      ...isFormValid,
      isValidCountryCode: isCountryCodeValid
    });
  }
}
