import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { IntentLauncherParams, startActivityAsync } from "expo-intent-launcher";
import { default as React, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Platform } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { WebViewNavigation } from "react-native-webview";
import { WebViewMessage } from "react-native-webview/lib/WebViewTypes";
import { ResultType } from "../components/ActionResultComponent";
import WebViewComponent from "../components/WebViewComponent";
import { useBackHandler } from "../hooks/BackHandlerHook";
import { RootStackParamsList } from "../navigation";
import { IAccountKey, IPersonKey } from "../services/AccountsService";
import { PaymentMode } from "../services/AffiliationsService";
import {
  invalidateAccount,
  invalidateCreditCards,
  invalidatePaymentModes,
  invalidateRefundInfo,
  invalidateTransactions
} from "../services/QueryService";
import { toastError } from "../services/ToastService";
import { useTransactionStatus } from "../services/TransactionsService";
import { TransactionStatus } from "../services/clients/PlatformClient";
import { colors } from "../styles/Styles";

export default function CreditCardPaymentScreen() {
  const navigation = useNavigation<StackNavigationProp<RootStackParamsList>>();
  const route = useRoute<RouteProp<RootStackParamsList, "creditCardPayment">>();
  const { t } = useTranslation();
  const hasNavigated = useRef(false);
  const flagActivityNewTask = 268435456;
  const originExternalWebBrowser = "EXTERNAL_WEB_BROWSER";
  const personKey: IPersonKey = {
    identifierId: route.params.identifierId,
    affiliationId: route.params.affiliationId,
    personId: route.params.personId
  };
  const accountKey: IAccountKey = {
    ...personKey,
    accountId: route.params.accountId
  };
  const transactionStatus = useTransactionStatus(route.params.transactionId, {
    refetchInterval: 2000
  });
  useBackHandler(() => !hasNavigated.current);

  const injectedJavaScript = useMemo(() => {
    if (route.params.paymentMode !== PaymentMode.Twint) return undefined;

    const isIOSWebView = `window.isIOSWebView=${
      Platform.OS === "ios" ? "true" : "false"
    }"; `;
    const isAndroidWebView = `window.isAndroidWebView=${
      Platform.OS === "android" ? "true" : "false"
    }; `;
    const isWebView = `window.isWebView=true; `;
    const initialSetup = `window.twintHookActivated=true; window.refreshUI(); `;

    return `${isIOSWebView}${isAndroidWebView}${isWebView}${initialSetup}`;
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", async (e) => {
      // If transaction completed then allow navigation
      if (hasNavigated.current) {
        navigation.dispatch(e.data.action);
        return;
      }

      e.preventDefault();
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (transactionStatus.data?.status === TransactionStatus.Successful) {
      paymentSuccessful();
    } else if (transactionStatus.data?.status === TransactionStatus.Canceled) {
      paymentCanceled();
    } else if (transactionStatus.data?.status === TransactionStatus.Failed) {
      paymentFailed();
    }
  }, [transactionStatus.data?.status]);

  return (
    <SafeAreaView style={{ backgroundColor: colors.background.light, flex: 1 }}>
      <WebViewComponent
        path={route.params.pspStartUrl}
        injectedJavaScript={injectedJavaScript}
        canNavigate={canNavigate}
        onNavigation={onNavigation}
        onMessage={onMessage}></WebViewComponent>
    </SafeAreaView>
  );

  function canNavigate(params: WebViewNavigation): boolean {
    return processCallback(params.url) !== true;
  }

  function onNavigation(params: WebViewNavigation): void {
    processCallback(params.url);
  }

  function onMessage(message: WebViewMessage): void {
    processCallback(message.data);
  }

  function processCallback(data: string): boolean {
    if (!data?.startsWith) {
      return false;
    }

    if (data.startsWith("twint-")) {
      manageIOSNavigation(data);
      return true;
    }

    if (data.startsWith("intent:")) {
      manageAndroidIntent(data);
      return true;
    }

    if (data.startsWith(route.params.successUrlCallback)) {
      paymentSuccessful();
      return true;
    }

    if (data.startsWith(route.params.cancelUrlCallback)) {
      paymentCanceled();
      return true;
    }

    if (data.startsWith(route.params.errorUrlCallback)) {
      paymentFailed();
    }

    return false;
  }

  function paymentSuccessful() {
    if (hasNavigated.current) return;
    hasNavigated.current = true;

    invalidateAccount(accountKey, {
      refetchType: "all"
    });
    invalidateTransactions(accountKey, {
      refetchType: "all"
    });
    invalidatePaymentModes(accountKey, {
      refetchType: "all"
    });
    invalidateCreditCards({ refetchType: "all" });
    invalidateRefundInfo(personKey, { refetchType: "all" });

    navigation.replace("loadAmountResult", {
      type: ResultType.Success,
      amount: route.params.amount,
      currency: route.params.currency,
      paymentMode: route.params.paymentMode
    });
  }

  function paymentCanceled() {
    if (hasNavigated.current) return;
    hasNavigated.current = true;

    navigation.replace("loadAmountResult", {
      type: ResultType.Info,
      identifierId: accountKey.identifierId,
      affiliationId: accountKey.affiliationId,
      personId: accountKey.personId,
      accountId: accountKey.accountId,
      paymentMode: route.params.paymentMode
    });
  }

  function paymentFailed() {
    if (hasNavigated.current) return;
    hasNavigated.current = true;

    navigation.replace("loadAmountResult", {
      type: ResultType.Error,
      identifierId: accountKey.identifierId,
      affiliationId: accountKey.affiliationId,
      personId: accountKey.personId,
      accountId: accountKey.accountId,
      paymentMode: route.params.paymentMode
    });
  }

  async function manageIOSNavigation(url: string) {
    try {
      const canOpen = await Linking.canOpenURL(url);
      if (!canOpen) {
        toastError(
          t("AlertErrorTitle", "Error"),
          t("TwintPaymentScreenAppNotFound", "No twint app was found")
        );
        return;
      }

      await Linking.openURL(url);
    } catch {
      toastError(
        t("AlertErrorTitle", "Error"),
        t("TwintPaymentScreenAppNotFound", "No twint app was found")
      );
    }
  }

  async function manageAndroidIntent(url: string) {
    try {
      const twintPaymentAction = "ch.twint.action.TWINT_PAYMENT";
      const twintUofAction = "ch.twint.action.TWINT_UOF_REGISTRATION";
      const action = url.includes(twintPaymentAction)
        ? twintPaymentAction
        : url.includes(twintUofAction)
        ? twintUofAction
        : undefined;

      if (action) {
        let token = url.substring(url.indexOf("S.code=") + 7, url.length);
        token = token.substring(0, token.indexOf(";"));

        const twintIntentParams: IntentLauncherParams = {
          extra: {
            code: token,
            startingOrigin: originExternalWebBrowser
          },
          flags: flagActivityNewTask
        };
        await startActivityAsync(action, twintIntentParams);
      }
    } catch (error) {
      toastError(
        t("AlertErrorTitle", "Error"),
        t("TwintPaymentScreenAppNotFound", "No twint app was found")
      );
    }
  }
}
