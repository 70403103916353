import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";
import { flatMap, orderBy } from "lodash";
import i18n from "../providers/i18n";
import { fetchAccount, fetchAccounts, IAccountKey } from "./AccountsService";
import { downloadFile } from "./BlobService";
import {
  FileResponse,
  FileType,
  ITransaction,
  ITransactionStatusInformation
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";
import {
  queryClient,
  transactionQueryKey,
  transactionsQueryKey,
  transactionStatusQueryKey
} from "./QueryService";

export interface ITransactionKey extends IAccountKey {
  transactionId: number;
}

export interface ITransactionAccount
  extends IAccountKey,
    ITransactionKey,
    ITransaction {
  currency: string;
}

export function useTransactions(
  accountKey?: IAccountKey | null,
  startDate?: Date | null,
  endDate?: Date | null,
  skip?: number | null,
  take?: number | null
) {
  return useQuery(
    [transactionsQueryKey, accountKey, startDate, endDate, skip, take],
    () => {
      return accountKey
        ? getAccountTransactions(accountKey, startDate, endDate, skip, take)
        : getTransactions(startDate, endDate, skip, take);
    }
  );
}

export function useInfiniteTransactions(
  accountKey?: IAccountKey | null,
  startDate?: Date | null,
  endDate?: Date | null,
  pageSize: number = 20
) {
  return useInfiniteQuery(
    [transactionsQueryKey, accountKey, startDate, endDate],
    ({ pageParam = [] }) => {
      return accountKey
        ? getAccountTransactions(
            accountKey,
            startDate,
            endDate,
            pageParam?.length ?? 0,
            pageSize
          )
        : getNextPageTransactions(
            pageParam ?? [],
            startDate,
            endDate,
            pageSize
          );
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < pageSize ? undefined : flatMap(allPages);
      }
    }
  );
}

async function getTransactions(
  startDate?: Date | null,
  endDate?: Date | null,
  skip?: number | null,
  take?: number | null,
  limitResults: boolean = true
): Promise<ITransactionAccount[]> {
  let accounts = await fetchAccounts();
  accounts = accounts.filter((a) => a.person?.personId && a.account?.accountId);

  const accountKeys: IAccountKey[] = accounts.map((a) => {
    return {
      identifierId: a.identifier.identifierId,
      affiliationId: a.affiliation.affiliationId,
      personId: a.person!.personId,
      accountId: a.account!.accountId
    };
  });

  const getAccountsTransactions = accountKeys.map((id) =>
    fetchAccountTransactions(id, startDate, endDate, skip, take)
  );
  const accountsTransactions = await Promise.all(getAccountsTransactions);

  let transactions = accountsTransactions.flatMap((a) => a);
  transactions = orderBy(
    transactions,
    [(t) => t.date, (t) => t.amount],
    ["desc", "asc"]
  );

  if (take && transactions.length > take && limitResults) {
    transactions = transactions.slice(0, take);
  }

  return transactions;
}

async function getNextPageTransactions(
  currentState: ITransactionAccount[],
  startDate?: Date | null,
  endDate?: Date | null,
  pageSize?: number | null
): Promise<ITransactionAccount[]> {
  let accounts = await fetchAccounts();
  accounts = accounts.filter((a) => a.person?.personId && a.account?.accountId);

  const accountKeys: IAccountKey[] = accounts.map((a) => {
    return {
      identifierId: a.identifier.identifierId,
      affiliationId: a.affiliation.affiliationId,
      personId: a.person!.personId,
      accountId: a.account!.accountId
    };
  });

  const getAccountsTransactions = accountKeys.map(async (id) => {
    const accountTrx = currentState.filter(
      (t) =>
        t.identifierId === id.identifierId &&
        t.affiliationId === id.affiliationId &&
        t.personId === id.personId &&
        t.accountId === id.accountId
    );
    const accountSkip = accountTrx.length;
    const accountTake = pageSize;

    const currentPagePosition = accountSkip % (pageSize ?? accountSkip);
    const currentPageStart = accountSkip - currentPagePosition;

    let currentPageTrx = await fetchAccountTransactions(
      id,
      startDate,
      endDate,
      currentPageStart,
      accountTake
    );
    if (currentPagePosition === 0) {
      return currentPageTrx;
    }

    currentPageTrx = currentPageTrx.slice(currentPagePosition);

    const nextPageStart = currentPageStart + (pageSize ?? 0);

    const nextPageTrx = await fetchAccountTransactions(
      id,
      startDate,
      endDate,
      nextPageStart,
      accountTake
    );
    return [...currentPageTrx, ...nextPageTrx];
  });
  const accountsTransactions = await Promise.all(getAccountsTransactions);

  let transactions = accountsTransactions.flatMap((a) => a);
  transactions = orderBy(
    transactions,
    [(t) => t.date, (t) => t.amount],
    ["desc", "asc"]
  );

  if (pageSize && transactions.length > pageSize) {
    transactions = transactions.slice(0, pageSize);
  }

  return transactions;
}

async function fetchAccountTransactions(
  accountKey?: IAccountKey | null,
  startDate?: Date | null,
  endDate?: Date | null,
  skip?: number | null,
  take?: number | null
): Promise<ITransactionAccount[]> {
  const transactions = await queryClient.fetchQuery<ITransactionAccount[]>(
    [transactionsQueryKey, accountKey, startDate, endDate, skip, take],
    () => {
      return accountKey
        ? getAccountTransactions(accountKey, startDate, endDate, skip, take)
        : getTransactions(startDate, endDate, skip, take);
    }
  );
  return transactions;
}

export function useExportTransactions() {
  return useMutation(
    ({
      accountKey,
      fileType,
      startDate,
      endDate,
      languageCode
    }: {
      accountKey: IAccountKey | undefined;
      fileType: FileType;
      startDate: Date;
      endDate: Date;
      languageCode: string;
    }) =>
      exportTransactions(accountKey, fileType, startDate, endDate, languageCode)
  );
}

async function exportTransactions(
  accountKey: IAccountKey | undefined,
  fileType: FileType,
  startDate: Date,
  endDate: Date,
  languageCode: string
): Promise<void> {
  const platformClient = platformClientFactory.create();
  let exportedTransactions: FileResponse;
  if (accountKey) {
    exportedTransactions = await platformClient.exportTransactions(
      accountKey.identifierId,
      accountKey.affiliationId,
      accountKey.personId,
      accountKey.accountId,
      startDate,
      endDate,
      fileType,
      languageCode
    );
  } else {
    exportedTransactions = await platformClient.exportAllTransactions(
      startDate,
      endDate,
      fileType,
      languageCode
    );
  }

  const fileExtension = fileType === FileType.Pdf ? ".pdf" : ".csv";
  const fileName = `${i18n.t(
    "ExportTransactionsDefaultFileName",
    "Transactions"
  )}${fileExtension}`;

  await downloadFile(
    fileName,
    exportedTransactions.data,
    fileType === FileType.Pdf ? "application/pdf" : "text/csv"
  );
}

export function useExportTransaction() {
  return useMutation(
    ({
      transactionKey,
      languageCode
    }: {
      transactionKey: ITransactionKey;
      languageCode: string;
    }) => exportTransaction(transactionKey, languageCode)
  );
}

async function exportTransaction(
  transactionKey: ITransactionKey,
  languageCode: string
): Promise<void> {
  const platformClient = platformClientFactory.create();
  const exportedTransaction: FileResponse =
    await platformClient.exportTransaction(
      transactionKey.identifierId,
      transactionKey.affiliationId,
      transactionKey.personId,
      transactionKey.accountId,
      transactionKey.transactionId,
      languageCode
    );

  const fileName = `${i18n.t(
    "ExportTransactionDefaultFileName",
    "Transaction"
  )}_${transactionKey.transactionId}.pdf`;

  await downloadFile(fileName, exportedTransaction.data, "application/pdf");
}

async function getAccountTransactions(
  accountKey: IAccountKey,
  startDate?: Date | null,
  endDate?: Date | null,
  skip?: number | null,
  take?: number | null
): Promise<ITransactionAccount[]> {
  const platformClient = platformClientFactory.create();
  const getTransactions = platformClient.getTransactions(
    accountKey.identifierId,
    accountKey.affiliationId,
    accountKey.personId,
    accountKey.accountId,
    startDate,
    endDate,
    skip,
    take
  );

  const getAccount = fetchAccount(accountKey);

  const [transactions, account] = await Promise.all([
    getTransactions,
    getAccount
  ]);

  let accountTransactions: ITransactionAccount[] = transactions.map((t) => {
    const accountTransaction: ITransactionAccount = {
      ...accountKey,
      ...t,
      currency: account.account!.currency
    };
    return accountTransaction;
  });
  accountTransactions = orderBy(
    accountTransactions,
    [(t) => t.date, (t) => t.amount],
    ["desc", "asc"]
  );

  return accountTransactions;
}

async function cancelTransaction(transactionId: string) {
  const platformClient = platformClientFactory.create();
  await platformClient.cancelTransaction(transactionId);
}

export function useCancelTransaction() {
  return useMutation((transactionId: string) =>
    cancelTransaction(transactionId)
  );
}

export async function getTransactionStatus(
  transactionId: string
): Promise<ITransactionStatusInformation> {
  const platformClient = platformClientFactory.create();
  const transactionStatusInformation =
    await platformClient.getTransactionStatus(transactionId);

  return transactionStatusInformation;
}

export function useTransactionStatus(
  transactionId: string,
  options?: UseQueryOptions<
    ITransactionStatusInformation,
    unknown,
    ITransactionStatusInformation,
    string[]
  >
): UseQueryResult<ITransactionStatusInformation> {
  return useQuery([transactionStatusQueryKey, transactionId], () =>
    getTransactionStatus(transactionId)
  );
}

export function useTransaction(transactionKey: ITransactionKey) {
  return useQuery([transactionQueryKey, transactionKey], () =>
    getAccountTransaction(transactionKey)
  );
}

async function getAccountTransaction(
  transactionKey: ITransactionKey
): Promise<ITransactionAccount> {
  const platformClient = platformClientFactory.create();
  const getTransaction = platformClient.getTransaction(
    transactionKey.identifierId,
    transactionKey.affiliationId,
    transactionKey.personId,
    transactionKey.accountId,
    transactionKey.transactionId
  );

  const getAccount = fetchAccount(transactionKey);

  const [transaction, account] = await Promise.all([
    getTransaction,
    getAccount
  ]);

  const accountTransaction: ITransactionAccount = {
    ...transactionKey,
    ...transaction,
    currency: account.account!.currency
  };

  return accountTransaction;
}
