import { RouteProp, useRoute } from "@react-navigation/core";
import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import isPast from "date-fns/esm/isPast";
import format from "date-fns/format";
import isWithinInterval from "date-fns/isWithinInterval";
import { usePreventScreenCapture } from "expo-screen-capture";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Pressable, View } from "react-native";
import { Button, Text } from "react-native-elements";
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from "react-native-reanimated";
import CardLayoutComponent from "../components/CardLayoutComponent";
import { CardDetailsInformationSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import useScreenSize from "../hooks/ScreenSizeHook";
import { RootStackParamsList } from "../navigation";
import {
  AccountDetail,
  IAccountKey,
  IPersonKey,
  useAccount
} from "../services/AccountsService";
import { parseDate } from "../services/ParserService";
import { LayoutFace } from "../services/clients/PlatformClient";
import { largePrimaryRoundedButtonStyle } from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { cornerRadius, listItemHeights, spacings } from "../styles/Constants";
import { fontSizes, typographies } from "../styles/Fonts";

export default function CardDetailsScreen() {
  const route = useRoute<RouteProp<RootStackParamsList, "cardDetails">>();
  const { t } = useTranslation();
  const screenSize = useScreenSize();

  const personKey: IPersonKey = {
    identifierId: route.params.identifierId,
    affiliationId: route.params.affiliationId,
    personId: route.params.personId
  };

  const accountKey: IAccountKey = {
    ...personKey,
    accountId: route.params.accountId
  };

  const [status, setStatus] = useState({
    isLoading: false,
    isSuccess: false
  });

  if (Platform.OS !== "web") {
    usePreventScreenCapture();
  }

  const accountQuery = useAccount(accountKey);

  const checkStatus = useCallback(async () => {
    setStatus({ ...status, isLoading: true });
    await new Promise((r) => setTimeout(r, 1000));
    setStatus({ isSuccess: true, isLoading: false });
    await new Promise((r) => setTimeout(r, 4000));
    setStatus({ ...status, isSuccess: false });
  }, [status]);

  return (
    <ScrollViewComponent>
      {screenSize.large ? (
        <CardLayoutComponent
          personKey={personKey}
          displaySuccessOverlay={status.isSuccess}
        />
      ) : (
        <FlipCardComponent personKey={personKey} isSuccess={status.isSuccess} />
      )}

      {accountQuery.isInitialLoading ? (
        <View
          style={{
            marginTop: spacings.md
          }}>
          <CardDetailsInformationSkeleton />
        </View>
      ) : (
        <>
          <Text
            style={{
              color: colors.primary,
              fontSize: fontSizes.xl,
              alignSelf: "flex-start",
              marginTop: spacings.md,
              marginBottom: spacings.sm
            }}>
            {t("CreditCardDetailsScreenInformation", "Information")}
          </Text>

          <CardInformationSection accountDetail={accountQuery.data} />

          <Button
            titleStyle={largePrimaryRoundedButtonStyle.title}
            containerStyle={[
              largePrimaryRoundedButtonStyle.container,
              {
                marginVertical: spacings.lg
              }
            ]}
            onPress={checkStatus}
            buttonStyle={[
              largePrimaryRoundedButtonStyle.button,
              {
                backgroundColor: !status.isSuccess
                  ? colors.primary
                  : colors.success
              }
            ]}
            loading={status.isLoading}
            disabled={status.isLoading}
            disabledStyle={largePrimaryRoundedButtonStyle.disabled}
            loadingStyle={largePrimaryRoundedButtonStyle.loading}
            title={
              status.isSuccess
                ? t("CardDetailsScreenValidStatus", "Valid")
                : t("CardDetailsScreenCheckStatus", "Check status")
            }
          />
        </>
      )}
    </ScrollViewComponent>
  );
}

function CardInformationSection({
  accountDetail
}: {
  accountDetail?: AccountDetail;
}) {
  const { t } = useTranslation();

  const categoryName = accountDetail?.person?.metadata?.CategoryName;

  const validityEndDate = accountDetail?.person?.metadata?.ValidityEndDate
    ? endOfDay(parseDate(accountDetail.person?.metadata?.ValidityEndDate))
    : undefined;
  const now = new Date();

  const badgeColor = !validityEndDate
    ? colors.text.secondary
    : isPast(validityEndDate)
    ? colors.danger
    : isWithinInterval(validityEndDate, { start: now, end: addDays(now, 7) })
    ? colors.warning
    : colors.success;

  const accountName = accountDetail?.person?.formattedName;

  return (
    <View
      style={{
        borderRadius: cornerRadius.md,
        overflow: "hidden",
        alignSelf: "stretch",
        rowGap: 1
      }}>
      <CardInformationDetailRow
        leftText={t("CardDetailsScreenName", "Name")}
        rightText={accountName ?? "-"}
      />
      <CardInformationDetailRow
        leftText={t("CardDetailsScreenCategory", "Category")}
        rightText={categoryName ?? "-"}
      />
      <CardInformationDetailRow
        leftText={t("CardDetailsScreenExpirationDate", "Expiration date")}
        rightText={validityEndDate ? format(validityEndDate, "P") : "∞"}
        badgeColor={badgeColor}
      />
    </View>
  );
}

function CardInformationDetailRow({
  leftText,
  rightText,
  badgeColor
}: {
  leftText: string;
  rightText: string;
  badgeColor?: string;
}) {
  return (
    <View
      style={{
        height: listItemHeights.lg,
        paddingHorizontal: spacings.xl,
        backgroundColor: colors.background.light,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      }}>
      <Text
        style={[typographies.body, { color: colors.primary }]}
        numberOfLines={1}>
        {leftText}
      </Text>

      <View style={{ alignSelf: "center" }}>
        {badgeColor && (
          <View
            style={{
              backgroundColor: badgeColor,
              borderRadius: cornerRadius.sm,
              position: "absolute",
              opacity: 0.1,
              left: 0,
              top: 0,
              right: 0,
              bottom: 0
            }}></View>
        )}
        <Text
          style={[
            typographies.body,
            {
              alignSelf: "flex-end",
              padding: spacings.sm,
              ...(badgeColor && {
                color: badgeColor
              })
            }
          ]}>
          {rightText}
        </Text>
      </View>
    </View>
  );
}

function FlipCardComponent({
  personKey,
  isSuccess
}: {
  personKey: IPersonKey;
  isSuccess: boolean;
}) {
  const flipValue = useSharedValue(0);
  const [isFrontLoaded, setIsFrontLoaded] = useState(false);
  const [isBackLoaded, setIsBackLoaded] = useState(false);
  const isLoaded = useMemo(
    () => isFrontLoaded && isBackLoaded,
    [isFrontLoaded, isBackLoaded]
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      // TODO: Workaround for reanimated on Web issue: https://github.com/software-mansion/react-native-reanimated/issues/3355
      (window as any)._frameTimestamp = null;
    }
  }, []);

  const frontAnimatedStyle = useAnimatedStyle(() => {
    const spinVal = interpolate(flipValue.value, [0, 1], [0, 180]);
    return {
      transform: [
        {
          rotateY: withTiming(`${spinVal}deg`, { duration: 300 })
        }
      ]
    };
  }, []);

  const backAnimatedStyle = useAnimatedStyle(() => {
    const spinVal = interpolate(flipValue.value, [0, 1], [180, 360]);
    return {
      transform: [
        {
          rotateY: withTiming(`${spinVal}deg`, { duration: 300 })
        }
      ]
    };
  }, []);

  return (
    <View style={{ alignItems: "center" }}>
      <Pressable
        disabled={!isFrontLoaded || !isBackLoaded}
        onPress={() => (flipValue.value = flipValue.value ? 0 : 1)}>
        <Animated.View
          style={[
            frontAnimatedStyle,
            {
              backfaceVisibility: "hidden"
            }
          ]}>
          <CardLayoutComponent
            personKey={personKey}
            face={LayoutFace.Front}
            displaySuccessOverlay={isSuccess}
            loaded={() => setIsFrontLoaded(true)}
            isLoading={!isLoaded}
          />
        </Animated.View>

        <Animated.View
          style={[
            backAnimatedStyle,
            {
              position: "absolute",
              backfaceVisibility: "hidden"
            }
          ]}>
          <CardLayoutComponent
            personKey={personKey}
            face={LayoutFace.Back}
            displaySuccessOverlay={isSuccess}
            loaded={() => setIsBackLoaded(true)}
            isLoading={!isLoaded}
          />
        </Animated.View>
      </Pressable>
    </View>
  );
}
