import addWeeks from "date-fns/addWeeks";
import formatISO from "date-fns/formatISO";
import isFuture from "date-fns/isFuture";
import parseISO from "date-fns/parseISO";
import { Image } from "expo-image";
import { openURL } from "expo-linking";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { Button, Text } from "react-native-elements";
import useUpdateAvailable from "../hooks/UpdateAvailableHook";
import { UPDATE_APP_REMINDER } from "../services/AboutService";
import * as SecureStorage from "../services/SecureStorage";
import {
  colors,
  containerStyle,
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function UpdateAppComponent() {
  const { t } = useTranslation();
  const updateAppInfo = useUpdateAvailable();
  const [isUpdateAlreadySkipped, setIsUpdateAlreadySkipped] = useState(false);

  useEffect(() => {
    refreshReminder();
  }, []);

  async function refreshReminder() {
    const reminder = await getReminderData();
    if (isFuture(reminder.nextReminder)) {
      setIsUpdateAlreadySkipped(true);
    } else {
      setIsUpdateAlreadySkipped(false);
    }
  }

  async function getReminderData() {
    const lastReminderData = await SecureStorage.getItemAsync(
      UPDATE_APP_REMINDER
    );
    const lastReminder = lastReminderData
      ? parseISO(lastReminderData)
      : undefined;
    const nextReminder = lastReminder ? addWeeks(lastReminder, 1) : new Date();
    return {
      lastReminder,
      nextReminder
    };
  }

  async function setLastReminder(date: Date | undefined): Promise<void> {
    await SecureStorage.setItemAsync(
      UPDATE_APP_REMINDER,
      date ? formatISO(date) : ""
    );
  }

  async function skipUpdate() {
    await setLastReminder(new Date());
    await refreshReminder();
  }

  async function openInstaller() {
    await openURL(updateAppInfo.installerUrl);
  }

  if (!updateAppInfo.isAvailable) return null;
  if (!updateAppInfo.isRequired && isUpdateAlreadySkipped) return null;

  return (
    <SafeAreaView
      style={[
        {
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: colors.background.neutral
        }
      ]}>
      <View style={containerStyle.container}>
        <View
          style={{
            marginTop: spacings.xxl,
            alignItems: "center"
          }}>
          <Image
            style={{ alignSelf: "center", width: 230, height: 100 }}
            source={require("../assets/img/secanda-branding.svg")}
            contentFit="contain"
          />

          <Text
            style={[
              typographies.heading,
              {
                marginVertical: spacings.lg
              }
            ]}>
            {t("UpdateAppScreenHeader", "New version available!")}
          </Text>

          <Text style={[typographies.body, { marginVertical: spacings.lg }]}>
            {t(
              "UpdateAppScreenFirstDescription",
              "A new version of the SECANDA app is now ready for download. This update includes the latest features and improvements to enhance your experience."
            )}
          </Text>

          <Text style={typographies.body}>
            {t(
              "UpdateAppScreenSecondDescription",
              "Update your app now to take advantage of these enhancements. Regular updates not only grant you access to new features but also include performance improvements and security updates."
            )}
          </Text>

          <Button
            title={t("UpdateAppScreenStoreButton", "Go to store")}
            onPress={openInstaller}
            titleStyle={largePrimaryRoundedButtonStyle.title}
            containerStyle={[
              largePrimaryRoundedButtonStyle.container,
              {
                marginTop: spacings.xxl,
                marginBottom: spacings.md
              }
            ]}
            buttonStyle={largePrimaryRoundedButtonStyle.button}
          />

          {!updateAppInfo.isRequired && (
            <Button
              title={t("UpdateAppScreenSkipButton", "Skip")}
              titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
              onPress={skipUpdate}
              containerStyle={largePrimaryRoundedButtonStyle.container}
              buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}
            />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
