import { faEye } from "@fortawesome/pro-light-svg-icons/faEye";
import { faFaceViewfinder } from "@fortawesome/pro-light-svg-icons/faFaceViewfinder";
import { faFingerprint } from "@fortawesome/pro-light-svg-icons/faFingerprint";
import { faKey } from "@fortawesome/pro-light-svg-icons/faKey";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { default as React, useEffect, useState } from "react";
import { Pressable, View } from "react-native";
import { useAuth } from "../providers/AuthenticationProvider";
import {
  AuthenticationTypes,
  getLocalAuthTypes,
  isLocalAuthSupported
} from "../services/LocalAuthService";
import { pressableStyle } from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { iconSizes, spacings } from "../styles/Constants";

export default function LocalAuthComponent() {
  const { localSignIn } = useAuth();
  const [supported, setSupported] = useState(false);
  const [authenticationTypes, setAuthenticationTypes] =
    useState<AuthenticationTypes>();

  useEffect(() => {
    async function getSupportedLocalAuthTypes(): Promise<void> {
      const isSupported = await isLocalAuthSupported();
      setSupported(isSupported);

      if (isSupported) {
        const types = await getLocalAuthTypes();
        setAuthenticationTypes(types);
      }
    }

    getSupportedLocalAuthTypes();
  }, []);

  if (!supported || !authenticationTypes) return null;

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        marginTop: spacings.xl
      }}>
      <Pressable
        style={({ pressed }) => pressed && pressableStyle.pressed}
        onPress={async () => await localSignIn()}>
        {getIcon(authenticationTypes)}
      </Pressable>
    </View>
  );

  function getIcon(type: AuthenticationTypes) {
    if (type.iris) {
      return (
        <FontAwesomeIcon
          icon={faEye}
          size={iconSizes.xl}
          color={colors.text.link}></FontAwesomeIcon>
      );
    }

    if (type.facial) {
      return (
        <FontAwesomeIcon
          icon={faFaceViewfinder}
          size={iconSizes.xl}
          color={colors.text.link}></FontAwesomeIcon>
      );
    }

    if (type.fingerprint) {
      return (
        <FontAwesomeIcon
          icon={faFingerprint}
          size={iconSizes.xl}
          color={colors.text.link}></FontAwesomeIcon>
      );
    }

    if (type.secret) {
      return (
        <FontAwesomeIcon
          icon={faKey}
          size={iconSizes.xl}
          color={colors.text.link}></FontAwesomeIcon>
      );
    }

    return null;
  }
}
