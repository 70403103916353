import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { orderBy, uniqBy } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-elements";
import AffiliationListItemComponent from "../components/AffiliationListItemComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { AccountListSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import {
  BeneficiaryScreenProps,
  BeneficiarySelectAffiliationStackParamsList
} from "../navigation/ProfileStackNavigation";
import {
  AccountDetail,
  fetchAccounts,
  useAccounts
} from "../services/AccountsService";
import { AffiliationStatus } from "../services/clients/PlatformClient";
import { spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";
import { virtualizedListItemStyle } from "../styles/Lists";

export default function SelectAffiliationBeneficiaryScreen() {
  const navigation =
    useNavigation<
      StackNavigationProp<BeneficiarySelectAffiliationStackParamsList>
    >();
  const { t } = useTranslation();
  const accountsQuery = useAccounts({
    select: (data) => {
      const accounts = uniqBy(data, (a) => a.affiliation.affiliationId);
      return orderBy(accounts, (a) => a.affiliation.name.toLowerCase());
    }
  });

  useEffect(() => {
    async function skipScreen() {
      const accounts = await fetchAccounts();
      if (!navigation.isFocused()) {
        return;
      }

      if (accounts.length === 1) {
        const accountDetail = accounts[0];
        const params: BeneficiaryScreenProps = {
          identifierId: accountDetail.identifier.identifierId,
          affiliationId: accountDetail.affiliation.affiliationId
        };

        navigation.replace("addBeneficiary", params);
      }
    }

    skipScreen();
  }, []);

  if (accountsQuery.isInitialLoading) {
    return <AccountListSkeleton />;
  }

  if (accountsQuery.data?.length === 0) {
    return (
      <EmptyContentComponent
        message={t(
          "SelectAffiliationBeneficiaryScreenEmptyList",
          "There are no affiliations to add beneficiaries."
        )}></EmptyContentComponent>
    );
  }

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.sm,
            marginBottom: spacings.md,
            textAlign: "center"
          }
        ]}>
        {t(
          "SelectAffiliationBeneficiaryScreenDescription",
          "Please select the affiliation where to add your new beneficiary"
        )}
      </Text>

      <DisplayAffiliations
        accounts={accountsQuery.data ?? []}
        onPress={accountSelected}></DisplayAffiliations>
    </ScrollViewComponent>
  );

  function accountSelected(account: AccountDetail): void {
    navigation.navigate("addBeneficiary", {
      affiliationId: account.affiliation.affiliationId,
      identifierId: account.identifier.identifierId
    });
  }
}

function DisplayAffiliations({
  accounts,
  onPress
}: {
  accounts: AccountDetail[];
  onPress: (account: AccountDetail) => void;
}) {
  return (
    <View style={{ width: "100%", flex: 1 }}>
      {accounts.map((item, index) => {
        return (
          <View
            key={index}
            style={[
              virtualizedListItemStyle.baseItem,
              index === 0 ? virtualizedListItemStyle.firstItem : null,
              index === accounts.length - 1
                ? virtualizedListItemStyle.lastItem
                : virtualizedListItemStyle.middleItem
            ]}>
            <AffiliationListItemComponent
              key={index}
              affiliation={item.affiliation}
              onPress={() => onPress(item)}
              disabled={item.affiliation.status === AffiliationStatus.Offline}
            />
          </View>
        );
      })}
    </View>
  );
}
