import { default as React, useCallback, useState } from "react";
import ContentLoader, { Circle, Rect } from "react-content-loader/native";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import { Card, Divider, ListItem } from "react-native-elements";
import { LayoutFace } from "../services/clients/PlatformClient";
import { largePrimaryRoundedButtonStyle } from "../styles/Buttons";
import { mediumCardStyle } from "../styles/Cards";
import { colors } from "../styles/Colors";
import { cornerRadius, listItemHeights, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";

const style = StyleSheet.create({
  transactionItem: {
    flexDirection: "row",
    alignItems: "center",
    height: 70,
    borderBottomColor: colors.background.neutral,
    borderBottomWidth: cornerRadius.xxs,
    paddingHorizontal: spacings.lg
  },
  contentLeft: {
    flexDirection: "row",
    flex: 1
  }
});

const defaultTextHeight: number = 20;

function TransactionListItemSkeleton() {
  return (
    <View style={style.transactionItem}>
      <View style={style.contentLeft}>
        <ContentLoader height={70} width={"100%"}>
          <Rect
            y={7.5}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={180}
            height={25}
          />
          <Rect
            y={37.5}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={100}
            height={25}
          />
        </ContentLoader>
      </View>

      <View style={{ position: "relative" }}>
        <ContentLoader height={100} width={80}>
          <Rect x={0} y={35} width={80} height={25} />
        </ContentLoader>
      </View>
    </View>
  );
}

function CreditCardListItemSkeleton() {
  return (
    <View
      style={[
        style.transactionItem,
        {
          height: listItemHeights.lg,
          paddingHorizontal: spacings.xl
        }
      ]}>
      <View style={style.contentLeft}>
        <ContentLoader height={listItemHeights.lg} width={"100%"}>
          <Rect
            y={17}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={32}
            height={25}
          />

          <Rect
            y={13}
            x={40}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={60}
            height={18}
          />
          <Rect
            y={34}
            x={40}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={180}
            height={13}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

function SearchbarSkeleton() {
  return (
    <View
      style={{
        height: 48,
        backgroundColor: colors.background.light,
        borderRadius: cornerRadius.md
      }}>
      <View
        style={[
          style.contentLeft,
          {
            paddingHorizontal: spacings.xl
          }
        ]}>
        <ContentLoader height={48} width={"100%"}>
          <Rect
            y={14}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={20}
            height={defaultTextHeight}
          />
          <Rect
            y={14}
            x={40}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={60}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

function AffiliationItemSkeleton() {
  return (
    <ListItem
      style={{
        borderWidth: cornerRadius.none
      }}
      containerStyle={{
        borderWidth: cornerRadius.none,
        height: listItemHeights.md
      }}>
      <ListItem.Content
        style={{
          flexDirection: "row",
          alignItems: "center"
        }}>
        <View
          style={{
            flexDirection: "row",
            flex: 1
          }}>
          <ContentLoader height={listItemHeights.md} width={"100%"}>
            <Rect
              y={19}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={150}
              height={20}
            />
          </ContentLoader>
        </View>

        <View style={{ position: "relative" }}>
          <LogoSkeleton height={42} width={42} />
        </View>
      </ListItem.Content>
    </ListItem>
  );
}

function AmountComponentSkeleton() {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        height: listItemHeights.lg,
        backgroundColor: colors.background.light,
        paddingHorizontal: spacings.lg,
        borderRadius: cornerRadius.md
      }}>
      <View style={style.contentLeft}>
        <ContentLoader height={listItemHeights.lg} width={"100%"}>
          <Rect
            y={7.5}
            x={0}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={100}
            height={22}
          />
          <Rect
            y={37.5}
            x={0}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={130}
            height={14}
          />
        </ContentLoader>
      </View>

      <View style={{ position: "relative" }}>
        <ContentLoader height={100} width={80}>
          <Rect
            x={0}
            y={35}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={80}
            height={25}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

function BeneficiaryListItemSkeleton() {
  return (
    <View
      style={[
        style.transactionItem,
        {
          height: listItemHeights.lg,
          paddingHorizontal: spacings.xl
        }
      ]}>
      <View style={style.contentLeft}>
        <ContentLoader height={listItemHeights.lg} width={"100%"}>
          <Rect
            y={12.5}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={32}
            height={35}
          />

          <Rect
            y={13}
            x={40}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={60}
            height={18}
          />
          <Rect
            y={34}
            x={40}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={180}
            height={13}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

function ButtonSkeleton() {
  return (
    <View
      style={[
        largePrimaryRoundedButtonStyle.container,
        {
          marginTop: spacings.lg,
          justifyContent: "center",
          alignItems: "center",
          height: listItemHeights.md,
          backgroundColor: colors.background.light
        }
      ]}>
      <ContentLoader height={defaultTextHeight} width={200}>
        <Rect
          y={0}
          x={0}
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={200}
          height={defaultTextHeight}
        />
      </ContentLoader>
    </View>
  );
}

function CheckboxSkeleton() {
  return (
    <View style={{ flexDirection: "row" }}>
      <ContentLoader width={20} height={20}>
        <Rect x={0} y={0} width={20} height={20}></Rect>
      </ContentLoader>

      <ContentLoader
        width={200}
        height={defaultTextHeight}
        style={{ marginLeft: spacings.md }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={200}
          height={defaultTextHeight}
        />
      </ContentLoader>
    </View>
  );
}

export function LogoSkeleton({
  height = 70,
  width = 100
}: {
  height?: number;
  width?: number;
}) {
  const centerYPosition = height / 2;
  const centerXPosition = width / 2;

  return (
    <ContentLoader height={height} width={width}>
      <Circle cx={centerXPosition} cy={centerYPosition} r={centerYPosition} />
    </ContentLoader>
  );
}

export function AccountsSectionSkeleton() {
  return (
    <View style={{ backgroundColor: colors.background.neutral }}>
      <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={120}
          height={25}
        />
      </ContentLoader>
      <AccountListItemSkeleton />
    </View>
  );
}

export function AccountListItemSkeleton() {
  return (
    <ListItem
      style={{
        marginBottom: spacings.md,
        borderRadius: cornerRadius.md,
        borderWidth: cornerRadius.none
      }}
      containerStyle={{
        borderRadius: cornerRadius.md,
        borderWidth: cornerRadius.none,
        height: 100
      }}>
      <ListItem.Content
        style={{
          flexDirection: "row",
          alignItems: "center"
        }}>
        <View
          style={{
            flexDirection: "row",
            flex: 1
          }}>
          <ContentLoader height={100} width={"100%"}>
            <Rect
              y={20}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={150}
              height={25}
            />
            <Rect
              y={50}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={100}
              height={25}
            />
          </ContentLoader>
        </View>

        <View style={{ position: "relative" }}>
          <LogoSkeleton />
        </View>
      </ListItem.Content>
    </ListItem>
  );
}

export function TransactionListSummarySkeleton() {
  const n = 4;

  return (
    <View
      style={{
        backgroundColor: colors.background.neutral
      }}>
      <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={120}
          height={25}
        />
      </ContentLoader>
      <View
        style={{
          borderTopLeftRadius: cornerRadius.md,
          borderTopRightRadius: cornerRadius.md,
          backgroundColor: colors.background.light
        }}>
        {[...Array(n)].map((_, index) => (
          <TransactionListItemSkeleton key={index} />
        ))}
      </View>
      <View
        style={{
          backgroundColor: colors.background.light,
          height: 40,
          alignItems: "center",
          borderBottomLeftRadius: cornerRadius.md,
          borderBottomRightRadius: cornerRadius.md
        }}>
        <ContentLoader height={30} width={70}>
          <Rect
            y={10}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={70}
            height={30}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

export function PersonalDataSkeleton() {
  return (
    <View style={{ marginBottom: spacings.sm }}>
      <ContentLoader
        style={{
          marginTop: spacings.lg,
          marginBottom: spacings.sm
        }}
        height={25}>
        <Rect
          ry={cornerRadius.md}
          rx={cornerRadius.md}
          width={200}
          height={25}
        />
      </ContentLoader>

      <View
        style={{
          backgroundColor: colors.background.light,
          height: 46,
          borderTopLeftRadius: cornerRadius.md,
          borderTopRightRadius: cornerRadius.md
        }}>
        <ContentLoader width={"100%"} height={46}>
          <Rect
            x={spacings.lg}
            y={13}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>

      <View
        style={{
          height: spacings.xxs,
          backgroundColor: colors.background.neutral
        }}></View>

      <View
        style={{
          backgroundColor: colors.background.light,
          height: 46,
          borderBottomLeftRadius: cornerRadius.md,
          borderBottomRightRadius: cornerRadius.md
        }}>
        <ContentLoader width={"100%"} height={46}>
          <Rect
            x={spacings.lg}
            y={13}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>

      <View
        style={{
          backgroundColor: colors.background.light,
          height: 46,
          marginTop: spacings.lg,
          borderRadius: cornerRadius.md,
          marginBottom: spacings.xs
        }}>
        <ContentLoader width={"100%"} height={46}>
          <Rect
            x={spacings.lg}
            y={13}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginBottom: spacings.xs
        }}>
        <View
          style={{
            backgroundColor: colors.background.light,
            borderRadius: cornerRadius.md,
            marginRight: spacings.sm,
            flex: 1
          }}>
          <ContentLoader height={46} width={"100%"}>
            <Rect
              x={spacings.lg}
              y={13}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={90}
              height={defaultTextHeight}
            />
          </ContentLoader>
        </View>
        <View
          style={{
            flex: 2,
            backgroundColor: colors.background.light,
            borderRadius: cornerRadius.md
          }}>
          <ContentLoader width={"100%"} height={46}>
            <Rect
              x={spacings.lg}
              y={13}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={200}
              height={defaultTextHeight}
            />
          </ContentLoader>
        </View>
      </View>
      <View
        style={{
          backgroundColor: colors.background.light,
          height: 46,
          borderRadius: cornerRadius.md
        }}>
        <ContentLoader width={"100%"} height={46}>
          <Rect
            x={spacings.lg}
            y={13}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

export function EnrollPersonalDataSkeleton() {
  return (
    <View style={containerStyle.container}>
      <View style={{ borderRadius: cornerRadius.md, overflow: "hidden" }}>
        <AffiliationItemSkeleton />
      </View>

      <ContentLoader
        style={{
          marginTop: spacings.lg,
          marginBottom: spacings.sm
        }}
        height={25}>
        <Rect
          ry={cornerRadius.md}
          rx={cornerRadius.md}
          width={150}
          height={25}
        />
      </ContentLoader>

      <View
        style={{
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md
        }}>
        <ContentLoader width={"100%"} height={46}>
          <Rect
            x={spacings.lg}
            y={13}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>
      <PersonalDataSkeleton />

      <ButtonSkeleton />
    </View>
  );
}

export function TransactionsListSkeleton() {
  let n = 3;

  return (
    <View style={containerStyle.container}>
      {[...Array(n)].map((_, index) => {
        let items: JSX.Element[] = [];
        for (let i = 0; i < n; i++) {
          items.push(<TransactionListItemSkeleton key={i} />);
        }
        n--;

        return (
          <View key={index}>
            <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
              <Rect
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                width={100}
                height={25}
              />
            </ContentLoader>
            <View
              style={{
                backgroundColor: colors.background.light,
                borderRadius: cornerRadius.md,
                marginBottom: index === n - 1 ? spacings.none : spacings.lg
              }}>
              {items}
            </View>
          </View>
        );
      })}
    </View>
  );
}

export function AffiliationCardLayoutSkeleton({
  layoutWidth,
  layoutHeight,
  face = LayoutFace.Front
}: {
  layoutWidth: number;
  layoutHeight: number;
  face?: LayoutFace;
}) {
  const centerXPosition = layoutWidth / 2;
  const centerYPosition = layoutHeight / 2;

  if (layoutHeight > layoutWidth) {
    if (face === LayoutFace.Front) {
      return (
        <ContentLoader height={layoutHeight} width={layoutWidth}>
          <Rect width={layoutWidth} height={30} />
          <Circle cx={centerXPosition} cy={100} r={50} />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            y={layoutHeight - 45}
            x={centerXPosition - 50}
            width={100}
            height={25}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            y={layoutHeight - 80}
            x={centerXPosition - 50}
            width={100}
            height={25}
          />
        </ContentLoader>
      );
    }

    return (
      <ContentLoader height={layoutHeight} width={layoutWidth}>
        <Rect width={layoutWidth} height={30} />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          y={45}
          x={centerXPosition - 50}
          width={100}
          height={25}
        />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          y={80}
          x={centerXPosition - 50}
          width={100}
          height={25}
        />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          y={layoutHeight - 45}
          x={centerXPosition - 50}
          width={100}
          height={25}
        />
      </ContentLoader>
    );
  }

  if (face === LayoutFace.Back) {
    return (
      <ContentLoader height={layoutHeight} width={layoutWidth}>
        <Rect width={layoutWidth} height={40} />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={20}
          y={centerYPosition - 30}
          width={layoutWidth - 40}
          height={25}
        />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={20}
          y={centerYPosition + 5}
          width={layoutWidth - 40}
          height={25}
        />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={20}
          y={centerYPosition + 40}
          width={layoutWidth - 40}
          height={25}
        />
      </ContentLoader>
    );
  }

  return (
    <ContentLoader height={layoutHeight} width={layoutWidth}>
      <Rect width={layoutWidth} height={40} />
      <Circle cx={70} cy={centerYPosition + 20} r={50} />
      <Rect
        ry={cornerRadius.sm}
        rx={cornerRadius.sm}
        x={centerXPosition + 20}
        y={centerYPosition - 30}
        width={100}
        height={25}
      />
      <Rect
        ry={cornerRadius.sm}
        rx={cornerRadius.sm}
        x={centerXPosition + 20}
        y={centerYPosition + 5}
        width={100}
        height={25}
      />
      <Rect
        ry={cornerRadius.sm}
        rx={cornerRadius.sm}
        x={centerXPosition + 20}
        y={centerYPosition + 40}
        width={100}
        height={25}
      />
    </ContentLoader>
  );
}

export function CreditCardItemSkeleton() {
  return (
    <View
      style={[
        {
          alignSelf: "center",
          paddingVertical: spacings.lg,
          marginHorizontal: spacings.lg
        },
        containerStyle.container
      ]}>
      <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={100}
          height={25}
        />
      </ContentLoader>
      <View
        style={{
          ...style.transactionItem,
          height: listItemHeights.lg,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md,
          overflow: "hidden"
        }}>
        <View
          style={[
            {
              borderRadius: cornerRadius.md,
              overflow: "hidden",
              backgroundColor: colors.background.light
            },
            style.contentLeft
          ]}>
          <ContentLoader height={listItemHeights.lg} width={"100%"}>
            <Rect
              y={17}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={32}
              height={25}
            />

            <Rect
              y={13}
              x={40}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={60}
              height={18}
            />
            <Rect
              y={34}
              x={40}
              ry={cornerRadius.sm}
              rx={cornerRadius.sm}
              width={180}
              height={13}
            />
          </ContentLoader>
        </View>
      </View>
    </View>
  );
}

export function CreditCardsListSkeleton() {
  let n = 2;

  return (
    <View style={containerStyle.container}>
      {[...Array(n)].map((_, index) => {
        let items: JSX.Element[] = [];
        for (let i = 0; i < n; i++) {
          items.push(<CreditCardListItemSkeleton key={i} />);
        }
        n--;

        return (
          <View key={index}>
            <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
              <Rect
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                width={100}
                height={25}
              />
            </ContentLoader>
            <View
              style={{
                backgroundColor: colors.background.light,
                borderRadius: cornerRadius.md,
                marginBottom: index === n - 1 ? spacings.none : spacings.lg
              }}>
              {items}
            </View>
          </View>
        );
      })}
    </View>
  );
}

export function SelectAffiliationToEnrollSkeleton() {
  let n = 6;

  return (
    <View style={containerStyle.container}>
      <SearchbarSkeleton />

      <View
        style={{
          marginTop: spacings.lg,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md,
          overflow: "hidden"
        }}>
        {[...Array(n)].map((_, index) => {
          return (
            <View key={index}>
              <AffiliationItemSkeleton />
              {index !== n - 1 && (
                <View
                  style={{
                    height: spacings.xxs,
                    backgroundColor: colors.background.neutral
                  }}
                />
              )}
            </View>
          );
        })}
      </View>

      <View
        style={{
          marginTop: spacings.xxl,
          borderRadius: cornerRadius.md,
          justifyContent: "center",
          alignItems: "center",
          height: listItemHeights.md,
          backgroundColor: colors.background.light
        }}>
        <ContentLoader height={defaultTextHeight} width={200}>
          <Rect
            y={0}
            x={0}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

export function AccountListSkeleton() {
  let n = 3;

  return (
    <View style={containerStyle.container}>
      <ContentLoader
        height={defaultTextHeight}
        width={200}
        style={{ alignSelf: "center", marginBottom: spacings.md }}>
        <Rect
          y={0}
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={200}
          height={defaultTextHeight}
        />
      </ContentLoader>

      {[...Array(n)].map((_, index) => {
        return <AccountListItemSkeleton key={index} />;
      })}
    </View>
  );
}

export function LoadAmountSelectionSkeleton() {
  let n = 4;

  return (
    <View style={containerStyle.container}>
      <AccountListItemSkeleton />

      <ContentLoader height={25} style={{ marginTop: spacings.lg }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={150}
          height={25}
        />
      </ContentLoader>

      <View
        style={{
          marginTop: spacings.sm,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md
        }}>
        {[...Array(n)].map((_, index) => {
          return <CreditCardListItemSkeleton key={index} />;
        })}
      </View>

      <ContentLoader
        height={25}
        style={{ marginTop: spacings.xl, marginBottom: spacings.sm }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={150}
          height={25}
        />
      </ContentLoader>
      <AmountComponentSkeleton />

      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-end"
        }}>
        <ContentLoader
          height={25}
          width={200}
          style={{
            marginTop: spacings.xxl,
            alignSelf: "center"
          }}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={24}
            height={24}
          />
          <Rect
            ry={cornerRadius.sm}
            x={35}
            rx={cornerRadius.sm}
            width={150}
            height={24}
          />
        </ContentLoader>
      </View>
      <ButtonSkeleton />
    </View>
  );
}

export function SendAmountSelectionSkeleton() {
  let n = 3;

  return (
    <View style={containerStyle.container}>
      <AccountListItemSkeleton />

      <ContentLoader height={25} style={{ marginTop: spacings.lg }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={150}
          height={25}
        />
      </ContentLoader>

      <View
        style={{
          marginTop: spacings.sm,
          marginBottom: spacings.lg,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md
        }}>
        {[...Array(n)].map((_, index) => {
          return <BeneficiaryListItemSkeleton key={index} />;
        })}
      </View>

      <AmountComponentSkeleton />

      <View
        style={{
          marginTop: spacings.lg,
          marginBottom: spacings.xl,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md,
          paddingHorizontal: spacings.lg,
          height: 100
        }}>
        <ContentLoader height={listItemHeights.lg} width={"100%"}>
          <Rect
            y={10}
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>

      <ButtonSkeleton />
    </View>
  );
}

export function ProfileSkeleton() {
  let n = 9;
  return (
    <View style={containerStyle.container}>
      <View
        style={{
          marginTop: spacings.sm,
          marginBottom: spacings.lg,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md
        }}>
        {[...Array(n)].map((_, index) => {
          return (
            <View
              key={index}
              style={[
                style.transactionItem,
                {
                  height: 80,
                  paddingHorizontal: spacings.xl
                }
              ]}>
              <View style={style.contentLeft}>
                <ContentLoader height={listItemHeights.lg} width={"100%"}>
                  <Rect
                    y={12.5}
                    ry={cornerRadius.sm}
                    rx={cornerRadius.sm}
                    width={32}
                    height={35}
                  />

                  <Rect
                    y={13}
                    x={40}
                    ry={cornerRadius.sm}
                    rx={cornerRadius.sm}
                    width={100}
                    height={18}
                  />
                  <Rect
                    y={34}
                    x={40}
                    ry={cornerRadius.sm}
                    rx={cornerRadius.sm}
                    width={200}
                    height={13}
                  />
                </ContentLoader>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export function BeneficiariesListSkeleton() {
  let n = 2;

  return (
    <View style={containerStyle.container}>
      {[...Array(n)].map((_, index) => {
        let items: JSX.Element[] = [];
        for (let i = 0; i < n; i++) {
          items.push(<BeneficiaryListItemSkeleton key={i} />);
        }
        n--;

        return (
          <View key={index}>
            <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
              <Rect
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                width={100}
                height={25}
              />
            </ContentLoader>
            <View
              style={{
                backgroundColor: colors.background.light,
                borderRadius: cornerRadius.md,
                marginBottom: index === n - 1 ? spacings.none : spacings.lg
              }}>
              {items}
            </View>
          </View>
        );
      })}
    </View>
  );
}

export function IdentifiersListSkeleton() {
  let n = 3;

  return (
    <View style={containerStyle.container}>
      <View
        style={{
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md
        }}>
        {[...Array(n)].map((_, index) => {
          return (
            <View
              key={index}
              style={{
                height: listItemHeights.md,
                borderBottomWidth: cornerRadius.xxs,
                borderBottomColor: colors.background.neutral,
                paddingHorizontal: spacings.lg
              }}>
              <View style={style.contentLeft}>
                <ContentLoader width={"100%"} height={listItemHeights.md}>
                  <Rect
                    y={19}
                    ry={cornerRadius.sm}
                    rx={cornerRadius.sm}
                    width={200}
                    height={defaultTextHeight}
                  />

                  {index === 1 && (
                    <Rect
                      x={210}
                      y={19}
                      ry={cornerRadius.sm}
                      rx={cornerRadius.sm}
                      width={100}
                      height={defaultTextHeight}
                    />
                  )}
                </ContentLoader>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export function IdentifierDetailSkeleton() {
  return (
    <View style={containerStyle.container}>
      <View
        style={{
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md
        }}>
        <View
          style={{
            flexDirection: "row",
            height: listItemHeights.md,
            paddingHorizontal: spacings.lg,
            justifyContent: "space-between",
            borderBottomColor: colors.background.neutral,
            borderBottomWidth: cornerRadius.xxs
          }}>
          <View style={style.contentLeft}>
            <ContentLoader width={200} height={listItemHeights.md}>
              <Rect
                y={19}
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                width={70}
                height={defaultTextHeight}
              />
            </ContentLoader>
          </View>

          <View
            style={{
              position: "relative",
              right: 0
            }}>
            <View>
              <ContentLoader height="100%" width={200}>
                <Rect
                  x={0}
                  y={19}
                  ry={cornerRadius.md}
                  rx={cornerRadius.md}
                  width={70}
                  height={defaultTextHeight}
                />

                <Rect
                  ry={cornerRadius.sm}
                  rx={cornerRadius.sm}
                  x={80}
                  y={19}
                  width={120}
                  height={defaultTextHeight}
                />
              </ContentLoader>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            height: listItemHeights.md,
            paddingHorizontal: spacings.lg,
            justifyContent: "space-between"
          }}>
          <View style={style.contentLeft}>
            <ContentLoader width={200} height={listItemHeights.md}>
              <Rect
                y={19}
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                width={50}
                height={defaultTextHeight}
              />
            </ContentLoader>
          </View>

          <View
            style={{
              position: "relative",
              right: 0
            }}>
            <View>
              <ContentLoader height="100%" width={200}>
                <Rect
                  ry={cornerRadius.sm}
                  rx={cornerRadius.sm}
                  x={80}
                  y={19}
                  width={120}
                  height={defaultTextHeight}
                />
              </ContentLoader>
            </View>
          </View>
        </View>
      </View>

      <ContentLoader
        height={defaultTextHeight}
        style={{ marginTop: spacings.xl }}
        width={100}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={100}
          height={defaultTextHeight}
        />
      </ContentLoader>

      <View
        style={{
          marginTop: spacings.sm,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md,
          overflow: "hidden"
        }}>
        {[...Array(3)].map((_, index) => {
          return (
            <View key={index}>
              <AffiliationItemSkeleton />
              {index !== 2 && (
                <View
                  style={{
                    height: spacings.xxs,
                    backgroundColor: colors.background.neutral
                  }}
                />
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
}

export function PaymentSlipSkeleton() {
  return (
    <View style={containerStyle.container}>
      <ContentLoader height={90}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={"100%"}
          height={defaultTextHeight}
        />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={20}
          width={"50%"}
          height={defaultTextHeight}
        />

        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={50}
          width={"100%"}
          height={defaultTextHeight}
        />
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={70}
          width={"50%"}
          height={defaultTextHeight}
        />
      </ContentLoader>

      <View
        style={{
          marginTop: spacings.md,
          marginBottom: spacings.xl,
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md
        }}>
        {[...Array(4)].map((_, index) => {
          return (
            <View
              key={index}
              style={{
                flexDirection: "row",
                height: 40
              }}>
              <View
                style={{
                  flex: 0.3,
                  paddingHorizontal: spacings.lg,
                  borderBottomColor: colors.background.neutral,
                  borderBottomWidth: cornerRadius.xxs,

                  justifyContent: "center"
                }}>
                <ContentLoader height={10}>
                  <Rect
                    ry={cornerRadius.sm}
                    rx={cornerRadius.sm}
                    x={0}
                    y={0}
                    width={Math.random() * 100}
                    height={10}
                  />
                </ContentLoader>
              </View>
              <Divider
                orientation="vertical"
                color={colors.background.neutral}></Divider>
              <View
                style={{
                  flex: 0.7,
                  paddingHorizontal: spacings.lg,
                  borderBottomColor: colors.background.neutral,
                  borderBottomWidth: cornerRadius.xxs,
                  justifyContent: "center"
                }}>
                <ContentLoader height={10}>
                  <Rect
                    ry={cornerRadius.sm}
                    rx={cornerRadius.sm}
                    x={0}
                    y={0}
                    width={Math.random() * 300}
                    height={10}
                  />
                </ContentLoader>
              </View>
            </View>
          );
        })}
      </View>

      <ContentLoader height={defaultTextHeight}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={300}
          height={defaultTextHeight}
        />
      </ContentLoader>

      <View
        style={{
          marginTop: spacings.md,
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md,
          padding: 40
        }}>
        <QRCodeSkeleton />
      </View>
    </View>
  );
}

export function QRCodeSkeleton() {
  return (
    <ContentLoader height={220} width={220}>
      <Rect
        ry={cornerRadius.sm}
        rx={cornerRadius.sm}
        x={0}
        y={0}
        width={220}
        height={220}
      />
    </ContentLoader>
  );
}

export function TransactionDetailSkeleton() {
  const [receiptWidth, setReceiptWidth] = useState(0);
  const onContentLayout = useCallback((event: LayoutChangeEvent) => {
    setReceiptWidth(event.nativeEvent.layout.width);
  }, []);

  function getCenteredXPosition(parentWidth: number, elementWidth: number) {
    return (parentWidth - elementWidth - spacings.lg * 2) / 2;
  }

  function getEndXPosition(parentWidth: number, elementWidth: number) {
    return parentWidth - elementWidth - spacings.lg * 2;
  }

  return (
    <View
      style={[
        containerStyle.container,
        {
          flex: 1,
          justifyContent: "center",
          maxWidth: 500
        }
      ]}>
      <View
        onLayout={onContentLayout}
        style={{
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.md,
          paddingHorizontal: spacings.lg
        }}>
        <ContentLoader
          height={125}
          width={receiptWidth}
          style={{ marginBottom: spacings.sm }}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getCenteredXPosition(receiptWidth, 150)}
            y={20}
            width={150}
            height={10}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getCenteredXPosition(receiptWidth, 200)}
            y={35}
            width={200}
            height={10}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getCenteredXPosition(receiptWidth, 150)}
            y={50}
            width={150}
            height={10}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getCenteredXPosition(receiptWidth, 170)}
            y={65}
            width={170}
            height={10}
          />

          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={0}
            y={100}
            width={60}
            height={10}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={0}
            y={115}
            width={70}
            height={10}
          />

          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getEndXPosition(receiptWidth, 60)}
            y={100}
            width={60}
            height={10}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getEndXPosition(receiptWidth, 70)}
            y={115}
            width={70}
            height={10}
          />
        </ContentLoader>

        {[...Array(10)].map((_, index) => {
          return (
            <ContentLoader
              key={index}
              height={10}
              width={receiptWidth}
              style={{ marginTop: index !== 0 ? spacings.xs : spacings.none }}>
              <Rect
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                x={0}
                y={0}
                width={10}
                height={10}
              />
              <Rect
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                x={15}
                y={0}
                width={Math.max(70, Math.random() * 250)}
                height={10}
              />

              <Rect
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                x={getEndXPosition(receiptWidth, 30)}
                y={0}
                width={30}
                height={10}
              />
            </ContentLoader>
          );
        })}

        <ContentLoader
          style={{ marginTop: spacings.md }}
          height={10}
          width={receiptWidth}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getEndXPosition(receiptWidth, 130)}
            y={0}
            width={50}
            height={10}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getEndXPosition(receiptWidth, 70)}
            y={0}
            width={70}
            height={10}
          />
        </ContentLoader>

        <ContentLoader
          style={{ marginTop: spacings.md }}
          height={10}
          width={receiptWidth}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getEndXPosition(receiptWidth, 180)}
            y={0}
            width={100}
            height={10}
          />
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getEndXPosition(receiptWidth, 70)}
            y={0}
            width={70}
            height={10}
          />
        </ContentLoader>

        <ContentLoader
          style={{ marginVertical: spacings.lg }}
          height={60}
          width={receiptWidth}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={getCenteredXPosition(receiptWidth, 300)}
            y={0}
            width={300}
            height={60}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

export function RequestDeleteAccountSkeleton() {
  return (
    <View
      style={[
        containerStyle.container,
        { flex: 1, justifyContent: "center", alignItems: "center" }
      ]}>
      <ContentLoader width={132} height={132}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={132}
          height={132}
        />
      </ContentLoader>

      <ContentLoader
        width={200}
        height={defaultTextHeight}
        style={{ marginTop: spacings.md }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={200}
          height={defaultTextHeight}
        />
      </ContentLoader>

      <ButtonSkeleton />
    </View>
  );
}

export function DeleteAccountConfirmationSkeleton() {
  return (
    <View style={containerStyle.container}>
      <AccountListItemSkeleton />

      <ContentLoader
        width={200}
        height={defaultTextHeight}
        style={{ marginTop: spacings.md }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={200}
          height={defaultTextHeight}
        />
      </ContentLoader>

      <ContentLoader
        width={300}
        height={defaultTextHeight * 2}
        style={{ marginTop: spacings.sm, marginBottom: spacings.xl }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={300}
          height={defaultTextHeight}
        />
      </ContentLoader>

      {[...Array(3)].map((_, i) => {
        return (
          <View style={{ marginBottom: spacings.xl }}>
            <CheckboxSkeleton key={i}></CheckboxSkeleton>
          </View>
        );
      })}

      <ButtonSkeleton />
    </View>
  );
}

export function RefundAccountBalanceConfirmationSkeleton() {
  return (
    <View style={containerStyle.container}>
      <AccountListItemSkeleton />

      <ContentLoader
        width={200}
        height={defaultTextHeight}
        style={{ marginTop: spacings.md }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          x={0}
          y={0}
          width={200}
          height={defaultTextHeight}
        />
      </ContentLoader>

      <Card
        containerStyle={[
          mediumCardStyle.container,
          {
            padding: spacings.xl,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: spacings.xl
          }
        ]}>
        <ContentLoader width={200} height={defaultTextHeight}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={0}
            y={0}
            width={200}
            height={defaultTextHeight}
          />
        </ContentLoader>

        <ContentLoader
          style={{ marginTop: spacings.sm }}
          width={200}
          height={30}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={0}
            y={0}
            width={200}
            height={30}
          />
        </ContentLoader>
      </Card>

      <View style={{ marginTop: spacings.xl, alignItems: "center" }}>
        <CheckboxSkeleton />
      </View>

      <View style={{ marginTop: spacings.xl, alignItems: "center" }}>
        <ButtonSkeleton />

        <ContentLoader
          style={{ marginTop: spacings.sm }}
          width={300}
          height={defaultTextHeight}>
          <Rect
            ry={cornerRadius.sm}
            rx={cornerRadius.sm}
            x={0}
            y={0}
            width={300}
            height={defaultTextHeight}
          />
        </ContentLoader>
      </View>
    </View>
  );
}

export function CardDetailsInformationSkeleton() {
  const n = 4;

  return (
    <View style={{ flex: 1, backgroundColor: colors.background.neutral }}>
      <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={120}
          height={25}
        />
      </ContentLoader>

      <View
        style={{
          borderRadius: cornerRadius.md,
          backgroundColor: colors.background.light
        }}>
        {[...Array(n)].map((_, index) => (
          <View style={[style.transactionItem, { height: 50 }]} key={index}>
            <View style={style.contentLeft}>
              <ContentLoader height={50} width={"100%"}>
                <Rect
                  y={12.5}
                  ry={cornerRadius.sm}
                  rx={cornerRadius.sm}
                  width={180}
                  height={25}
                />
              </ContentLoader>
            </View>

            <View style={{ position: "relative" }}>
              <ContentLoader height={100} width={80}>
                <Rect x={0} y={35} width={80} height={25} />
              </ContentLoader>
            </View>
          </View>
        ))}
      </View>

      <ButtonSkeleton />
    </View>
  );
}

export function ExternalSitesSkeleton() {
  const itemSize = 100;
  const n = 4;

  return (
    <View style={{ flex: 1, backgroundColor: colors.background.neutral }}>
      <ContentLoader height={25} style={{ marginBottom: spacings.sm }}>
        <Rect
          ry={cornerRadius.sm}
          rx={cornerRadius.sm}
          width={120}
          height={25}
        />
      </ContentLoader>

      <View style={{ flexDirection: "row", gap: spacings.sm }}>
        {[...Array(n)].map((_, index) => (
          <View
            key={index.toString()}
            style={{
              flexDirection: "column",
              width: itemSize,
              justifyContent: "center"
            }}>
            <View
              style={{
                height: itemSize,
                borderRadius: cornerRadius.md,
                backgroundColor: colors.background.light,
                alignItems: "center",
                justifyContent: "center"
              }}>
              <LogoSkeleton />
            </View>

            <ContentLoader height={25} style={{ marginTop: spacings.sm }}>
              <Rect
                ry={cornerRadius.sm}
                rx={cornerRadius.sm}
                x={20}
                width={60}
                height={25}
              />
            </ContentLoader>
          </View>
        ))}
      </View>
    </View>
  );
}
