import {
  CompositeNavigationProp,
  useNavigation
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import Constants from "expo-constants";
import { Image } from "expo-image";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Button, CheckBox, Text } from "react-native-elements";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { RootStackParamsList } from "../navigation";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";
import { useUpdateLegalTerms } from "../services/LegalTermsService";
import { toastError } from "../services/ToastService";
import { ILegalTermsInfo } from "../services/clients/PlatformClient";
import {
  colors,
  cornerRadius,
  fontSizes,
  largePrimaryRoundedButtonStyle,
  primaryLinkButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function UpdateTermsAndPoliciesScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<RootStackParamsList, "updateTermsAndPolicies">,
        StackNavigationProp<HomeStackParamsList>
      >
    >();

  const legalTermsMutation = useUpdateLegalTerms();

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreePolicies, setAgreePolicies] = useState(false);
  const accepted = useRef(false);

  const isFormValid = agreeTerms && agreePolicies;

  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", (e) => {
      if (accepted.current) {
        navigation.dispatch(e.data.action);
        return;
      }

      e.preventDefault();
    });
    return unsubscribe;
  }, []);

  return (
    <ScrollViewComponent>
      <View
        style={{
          marginTop: spacings.xxl,
          alignItems: "center"
        }}>
        <Image
          style={{ alignSelf: "center", width: 230, height: 100 }}
          source={require("../assets/img/secanda-branding.svg")}
          contentFit="contain"
        />
        <Text
          style={[
            typographies.heading,
            {
              marginVertical: spacings.lg
            }
          ]}>
          {t(
            "UpdateTermsAndPoliciesScreenHeader",
            "We have updated our Terms and Policies."
          )}
        </Text>

        <Text style={[typographies.body, { marginVertical: spacings.lg }]}>
          {t(
            "UpdateTermsAndPoliciesScreenFirstDescription",
            "Because we value you as a customer, we want you to know we have made updates to our Terms and Conditions or to our Privacy Policies, including information on procedures for resolving disputes with us. We encourage you to review all the updated terms and policies that apply to you."
          )}
        </Text>

        <Text style={[typographies.body, { marginVertical: spacings.lg }]}>
          {t(
            "UpdateTermsAndPoliciesScreenSecondDescription",
            "Accepting them is your agreement to these updated Terms, Conditions and Privacy Policies."
          )}
        </Text>

        <CheckBox
          containerStyle={{
            marginHorizontal: spacings.none,
            backgroundColor: colors.background.transparent,
            borderWidth: cornerRadius.none,
            alignSelf: "flex-start",
            padding: spacings.none,
            marginVertical: spacings.none,
            marginTop: spacings.xl,
            marginRight: spacings.none
          }}
          checked={agreeTerms}
          checkedIcon="check-square"
          checkedColor={colors.primary}
          title={
            <>
              <Text
                style={[
                  typographies.small,
                  {
                    marginRight: spacings.none,
                    marginLeft: spacings.sm,
                    fontWeight: "normal"
                  }
                ]}>
                {t(
                  "UpdateTermsAndPoliciesScreenConditionTitle",
                  "I agree to the "
                )}
              </Text>
              <Button
                containerStyle={{ justifyContent: "center" }}
                buttonStyle={{
                  padding: spacings.none
                }}
                type="clear"
                title={t(
                  "UpdateTermsAndPoliciesScreenTermsAndConditions",
                  "terms and conditions"
                )}
                titleStyle={[
                  primaryLinkButtonStyle.title,
                  {
                    textAlignVertical: "center",
                    fontSize: fontSizes.sm,
                    textDecorationLine: "underline"
                  }
                ]}
                onPress={() =>
                  navigation.navigate("termsAndConditions")
                }></Button>
            </>
          }
          onPress={() => setAgreeTerms(!agreeTerms)}></CheckBox>

        <CheckBox
          containerStyle={{
            marginHorizontal: spacings.none,
            backgroundColor: colors.background.transparent,
            borderWidth: cornerRadius.none,
            alignSelf: "flex-start",
            padding: spacings.none,
            marginVertical: spacings.none,
            marginTop: spacings.xl,
            marginRight: spacings.none
          }}
          checked={agreePolicies}
          checkedIcon="check-square"
          checkedColor={colors.primary}
          title={
            <>
              <Text
                style={[
                  typographies.small,
                  {
                    marginRight: spacings.none,
                    marginLeft: spacings.sm,
                    fontWeight: "normal"
                  }
                ]}>
                {t(
                  "UpdateTermsAndPoliciesScreenPolicyTitle",
                  "I agree to the "
                )}
              </Text>
              <Button
                containerStyle={{ justifyContent: "center" }}
                buttonStyle={{
                  padding: spacings.none
                }}
                type="clear"
                title={t(
                  "UpdateTermsAndPoliciesScreenPolicies",
                  "privacy policies"
                )}
                titleStyle={[
                  primaryLinkButtonStyle.title,
                  {
                    textAlignVertical: "center",
                    fontSize: fontSizes.sm,
                    textDecorationLine: "underline"
                  }
                ]}
                onPress={() => navigation.navigate("privacyPolicy")}></Button>
            </>
          }
          onPress={() => setAgreePolicies(!agreePolicies)}></CheckBox>

        <Button
          title={t("UpdateTermsAndPoliciesAcceptButton", "Accept")}
          disabled={!isFormValid || legalTermsMutation.isLoading}
          onPress={updateAcceptedLegalTermsAsync}
          titleStyle={largePrimaryRoundedButtonStyle.title}
          containerStyle={[
            largePrimaryRoundedButtonStyle.container,
            {
              marginTop: spacings.xxl
            }
          ]}
          buttonStyle={largePrimaryRoundedButtonStyle.button}
          disabledStyle={largePrimaryRoundedButtonStyle.disabled}
          loading={legalTermsMutation.isLoading}
          loadingStyle={largePrimaryRoundedButtonStyle.loading}></Button>
      </View>
    </ScrollViewComponent>
  );

  async function updateAcceptedLegalTermsAsync() {
    try {
      if (!isFormValid) {
        return;
      }

      const legalTermsInfo: ILegalTermsInfo = {
        version: Constants.expoConfig?.extra?.legalTermsVersion
      };

      await legalTermsMutation.mutateAsync(legalTermsInfo);

      accepted.current = true;

      navigation.reset({
        index: 0,
        routes: [{ name: "root" }]
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t("UpdateTermsAndPoliciesScreenUnknownError", "Internal Server Error")
      );
    }
  }
}
