import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { getServerStatus } from "../services/PlatformService";
import { identityClientAxiosInstance } from "./../services/clients/IdentityClientFactory";
import { platformClientAxiosInstance } from "./../services/clients/PlatformClientFactory";

const useRemoteServerState = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    const platformInterceptor =
      platformClientAxiosInstance.interceptors.response.use(
        undefined,
        (error: AxiosError) => {
          // We just want to set the maintenance mode when server is in maintenance mode
          // If another error comes, we must keep the maintenance mode
          if (error.response?.status === 503) {
            setMaintenanceMode(true);
          }
          return Promise.reject(error);
        }
      );

    const identityInterceptor =
      identityClientAxiosInstance.interceptors.response.use(
        undefined,
        (error: AxiosError) => {
          // We just want to set the maintenance mode when server is in maintenance mode
          // If another error comes, we must keep the maintenance mode
          if (error.response?.status === 503) {
            setMaintenanceMode(true);
          }
          return Promise.reject(error);
        }
      );

    const intervalId = setInterval(async () => {
      try {
        await getServerStatus();
        // If we reach this step then the server is not in maintenance mode anymore
        setMaintenanceMode(false);
      } catch (e) {}
    }, 60000);

    return () => {
      platformClientAxiosInstance.interceptors.response.eject(
        platformInterceptor
      );
      identityClientAxiosInstance.interceptors.response.eject(
        identityInterceptor
      );
      clearInterval(intervalId);
    };
  }, []);

  return { maintenanceMode: maintenanceMode };
};

export default useRemoteServerState;
