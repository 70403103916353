import { useMutation, useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { IAccountKey, IPersonKey, fetchAccounts } from "./AccountsService";
import {
  creditCardQueryKey,
  creditCardsQueryKey,
  invalidateAccount,
  invalidateCreditCards,
  invalidatePaymentModes,
  invalidateRefundInfo,
  invalidateTransactions,
  refundInfoQueryKey
} from "./QueryService";
import {
  CreditCardLoadingInfo,
  IAffiliation,
  ICreditCard,
  IRefundInfoResponse,
  IRefundResponse,
  PspInfo,
  RefundInfoResponse,
  RefundResponse
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

export type CreditCardDetails = {
  accountKey: IAccountKey;
  affiliation: IAffiliation;
  creditCard: ICreditCard;
};

export interface ICreditCardKey extends IAccountKey {
  creditCardId: number;
}

export function useRemoveRegisteredCreditCard() {
  return useMutation(
    ({
      accountKey,
      creditCardId
    }: {
      accountKey: IAccountKey;
      creditCardId: number;
    }) => removeRegisteredCreditCard(accountKey, creditCardId)
  );
}

async function removeRegisteredCreditCard(
  accountKey: IAccountKey,
  creditCardId: number
) {
  const platformClient = platformClientFactory.create();
  await platformClient.deleteRegisteredCreditCard(
    accountKey.identifierId,
    accountKey.affiliationId,
    accountKey.personId,
    accountKey.accountId,
    creditCardId
  );
  await Promise.all([
    invalidateCreditCards({
      refetchType: "all"
    }),
    invalidatePaymentModes(accountKey, {
      refetchType: "all"
    })
  ]);
}

export function useLoadAccountByCreditCard() {
  return useMutation(
    ({
      accountKey,
      creditCardId,
      creditCardModel
    }: {
      accountKey: IAccountKey;
      creditCardId?: number;
      creditCardModel: CreditCardLoadingInfo;
    }) => loadAccountByCreditCard(accountKey, creditCardId, creditCardModel)
  );
}

export function useCreditCard(creditCardKey: ICreditCardKey) {
  return useQuery([creditCardQueryKey, creditCardKey], () =>
    getCreditCard(creditCardKey)
  );
}

async function getCreditCard(creditCardKey: ICreditCardKey) {
  const platformClient = platformClientFactory.create();
  const creditCard = await platformClient.getRegisteredCreditCard(
    creditCardKey.identifierId,
    creditCardKey.affiliationId,
    creditCardKey.personId,
    creditCardKey.accountId,
    creditCardKey.creditCardId
  );

  return creditCard;
}

async function loadAccountByCreditCard(
  accountKey: IAccountKey,
  creditCardId: number | undefined,
  creditCardModel: CreditCardLoadingInfo
): Promise<PspInfo> {
  const platformClient = platformClientFactory.create();
  const pspStartUrl = creditCardId
    ? await platformClient.loadAccountWithRegisteredCreditCard(
        accountKey.identifierId,
        accountKey.affiliationId,
        accountKey.personId,
        accountKey.accountId,
        creditCardId,
        creditCardModel
      )
    : await platformClient.loadAccountWithUnregisteredCreditCard(
        accountKey.identifierId,
        accountKey.affiliationId,
        accountKey.personId,
        accountKey.accountId,
        creditCardModel
      );

  return pspStartUrl;
}

export function useCreditCards() {
  return useQuery([creditCardsQueryKey], getCreditCards);
}

async function getCreditCards() {
  const accounts = await fetchAccounts();
  const platformClient = platformClientFactory.create();
  const creditCardsDetails: CreditCardDetails[] = [];

  await Promise.all(
    accounts.map(async (accountDetail) => {
      if (accountDetail.person && accountDetail.account) {
        const accountKey: IAccountKey = {
          identifierId: accountDetail.identifier.identifierId,
          accountId: accountDetail.account.accountId,
          personId: accountDetail.person.personId,
          affiliationId: accountDetail.affiliation.affiliationId
        };
        const creditCards = await platformClient.getRegisteredCreditCards(
          accountDetail.identifier.identifierId,
          accountDetail.affiliation.affiliationId,
          accountDetail.person.personId,
          accountDetail.account.accountId
        );

        creditCards.map((card) => {
          creditCardsDetails.push({
            affiliation: accountDetail.affiliation,
            accountKey: accountKey,
            creditCard: card
          });
        });
      }
    })
  );

  return orderBy(creditCardsDetails, [
    (c) => c.creditCard.type,
    (c) => c.creditCard.truncatedPan
  ]);
}

export function useRefundInfo(personKey: IPersonKey) {
  return useQuery(
    [refundInfoQueryKey, personKey],
    () => getRefundInfo(personKey),
    {
      cacheTime: 0
    }
  );
}

async function getRefundInfo(personKey: IPersonKey) {
  const platformClient = platformClientFactory.create();
  const accounts = await fetchAccounts().then((accounts) =>
    accounts.filter(
      (a) =>
        a.identifier.identifierId === personKey.identifierId &&
        a.affiliation.affiliationId === personKey.affiliationId &&
        a.person?.personId === personKey.personId &&
        !!a.account?.balance
    )
  );

  const result: IRefundInfoResponse = new RefundInfoResponse({
    pendingAmount: 0,
    refundableAmount: 0,
    maxRefundAmount: 0,
    daysBetweenRefunds: 0,
    nextAvailableDate: new Date()
  });

  for (const account of accounts) {
    const refundInfo = await platformClient.getRefundInfo(
      personKey.identifierId,
      personKey.affiliationId,
      personKey.personId,
      account.account!.accountId
    );
    result.refundableAmount += refundInfo.refundableAmount;
    result.pendingAmount += refundInfo.pendingAmount;
    result.maxRefundAmount = Math.max(
      result.maxRefundAmount,
      refundInfo.maxRefundAmount
    );
    result.nextAvailableDate =
      refundInfo.nextAvailableDate > result.nextAvailableDate
        ? refundInfo.nextAvailableDate
        : result.nextAvailableDate;
    result.daysBetweenRefunds = Math.max(
      result.daysBetweenRefunds,
      refundInfo.daysBetweenRefunds
    );
  }

  return result;
}

export function useRefund() {
  return useMutation(({ personKey }: { personKey: IPersonKey }) =>
    refund(personKey)
  );
}

async function refund(personKey: IPersonKey) {
  const platformClient = platformClientFactory.create();
  const accounts = await fetchAccounts().then((accounts) =>
    accounts.filter(
      (a) =>
        a.identifier.identifierId === personKey.identifierId &&
        a.affiliation.affiliationId === personKey.affiliationId &&
        a.person?.personId === personKey.personId &&
        !!a.account?.balance
    )
  );

  const result: IRefundResponse = new RefundResponse({
    pendingAmount: 0,
    refundedAmount: 0
  });

  for (const account of accounts) {
    const accountKey: IAccountKey = {
      identifierId: account.identifier.identifierId,
      affiliationId: account.affiliation.affiliationId,
      personId: account.person!.personId,
      accountId: account.account!.accountId
    };
    const refundInfo = await platformClient.refund(
      accountKey.identifierId,
      accountKey.affiliationId,
      accountKey.personId,
      accountKey.accountId
    );
    result.pendingAmount += refundInfo.pendingAmount;
    result.refundedAmount += refundInfo.refundedAmount;

    await Promise.all([
      invalidateAccount(accountKey, {
        refetchType: "all"
      }),
      invalidateTransactions(accountKey, {
        refetchType: "all"
      })
    ]);
  }

  await invalidateRefundInfo(personKey, { refetchType: "all" });

  return result;
}
