import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { CreditCardItemSkeleton } from "../components/LoadingSkeletonComponent";
import { CreditCardItemContentComponent } from "../components/PaymentModesListItemComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { CreditCardsStackParamsList } from "../navigation/ProfileStackNavigation";
import { useAccount } from "../services/AccountsService";
import {
  CreditCardDetails,
  ICreditCardKey,
  useCreditCard,
  useRemoveRegisteredCreditCard
} from "../services/CreditCardsService";
import { alert } from "../services/DialogService";
import { toastError, toastSuccess } from "../services/ToastService";
import { primaryLinkButtonStyle } from "../styles/Buttons";
import { colors } from "../styles/Colors";
import {
  cornerRadius,
  iconSizes,
  listItemHeights,
  spacings
} from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { fontFamilies, fontSizes } from "../styles/Fonts";

export default function CreditCardsScreen() {
  const route = useRoute<RouteProp<CreditCardsStackParamsList, "creditCard">>();
  const navigation =
    useNavigation<NavigationProp<CreditCardsStackParamsList>>();
  const { t } = useTranslation();

  const creditCardKey: ICreditCardKey = {
    accountId: route.params.accountId,
    affiliationId: route.params.affiliationId,
    creditCardId: route.params.creditCardId,
    identifierId: route.params.identifierId,
    personId: route.params.personId
  };

  const accountQuery = useAccount({
    accountId: creditCardKey.accountId,
    affiliationId: creditCardKey.affiliationId,
    identifierId: creditCardKey.identifierId,
    personId: creditCardKey.personId
  });
  const creditCardQuery = useCreditCard(creditCardKey);

  const removeRegisteredCreditCardMutation = useRemoveRegisteredCreditCard();

  useEffect(() => {
    if (accountQuery.isInitialLoading || creditCardQuery.isInitialLoading) {
      return;
    }

    navigation.setOptions({
      headerRight: () => (
        <Button
          onPress={() => deleteRegisteredCreditCard()}
          type="clear"
          icon={
            <FontAwesomeIcon
              icon={faTrash}
              color={colors.text.link}
              size={iconSizes.lg}></FontAwesomeIcon>
          }
          titleStyle={[
            primaryLinkButtonStyle.title,
            { fontSize: fontSizes.md }
          ]}></Button>
      )
    });
  }, [accountQuery.isInitialLoading, creditCardQuery.isInitialLoading]);

  if (creditCardQuery.isInitialLoading || accountQuery.isInitialLoading) {
    return <CreditCardItemSkeleton />;
  }

  if (!accountQuery.data || !creditCardQuery.data) {
    return (
      <EmptyContentComponent
        message={t(
          "CreditCardDetailNotFound",
          "Credit card data not found."
        )}></EmptyContentComponent>
    );
  }

  const creditCardDetails: CreditCardDetails = {
    accountKey: {
      accountId: creditCardKey.accountId,
      affiliationId: creditCardKey.affiliationId,
      identifierId: creditCardKey.identifierId,
      personId: creditCardKey.personId
    },
    affiliation: accountQuery.data?.affiliation,
    creditCard: {
      creditCardId: creditCardQuery.data?.creditCardId,
      truncatedPan: creditCardQuery.data?.truncatedPan,
      type: creditCardQuery.data?.type
    }
  };

  return (
    <View style={containerStyle.container}>
      <Text
        style={{
          color: colors.secondary,
          fontSize: fontSizes.md,
          fontFamily: fontFamilies.default,
          marginBottom: spacings.sm
        }}>
        {accountQuery.data?.affiliation.name}
      </Text>

      <View
        style={{
          height: listItemHeights.lg,
          justifyContent: "center",
          paddingHorizontal: spacings.xl,
          borderRadius: cornerRadius.md,
          overflow: "hidden",
          backgroundColor: colors.background.light
        }}>
        <CreditCardItemContentComponent item={creditCardQuery.data} />
      </View>
    </View>
  );

  function deleteRegisteredCreditCard() {
    alert(
      t("CreditCardScreenRemoveCreditCard", "Delete registered credit card"),
      t(
        "CreditCardScreenRemoveCreditCardMessage",
        "Are you sure you want to delete this credit card?"
      ),
      [
        {
          text: t("AlertConfirm", "Confirm"),
          onPress: async () => await removeCreditCard(),
          style: "destructive"
        },
        { text: t("AlertCancel", "Cancel"), style: "cancel" }
      ]
    );
  }

  async function removeCreditCard() {
    try {
      await removeRegisteredCreditCardMutation.mutateAsync({
        accountKey: creditCardDetails.accountKey,
        creditCardId: creditCardKey.creditCardId
      });
      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "CreditCardScreenRemoveCreditCardSuccess",
          "Credit card successfully removed."
        )
      );

      navigation.goBack();
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "CreditCardScreenRemoveCreditCardError",
          "Error while removing the card"
        )
      );
    }
  }
}
