import * as LocalAuthentication from "expo-local-authentication";
import { Platform } from "react-native";
import { LOCAL_AUTH_ENABLED } from "../providers/IdentityConstants";
import { default as i18n } from "../providers/i18n";
import { alert } from "./DialogService";
import * as SecureStorage from "./SecureStorage";

export type AuthenticationTypes = {
  secret: boolean;
  fingerprint: boolean;
  facial: boolean;
  iris: boolean;
};

async function getBiometricAuthTypes(): Promise<
  LocalAuthentication.AuthenticationType[]
> {
  const enrolledLevel = await LocalAuthentication.getEnrolledLevelAsync();
  if (enrolledLevel !== LocalAuthentication.SecurityLevel.BIOMETRIC) return [];

  const hasHardware = await LocalAuthentication.hasHardwareAsync();
  if (!hasHardware) return [];

  const isEnrolled = await LocalAuthentication.isEnrolledAsync();
  if (!isEnrolled) return [];

  const authenticationTypes =
    await LocalAuthentication.supportedAuthenticationTypesAsync();
  return authenticationTypes;
}

export async function getLocalAuthTypes(): Promise<AuthenticationTypes> {
  const enrolledLevel = await LocalAuthentication.getEnrolledLevelAsync();
  const authenticationTypes = await getBiometricAuthTypes();

  const secret = enrolledLevel === LocalAuthentication.SecurityLevel.SECRET;
  const fingerprint = authenticationTypes.includes(
    LocalAuthentication.AuthenticationType.FINGERPRINT
  );
  const facial = authenticationTypes.includes(
    LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION
  );
  const iris = authenticationTypes.includes(
    LocalAuthentication.AuthenticationType.IRIS
  );

  return {
    secret: secret,
    fingerprint: fingerprint,
    facial: facial,
    iris: iris
  };
}

export async function isLocalAuthSupported(): Promise<boolean> {
  const authenticationTypes = await getLocalAuthTypes();
  return (
    authenticationTypes.secret ||
    authenticationTypes.fingerprint ||
    authenticationTypes.facial ||
    authenticationTypes.iris
  );
}

export async function isLocalAuthActivated(): Promise<boolean> {
  const isSupported = await isLocalAuthSupported();
  if (!isSupported) return false;

  const isActivated = await SecureStorage.getItemAsync(LOCAL_AUTH_ENABLED);
  return isActivated === true.toString();
}

export async function localAuthenticationAsync(): Promise<boolean> {
  const supported = await isLocalAuthSupported();
  if (!supported) return false;

  const options: LocalAuthentication.LocalAuthenticationOptions = {
    promptMessage: i18n.t(
      "AuthLocalSignInPromptMessage",
      "Login to your account"
    ),
    cancelLabel: i18n.t("AuthLocalSignInCancelButton", "Cancel")
  };
  const result = await LocalAuthentication.authenticateAsync(options);
  return result.success;
}

export async function configureLocalAuthAsync(): Promise<void> {
  const isConfigured = await SecureStorage.getItemAsync(LOCAL_AUTH_ENABLED);
  if (isConfigured) return;

  const localAuthSupported = await isLocalAuthSupported();
  if (!localAuthSupported) return;

  const localAuthTypeName = await getDefaultAuthTypeName();

  alert(
    i18n.t("AuthLocalEnableItTitle", "Sign in with {{type}}", {
      type: localAuthTypeName
    }),
    i18n.t(
      "AuthLocalEnableItMessage",
      "Would you like to secure account access with {{type}}?",
      {
        type: localAuthTypeName
      }
    ),
    [
      {
        text: i18n.t("AuthLocalEnableItYes", "Yes"),
        onPress: async () => {
          await SecureStorage.setItemAsync(LOCAL_AUTH_ENABLED, true.toString());
        },
        style: "default"
      },
      {
        text: i18n.t("AuthLocalEnableItNo", "No"),
        onPress: async () => {
          await SecureStorage.setItemAsync(
            LOCAL_AUTH_ENABLED,
            false.toString()
          );
        },
        style: "cancel"
      }
    ]
  );
}

export async function getDefaultAuthTypeName(): Promise<string | undefined> {
  const types = await getLocalAuthTypes();
  if (types.iris) return i18n.t("AuthenticationTypes.Iris", "Iris recognition");
  if (types.facial && types.fingerprint)
    return i18n.t("AuthenticationTypes.Biometric", "Biometric");
  if (types.facial)
    return Platform.OS === "ios"
      ? i18n.t("AuthenticationTypes.FaceID", "Face ID")
      : i18n.t("AuthenticationTypes.Facial", "Facial recognition");
  if (types.fingerprint)
    return Platform.OS === "ios"
      ? i18n.t("AuthenticationTypes.TouchID", "Touch ID")
      : i18n.t("AuthenticationTypes.Fingerprint", "Fingerprint");
  if (types.secret)
    return Platform.OS === "ios"
      ? i18n.t("AuthenticationTypes.Passcode", "Passcode")
      : i18n.t("AuthenticationTypes.Secret", "Device Code");
  return undefined;
}

export async function getDefaultAuthTypeDescription(): Promise<
  string | undefined
> {
  const types = await getLocalAuthTypes();
  if (types.facial && types.fingerprint)
    return i18n.t(
      "AuthenticationTypes.BiometricDescription",
      "Require biometric recognition to sign in."
    );
  if (types.iris)
    return i18n.t(
      "AuthenticationTypes.IrisDescription",
      "Require iris recognition to sign in"
    );
  if (types.facial)
    return Platform.OS === "ios"
      ? i18n.t(
          "AuthenticationTypes.FaceIDDescription",
          "Require Face ID to sign in"
        )
      : i18n.t(
          "AuthenticationTypes.FacialDescription",
          "Require face recognition to sign in"
        );
  if (types.fingerprint)
    return Platform.OS === "ios"
      ? i18n.t(
          "AuthenticationTypes.TouchIDDescription",
          "Require Touch ID to sign in"
        )
      : i18n.t(
          "AuthenticationTypes.FingerprintDescription",
          "Require fingerprint to sign in"
        );
  if (types.secret)
    return Platform.OS === "ios"
      ? i18n.t(
          "AuthenticationTypes.PasscodeDescription",
          "Require passcode to sign in"
        )
      : i18n.t(
          "AuthenticationTypes.SecretDescription",
          "Require device code to sign in"
        );
  return undefined;
}
