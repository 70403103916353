import Constants from "expo-constants";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Platform } from "react-native";
import i18n from "./i18n";

export default function ReCaptchaProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const googleReCaptchaSiteKey =
    Constants.expoConfig?.extra?.googleReCaptchaSiteKey;

  if (Platform.OS !== "web") {
    return children as React.ReactElement;
  }

  return (
    <GoogleReCaptchaProvider
      language={i18n.language}
      reCaptchaKey={googleReCaptchaSiteKey}>
      {children}
    </GoogleReCaptchaProvider>
  );
}
