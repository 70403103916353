import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";
import {
  logLogin,
  logLogout,
  setUserId,
  setUserProfile
} from "../services/AnalyticsService";
import {
  loginAsync,
  logoutAsync,
  refreshTokenAsync
} from "../services/AuthenticationService";
import {
  isLocalAuthActivated,
  localAuthenticationAsync
} from "../services/LocalAuthService";
import { queryClient, queryClientPersister } from "../services/QueryService";
import * as SecureStorage from "../services/SecureStorage";
import {
  AuthContext,
  AuthContextActions,
  AuthContextData,
  AuthState
} from "./AuthContext";
import {
  ACCESS_TOKEN,
  LOCAL_AUTH_ENABLED,
  REFRESH_TOKEN,
  REMEMBER_ME,
  USER_IDENTIFIER
} from "./IdentityConstants";

export default function AuthProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    grantAccess: false,
    accessToken: null,
    refreshToken: null,
    userIdentifier: null
  });

  useEffect(() => {
    async function initialize(): Promise<void> {
      let accessToken: string | null = null;
      let refreshToken: string | null = null;
      let identifier: string | null = null;
      let localAuthActivated = false;

      try {
        accessToken = await SecureStorage.getItemAsync(ACCESS_TOKEN);
        refreshToken = await SecureStorage.getItemAsync(REFRESH_TOKEN);
        identifier = await SecureStorage.getItemAsync(USER_IDENTIFIER);
        localAuthActivated = await isLocalAuthActivated();

        await setUserId(identifier);
      } finally {
        dispatch({
          type: "RESTORE_TOKEN",
          accessToken: accessToken,
          grantAccess: localAuthActivated ? false : accessToken !== null,
          refreshToken: refreshToken,
          identifier: identifier
        });
      }
    }

    initialize();
  }, []);

  const signIn = useCallback(
    async (userIdentifier: string, password: string, rememberMe: boolean) => {
      const response = await loginAsync(userIdentifier, password);

      await SecureStorage.setItemAsync(REMEMBER_ME, rememberMe.toString());
      await SecureStorage.setItemAsync(ACCESS_TOKEN, response.access_token);
      await SecureStorage.setItemAsync(REFRESH_TOKEN, response.refresh_token);
      await SecureStorage.setItemAsync(USER_IDENTIFIER, userIdentifier);

      await setUserId(userIdentifier);
      await logLogin();

      dispatch({
        type: "SIGN_IN",
        grantAccess: response.access_token !== null,
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        identifier: userIdentifier
      });
    },
    []
  );

  const signOut = useCallback(async () => {
    try {
      await queryClientPersister.removeClient();
      queryClient.clear();

      if (state.refreshToken) {
        try {
          await logoutAsync(state.refreshToken);
        } catch {
          // Ignore
        }
      }
    } finally {
      await SecureStorage.deleteItemAsync(ACCESS_TOKEN);
      await SecureStorage.deleteItemAsync(REFRESH_TOKEN);
      await SecureStorage.deleteItemAsync(LOCAL_AUTH_ENABLED);
      await SecureStorage.setItemAsync(REMEMBER_ME, false.toString());

      await logLogout();
      await setUserId(null);
      await setUserProfile(null);

      dispatch({
        type: "SIGN_OUT",
        accessToken: null,
        grantAccess: false,
        refreshToken: null,
        identifier: null
      });
    }
  }, [state.refreshToken]);

  const localSignIn = useCallback(async () => {
    const isSuccess = await localAuthenticationAsync();

    if (isSuccess) {
      dispatch({
        type: "SIGN_IN",
        grantAccess: isSuccess,
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        identifier: state.userIdentifier
      });
    }
  }, [state]);

  const refreshAccessToken = useCallback(async () => {
    const refreshToken = await SecureStorage.getItemAsync(REFRESH_TOKEN);
    if (!refreshToken) {
      return null;
    }

    const response = await refreshTokenAsync(refreshToken);

    await SecureStorage.setItemAsync(ACCESS_TOKEN, response.access_token);
    await SecureStorage.setItemAsync(REFRESH_TOKEN, response.refresh_token);

    dispatch({
      type: "REFRESH_TOKEN",
      grantAccess: response.access_token !== null,
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      identifier: state.userIdentifier
    });

    return response;
  }, [state.userIdentifier]);

  const authContext: AuthContextActions = useMemo(
    () => ({
      signIn,
      localSignIn,
      signOut,
      refreshAccessToken
    }),
    [signIn, localSignIn, signOut, refreshAccessToken]
  );

  return (
    <AuthContext.Provider value={{ ...state, ...authContext }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) throw new Error("Authentication context is required");
  return context;
}

function reducer(
  prevState: AuthState,
  action: {
    type: string;
    grantAccess: boolean;
    accessToken: string | null;
    refreshToken: string | null;
    identifier: string | null;
  }
): AuthState {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        grantAccess: action.grantAccess,
        userIdentifier: action.identifier,
        isLoading: false
      };
    case "SIGN_IN":
      return {
        ...prevState,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        grantAccess: action.grantAccess,
        userIdentifier: action.identifier
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        accessToken: null,
        refreshToken: null,
        grantAccess: false
      };
    case "REFRESH_TOKEN":
      return {
        ...prevState,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken
      };
    default:
      return prevState;
  }
}
