import { NavigatorScreenParams } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import usePreselectedAccount from "../hooks/PreselectedAccountHook";
import SendAmountSelectionScreen from "../screens/SendAmountSelectionScreen";
import SendScreen from "../screens/SendScreen";
import { useAccounts } from "../services/AccountsService";
import { headerStyle } from "../styles/Styles";

export type SendAmountSelectionScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};
export type SendSelectAccountStackParamsList = {
  send: undefined;
  amountSelection: SendAmountSelectionScreenProps;
};
export type SendStackParamsList = {
  selectAccountStack: NavigatorScreenParams<SendSelectAccountStackParamsList>;
};
const SendStack = createStackNavigator<SendStackParamsList>();

export default function SendStackScreen() {
  const { t } = useTranslation();

  return (
    <SendStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <SendStack.Screen
        name="selectAccountStack"
        component={SelectAccountScreen}
        options={{
          headerShown: false
        }}
      />
    </SendStack.Navigator>
  );
}

const SendAccountScreenStack =
  createStackNavigator<SendSelectAccountStackParamsList>();

function SelectAccountScreen() {
  const { t } = useTranslation();
  const accountsQuery = useAccounts();
  const { preselectedAccount } = usePreselectedAccount();

  return (
    <SendAccountScreenStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <SendAccountScreenStack.Screen
        name="send"
        component={SendScreen}
        options={{
          title: t("SendScreenTitle", "Transfer")
        }}
      />
      <SendAccountScreenStack.Screen
        name="amountSelection"
        component={SendAmountSelectionScreen}
        options={{
          animationEnabled:
            (accountsQuery.data?.length ?? 0) > 1 &&
            Platform.OS !== "web" &&
            !!!preselectedAccount,
          title: t("SendAmountSelectionScreenTitle", "Transfer")
        }}></SendAccountScreenStack.Screen>
    </SendAccountScreenStack.Navigator>
  );
}
