import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, TextInput, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import validator from "validator";
import AffiliationContactDataSummary from "../components/AffiliationContactDataSummaryComponent";
import NameDataComponent from "../components/NameDataComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useReCaptchaToken from "../hooks/ReCaptchaHook";
import { RootStackParamsList } from "../navigation";
import {
  ContactSelectAffiliationStackParamsList,
  ProfileStackParamsList
} from "../navigation/ProfileStackNavigation";
import { useAuth } from "../providers/AuthenticationProvider";
import {
  useAffiliationById,
  useAffiliationContact
} from "../services/AffiliationsService";
import { useContactPlatform } from "../services/PlatformService";
import { useProfile } from "../services/ProfileService";
import { toastError, toastSuccess } from "../services/ToastService";
import { IAffiliation, IProfileInfo } from "../services/clients/PlatformClient";
import {
  colors,
  containerStyle,
  cornerRadius,
  largeInputStyle,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function ContactAffiliationScreen() {
  const route =
    useRoute<RouteProp<ContactSelectAffiliationStackParamsList, "contact">>();
  const { t } = useTranslation();

  const { grantAccess } = useAuth();

  const profileQuery = useProfile({ enabled: !!grantAccess });
  const affiliationQuery = useAffiliationById(route.params?.affiliationId!, {
    enabled: !!route.params?.affiliationId
  });
  const affiliationContactQuery = useAffiliationContact(
    route.params?.affiliationId!,
    { enabled: !!route.params?.affiliationId }
  );

  if (
    profileQuery.isInitialLoading ||
    affiliationQuery.isInitialLoading ||
    affiliationContactQuery.isInitialLoading
  ) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return (
    <ScrollViewComponent>
      {affiliationQuery.data && affiliationContactQuery.data && (
        <>
          <Text
            style={[
              typographies.title,
              {
                marginBottom: spacings.sm
              }
            ]}>
            {t(
              "ContactAffiliationScreenContactAffiliationTitle",
              "Contact your affiliation"
            )}
          </Text>
          <AffiliationContactDataSummary
            affiliation={affiliationQuery.data}
            affiliationContact={
              affiliationContactQuery.data
            }></AffiliationContactDataSummary>
        </>
      )}

      <View style={!!route.params?.affiliationId && { marginTop: spacings.lg }}>
        <SecandaContactForm
          profileInfo={profileQuery.data}
          affiliation={affiliationQuery.data}
        />
      </View>
    </ScrollViewComponent>
  );
}

function SecandaContactForm({
  profileInfo,
  affiliation
}: {
  profileInfo?: IProfileInfo;
  affiliation?: IAffiliation;
}) {
  const { t } = useTranslation();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<ProfileStackParamsList, "profile">,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const contactPlatformMutation = useContactPlatform();
  const { grantAccess, userIdentifier } = useAuth();
  const { executeRecaptcha } = useReCaptchaToken("contact");

  const [firstName, setFirstName] = useState<string | undefined>(
    profileInfo?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(
    profileInfo?.lastName
  );
  const [email, setEmail] = useState(userIdentifier || "");
  const [affiliationName, setAffiliationName] = useState(
    affiliation?.name || ""
  );
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);

  const emailRef = useRef<TextInput>(null);
  const affiliationCenterRef = useRef<TextInput>(null);
  const affiliationNameRef = useRef<TextInput>(null);
  const subjectRef = useRef<TextInput>(null);
  const messageRef = useRef<TextInput>(null);

  const isFormValid =
    firstName && lastName && email && subject && message && emailIsValid;

  async function contactPlatformAsync() {
    if (!isFormValid) {
      return;
    }

    try {
      const formattedMessage = t(
        "ContactPlatformFormattedMessage",
        "Affiliation: {{affiliationName}} <br>Account owner: {{accountOwner}} <br>{{message}}",
        {
          affiliationName: affiliationName,
          accountOwner: `${firstName} ${lastName}`,
          message: message.replace(/(?:\r\n|\r|\n)/g, "<br>")
        }
      );

      const recaptchaToken = await executeRecaptcha();
      await contactPlatformMutation.mutateAsync({
        contactInfoModel: {
          email: email!,
          message: formattedMessage,
          subject: subject,
          name: `${firstName} ${lastName}`
        },
        recaptchaToken: recaptchaToken
      });

      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "ContactAffiliationScreenContactedSuccess",
          "Thank you for contacting us. We will get back to you shortly."
        )
      );

      grantAccess
        ? navigation.navigate("profile")
        : navigation.replace("auth", { screen: "login" });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      switch (e?.status) {
        case 400:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "ContactAffiliationScreenContactedError",
              "An error occurred when contacting SECANDA."
            )
          );
          break;
        default:
          toastError(
            t("AlertErrorTitle", "Error"),
            t("ContactAffiliationScreenUnknownError", "Internal server error")
          );
          break;
      }
    }
  }

  return (
    <>
      <Text
        style={[
          typographies.title,
          {
            marginBottom: spacings.sm
          }
        ]}>
        {t("ContactAffiliationScreenContactSecandaTitle", "Contact SECANDA")}
      </Text>

      <NameDataComponent
        onFirstNameChanged={setFirstName}
        onLastNameChanged={setLastName}
        defaultFirstName={firstName}
        defaultLastName={lastName}
        onSubmitCallback={() => emailRef.current?.focus()}
      />

      <Input
        autoCapitalize="none"
        inputMode="email"
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          largeInputStyle.container,
          {
            marginVertical: spacings.xl,
            borderColor: !emailIsValid ? colors.danger : undefined,
            borderWidth: !emailIsValid ? cornerRadius.xxs : undefined
          }
        ]}
        ref={emailRef}
        returnKeyType="next"
        onSubmitEditing={() => {
          affiliationCenterRef.current?.focus();
        }}
        inputStyle={largeInputStyle.input}
        placeholder={t("RegisterScreenEmailPlaceHolder", "Email *")}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        errorMessage={
          !emailIsValid
            ? t("RegisterScreenEmailNotValid", "Email is not valid")
            : undefined
        }
        onBlur={() =>
          setEmailIsValid(email !== undefined && validator.isEmail(email))
        }
        onChangeText={setEmail}
        value={email}></Input>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          largeInputStyle.container,
          {
            marginBottom: spacings.xl
          }
        ]}
        inputStyle={largeInputStyle.input}
        placeholder={t(
          "ContactAffiliationScreenAffiliationPlaceHolder",
          "Affiliation name"
        )}
        ref={affiliationCenterRef}
        returnKeyType="next"
        onSubmitEditing={() => {
          affiliationNameRef.current?.focus();
        }}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        onChangeText={setAffiliationName}
        value={affiliationName}></Input>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          largeInputStyle.container,
          {
            marginBottom: spacings.xl
          }
        ]}
        inputStyle={largeInputStyle.input}
        placeholder={t(
          "ContactAffiliationScreenSubjectPlaceHolder",
          "Subject *"
        )}
        ref={subjectRef}
        returnKeyType="next"
        onSubmitEditing={() => {
          messageRef.current?.focus();
        }}
        placeholderTextColor={colors.text.placeholder}
        onChangeText={setSubject}
        value={subject}></Input>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        inputStyle={largeInputStyle.input}
        containerStyle={[
          largeInputStyle.container,
          {
            height: undefined,
            flex: 1,
            marginBottom: spacings.xl
          }
        ]}
        autoCorrect={false}
        value={message}
        numberOfLines={4}
        onChangeText={setMessage}
        placeholder={t(
          "ContactAffiliationScreenMessagePlaceHolder",
          "Message *"
        )}
        ref={messageRef}
        multiline={true}
        placeholderTextColor={colors.text.placeholder}></Input>

      <Button
        disabled={!isFormValid || contactPlatformMutation.isLoading}
        title={t("ContactAffiliationScreenSendButton", "Send")}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        containerStyle={largePrimaryRoundedButtonStyle.container}
        loading={contactPlatformMutation.isLoading}
        onPress={contactPlatformAsync}
        titleStyle={[largePrimaryRoundedButtonStyle.title, { flex: 1 }]}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}></Button>
    </>
  );
}
