import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { BaseResultScreenProps } from ".";
import ResetPasswordResultScreen from "../screens/ResetPasswordResultScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import ValidateEmailScreen from "../screens/ValidateEmailScreen";
import { headerStyle } from "../styles/Styles";

export type ValidateEmailScreenProps = {
  userId: number;
  identifierId: number;
  token: string;
};

export type ResetPasswordScreenProps = {
  userId: number;
  token: string;
};

export type ResetPasswordResultScreenProps = BaseResultScreenProps & {
  description: string;
};

export type UserStackParamsList = {
  validateEmail: ValidateEmailScreenProps | undefined;
  resetPassword: ResetPasswordScreenProps | undefined;
  resetPasswordResult: ResetPasswordResultScreenProps;
};

const UserStack = createStackNavigator<UserStackParamsList>();

export default function UserStackScreen() {
  const { t } = useTranslation();

  return (
    <UserStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <UserStack.Screen
        name="validateEmail"
        component={ValidateEmailScreen}
        options={{
          headerShown: false,
          title: t("ValidateEmailScreenTitle", "Validate email")
        }}></UserStack.Screen>
      <UserStack.Screen
        name="resetPassword"
        component={ResetPasswordScreen}
        options={{
          title: t("ResetPasswordScreenTitle", "Reset Password")
        }}></UserStack.Screen>
      <UserStack.Screen
        name="resetPasswordResult"
        component={ResetPasswordResultScreen}
        options={{
          headerShown: false,
          title: t("ResetPasswordScreenTitle", "Reset Password")
        }}></UserStack.Screen>
    </UserStack.Navigator>
  );
}
