import { useMutation, useQuery } from "@tanstack/react-query";
import { IAccountKey } from "./AccountsService";
import { getDataUrl } from "./BlobService";
import {
  paymentSlipQueryKey,
  qrBillQrCodeQueryKey,
  queryClient
} from "./QueryService";
import {
  IPaymentSlip,
  IPaymentSlipInfo,
  PaymentSlip,
  PaymentSlipInfo,
  QrBillGraphicsFormat,
  QrBillOutputSize
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

export interface IPaymentSlipKey extends IAccountKey {
  paymentSlipId: number;
}

async function createPaymentSlip(
  accountId: IAccountKey,
  paymentSlipInfo: IPaymentSlipInfo
): Promise<PaymentSlip> {
  const platformClient = platformClientFactory.create();
  const paymentSlip = await platformClient.requestPaymentSlip(
    accountId.identifierId,
    accountId.affiliationId,
    accountId.personId,
    accountId.accountId,
    new PaymentSlipInfo(paymentSlipInfo)
  );

  return paymentSlip;
}

export function useCreatePaymentSlip() {
  return useMutation(
    ({
      accountId,
      paymentSlipInfo
    }: {
      accountId: IAccountKey;
      paymentSlipInfo: IPaymentSlipInfo;
    }) => createPaymentSlip(accountId, paymentSlipInfo),
    {
      onSuccess: (data, variables) => {
        const paymentSlipKey: IPaymentSlipKey = {
          ...variables.accountId,
          paymentSlipId: data.paymentSlipId
        };
        queryClient.setQueryData([paymentSlipQueryKey, paymentSlipKey], data);
      }
    }
  );
}

async function getQrBillQrCode(
  paymentSlipKey: IPaymentSlipKey
): Promise<string | null> {
  const platformClient = platformClientFactory.create();

  const fileResponse = await platformClient.getRequestedPaymentSlipQrBill(
    paymentSlipKey.identifierId,
    paymentSlipKey.affiliationId,
    paymentSlipKey.personId,
    paymentSlipKey.accountId,
    paymentSlipKey.paymentSlipId,
    QrBillGraphicsFormat.Png,
    QrBillOutputSize.QrCodeOnly
  );
  return await getDataUrl(fileResponse.data);
}

export function useQrBillQrCode(paymentSlipKey: IPaymentSlipKey) {
  return useQuery([qrBillQrCodeQueryKey, paymentSlipKey], () =>
    getQrBillQrCode(paymentSlipKey!)
  );
}

async function getPaymentSlip(
  paymentSlipKey: IPaymentSlipKey
): Promise<IPaymentSlip> {
  const platformClient = platformClientFactory.create();
  return await platformClient.getRequestedPaymentSlip(
    paymentSlipKey.identifierId,
    paymentSlipKey.affiliationId,
    paymentSlipKey.personId,
    paymentSlipKey.accountId,
    paymentSlipKey.paymentSlipId
  );
}

export function usePaymentSlip(paymentSlipKey: IPaymentSlipKey) {
  return useQuery([paymentSlipQueryKey, paymentSlipKey], () =>
    getPaymentSlip(paymentSlipKey)
  );
}
