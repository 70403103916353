import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import countries from "i18n-iso-countries";
import { t } from "i18next";
import { entries, orderBy, partition } from "lodash";
import React, { useMemo, useState } from "react";
import { SectionList, View } from "react-native";
import { ListItem, SearchBar, Text } from "react-native-elements";
import i18n from "../providers/i18n";
import { colors } from "../styles/Colors";
import { iconSizes, listItemHeights, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { typographies } from "../styles/Fonts";
import { searchBarStyle } from "../styles/Inputs";
import { virtualizedListItemStyle } from "../styles/Lists";

const favoritesCountriesCode = ["CH", "DE", "ES", "GB", "US"];

export default function CountrySelectionComponent({
  defaultCountryCode,
  onCountrySelection
}: {
  defaultCountryCode: string | undefined;
  onCountrySelection: (countryCode?: string) => Promise<void> | void;
}) {
  const [selectedCountry, setSelectedCountry] = useState(defaultCountryCode);

  const [filter, setFilter] = useState("");

  const allCountries = useMemo(() => {
    const languageCode = i18n.language.substring(0, 2);
    const result = entries(
      countries.getNames(languageCode, { select: "official" })
    ).map(([key, value]) => {
      return { code: key, name: value };
    });
    return orderBy(result, ["name"]);
  }, [i18n.language]);

  const allGroupedCountries = useMemo(() => {
    const [favoriteCountries, otherCountries] = partition(allCountries, (c) =>
      favoritesCountriesCode.includes(c.code)
    );
    return [{ data: favoriteCountries }, { data: otherCountries }];
  }, [allCountries]);

  const filteredGroupedCountries = React.useMemo(() => {
    if (!filter) return allGroupedCountries;

    const normalizedFilter = filter.toLowerCase();
    return allGroupedCountries.map((group) => {
      return {
        data: group.data.filter((d) =>
          d.name.toLowerCase().includes(normalizedFilter)
        )
      };
    });
  }, [filter, allGroupedCountries]);

  return (
    <SectionList
      stickySectionHeadersEnabled={false}
      sections={filteredGroupedCountries}
      contentContainerStyle={containerStyle.container}
      ListHeaderComponent={
        <SearchBar
          inputStyle={searchBarStyle.input}
          inputContainerStyle={searchBarStyle.inputContainer}
          containerStyle={[
            searchBarStyle.container,
            { marginBottom: spacings.lg }
          ]}
          placeholder={t(
            "SelectAffiliationToEnrollSearchBarPlaceholder",
            "Search"
          )}
          placeholderTextColor={colors.text.placeholder}
          value={filter}
          searchIcon={() => {
            return (
              <FontAwesomeIcon
                icon={faSearch}
                size={iconSizes.md}
                color={colors.secondary}></FontAwesomeIcon>
            );
          }}
          // TODO: Known typescript check issue: https://github.com/react-native-elements/react-native-elements/issues/3089
          onChangeText={(value) => {
            setFilter(value);
          }}></SearchBar>
      }
      renderItem={({ item, index, section }) => (
        <View
          key={index}
          style={[
            virtualizedListItemStyle.baseItem,
            index === 0 ? virtualizedListItemStyle.firstItem : null,
            index === section.data.length - 1
              ? [
                  virtualizedListItemStyle.lastItem,
                  { marginBottom: spacings.lg }
                ]
              : virtualizedListItemStyle.middleItem
          ]}>
          <CountriesListItem
            key={index}
            name={item.name}
            selected={selectedCountry === item.code}
            onPress={() => onCountryValueChange(item.code)}
          />
        </View>
      )}
      keyExtractor={(_, index) => index.toString()}></SectionList>
  );

  function onCountryValueChange(value: string) {
    setSelectedCountry(value);
    onCountrySelection?.(value);
  }
}

function CountriesListItem({
  name,
  selected,
  onPress
}: {
  name: string;
  selected: boolean;
  onPress: () => void;
}) {
  return (
    <ListItem
      containerStyle={{
        height: listItemHeights.md,
        padding: spacings.lg,
        flexDirection: "row",
        alignItems: "center"
      }}
      onPress={onPress}>
      <Text style={[typographies.body, { flex: 1 }]}>{name}</Text>

      {selected && (
        <FontAwesomeIcon
          style={{
            color: colors.text.primary
          }}
          icon={faCheck}
          size={iconSizes.lg}></FontAwesomeIcon>
      )}
    </ListItem>
  );
}
