import { faKey } from "@fortawesome/pro-light-svg-icons/faKey";
import { faUserLock } from "@fortawesome/pro-light-svg-icons/faUserLock";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-elements";
import LocalAuthSelectionComponent from "../components/LocalAuthSelectionComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { SecurityStackParamsList } from "../navigation/ProfileStackNavigation";
import {
  getDefaultAuthTypeDescription,
  getDefaultAuthTypeName,
  isLocalAuthSupported
} from "../services/LocalAuthService";
import { virtualizedListItemStyle } from "../styles/Lists";
import { colors, iconSizes, spacings, typographies } from "../styles/Styles";
import { ProfileItemDetail, itemHeight } from "./ProfileScreen";

export default function SecuritySettingsScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<SecurityStackParamsList>>();

  const [localAuthSupported, setLocalAuthSupported] = useState(false);
  const [localAuthTypeName, setLocalAuthTypeName] = useState<string>();
  const [localAuthTypeDescription, setLocalAuthTypeDescription] =
    useState<string>();

  useEffect(() => {
    async function checkLocalAuthSupported(): Promise<void> {
      const isSupported = await isLocalAuthSupported();
      const typeName = await getDefaultAuthTypeName();
      const typeDescription = await getDefaultAuthTypeDescription();
      setLocalAuthSupported(isSupported);
      setLocalAuthTypeName(typeName);
      setLocalAuthTypeDescription(typeDescription);
    }

    checkLocalAuthSupported();
  }, []);

  return (
    <ScrollViewComponent>
      <ProfileItemDetail
        firstItem
        lastItem={!localAuthSupported}
        title={t(
          "SecuritySettingsScreenChangePasswordTitle",
          "Change password"
        )}
        description={t(
          "SecuritySettingsScreenChangePasswordDescription",
          "Edit the password of your account"
        )}
        iconLeft={faKey}
        onPress={() => navigation.navigate("changePassword")}
      />

      {localAuthSupported && (
        <View
          style={[
            virtualizedListItemStyle.lastItem,
            {
              backgroundColor: colors.background.light,
              flexDirection: "row",
              height: itemHeight,
              paddingHorizontal: spacings.xl,
              paddingVertical: spacings.lg,
              alignItems: "center"
            }
          ]}>
          <FontAwesomeIcon
            icon={faUserLock}
            size={iconSizes.xl}
            color={colors.text.primary}></FontAwesomeIcon>

          <View
            style={{
              flexDirection: "column",
              marginHorizontal: spacings.lg,
              flex: 1
            }}>
            <Text style={typographies.body}>{localAuthTypeName}</Text>
            <Text style={typographies.caption}>{localAuthTypeDescription}</Text>
          </View>

          <LocalAuthSelectionComponent />
        </View>
      )}
    </ScrollViewComponent>
  );
}
