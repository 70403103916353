import { default as React, useEffect } from "react";
import { Platform, View } from "react-native";
import { default as WebView, WebViewMessageEvent } from "react-native-webview";
import {
  ShouldStartLoadRequest,
  WebViewMessage,
  WebViewNavigation,
  WebViewNavigationEvent
} from "react-native-webview/lib/WebViewTypes";
import PDFReader from "rn-pdf-reader-js";
import { cornerRadius } from "../styles/Constants";

export default function WebViewComponent({
  path,
  injectedJavaScript,
  canNavigate,
  onNavigation,
  onMessage
}: {
  path: string;
  injectedJavaScript?: string;
  canNavigate?: (path: WebViewNavigation) => boolean;
  onNavigation?: (path: WebViewNavigation) => void;
  onMessage?: (event: WebViewMessage) => void;
}) {
  useEffect(() => {
    if (Platform.OS !== "web") return;
    window.removeEventListener("message", onFrameMessage);
    window.addEventListener("message", onFrameMessage);

    return () => window.removeEventListener("message", onFrameMessage);
  }, [onMessage]);

  if (Platform.OS === "web") {
    return (
      <iframe
        id="iframeId"
        style={{ flex: 1, width: "100%", border: cornerRadius.none }}
        src={path}
        onLoad={onFrameLoad}></iframe>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      {path.includes("pdf") ? (
        <PdfViewer path={path}></PdfViewer>
      ) : (
        <WebView
          originWhitelist={["*"]}
          source={{ uri: path }}
          style={{ flex: 1 }}
          onLoad={onWebViewLoad}
          onMessage={onWebViewMessage}
          injectedJavaScript={injectedJavaScript}
          onShouldStartLoadWithRequest={onWebViewLoadRequest}
        />
      )}
    </View>
  );

  function onFrameLoad() {
    if (onNavigation) {
      onNavigation({
        url: path,
        navigationType: "other",
        canGoBack: true,
        canGoForward: true,
        loading: false,
        title: "",
        lockIdentifier: 0
      });
    }
  }

  function onFrameMessage(message: MessageEvent) {
    if (onMessage) {
      onMessage({
        data: message.data,
        url: message.origin,
        canGoBack: true,
        canGoForward: true,
        loading: false,
        title: "",
        lockIdentifier: message.timeStamp
      });
    }
  }

  function onWebViewLoad(event: WebViewNavigationEvent) {
    if (onNavigation) {
      onNavigation(event.nativeEvent);
    }
  }

  function onWebViewMessage(event: WebViewMessageEvent) {
    if (onMessage) {
      onMessage(event.nativeEvent);
    }
  }

  function onWebViewLoadRequest(event: ShouldStartLoadRequest): boolean {
    if (canNavigate) {
      return canNavigate(event);
    }
    return true;
  }
}

function PdfViewer({ path }: { path: string }) {
  const isBase64 = path.startsWith("data:application/pdf;base64");

  return (
    <PDFReader source={isBase64 ? { base64: path } : { uri: path }}></PDFReader>
  );
}
