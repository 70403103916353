import { faHandHoldingDollar } from "@fortawesome/pro-light-svg-icons/faHandHoldingDollar";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons/faPaperPlane";
import { faWarning } from "@fortawesome/pro-light-svg-icons/faWarning";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { Button, Card, Text } from "react-native-elements";
import { RequestDeleteAccountSkeleton } from "../components/LoadingSkeletonComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";
import {
  AccountDetail,
  IPersonKey,
  useAccounts
} from "../services/AccountsService";
import { useAffiliation } from "../services/AffiliationsService";
import { useRefundInfo } from "../services/CreditCardsService";
import { humanizeCurrency } from "../services/HumanizerService";
import { useRequestDeletePerson } from "../services/PersonsService";
import { toastError, toastSuccess } from "../services/ToastService";
import { iconSizes, spacings } from "../styles/Constants";
import {
  colors,
  containerStyle,
  fontSizes,
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle,
  mediumCardStyle,
  typographies
} from "../styles/Styles";

export default function RequestDeleteAccountScreen() {
  const route =
    useRoute<RouteProp<HomeStackParamsList, "requestDeleteAccount">>();
  const navigation = useNavigation<NavigationProp<HomeStackParamsList>>();
  const { t } = useTranslation();

  const { identifierId, affiliationId, personId } = route.params;
  const personKey: IPersonKey = {
    identifierId,
    affiliationId,
    personId
  };

  const affiliationQuery = useAffiliation(personKey);
  const accountsQuery = useAccounts({
    select: (accounts) =>
      accounts.filter(
        (a) =>
          a.identifier.identifierId === identifierId &&
          a.affiliation.affiliationId === affiliationId &&
          a.person?.personId === personId
      )
  });
  const refundInfoQuery = useRefundInfo(personKey);

  const hasNegativeBalance = useMemo(
    () =>
      !!accountsQuery.data?.some((a) => a.account && a.account?.balance < 0),
    [accountsQuery.data]
  );

  const requestDeletePersonMutation = useRequestDeletePerson();
  const isRefundAvailable =
    affiliationQuery.data?.configuration.refundByCreditCard &&
    refundInfoQuery.data?.refundableAmount !== 0;

  if (
    affiliationQuery.isInitialLoading ||
    accountsQuery.isInitialLoading ||
    refundInfoQuery.isInitialLoading
  ) {
    return <RequestDeleteAccountSkeleton />;
  }

  if (hasNegativeBalance) {
    return (
      <View style={containerStyle.container}>
        <Text
          style={[
            typographies.body,
            {
              textAlign: "center"
            }
          ]}>
          {t(
            "RequestDeleteAccountNegativeBalance",
            "You cannot delete your account, your balance is below zero. Please get in touch with your affiliation to perform the account deletion."
          )}
        </Text>
      </View>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={[
          {
            paddingVertical: spacings.xl,
            flex: 1
          },
          containerStyle.container
        ]}>
        {isRefundAvailable ? (
          <BalanceRefundableSummary
            personKey={personKey}
            skipRefundCallbackLoading={requestDeletePersonMutation.isLoading}
            skipRefundCallback={requestDeleteAccountAsync}
          />
        ) : (
          <BalanceNotRefundableSummary
            personKey={personKey}
            onDeleteCallback={requestDeleteAccountAsync}
            onDeleteCallbackLoading={requestDeletePersonMutation.isLoading}
          />
        )}
      </View>
    </SafeAreaView>
  );

  async function requestDeleteAccountAsync() {
    try {
      await requestDeletePersonMutation.mutateAsync({
        personKey
      });

      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "RequestDeleteAccountScreenDeleteSuccessMessage",
          "An email has been sent in order to confirm the deletion of the account."
        )
      );
      navigation.navigate("home");
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      switch (e?.status) {
        case 400:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "RequestDeleteAccountScreenDataError",
              "An error occurred during the deletion. Please check the data."
            )
          );
          break;
        case 404:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "RequestDeleteAccountScreenNotFound",
              "The account was not found."
            )
          );
          break;
        default:
          toastError(
            t("AlertErrorTitle", "Error"),
            t("RequestDeleteAccountScreenUnknownError", "Internal server error")
          );
          break;
      }
    }
  }
}

function BalanceRefundableSummary({
  personKey,
  skipRefundCallbackLoading,
  skipRefundCallback
}: {
  personKey: IPersonKey;
  skipRefundCallbackLoading: boolean;
  skipRefundCallback: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<HomeStackParamsList>>();
  const accountsQuery = useAccounts({
    select: (accounts) =>
      accounts.filter(
        (a) =>
          a.identifier.identifierId === personKey.identifierId &&
          a.affiliation.affiliationId === personKey.affiliationId &&
          a.person?.personId === personKey.personId &&
          !!a.account?.balance
      )
  });
  const refundInfoQuery = useRefundInfo(personKey);

  if (!refundInfoQuery.data || !accountsQuery.data) {
    return null;
  }

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: spacings.xl
      }}>
      <FontAwesomeIcon
        icon={faHandHoldingDollar}
        size={iconSizes.xxxl}
        style={{ color: colors.primary30 }}
      />
      <Text
        style={[
          typographies.heading,
          {
            fontSize: fontSizes.lg,
            marginTop: spacings.lg,
            marginBottom: spacings.xs,
            textAlign: "center",
            color: colors.primary
          }
        ]}>
        {t(
          "RequestDeleteAccountScreenRefundBeforeDeleteTitle",
          "Before deleting the account"
        )}
      </Text>

      <Text
        style={[
          typographies.subtitle,
          {
            fontSize: fontSizes.sm,
            textAlign: "center",
            marginBottom: spacings.xl
          }
        ]}>
        {t(
          "RequestDeleteAccountScreenRefundBeforeDeleteHeader",
          "You still have an amount of {{amount}} available to refund. Do you want to proceed with the refund before requesting the deletion of the account?",
          {
            amount: humanizeCurrency(
              refundInfoQuery.data.refundableAmount,
              accountsQuery.data[0].account?.currency!
            )
          }
        )}
      </Text>

      <Button
        title={t(
          "RequestDeleteAccountScreenProceedRefundButton",
          "Proceed to refund"
        )}
        onPress={() => {
          if (accountsQuery.data[0].account?.accountId) {
            navigation.navigate("refundAccountBalance", {
              identifierId: personKey.identifierId,
              affiliationId: personKey.affiliationId,
              personId: personKey.personId,
              accountId: accountsQuery.data[0].account.accountId
            });
          }
        }}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        containerStyle={largePrimaryRoundedButtonStyle.container}
      />

      {refundInfoQuery.data.pendingAmount !== 0 && (
        <Text
          style={[
            typographies.small,
            {
              textAlign: "center",
              marginTop: spacings.sm,
              marginHorizontal: spacings.lg
            }
          ]}>
          {t(
            "RequestDeleteAccountScreenNotFullRefundWarning",
            "* You have an amount of {{amount}} that cannot be refunded. Please, get in touch with your affiliation to get the refund of this amount.",
            {
              amount: humanizeCurrency(
                refundInfoQuery.data.pendingAmount,
                accountsQuery.data[0].account?.currency!
              )
            }
          )}
        </Text>
      )}

      <Button
        loading={skipRefundCallbackLoading}
        disabled={skipRefundCallbackLoading}
        title={t("RequestDeleteAccountScreenSkipRefundButton", "Skip")}
        onPress={skipRefundCallback}
        buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}
        titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          { marginTop: spacings.md }
        ]}
      />

      <Text
        style={[
          typographies.small,
          {
            textAlign: "center",
            marginTop: spacings.sm,
            marginHorizontal: spacings.lg
          }
        ]}>
        {t(
          "RequestDeleteAccountScreenSecurityWarning",
          "* As security measure, a confirmation email will be sent to you."
        )}
      </Text>
    </View>
  );
}

function BalanceNotRefundableSummary({
  personKey,
  onDeleteCallbackLoading,
  onDeleteCallback
}: {
  personKey: IPersonKey;
  onDeleteCallbackLoading: boolean;
  onDeleteCallback: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const accountsQuery = useAccounts({
    select: (accounts) =>
      accounts.filter(
        (a) =>
          a.identifier.identifierId === personKey.identifierId &&
          a.affiliation.affiliationId === personKey.affiliationId &&
          a.person?.personId === personKey.personId &&
          !!a.account?.balance
      )
  });
  const affiliationQuery = useAffiliation(personKey);
  const refundInfoQuery = useRefundInfo(personKey);
  const availableBalance = useMemo(() => {
    const balance =
      accountsQuery.data?.reduce(
        (balance: number, account: AccountDetail) =>
          balance + (account.account?.balance ?? 0),
        0
      ) ?? 0;

    return balance;
  }, [accountsQuery.data]);

  const displayAccountBalanceIssueBanner =
    !affiliationQuery.data?.configuration.refundByCreditCard &&
    availableBalance !== 0;

  if (!affiliationQuery.data || !refundInfoQuery.data || !accountsQuery.data) {
    return null;
  }

  return (
    <>
      {displayAccountBalanceIssueBanner && (
        <Card
          containerStyle={[
            mediumCardStyle.container,
            {
              backgroundColor: colors.warning,
              padding: spacings.lg
            }
          ]}>
          <View style={{ flexDirection: "row" }}>
            <FontAwesomeIcon
              icon={faWarning}
              size={fontSizes.xxxl}
              color={colors.text.light}
            />

            <View
              style={{
                marginLeft: spacings.lg,
                flexDirection: "column",
                justifyContent: "center",
                flex: 1
              }}>
              <Text
                style={[
                  typographies.title,
                  { color: colors.text.light, marginBottom: spacings.sm }
                ]}>
                {t(
                  "RequestDeleteAccountScreenBalanceNotRefundableWarningTitle",
                  "Account balance issues"
                )}
              </Text>
              {!affiliationQuery.data.configuration.refundByCreditCard &&
                refundInfoQuery.data.refundableAmount !== 0 && (
                  <Text
                    style={[typographies.small, { color: colors.text.light }]}>
                    {t(
                      "RequestDeleteAccountScreenBalanceNotRefundableWarningAffiliationNotAllowed",
                      "Your account balance of {{amount}} will be definitely lost! Refund is not allowed in this affiliation",
                      {
                        amount: humanizeCurrency(
                          availableBalance,
                          accountsQuery.data[0].account!.currency
                        )
                      }
                    )}
                  </Text>
                )}

              {refundInfoQuery.data.refundableAmount === 0 &&
                refundInfoQuery.data.pendingAmount !== 0 && (
                  <Text
                    style={[typographies.small, { color: colors.text.light }]}>
                    {t(
                      "RequestDeleteAccountScreenBalanceNotRefundableWarningNonrefundableAmount",
                      "Your account balance of {{amount}} is not available to refund.\nTo get a refund of the unavailable amount, please get in touch with your affiliation.",
                      {
                        amount: humanizeCurrency(
                          refundInfoQuery.data.pendingAmount,
                          accountsQuery.data[0].account!.currency
                        )
                      }
                    )}
                  </Text>
                )}
            </View>
          </View>
        </Card>
      )}

      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingTop: spacings.xl
        }}>
        <FontAwesomeIcon
          icon={faPaperPlane}
          size={iconSizes.xxxl}
          style={{ color: colors.primary30 }}
        />
        <Text
          style={[
            typographies.heading,
            {
              fontSize: fontSizes.lg,
              marginTop: spacings.lg,
              marginBottom: spacings.xs,
              textAlign: "center",
              color: colors.primary
            }
          ]}>
          {t("RequestDeleteAccountScreenTitle", "Delete account")}
        </Text>

        <Text
          style={[
            typographies.subtitle,
            {
              fontSize: fontSizes.sm,
              textAlign: "center",
              marginBottom: spacings.xl
            }
          ]}>
          {t(
            "RequestDeleteAccountScreenHeader",
            "You are about to initiate the deletion of the account. As security measure, a confirmation email will be sent to you."
          )}
        </Text>

        <Button
          title={t(
            "RequestDeleteAccountScreenDeleteButton",
            "Send deletion email"
          )}
          disabled={onDeleteCallbackLoading}
          onPress={onDeleteCallback}
          loading={onDeleteCallbackLoading}
          buttonStyle={largePrimaryRoundedButtonStyle.button}
          titleStyle={largePrimaryRoundedButtonStyle.title}
          loadingStyle={largePrimaryRoundedButtonStyle.loading}
          disabledStyle={largePrimaryRoundedButtonStyle.disabled}
          containerStyle={largePrimaryRoundedButtonStyle.container}
        />
      </View>
    </>
  );
}
