import addWeeks from "date-fns/addWeeks";
import formatISO from "date-fns/formatISO";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import Constants from "expo-constants";
import { DeviceType, getDeviceTypeAsync, osName } from "expo-device";
import * as Linking from "expo-linking";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { UAParser } from "ua-parser-js";
import * as SecureStorage from "../services/SecureStorage";
import {
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle
} from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { cornerRadius, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { typographies } from "../styles/Fonts";

const ClientUAInstance = new UAParser();
const DOWNLOAD_APP_REMINDER = "download_app_reminder";

export default function SmartBannerComponent() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    async function getSmartBannerVisibility() {
      if (Platform.OS !== "web") {
        return;
      }

      var deviceType = await getDeviceTypeAsync();
      if (deviceType !== DeviceType.PHONE) {
        return;
      }

      const browser = ClientUAInstance.getBrowser();
      if (browser.name === "Mobile Safari") {
        return;
      }

      const nextDate = await SecureStorage.getItemAsync(DOWNLOAD_APP_REMINDER);
      if (!nextDate) {
        setIsVisible(true);
        return;
      }

      const nextDateParsed = parseISO(nextDate);
      if (isBefore(nextDateParsed, Date.now())) {
        setIsVisible(true);
      }
    }

    getSmartBannerVisibility();
  }, []);

  async function openApp() {
    const storeUrl =
      osName === "iOS"
        ? Constants.expoConfig?.extra?.appStoreUrl
        : Constants.expoConfig?.extra?.playStoreUrl;

    if (!storeUrl) {
      return;
    }

    try {
      await Linking.openURL(storeUrl);
      setIsVisible(false);
    } catch (e) {
      // ignore
    }
  }

  async function dismissBanner() {
    await SecureStorage.setItemAsync(
      DOWNLOAD_APP_REMINDER,
      formatISO(addWeeks(Date.now(), 1))
    );
    setIsVisible(false);
  }

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <View
        style={{
          backgroundColor: colors.background.dark,
          opacity: 0.3,
          position: "absolute",
          height: "100%",
          width: "100%"
        }}
      />
      <View
        style={[
          containerStyle.container,
          {
            backgroundColor: colors.background.light,
            position: "absolute",
            bottom: 0,
            borderTopLeftRadius: cornerRadius.xxl,
            borderTopRightRadius: cornerRadius.xxl,
            paddingVertical: spacings.xl
          }
        ]}>
        <Text
          style={[
            typographies.title,
            { textAlign: "center", marginBottom: spacings.sm }
          ]}>
          {t("SmartBannerComponentTitle", "Get the full experience on the app")}
        </Text>

        <Text
          style={[
            typographies.body,
            { textAlign: "center", marginBottom: spacings.sm }
          ]}>
          {t(
            "SmartBannerComponentDescription",
            "Manage easily your accounts and enjoy great features on the app"
          )}
        </Text>

        <Button
          titleStyle={largePrimaryRoundedButtonStyle.title}
          containerStyle={[
            largePrimaryRoundedButtonStyle.container,
            { marginVertical: spacings.md }
          ]}
          buttonStyle={largePrimaryRoundedButtonStyle.button}
          title={t("SmartBannerComponentOpenApp", "Get SECANDA")}
          onPress={openApp}></Button>
        <Button
          titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
          buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}
          title={t("SmartBannerComponentDismissBanner", "Not now")}
          onPress={dismissBanner}></Button>
      </View>
    </>
  );
}
