import { useNavigation } from "@react-navigation/core";
import { NavigationProp } from "@react-navigation/native";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, SafeAreaView, TextInput, View } from "react-native";
import { Button, Input } from "react-native-elements";
import validator from "validator";
import { ResultType } from "../components/ActionResultComponent";
import PasswordToggleEyeComponent from "../components/PasswordToggleEyeComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { RootStackParamsList } from "../navigation";
import { useChangePassword } from "../services/ProfileService";
import { largePrimaryRoundedButtonStyle } from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { cornerRadius, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { largeInputStyle } from "../styles/Inputs";

export default function ChangePasswordScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  const changePasswordMutation = useChangePassword();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [oldPasswordSecureTextEntry, setOldPasswordSecureTextEntry] =
    useState(true);
  const [newPasswordSecureTextEntry, setNewPasswordSecureTextEntry] =
    useState(true);
  const [
    confirmNewPasswordSecureTextEntry,
    setConfirmNewPasswordSecureTextEntry
  ] = useState(true);
  const [formFieldsValidation, setFormFieldsValidation] = useState({
    emptyOldPassword: false,
    matchingOldPassword: true,
    emptyNewPassword: false,
    newPasswordMatchLength: true,
    emptyConfirmPassword: false,
    confirmPasswordMatchLength: true,
    confirmPasswordEquality: true,
    passwordMatchAlphanumeric: true
  });

  const isFormValid =
    oldPassword &&
    newPassword &&
    confirmNewPassword &&
    !formFieldsValidation.emptyOldPassword &&
    !formFieldsValidation.emptyNewPassword &&
    !formFieldsValidation.emptyConfirmPassword &&
    formFieldsValidation.confirmPasswordMatchLength &&
    formFieldsValidation.confirmPasswordEquality &&
    formFieldsValidation.passwordMatchAlphanumeric &&
    formFieldsValidation.matchingOldPassword &&
    formFieldsValidation.newPasswordMatchLength;

  const minPasswordLength = 6;

  const oldPasswordRef = useRef<TextInput>(null);
  const newPasswordRef = useRef<TextInput>(null);
  const confirmNewPasswordRef = useRef<TextInput>(null);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={[
          {
            paddingVertical: spacings.xl
          },
          containerStyle.container
        ]}>
        <Input
          ref={oldPasswordRef}
          autoFocus={Platform.OS !== "web"}
          containerStyle={[
            largeInputStyle.container,
            {
              ...(formFieldsValidation.emptyOldPassword && {
                borderColor: colors.danger,
                borderWidth: cornerRadius.xxs
              })
            }
          ]}
          returnKeyType="next"
          onSubmitEditing={() => {
            newPasswordRef.current?.focus();
          }}
          inputContainerStyle={largeInputStyle.inputContainer}
          inputStyle={largeInputStyle.input}
          placeholder={t(
            "ChangePasswordScreenOldPasswordPlaceholder",
            "Old password"
          )}
          autoCapitalize="none"
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          rightIcon={
            <PasswordToggleEyeComponent
              isSecure={oldPasswordSecureTextEntry}
              onPress={() =>
                setOldPasswordSecureTextEntry(!oldPasswordSecureTextEntry)
              }
            />
          }
          secureTextEntry={oldPasswordSecureTextEntry}
          onChangeText={setOldPassword}
          onBlur={() => {
            setFormFieldsValidation({
              ...formFieldsValidation,
              matchingOldPassword: true,
              emptyOldPassword: validator.isEmpty(oldPassword)
            });
          }}
          value={oldPassword}
          errorMessage={
            formFieldsValidation.emptyOldPassword
              ? t(
                  "ChangePasswordScreenEmptyFieldMessage",
                  "Field can not be empty."
                )
              : !formFieldsValidation.matchingOldPassword
              ? t(
                  "ChangePasswordScreenPasswordNotMatch",
                  "Password does not match old password."
                )
              : undefined
          }></Input>

        <Input
          containerStyle={[
            largeInputStyle.container,
            {
              marginVertical: spacings.xl,
              borderColor:
                formFieldsValidation.emptyNewPassword ||
                !formFieldsValidation.newPasswordMatchLength ||
                !formFieldsValidation.passwordMatchAlphanumeric
                  ? colors.danger
                  : undefined,
              borderWidth:
                formFieldsValidation.emptyNewPassword ||
                !formFieldsValidation.newPasswordMatchLength ||
                !formFieldsValidation.passwordMatchAlphanumeric
                  ? cornerRadius.xxs
                  : undefined
            }
          ]}
          ref={newPasswordRef}
          returnKeyType="next"
          onSubmitEditing={() => {
            confirmNewPasswordRef.current?.focus();
          }}
          inputContainerStyle={largeInputStyle.inputContainer}
          inputStyle={largeInputStyle.input}
          rightIcon={
            <PasswordToggleEyeComponent
              isSecure={newPasswordSecureTextEntry}
              onPress={() =>
                setNewPasswordSecureTextEntry(!newPasswordSecureTextEntry)
              }
            />
          }
          secureTextEntry={newPasswordSecureTextEntry}
          placeholder={t(
            "ChangePasswordScreenNewPasswordPlaceholder",
            "New password"
          )}
          autoCapitalize="none"
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          onChangeText={setNewPassword}
          onBlur={() => validateNewPassword()}
          value={newPassword}
          errorMessage={
            formFieldsValidation.emptyNewPassword
              ? t(
                  "ChangePasswordScreenEmptyFieldMessage",
                  "Field can not be empty."
                )
              : !formFieldsValidation.newPasswordMatchLength
              ? t(
                  "ChangePasswordScreenPasswordInvalidLength",
                  "Minimum length 6 characters."
                )
              : !formFieldsValidation.passwordMatchAlphanumeric
              ? t(
                  "ChangePasswordScreenPasswordNotMatchAlphanumeric",
                  "Alphanumeric characters required."
                )
              : undefined
          }></Input>

        <Input
          containerStyle={[
            largeInputStyle.container,
            {
              borderColor:
                formFieldsValidation.emptyConfirmPassword ||
                !formFieldsValidation.confirmPasswordMatchLength ||
                !formFieldsValidation.confirmPasswordEquality
                  ? colors.danger
                  : undefined,
              borderWidth:
                formFieldsValidation.emptyConfirmPassword ||
                !formFieldsValidation.confirmPasswordMatchLength ||
                !formFieldsValidation.confirmPasswordEquality
                  ? cornerRadius.xxs
                  : undefined,
              marginBottom: spacings.xl
            }
          ]}
          ref={confirmNewPasswordRef}
          inputContainerStyle={largeInputStyle.inputContainer}
          inputStyle={largeInputStyle.input}
          rightIcon={
            <PasswordToggleEyeComponent
              isSecure={confirmNewPasswordSecureTextEntry}
              onPress={() =>
                setConfirmNewPasswordSecureTextEntry(
                  !confirmNewPasswordSecureTextEntry
                )
              }
            />
          }
          secureTextEntry={confirmNewPasswordSecureTextEntry}
          placeholder={t(
            "ChangePasswordScreenConfirmNewPasswordPlaceholder",
            "Confirm new password"
          )}
          autoCapitalize="none"
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          onSubmitEditing={changePasswordAsync}
          onChangeText={setConfirmNewPassword}
          onBlur={() => validateConfirmPassword()}
          value={confirmNewPassword}
          errorMessage={
            formFieldsValidation.emptyConfirmPassword
              ? t(
                  "ChangePasswordScreenEmptyFieldMessage",
                  "Field can not be empty."
                )
              : !formFieldsValidation.confirmPasswordMatchLength
              ? t(
                  "ChangePasswordScreenPasswordInvalidLength",
                  "Minimum length 6 characters."
                )
              : !formFieldsValidation.confirmPasswordEquality
              ? t(
                  "ChangePasswordScreenConfirmPasswordNotMatch",
                  "Passwords do not match."
                )
              : undefined
          }></Input>

        <Button
          title={t(
            "ChangePasswordScreenChangePasswordButtonTitle",
            "Change password"
          )}
          disabled={!isFormValid || changePasswordMutation.isLoading}
          onPress={changePasswordAsync}
          titleStyle={largePrimaryRoundedButtonStyle.title}
          containerStyle={largePrimaryRoundedButtonStyle.container}
          buttonStyle={largePrimaryRoundedButtonStyle.button}
          loading={changePasswordMutation.isLoading}
          loadingStyle={largePrimaryRoundedButtonStyle.loading}
          disabledStyle={largePrimaryRoundedButtonStyle.disabled}></Button>
      </View>
    </SafeAreaView>
  );

  function validateNewPassword() {
    setFormFieldsValidation({
      ...formFieldsValidation,
      emptyNewPassword: validator.isEmpty(newPassword),
      newPasswordMatchLength: validator.isLength(newPassword, {
        min: minPasswordLength
      }),
      passwordMatchAlphanumeric: validator.matches(
        newPassword,
        "^(?=.*\\d)(?=.*[a-zA-Z]).{0,}$"
      )
    });
  }

  function validateConfirmPassword() {
    setFormFieldsValidation({
      ...formFieldsValidation,
      emptyConfirmPassword: validator.isEmpty(confirmNewPassword),
      confirmPasswordMatchLength: validator.isLength(confirmNewPassword, {
        min: minPasswordLength
      }),
      confirmPasswordEquality: validator.equals(confirmNewPassword, newPassword)
    });
  }

  async function changePasswordAsync() {
    if (!isFormValid) {
      return;
    }

    try {
      await changePasswordMutation.mutateAsync({
        changePasswordModel: {
          oldPassword: oldPassword,
          newPassword: newPassword
        }
      });
      navigation.navigate("changePasswordResult", {
        type: ResultType.Success,
        description: t(
          "ChangePasswordScreenPasswordChangedSuccessful",
          "Password changed successfully"
        )
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("changePasswordResult", {
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      switch (e?.status) {
        case 404:
          errorDescription = t(
            "ChangePasswordScreenOldPasswordIncorrect",
            "The old password you entered is incorrect."
          );
          setFormFieldsValidation({
            ...formFieldsValidation,
            matchingOldPassword: false
          });
          break;
        default:
          errorDescription = t(
            "ChangePasswordScreenChangeUnknownError",
            "An error occurred when changing the password. Please, try again later."
          );
          break;
      }

      navigation.navigate("changePasswordResult", {
        type: ResultType.Error,
        description: errorDescription
      });
    }
  }
}
