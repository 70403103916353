import { round } from "lodash";

export function getFractionalDigitCount(value: number) {
  let result = 0;
  while (value % 1 != 0) {
    value *= 10;
    result++;
  }
  return result;
}

export function roundNumber(number: number, roundingFactor: number) {
  const roundingFactorFractionDigits = getFractionalDigitCount(roundingFactor);

  return round(
    round(number / roundingFactor) * roundingFactor,
    roundingFactorFractionDigits
  );
}
