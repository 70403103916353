import { default as i18n } from "i18next";
import { initReactI18next } from "react-i18next";
import { default as de } from "../assets/i18n/de.json";
import { default as en } from "../assets/i18n/en.json";
import { default as es } from "../assets/i18n/es.json";
import { default as fr } from "../assets/i18n/fr.json";
import { languageDetector } from "./LanguageDetector";

const translations = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  },
  es: {
    translation: es
  },
  de: {
    translation: de
  }
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
