import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBookUser } from "@fortawesome/pro-light-svg-icons/faBookUser";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { first, groupBy, map, orderBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, SectionList, Text, View } from "react-native";
import { Button, ListItem } from "react-native-elements";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { BeneficiariesListSkeleton } from "../components/LoadingSkeletonComponent";
import { useAction } from "../hooks/ActionHook";
import { BeneficiariesStackParamsList } from "../navigation/ProfileStackNavigation";
import { useAccounts } from "../services/AccountsService";
import { useAllBeneficiaries } from "../services/BeneficiariesService";
import { invalidateAllBeneficiaries } from "../services/QueryService";
import { listItemHeights } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { virtualizedListItemStyle } from "../styles/Lists";
import {
  colors,
  iconSizes,
  primaryLinkButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function BeneficiariesScreen() {
  const navigation =
    useNavigation<NavigationProp<BeneficiariesStackParamsList>>();
  const { t } = useTranslation();
  const beneficiariesQuery = useAllBeneficiaries();
  const refreshAction = useAction(invalidateAllBeneficiaries);
  const accountsQuery = useAccounts();

  const groupedBeneficiariesByAffiliation = useMemo(() => {
    const groupedBeneficiaries = groupBy(
      beneficiariesQuery.data,
      (b) => b.affiliationKey.affiliationId
    );
    const data = map(groupedBeneficiaries, (value, _) => ({
      title: first(value)?.affiliation.name,
      data: value
    }));

    return orderBy(data, (d) => d.title?.toLowerCase());
  }, [beneficiariesQuery.data]);

  useEffect(() => {
    if (!accountsQuery.data?.length) {
      return;
    }

    navigation.setOptions({
      headerRight: () => (
        <Button
          icon={
            <FontAwesomeIcon
              icon={faPlus}
              color={colors.text.link}
              size={iconSizes.md}></FontAwesomeIcon>
          }
          title={t("BeneficiariesListScreenAddNewButton", "Add")}
          onPress={() =>
            navigation.navigate("selectAffiliationStack", {
              screen: "selectAffiliationBeneficiary"
            })
          }
          type="clear"
          titleStyle={primaryLinkButtonStyle.title}></Button>
      )
    });
  }, [accountsQuery.data]);

  function RenderSectionHeader(title: string | undefined) {
    return (
      <View
        style={{
          marginBottom: spacings.sm
        }}>
        <Text style={[typographies.body, { color: colors.text.tertiary }]}>
          {title}
        </Text>
      </View>
    );
  }

  if (beneficiariesQuery.isInitialLoading || accountsQuery.isInitialLoading) {
    return <BeneficiariesListSkeleton />;
  }

  if (beneficiariesQuery.data?.length === 0) {
    return (
      <EmptyContentComponent
        message={t(
          "BeneficiariesScreenNoBeneficiaries",
          "You don't have any beneficiary."
        )}
        refreshControl={
          <RefreshControl
            refreshing={refreshAction.isBusy}
            onRefresh={refreshAction.execute}
          />
        }></EmptyContentComponent>
    );
  }

  return (
    <SectionList
      refreshing={refreshAction.isBusy}
      onRefresh={refreshAction.execute}
      stickySectionHeadersEnabled={false}
      sections={groupedBeneficiariesByAffiliation}
      renderSectionHeader={({ section: { title } }) =>
        RenderSectionHeader(title)
      }
      contentContainerStyle={containerStyle.container}
      renderItem={({ item, index, section }) => (
        <View
          key={index}
          style={[
            virtualizedListItemStyle.baseItem,
            index === 0 ? virtualizedListItemStyle.firstItem : null,
            index === section.data.length - 1
              ? [
                  virtualizedListItemStyle.lastItem,
                  { marginBottom: spacings.lg }
                ]
              : virtualizedListItemStyle.middleItem
          ]}>
          <BeneficiaryListItem
            icon={faBookUser}
            key={index}
            onPress={() =>
              navigation.navigate("beneficiary", {
                affiliationId: item.affiliationKey.affiliationId,
                identifierId: item.affiliationKey.identifierId,
                beneficiaryId: item.beneficiary.beneficiaryId
              })
            }
            topText={item.beneficiary.name}
            bottomText={item.beneficiary.value}
          />
        </View>
      )}
      keyExtractor={(_, index) => index.toString()}></SectionList>
  );
}

export function BeneficiaryListItem({
  icon,
  topText,
  bottomText,
  selected,
  onPress
}: {
  icon: IconProp;
  topText?: string;
  bottomText?: string;
  selected?: boolean;
  onPress: () => void;
}) {
  const color = selected ? colors.text.tertiary : colors.text.primary;

  return (
    <ListItem
      containerStyle={{
        height: listItemHeights.lg,
        paddingHorizontal: spacings.xl
      }}
      onPress={onPress}>
      <ListItem.Content>
        <View
          style={{
            alignSelf: "stretch",
            flexDirection: "row",
            alignItems: "center"
          }}>
          <FontAwesomeIcon
            icon={icon}
            color={color}
            style={{ marginRight: spacings.md }}
            size={iconSizes.xl}></FontAwesomeIcon>

          <View
            style={{
              flex: 1
            }}>
            <Text style={[typographies.body, { color: color }]}>{topText}</Text>
            {bottomText && (
              <Text style={[typographies.caption, { color: color }]}>
                {bottomText}
              </Text>
            )}
          </View>

          {selected && (
            <FontAwesomeIcon icon={faCheck} color={color} size={iconSizes.xl} />
          )}
        </View>
      </ListItem.Content>
    </ListItem>
  );
}
