import { compareVersions } from "compare-versions";
import Constants from "expo-constants";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import { useUpdateAppInfo } from "../services/AboutService";

export default function useUpdateAvailable() {
  const [updateAppInfo, setUpdateAppInfo] = useState({
    isAvailable: false,
    isRequired: false,
    installerUrl: ""
  });
  const updateAppInfoQuery = useUpdateAppInfo();

  useEffect(() => {
    async function checkUpdateApp() {
      if (!updateAppInfoQuery.data) {
        return;
      }

      let appInstallerUrl =
        Platform.OS === "android"
          ? updateAppInfoQuery.data.androidInstaller
          : Platform.OS === "ios"
          ? updateAppInfoQuery.data.iosInstaller
          : updateAppInfoQuery.data.webInstaller;

      const currentVersion = Constants.expoConfig?.version;
      setUpdateAppInfo({
        isAvailable:
          !!currentVersion &&
          compareVersions(
            currentVersion,
            updateAppInfoQuery.data.targetVersion
          ) === -1,
        isRequired:
          !!currentVersion &&
          compareVersions(
            currentVersion,
            updateAppInfoQuery.data.minimumVersion
          ) === -1,
        installerUrl: appInstallerUrl
      });
    }

    checkUpdateApp();
  }, [updateAppInfoQuery.data]);

  return updateAppInfo;
}
