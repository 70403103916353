import { AxiosInstance } from "axios";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { IdentityClient } from "./IdentityClient";
import { createAxios } from "./PlatformClientExtensions";

export const identityClientAxiosInstance: AxiosInstance = createAxios();

const { clientId, clientSecret } = getIdentityClient();
const identityClient = new IdentityClient(
  Constants.expoConfig?.extra?.identityAddress,
  clientId,
  clientSecret,
  identityClientAxiosInstance
);

class IdentityClientFactory {
  create(): IdentityClient {
    return identityClient;
  }
}

export const identityClientFactory: IdentityClientFactory =
  new IdentityClientFactory();

function getIdentityClient(): { clientId: string; clientSecret: string } {
  if (Platform.OS === "ios") {
    return {
      clientId: Constants.expoConfig?.extra?.identityIosClientId,
      clientSecret: Constants.expoConfig?.extra?.identityIosClientSecret
    };
  }

  if (Platform.OS === "android") {
    return {
      clientId: Constants.expoConfig?.extra?.identityAndroidClientId,
      clientSecret: Constants.expoConfig?.extra?.identityAndroidClientSecret
    };
  }

  return {
    clientId: Constants.expoConfig?.extra?.identityWebClientId,
    clientSecret: Constants.expoConfig?.extra?.identityWebClientSecret
  };
}
