import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import { RootStackParamsList } from "../navigation";
import { humanizeCurrency } from "../services/HumanizerService";

export default function SendAmountResultScreen() {
  const { t } = useTranslation();
  const route = useRoute<RouteProp<RootStackParamsList, "sendAmountResult">>();
  const navigation =
    useNavigation<
      StackNavigationProp<RootStackParamsList, "sendAmountResult">
    >();
  const homeNavigation = useResetHomeNavigation();
  const isSuccess = route.params.type === ResultType.Success;

  return (
    <ActionResultComponent
      title={
        isSuccess
          ? humanizeCurrency(route.params.amount, route.params.currency)
          : t("SendAmountResultScreenErrorTitle", "Oops...")
      }
      description={route.params.description}
      resultType={route.params.type}
      buttonTitle={t("SendAmountResultScreenButtonTitle", "Go home")}
      buttonCallback={() => homeNavigation.reset()}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
    />
  );
}
