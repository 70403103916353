import React from "react";
import { TokenResponse } from "../services/AuthenticationService";

export const AuthContext = React.createContext<AuthContextData>({
  isLoading: true,
  grantAccess: false,
  accessToken: null,
  refreshToken: null,
  userIdentifier: null,
  signIn: async (): Promise<void> => {},
  localSignIn: async (): Promise<void> => {},
  signOut: async (): Promise<void> => {},
  refreshAccessToken: async (): Promise<TokenResponse | null> => null
} as AuthContextData);

export interface AuthState {
  isLoading: boolean;
  grantAccess: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  userIdentifier: string | null;
}

export interface AuthContextActions {
  signIn: (
    userIdentifier: string,
    password: string,
    rememberMe: boolean
  ) => Promise<void>;
  localSignIn: () => Promise<void>;
  signOut: () => Promise<void>;
  refreshAccessToken: () => Promise<TokenResponse | null>;
}

export interface AuthContextData extends AuthState, AuthContextActions {}
