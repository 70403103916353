import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSelectionComponent, {
  languages
} from "../components/LanguageSelectionComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { fetchProfile, useUpdateProfile } from "../services/ProfileService";
import { toastError } from "../services/ToastService";
import { IProfileInfo } from "../services/clients/PlatformClient";

export default function LanguageSelectionScreen() {
  const { i18n, t } = useTranslation();
  const updateProfileMutation = useUpdateProfile();

  const language =
    languages.find((l) => l.value === i18n.language)?.value ??
    languages[0].value;

  async function onLanguageValueChange(value?: string) {
    if (!value || value === language) {
      return;
    }

    await i18n.changeLanguage(value);
    await updateUserLanguage(value);
  }

  async function updateUserLanguage(languageCode: string | undefined) {
    try {
      const profileInfo: IProfileInfo = await fetchProfile();

      await updateProfileMutation.mutateAsync({
        ...profileInfo,
        languageCode: languageCode
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "LanguageSelectionScreenProfileLanguageUpdateErrorMessage",
          "An error occurred when updating the user profile language"
        )
      );
    }
  }

  return (
    <LanguageSelectionComponent
      defaultLanguage={language}
      onLanguageSelectedCallback={onLanguageValueChange}
    />
  );
}
