import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { BarCodeScanner, BarCodeScannerResult } from "expo-barcode-scanner";
import { CameraType } from "expo-camera";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { SafeAreaView } from "react-native-safe-area-context";
import { ResultType } from "../components/ActionResultComponent";
import { CameraViewComponent } from "../components/CameraViewComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useHasCameraFeature from "../hooks/HasCameraFeatureHook";
import {
  ActivationSelectAffiliationStackParamsList,
  RootStackParamsList
} from "../navigation";
import { useActivatePerson } from "../services/PersonsService";
import { IActivatePersonRequest } from "../services/clients/PlatformClient";
import {
  colors,
  containerStyle,
  cornerRadius,
  iconSizes,
  largeInputStyle,
  largePrimaryRoundedButtonStyle,
  pressableStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function ActivationCodeScreen() {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState("");
  const activatePersonMutation = useActivatePerson();
  const route =
    useRoute<
      RouteProp<ActivationSelectAffiliationStackParamsList, "activationCode">
    >();
  const navigation = useNavigation<StackNavigationProp<RootStackParamsList>>();

  const [validation, setValidation] = useState({
    invalidActivationCode: false
  });
  const isFormValid = verificationCode && !validation.invalidActivationCode;

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const hasCameraFeature = useHasCameraFeature();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!route.params.skipVisible) {
      navigation.setOptions({ headerRight: undefined });
    }
  }, []);

  useEffect(() => {
    if (validation.invalidActivationCode) {
      setValidation({
        ...validation,
        invalidActivationCode: false
      });
    }
  }, [verificationCode]);

  async function handleBarCodeScanned(data: string) {
    closeCamera();
    setVerificationCode(data);
    await activatePersonAsync(data);
  }

  function closeCamera() {
    setIsCameraOpen(false);
    setHasPermission(null);
    navigation.setOptions({ headerShown: true });
  }

  async function openCamera() {
    setIsCameraOpen(true);
    const permissionResponse = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(permissionResponse.granted);
    navigation.setOptions({ headerShown: false });
  }

  if (isCameraOpen) {
    if (hasPermission === false) {
      return (
        <View style={containerStyle.containerCenter}>
          <Text style={[typographies.title, { marginBottom: spacings.md }]}>
            {t("CameraPermissionTitle", "Camera permission")}
          </Text>
          <Text style={[typographies.body, { textAlign: "center" }]}>
            {t(
              "NoCameraAccessMessage",
              "Unable to scan barcode without access to camera. Please enable access in the permissions settings of your device."
            )}
          </Text>
        </View>
      );
    }

    if (hasPermission === true) {
      return (
        <View style={containerStyle.containerStretch}>
          {isFocused && (
            <CameraViewComponent
              type={CameraType.back}
              onBarCodeScanned={async (props: BarCodeScannerResult) =>
                activatePersonMutation.isLoading
                  ? undefined
                  : await handleBarCodeScanned(props.data)
              }
              barCodeScannerSettings={{
                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr]
              }}
              style={StyleSheet.absoluteFillObject}>
              <SafeAreaView>
                <Pressable
                  style={({ pressed }) => [
                    pressed && pressableStyle.pressed,
                    {
                      marginRight: spacings.md,
                      marginTop: spacings.md,
                      backgroundColor: colors.background.neutral,
                      borderRadius: cornerRadius.sm,
                      alignSelf: "flex-end",
                      padding: spacings.sm
                    }
                  ]}
                  onPress={closeCamera}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size={iconSizes.xl}
                    style={{
                      color: colors.dark
                    }}></FontAwesomeIcon>
                </Pressable>
              </SafeAreaView>
            </CameraViewComponent>
          )}
        </View>
      );
    }
  }

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.xl,
            marginBottom: spacings.md,
            textAlign: "center"
          }
        ]}>
        {t(
          "ActivationCodeScreenHeader",
          "You can validate your account with your verification code manually"
        )}
      </Text>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        inputStyle={largeInputStyle.input}
        containerStyle={[
          largeInputStyle.container,
          {
            marginBottom: spacings.lg,
            borderColor: validation.invalidActivationCode
              ? colors.danger
              : undefined,
            borderWidth: validation.invalidActivationCode
              ? cornerRadius.xxs
              : undefined
          }
        ]}
        onSubmitEditing={async () =>
          await activatePersonAsync(verificationCode)
        }
        onChangeText={setVerificationCode}
        value={verificationCode}
        inputMode="text"
        placeholder={t(
          "ActivationCodeScreenVerificationCodePlaceholder",
          "Verification code"
        )}
        errorMessage={
          validation.invalidActivationCode
            ? t(
                "ActivationCodeScreenInvalidCode",
                "The activation code is not valid"
              )
            : undefined
        }
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}></Input>

      {hasCameraFeature && (
        <>
          <Text
            style={[
              typographies.body,
              {
                marginTop: spacings.lg,
                marginBottom: spacings.md,
                alignSelf: "center"
              }
            ]}>
            {t("ActivationCodeScreenHeaderScanQr", "Or scanning the QR code")}
          </Text>

          <Button
            title={t("ActivationCodeScreenScanButton", "Scan QR")}
            buttonStyle={largePrimaryRoundedButtonStyle.button}
            containerStyle={largePrimaryRoundedButtonStyle.container}
            disabled={isCameraOpen}
            loading={isCameraOpen}
            loadingStyle={largePrimaryRoundedButtonStyle.loading}
            disabledStyle={largePrimaryRoundedButtonStyle.disabled}
            onPress={openCamera}></Button>
        </>
      )}

      <Text
        style={[
          typographies.small,
          {
            marginTop: spacings.md,
            textAlign: "center",
            marginBottom: spacings.xxl
          }
        ]}>
        {t(
          "ActivationCodeScreenCodeAvailableOnTerminal",
          "This code can be requested by presenting your card on a Terminal"
        )}
      </Text>

      <Button
        title={t("ActivationCodeScreenValidateButton", "Validate")}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        disabled={!isFormValid || activatePersonMutation.isLoading}
        onPress={async () => await activatePersonAsync(verificationCode)}
        loading={activatePersonMutation.isLoading}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        containerStyle={largePrimaryRoundedButtonStyle.container}></Button>
    </ScrollViewComponent>
  );

  async function activatePersonAsync(verificationCode: string) {
    if (!isFormValid) {
      return;
    }

    const activatePersonRequest: IActivatePersonRequest = {
      activationCode: verificationCode
    };

    try {
      await activatePersonMutation.mutateAsync({
        personKey: {
          identifierId: route.params.identifierId,
          affiliationId: route.params.affiliationId,
          personId: route.params.personId
        },
        activatePersonRequest: activatePersonRequest
      });

      navigation.navigate("activationCodeResult", {
        type: ResultType.Success,
        description: t(
          "ActivationCodeScreenActivationSuccessMessage",
          "The account has been validated successfully."
        ),
        identifierId: route.params.identifierId,
        affiliationId: route.params.affiliationId,
        personId: route.params.personId,
        accountId: route.params.accountId
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("activationCodeResult", {
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      switch (e?.status) {
        case 400:
          errorDescription = t(
            "ActivationCodeScreenDataError",
            "An error occurred during the validation. Please check the data."
          );
          break;
        case 404:
          setValidation({
            ...validation,
            invalidActivationCode: true
          });
          return;
        case 422:
          errorDescription = t(
            "ActivationCodeScreenActivationNotAllowed",
            "The validation is not available in the selected affiliation."
          );
          break;
        default:
          errorDescription = t(
            "ActivationCodeScreenUnknownError",
            "Internal server error"
          );
          break;
      }

      navigation.navigate("activationCodeResult", {
        type: ResultType.Error,
        description: errorDescription
      });
    }
  }
}
