import { faCloudSlash } from "@fortawesome/pro-light-svg-icons/faCloudSlash";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutChangeEvent, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useConnectionState from "../hooks/ConnectionStateHook";
import { colors, spacings, typographies } from "../styles/Styles";

export default function OfflineBannerComponent() {
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();
  const connectionState = useConnectionState();
  const [innerHeight, setInnerHeight] = useState(0);

  const onContentLayout = useCallback((event: LayoutChangeEvent) => {
    setInnerHeight(event.nativeEvent.layout.height);
  }, []);

  if (connectionState.isOnline) return null;

  return (
    <>
      <View
        style={{
          paddingTop: insets.top,
          backgroundColor: colors.background.disabled,
          position: "absolute",
          zIndex: 1000,
          width: "100%"
        }}>
        <View
          style={{
            paddingVertical: spacings.xs,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
          onLayout={onContentLayout}>
          <FontAwesomeIcon
            icon={faCloudSlash}
            color={colors.text.primary}
            style={{ alignSelf: "center", marginRight: spacings.sm }}
          />

          <Text style={[typographies.small]}>
            {t("OfflineBannerMessage", "No internet connection")}
          </Text>
        </View>
      </View>

      <View
        style={{
          height: innerHeight
        }}></View>
    </>
  );
}
