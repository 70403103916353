import { faPaperPlane } from "@fortawesome/pro-light-svg-icons/faPaperPlane";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import { RootStackParamsList } from "../navigation";
import { ProfileStackParamsList } from "../navigation/ProfileStackNavigation";
import { colors, fontSizes } from "../styles/Styles";

export default function AddIdentifierResultScreen() {
  const route =
    useRoute<RouteProp<RootStackParamsList, "addIdentifierResult">>();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<ProfileStackParamsList, "profile">,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const { t } = useTranslation();
  const isSuccess = route.params.type === ResultType.Success;

  return (
    <ActionResultComponent
      icon={isSuccess ? faPaperPlane : undefined}
      iconStyle={isSuccess && { color: colors.text.primary }}
      title={
        isSuccess
          ? t(
              "AddIdentifierResultScreenSuccessTitle",
              "Confirmation email sent"
            )
          : t("AddIdentifierResultScreenErrorTitle", "Oops...")
      }
      titleStyle={{ fontSize: fontSizes.lg }}
      description={route.params.description}
      descriptionStyle={{ fontSize: fontSizes.sm }}
      resultType={route.params.type}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
      buttonTitle={t("AddIdentifierResultScreenButtonTitle", "Go back")}
      buttonCallback={() =>
        navigation.navigate("root", {
          screen: "profileStack",
          params: {
            screen: "profile"
          }
        })
      }
    />
  );
}
