import { Image } from "react-native";

export function getImageSize(
  uri: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => {
        resolve({ width, height });
      },
      (failure) => reject(failure)
    );
  });
}

export function computeImageSize(
  width: number,
  height: number,
  maxWidth: number | undefined,
  maxHeight: number | undefined
): { width: number; height: number } {
  const ratio = width / height;
  if (maxHeight && maxHeight < height) {
    height = maxHeight;
    width = height * ratio;
  }
  if (maxWidth && maxWidth < width) {
    width = maxWidth;
    height = width / ratio;
  }

  return { width, height };
}
