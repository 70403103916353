import React, { RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, View } from "react-native";
import { Input } from "react-native-elements";
import validator from "validator";
import { colors } from "../styles/Colors";
import { cornerRadius, spacings } from "../styles/Constants";
import { largeInputStyle } from "../styles/Inputs";
import { isMobilePhone } from "../utils/PhoneValidator";
import DatePickerComponent from "./DatePickerComponent";

export default function OptionalPersonalDataComponent({
  defaultPhoneNumber,
  defaultBirthdate,
  phoneRef,
  onPhoneNumberChanged,
  onBirthdateChanged,
  onSubmitCallback
}: {
  defaultPhoneNumber?: string;
  defaultBirthdate?: Date;
  phoneRef?: RefObject<TextInput>;
  onPhoneNumberChanged?: (phoneNumber?: string) => void;
  onBirthdateChanged?: (birthdate?: Date) => void;
  onSubmitCallback?: () => void;
}) {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber ?? "");
  const [birthdate, setBirthDate] = useState(defaultBirthdate);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  return (
    <View style={{ marginTop: spacings.xs }}>
      {onBirthdateChanged && (
        <DatePickerComponent
          date={birthdate}
          onValueChange={onBirthdateChangeValue}
        />
      )}

      {onPhoneNumberChanged && (
        <Input
          inputMode="tel"
          containerStyle={[
            largeInputStyle.container,
            {
              marginBottom: spacings.sm,
              borderWidth: !isValidPhoneNumber ? cornerRadius.xxs : undefined,
              borderColor: !isValidPhoneNumber ? colors.danger : undefined
            }
          ]}
          ref={phoneRef}
          inputContainerStyle={largeInputStyle.inputContainer}
          inputStyle={largeInputStyle.input}
          placeholder={t(
            "OptionalPersonalDataMobileNumberPlaceholder",
            "Mobile phone number"
          )}
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          value={phoneNumber}
          onSubmitEditing={onSubmitCallback}
          onChangeText={onPhoneNumberChangeText}
          errorMessage={
            !isValidPhoneNumber
              ? t(
                  "OptionalPersonalDataComponentPhoneNumberNotValid",
                  "Phone number is not valid"
                )
              : undefined
          }></Input>
      )}
    </View>
  );

  function onPhoneNumberChangeText(value: string) {
    setPhoneNumber(value);

    const isValidPhoneNumber = !validator.isEmpty(value)
      ? isMobilePhone(value)
      : true;

    onPhoneNumberChanged?.(validator.isEmpty(value) ? undefined : value);
    setIsValidPhoneNumber(isValidPhoneNumber);
  }

  function onBirthdateChangeValue(value: Date | undefined) {
    setBirthDate(value);
    onBirthdateChanged?.(value);
  }
}
