import {
  CompositeNavigationProp,
  RouteProp,
  useLinkBuilder,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import Decimal from "decimal.js";
import Constants from "expo-constants";
import { isEqual } from "lodash";
import { default as React, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, TextInput, View } from "react-native";
import { Button, Text } from "react-native-elements";
import AccountListItemComponent from "../components/AccountListItemComponent";
import { ResultType } from "../components/ActionResultComponent";
import AmountSelectionComponent from "../components/AmountSelectionComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import HyperlinkedTextComponent from "../components/HyperlinkedTextComponent";
import { LoadAmountSelectionSkeleton } from "../components/LoadingSkeletonComponent";
import { PaymentModesListItemComponent } from "../components/PaymentModesListItemComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import {
  CreditCardPaymentScreenProps,
  LoadAmountResultScreenProps,
  RootStackParamsList
} from "../navigation";
import {
  LoadAmountSelectionStackParamsList,
  PaymentSlipPaymentScreenProps
} from "../navigation/LoadStackNavigation";
import i18n from "../providers/i18n";
import { IAccountKey, useAccount } from "../services/AccountsService";
import {
  PaymentMode,
  PaymentModeDetails,
  useAffiliationById,
  usePaymentModes
} from "../services/AffiliationsService";
import { useLoadAccountByCreditCard } from "../services/CreditCardsService";
import { useFee, useFees } from "../services/FeesService";
import { humanizeCurrency } from "../services/HumanizerService";
import { useCreatePaymentSlip } from "../services/PaymentSlipsService";
import {
  invalidateAccount,
  invalidateCreditCards,
  invalidatePaymentModes,
  invalidateTransactions
} from "../services/QueryService";
import { getTransactionStatus } from "../services/TransactionsService";
import {
  AffiliationStatus,
  CreditCardLoadingInfo,
  CreditCardType,
  IPaymentSlipInfo,
  PaymentSlip,
  PspRedirectMethod,
  TransactionStatus
} from "../services/clients/PlatformClient";
import { largePrimaryRoundedButtonStyle } from "../styles/Buttons";
import { spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";
import { virtualizedListItemStyle } from "../styles/Lists";

type creditCardPaymentData = {
  transactionId: string;
  pspStartUrl: string;
  status: TransactionStatus;
  successUrlCallback: string;
  errorUrlCallback: string;
  cancelUrlCallback: string;
};

export default function LoadAmountSelectionScreen() {
  const route =
    useRoute<
      RouteProp<LoadAmountSelectionStackParamsList, "amountSelection">
    >();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<
          LoadAmountSelectionStackParamsList,
          "amountSelection"
        >,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const { t } = useTranslation();

  const [amount, setAmount] = useState<number>();
  const [hasFees, setHasFees] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] =
    useState<PaymentModeDetails>();
  const amountSelectionRef = useRef<TextInput>(null);

  const accountKey: IAccountKey = {
    identifierId: route?.params?.identifierId,
    affiliationId: route?.params?.affiliationId,
    personId: route?.params?.personId,
    accountId: route?.params?.accountId
  };
  const affiliationQuery = useAffiliationById(accountKey.affiliationId);
  const accountQuery = useAccount(accountKey);
  const paymentModesQuery = usePaymentModes(accountKey);
  const loadAccountByCreditCardMutation = useLoadAccountByCreditCard();
  const paymentSlipMutation = useCreatePaymentSlip();

  const feesQuery = useFees(accountKey);
  const singleFeeQuery = useFee(
    accountKey,
    selectedPaymentMode?.paymentMode!,
    amount,
    {
      enabled: selectedPaymentMode !== undefined && hasFees
    }
  );
  const amountHint = useMemo(
    () => selectAmountHint(),
    [
      hasFees,
      amount,
      selectedPaymentMode,
      singleFeeQuery.isInitialLoading,
      singleFeeQuery.data,
      accountQuery.data
    ]
  );

  const amountIncludingFees = useMemo(
    () =>
      new Decimal(amount ?? 0)
        .plus(singleFeeQuery.data?.fees?.totalFee ?? 0)
        .toNumber(),
    [amount, singleFeeQuery.data]
  );

  const linkBuilder = useLinkBuilder();
  const isWeb = Platform.OS === "web";

  useEffect(() => {
    if (paymentModesQuery.data?.length === 1)
      setSelectedPaymentMode(paymentModesQuery.data[0]);
  }, [paymentModesQuery.data]);

  useEffect(() => {
    setHasFees(
      feesQuery.data?.some(
        (f) =>
          f.fees?.minimumFee ||
          f.fees?.percentFee ||
          f.fees?.amountFee ||
          f.fees?.totalFee
      ) ?? false
    );
  }, [feesQuery.data]);

  function amountInputOnLayout() {
    if (selectedPaymentMode) amountSelectionRef.current?.focus();
  }

  if (
    paymentModesQuery.isInitialLoading ||
    accountQuery.isInitialLoading ||
    feesQuery.isInitialLoading
  ) {
    return <LoadAmountSelectionSkeleton />;
  }

  if (paymentModesQuery.data?.length == 0) {
    return (
      <EmptyContentComponent
        message={t(
          "LoadAmountSelectionScreenNoPaymentModes",
          "This affiliation does not accept the loading of the wallet through SECANDA."
        )}></EmptyContentComponent>
    );
  }

  async function loadAccountAsync() {
    if (!amountIncludingFees) {
      return;
    }

    setIsBusy(true);

    try {
      switch (selectedPaymentMode?.paymentMode) {
        case PaymentMode.CreditCard:
        case PaymentMode.Twint:
          const creditCardType =
            selectedPaymentMode?.paymentMode === PaymentMode.Twint
              ? CreditCardType.Twint
              : selectedPaymentMode?.registeredCreditCard?.type;
          const creditCardPayment = await createCreditCardPayment(
            creditCardType
          );
          const creditCardParams: CreditCardPaymentScreenProps = {
            identifierId: accountKey.identifierId,
            affiliationId: accountKey.affiliationId,
            personId: accountKey.personId,
            accountId: accountKey.accountId,
            paymentMode: selectedPaymentMode?.paymentMode,
            amount: amountIncludingFees,
            currency: accountQuery.data?.account?.currency!,
            ...creditCardPayment
          };

          if (creditCardPayment.status === TransactionStatus.Successful) {
            invalidateAccount(accountKey, {
              refetchType: "all"
            });
            invalidateTransactions(accountKey, {
              refetchType: "all"
            });
            invalidatePaymentModes(accountKey, {
              refetchType: "all"
            });
            invalidateCreditCards({ refetchType: "all" });
            navigation.replace("loadAmountResult", {
              type: ResultType.Success,
              amount: amountIncludingFees,
              currency: accountQuery.data?.account?.currency!,
              paymentMode: PaymentMode.CreditCard
            });
            return;
          } else if (creditCardPayment.status === TransactionStatus.Canceled) {
            navigation.replace("loadAmountResult", {
              type: ResultType.Info,
              identifierId: accountKey.identifierId,
              affiliationId: accountKey.affiliationId,
              personId: accountKey.personId,
              accountId: accountKey.accountId,
              paymentMode: PaymentMode.CreditCard
            });
            return;
          } else if (creditCardPayment.status === TransactionStatus.Failed) {
            navigation.replace("loadAmountResult", {
              type: ResultType.Error,
              identifierId: accountKey.identifierId,
              affiliationId: accountKey.affiliationId,
              personId: accountKey.personId,
              accountId: accountKey.accountId,
              paymentMode: PaymentMode.CreditCard
            });
            return;
          }

          if (isWeb) {
            window.location.replace(creditCardParams.pspStartUrl);
          } else {
            navigation.navigate("creditCardPayment", creditCardParams);
          }
          break;
        case PaymentMode.PaymentSlip:
          const paymentSlip = await createPaymentSlip();
          const paymentSlipParams: PaymentSlipPaymentScreenProps = {
            identifierId: accountKey.identifierId,
            affiliationId: accountKey.affiliationId,
            personId: accountKey.personId,
            accountId: accountKey.accountId,
            paymentSlipId: paymentSlip.paymentSlipId
          };
          navigation.navigate("paymentSlipPayment", paymentSlipParams);
          break;
      }
    } catch (error) {
      navigation.navigate("loadAmountResult", {
        type: ResultType.Error,
        identifierId: accountKey.identifierId,
        affiliationId: accountKey.affiliationId,
        personId: accountKey.personId,
        accountId: accountKey.accountId,
        paymentMode: selectedPaymentMode?.paymentMode!
      });
    } finally {
      setIsBusy(false);
    }
  }

  async function createCreditCardPayment(
    creditCardType: CreditCardType | undefined
  ): Promise<creditCardPaymentData> {
    const timeStamp = new Date().getTime();
    let appAddress = Constants.expoConfig?.extra?.appAddress;
    let successUrlCallback = `${appAddress}#${timeStamp}-success`;
    let errorUrlCallback = `${appAddress}#${timeStamp}-cancel`;
    let cancelUrlCallback = `${appAddress}#${timeStamp}-error`;

    if (isWeb) {
      appAddress = window.location.href ?? appAddress;
      const loadAmountResultProps: LoadAmountResultScreenProps = {
        type: ResultType.Success,
        amount: amountIncludingFees,
        currency: accountQuery.data?.account?.currency!,
        identifierId: accountKey.identifierId,
        affiliationId: accountKey.affiliationId,
        personId: accountKey.personId,
        accountId: accountKey.accountId,
        paymentMode: PaymentMode.CreditCard
      };
      successUrlCallback = new URL(
        linkBuilder("loadAmountResult", {
          ...loadAmountResultProps,
          type: ResultType.Success
        }) ?? "",
        appAddress
      ).href;
      errorUrlCallback = new URL(
        linkBuilder("loadAmountResult", {
          ...loadAmountResultProps,
          type: ResultType.Error
        }) ?? "",
        appAddress
      ).href;
      cancelUrlCallback = new URL(
        linkBuilder("loadAmountResult", {
          ...loadAmountResultProps,
          type: ResultType.Info
        }) ?? "",
        appAddress
      ).href;
    }

    var creditCardLoadingInfo = new CreditCardLoadingInfo({
      amount: amountIncludingFees,
      languageCode: i18n.language,
      pspSuccessUrl: successUrlCallback,
      pspErrorUrl: errorUrlCallback,
      pspCancelUrl: cancelUrlCallback,
      creditCardType: creditCardType,
      pspRedirectMethod: PspRedirectMethod.Get
    });

    const data = await loadAccountByCreditCardMutation.mutateAsync({
      accountKey: accountKey,
      creditCardId: selectedPaymentMode?.registeredCreditCard?.creditCardId,
      creditCardModel: creditCardLoadingInfo
    });
    const status = await getTransactionStatus(data.transactionId);

    return {
      transactionId: data.transactionId,
      pspStartUrl: data.pspStartUrl,
      status: status.status,
      successUrlCallback,
      errorUrlCallback,
      cancelUrlCallback
    };
  }

  async function createPaymentSlip(): Promise<PaymentSlip> {
    const paymentSlipInfo: IPaymentSlipInfo = {
      amount: amountIncludingFees,
      languageCode: i18n.language
    };

    const data = await paymentSlipMutation.mutateAsync({
      accountId: accountKey,
      paymentSlipInfo: paymentSlipInfo
    });

    return data;
  }

  return (
    <ScrollViewComponent>
      {accountQuery.data && (
        <AccountListItemComponent
          account={accountQuery.data}
          disabled={
            accountQuery.data.affiliation.status === AffiliationStatus.Offline
          }></AccountListItemComponent>
      )}

      <Text
        style={[
          typographies.title,
          { marginTop: spacings.md, marginBottom: spacings.sm }
        ]}>
        {t("LoadAccountScreenPaymentMethodTitle", "Payment method")}
      </Text>

      {paymentModesQuery.data?.map((item, index) => (
        <View
          key={index}
          style={[
            virtualizedListItemStyle.baseItem,
            index === 0 ? virtualizedListItemStyle.firstItem : null,
            index === paymentModesQuery.data.length - 1
              ? virtualizedListItemStyle.lastItem
              : virtualizedListItemStyle.middleItem
          ]}>
          <PaymentModesListItemComponent
            item={item}
            selected={isEqual(selectedPaymentMode, item)}
            key={index}
            onPress={() => selectPaymentMode(item)}
          />
        </View>
      ))}

      <Text
        style={[
          typographies.title,
          {
            marginTop: spacings.xl,
            marginBottom: spacings.sm
          }
        ]}>
        {t("LoadAccountScreenAmountTitle", "Amount")}
      </Text>

      <AmountSelectionComponent
        currency={accountQuery.data?.account?.currency}
        maximumAmount={accountQuery.data?.account?.maximumLoadAmount}
        minimumAmount={selectedPaymentMode?.minimumAmount}
        onAmountChange={setAmount}
        amountHint={amountHint}
        roundingFactor={accountQuery.data?.account?.roundingFactor}
        onSubmitCallback={loadAccountAsync}
        amountInputRef={amountSelectionRef}
        amountInputOnLayout={amountInputOnLayout}></AmountSelectionComponent>

      <HyperlinkedTextComponent
        containerStyle={{
          marginTop: spacings.xxl,
          marginRight: spacings.none,
          marginLeft: spacings.sm
        }}
        firstText={t(
          "AmountSelectionComponentAgreementText",
          "By pressing Load, you accept the"
        )}
        firstHyperlinkText={t(
          "AmountSelectionComponentTermsConditions",
          "Terms & Conditions"
        )}
        onFirstHyperlinkCallback={() =>
          navigation.navigate("termsAndConditions", {
            identifierId: accountKey.identifierId,
            affiliationId: accountKey.affiliationId
          })
        }
        secondText={t(
          "AmountSelectionComponentOfLabel",
          "of {{affiliationName}}",
          {
            affiliationName: affiliationQuery.data?.name
          }
        )}
      />

      <Button
        title={t("LoadAmountSelectionScreenLoadButton", "Load {{amount}}", {
          amount: humanizeCurrency(
            amountIncludingFees,
            accountQuery.data?.account?.currency!
          )
        })}
        loading={
          paymentSlipMutation.isLoading ||
          loadAccountByCreditCardMutation.isLoading ||
          isBusy
        }
        onPress={loadAccountAsync}
        disabled={
          !amount ||
          !selectedPaymentMode ||
          paymentSlipMutation.isLoading ||
          loadAccountByCreditCardMutation.isLoading ||
          isBusy
        }
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.md
          }
        ]}></Button>
    </ScrollViewComponent>
  );

  function selectPaymentMode(item: PaymentModeDetails) {
    setSelectedPaymentMode(item);
    amountSelectionRef.current?.focus();
  }

  function selectAmountHint() {
    if (hasFees && singleFeeQuery.isInitialLoading && selectedPaymentMode) {
      return t("LoadAmountSelectionScreenLoadingFee", "loading fee");
    }
    if (!amount && singleFeeQuery.data?.fees?.percentFee) {
      if (singleFeeQuery.data?.fees?.amountFee) {
        return t(
          "LoadAmountSelectionFeeAmount",
          "{{amountFee}} + {{percentFee}}% fee",
          {
            amountFee: humanizeCurrency(
              singleFeeQuery.data?.fees?.amountFee,
              accountQuery.data?.account?.currency!
            ),
            percentFee: singleFeeQuery.data?.fees?.percentFee
          }
        );
      }

      return t("LoadAmountSelectionScreenPercentFee", "{{percentFee}}% fee", {
        percentFee: singleFeeQuery.data?.fees?.percentFee
      });
    }
    if (singleFeeQuery.data?.fees?.totalFee) {
      return t("LoadAmountSelectionScreenFee", "{{amount}} fee", {
        amount: humanizeCurrency(
          singleFeeQuery.data?.fees?.totalFee,
          accountQuery.data?.account?.currency!
        )
      });
    }

    return t("LoadAmountSelectionScreenNoFee", "no fee");
  }
}
