import { useQuery } from "@tanstack/react-query";
import { getDataUrl } from "./BlobService";
import {
  externalSiteImageQueryKey,
  externalSitesQueryKey
} from "./QueryService";
import { ApiException, ExternalSite } from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

export const externalSiteImageStaleTime: number = 60 * 60 * 1000;

export interface IExternalSiteKey {
  affiliationId: number;
  externalSiteId: string;
}

async function getExternalSites(
  affiliationId: number
): Promise<ExternalSite[]> {
  const platformClient = platformClientFactory.create();
  const externalSites = await platformClient.getExternalSites(affiliationId);
  return externalSites;
}

async function getExternalSiteImage(
  externalSiteKey: IExternalSiteKey
): Promise<string | null> {
  try {
    const platformClient = platformClientFactory.create();
    const fileResponse = await platformClient.getExternalSiteImage(
      externalSiteKey.affiliationId,
      externalSiteKey.externalSiteId
    );
    return getDataUrl(fileResponse.data);
  } catch (e) {
    if (e instanceof ApiException && e.status <= 400) {
      // No image defined
      return null;
    }

    throw e;
  }
}

export function useExternalSites(affiliationId: number) {
  return useQuery([externalSitesQueryKey, affiliationId], () =>
    getExternalSites(affiliationId)
  );
}

export function useExternalSiteImage(externalSiteKey: IExternalSiteKey) {
  return useQuery(
    [externalSiteImageQueryKey, externalSiteKey],
    () => getExternalSiteImage(externalSiteKey),
    { staleTime: externalSiteImageStaleTime }
  );
}
