import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { Text } from "react-native-elements";
import { colors, typographies } from "../styles/Styles";

export default function HyperlinkedTextComponent({
  firstText,
  firstHyperlinkText,
  secondText,
  secondHyperlinkText,
  onFirstHyperlinkCallback,
  onSecondHyperlinkCallback,
  containerStyle,
  textStyle = {
    ...typographies.small,
    textAlign: "center"
  },
  linkTextStyle = {
    ...typographies.small,
    color: colors.secondary
  }
}: {
  firstText?: string;
  firstHyperlinkText: string;
  secondText?: string;
  secondHyperlinkText?: string;
  onFirstHyperlinkCallback: () => void;
  onSecondHyperlinkCallback?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  linkTextStyle?: StyleProp<TextStyle>;
}) {
  return (
    <View style={containerStyle}>
      <Text style={textStyle}>
        {firstText}&nbsp;
        <Text style={linkTextStyle} onPress={onFirstHyperlinkCallback}>
          {firstHyperlinkText}
        </Text>
        {secondText && <>&nbsp;{secondText}</>}
        {secondHyperlinkText && (
          <>
            &nbsp;
            <Text style={linkTextStyle} onPress={onSecondHyperlinkCallback}>
              {secondHyperlinkText}
            </Text>
          </>
        )}
      </Text>
    </View>
  );
}
