import { isValid, parseISO, toDate } from "date-fns";

export function parseDate(value: string | number | Date): Date {
  if (isValid(value)) return toDate(value as number | Date);
  return new Date(value);
}

export function jsonReviver(_: string, value: any) {
  if (typeof value === "string") {
    if (isFullISOMatch(value) || isDate(value)) {
      const date = parseISO(value);
      if (isValid(date)) {
        return date;
      }
    }
  }

  return value;
}

function isFullISOMatch(dateString: string) {
  const ISO_8601_FULL =
    /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

  return ISO_8601_FULL.test(dateString);
}

function isDate(dateString: string) {
  const dateRegex = /^\d{4}-\d\d-\d\d$/;

  return dateRegex.test(dateString);
}
