import { default as React, useEffect, useState } from "react";
import { Platform } from "react-native";
import { Switch } from "react-native-elements";
import { LOCAL_AUTH_ENABLED } from "../providers/IdentityConstants";
import {
  isLocalAuthActivated,
  isLocalAuthSupported
} from "../services/LocalAuthService";
import * as SecureStorage from "../services/SecureStorage";
import { colors, spacings } from "../styles/Styles";

export default function LocalAuthSelectionComponent() {
  const [isActivated, setActivated] = useState(false);
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    async function getLocalAuthActivatedAsync(): Promise<void> {
      const isActivated = await isLocalAuthActivated();
      const supported = await isLocalAuthSupported();

      setActivated(isActivated && supported);
      setIsSupported(supported);
    }

    getLocalAuthActivatedAsync();
  }, []);

  async function setActivatedAsync(value: boolean): Promise<void> {
    await SecureStorage.setItemAsync(LOCAL_AUTH_ENABLED, value.toString());
    setActivated(value);
  }

  return (
    <Switch
      color={Platform.OS === "android" ? colors.primary50 : colors.primary}
      thumbColor={Platform.OS === "android" ? colors.primary : undefined}
      {...(Platform.OS === "web" && {
        activeThumbColor: colors.primary,
        activeTrackColor: colors.primary50
      })}
      value={isActivated}
      disabled={!isSupported}
      onValueChange={setActivatedAsync}
      style={
        Platform.OS === "android" && {
          marginVertical: -spacings.lg
        }
      }
    />
  );
}
