import { NavigationProp, useNavigation } from "@react-navigation/native";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import {
  AccountTransactionsListScreenProps,
  HomeStackParamsList
} from "../navigation/HomeStackNavigation";
import { useTransactions } from "../services/TransactionsService";
import { typographies } from "../styles/Fonts";
import { colors, cornerRadius, spacings } from "../styles/Styles";
import { EmptyContentComponent } from "./EmptyContentComponent";
import { TransactionListSummarySkeleton } from "./LoadingSkeletonComponent";
import TransactionsListItemComponent from "./TransactionsListItemComponent";

export default function TransactionsListSummaryComponent({
  take,
  identifierId,
  affiliationId,
  personId,
  accountId
}: {
  take: number;
  identifierId?: number;
  affiliationId?: number;
  personId?: number;
  accountId?: number;
}) {
  const navigation = useNavigation<NavigationProp<HomeStackParamsList>>();
  const { t } = useTranslation();

  const transactionsQuery =
    identifierId && affiliationId && personId && accountId
      ? useTransactions(
          { identifierId, affiliationId, personId, accountId },
          null,
          null,
          null,
          take
        )
      : useTransactions(null, null, null, null, take);

  if (transactionsQuery.isInitialLoading) {
    return <TransactionListSummarySkeleton />;
  }

  if (!transactionsQuery.isInitialLoading && !transactionsQuery.data?.length) {
    return (
      <EmptyContentComponent
        message={t(
          "TransactionListSummaryNoTransactionMessage",
          "You don't have any transactions."
        )}></EmptyContentComponent>
    );
  }

  return (
    <View style={{ paddingTop: spacings.none }}>
      <Text
        style={[
          typographies.title,
          { textAlign: "left", marginBottom: spacings.sm }
        ]}>
        {t("TransactionListSummaryTitle", "Transactions")}
      </Text>

      <View
        style={{
          overflow: "hidden",
          borderRadius: cornerRadius.md,
          backgroundColor: colors.background.light
        }}>
        {transactionsQuery.data?.map((item, index) => {
          return (
            <View
              key={index}
              style={{
                borderBottomColor: colors.background.neutral,
                borderBottomWidth: cornerRadius.xxs
              }}>
              <TransactionsListItemComponent
                item={item}
                dateFormat={(date) =>
                  formatDistanceToNow(date, {
                    addSuffix: true
                  })
                }></TransactionsListItemComponent>
            </View>
          );
        })}

        <Button
          title={t("TransactionsListSummarySeeAllButton", "See All")}
          buttonStyle={{
            backgroundColor: colors.background.light,
            height: 40
          }}
          containerStyle={{ alignSelf: "stretch" }}
          titleStyle={[
            typographies.small,
            {
              color: colors.text.link
            }
          ]}
          onPress={() =>
            navigation.navigate("transactionsStack", {
              screen: "transactions",
              params:
                identifierId && affiliationId && personId && accountId
                  ? ({
                      identifierId,
                      affiliationId,
                      personId,
                      accountId
                    } as AccountTransactionsListScreenProps)
                  : undefined
            })
          }
        />
      </View>
    </View>
  );
}
