import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  NavigatorScreenParams,
  useNavigation
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { Button } from "react-native-elements";
import { RootStackParamsList } from ".";
import usePreselectedAccount from "../hooks/PreselectedAccountHook";
import AccountDetailsScreen from "../screens/AccountDetailsScreen";
import DeleteAccountConfirmationScreen from "../screens/DeleteAccountConfirmationScreen";
import ExportTransactionsScreen from "../screens/ExportTransactionsScreen";
import HomeScreen from "../screens/HomeScreen";
import RefundAccountBalanceConfirmationScreen from "../screens/RefundAccountBalanceConfirmationScreen";
import RequestDeleteAccountScreen from "../screens/RequestDeleteAccountScreen";
import TransactionDetailScreen from "../screens/TransactionDetailScreen";
import TransactionsListScreen from "../screens/TransactionsListScreen";
import { useAccounts } from "../services/AccountsService";
import {
  colors,
  headerStyle,
  iconSizes,
  primaryLinkButtonStyle
} from "../styles/Styles";

export type AccountDetailsScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};
export type RequestDeleteAccountScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};
export type DeleteAccountConfirmationScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  token: string;
};
export type RefundAccountBalanceConfirmationScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};
export type TransactionDetailsScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
  transactionId: number;
};
export type AccountTransactionsListScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};

export type ExportTransactionsScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};

export type TransactionsStackParamsList = {
  transactions: AccountTransactionsListScreenProps | undefined;
  transactionDetail: TransactionDetailsScreenProps;
  exportTransactions: ExportTransactionsScreenProps | undefined;
};

export type HomeStackParamsList = {
  home: undefined;
  accountDetails: AccountDetailsScreenProps;
  requestDeleteAccount: RequestDeleteAccountScreenProps;
  deleteAccountConfirmation: DeleteAccountConfirmationScreenProps;
  transactionsStack: NavigatorScreenParams<TransactionsStackParamsList>;
  refundAccountBalance: RefundAccountBalanceConfirmationScreenProps;
};

const HomeStack = createStackNavigator<HomeStackParamsList>();
const TransactionsStack = createStackNavigator<TransactionsStackParamsList>();

export default function HomeStackScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  const accountsQuery = useAccounts();
  const { preselectedAccount } = usePreselectedAccount();

  return (
    <HomeStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <HomeStack.Screen
        name="home"
        component={HomeScreen}
        options={{
          title: t("HomeScreenTitle", "Home"),
          headerRight: () => (
            <Button
              icon={
                <FontAwesomeIcon
                  icon={faPlus}
                  color={colors.text.link}
                  size={iconSizes.md}></FontAwesomeIcon>
              }
              title={t("HomeScreenAddAccountButtonTitle", "Add")}
              onPress={() =>
                navigation.navigate("enrollSelectAffiliationStack", {
                  screen: "selectAffiliationToEnroll"
                })
              }
              type="clear"
              titleStyle={primaryLinkButtonStyle.title}></Button>
          )
        }}></HomeStack.Screen>
      <HomeStack.Screen
        name="accountDetails"
        component={AccountDetailsScreen}
        options={{
          animationEnabled:
            Platform.OS !== "web" &&
            (accountsQuery.data?.length ?? 0) > 1 &&
            !!!preselectedAccount,
          title: t("AccountDetailsScreenTitle", "Account")
        }}></HomeStack.Screen>
      <HomeStack.Screen
        name="requestDeleteAccount"
        component={RequestDeleteAccountScreen}
        options={{
          title: t("RequestDeleteAccountScreenTitle", "Delete account")
        }}></HomeStack.Screen>
      <HomeStack.Screen
        name="deleteAccountConfirmation"
        component={DeleteAccountConfirmationScreen}
        options={{
          title: t("DeleteAccountConfirmationScreenTitle", "Delete account")
        }}></HomeStack.Screen>
      <HomeStack.Screen
        name="refundAccountBalance"
        component={RefundAccountBalanceConfirmationScreen}
        options={{
          title: t("RefundAccountBalanceConfirmationScreenTitle", "Refund")
        }}></HomeStack.Screen>
      <HomeStack.Screen
        name="transactionsStack"
        options={{
          headerShown: false
        }}
        component={TransactionsStackScreen}
      />
    </HomeStack.Navigator>
  );
}

function TransactionsStackScreen() {
  const { t } = useTranslation();

  return (
    <TransactionsStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <TransactionsStack.Screen
        name="transactions"
        component={TransactionsListScreen}
        options={{
          title: t("TransactionsListScreenTitle", "Transactions")
        }}></TransactionsStack.Screen>
      <TransactionsStack.Screen
        name="transactionDetail"
        component={TransactionDetailScreen}
        options={{
          title: t("TransactionDetailScreenTitle", "Detail")
        }}></TransactionsStack.Screen>
      <TransactionsStack.Screen
        name="exportTransactions"
        component={ExportTransactionsScreen}
        options={{
          title: t("ExportTransactionsScreenTitle", "Export transactions")
        }}></TransactionsStack.Screen>
    </TransactionsStack.Navigator>
  );
}
