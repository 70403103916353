import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import qs from "qs";

export class IdentityClient {
  private readonly instance: AxiosInstance;
  private readonly baseUrl?: string;
  private readonly clientId?: string;
  private readonly clientSecret?: string;

  constructor(
    baseUrl?: string,
    clientId?: string,
    clientSecret?: string,
    instance?: AxiosInstance
  ) {
    this.instance = instance ?? axios.create();
    this.baseUrl = baseUrl;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
  }

  public async RequestClientCredentials(
    scopes: string
  ): Promise<TokenResponse> {
    const url = this.baseUrl + "/token";

    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: url,
      headers: {
        Accept: "*/*",
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        grant_type: "client_credentials",
        client_id: this.clientId,
        client_secret: this.clientSecret,
        scope: scopes
      })
    };

    const response = await this.instance.request<TokenResponse>(requestConfig);
    return response.data;
  }

  public async RequestResourceOwnerPasswordAsync(
    email: string,
    password: string,
    scopes: string
  ): Promise<TokenResponse> {
    const url = this.baseUrl + "/token";

    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: url,
      headers: {
        Accept: "*/*",
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        grant_type: "password",
        client_id: this.clientId,
        client_secret: this.clientSecret,
        scope: scopes,
        username: email,
        password: password
      })
    };

    const response = await this.instance.request<TokenResponse>(requestConfig);
    return response.data;
  }

  public async RequestRefreshTokenAsync(
    refreshToken: string
  ): Promise<TokenResponse> {
    const url = this.baseUrl + "/token";

    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: url,
      headers: {
        Accept: "*/*",
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        grant_type: "refresh_token",
        client_id: this.clientId,
        client_secret: this.clientSecret,
        refresh_token: refreshToken
      })
    };

    const response = await this.instance.request<TokenResponse>(requestConfig);
    return response.data;
  }

  public async RequestTokenRevocationAsync(token: string): Promise<void> {
    const url = this.baseUrl + "/revocation";

    const requestConfig: AxiosRequestConfig = {
      method: "POST",
      url: url,
      headers: {
        Accept: "*/*",
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        client_id: this.clientId,
        client_secret: this.clientSecret,
        token: token
      })
    };

    await axios.request(requestConfig);
  }

  public async RequestUserInfoAsync(
    accessToken: string
  ): Promise<UserInfoResponse> {
    const url = this.baseUrl + "/userinfo";

    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {
        Accept: "*/*",
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`
      }
    };

    const response = await this.instance.request<UserInfoResponse>(
      requestConfig
    );
    return response.data;
  }
}

export interface TokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}

export interface UserInfoResponse {
  preferred_username: string;
}
