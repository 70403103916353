import {
  format,
  isAfter,
  isThisYear,
  isToday,
  isYesterday,
  startOfDay,
  subDays
} from "date-fns";
import i18next from "i18next";
import i18n from "../providers/i18n";

export function humanizeDay(date: Date): string {
  if (isToday(date)) {
    return i18next.t("HumanizeDayToday", "Today");
  }
  if (isYesterday(date)) {
    return i18next.t("HumanizeDayYesterday", "Yesterday");
  }

  const lastWeek = subDays(startOfDay(new Date()), 6);
  if (isAfter(date, lastWeek)) {
    return format(date, "EEEE");
  }

  if (isThisYear(date)) {
    return format(date, "d MMMM");
  }
  return format(date, "PPP");
}

export function humanizeCurrency(number: number, currencyCode: string): string {
  const locale = i18n.language;
  const localizedNumber = number.toLocaleString(locale, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol"
  });
  return localizedNumber;
}
