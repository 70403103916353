import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ILegalTermsInfo, LegalTermsInfo } from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";
import { invalidateLegalTerms, legalTermsQueryKey } from "./QueryService";

export const legalTermsStaleTime: number = Infinity;

async function getLegalTerms(): Promise<ILegalTermsInfo> {
  const platformClient = platformClientFactory.create();

  var legalTermsInfo = await platformClient.getLegalTerms();
  return legalTermsInfo;
}

async function updateLegalTerms(
  legalTermsInfo: ILegalTermsInfo
): Promise<void> {
  const platformClient = platformClientFactory.create();

  const legalTermsData = new LegalTermsInfo(legalTermsInfo);

  await platformClient.updateLegalTerms(legalTermsData);

  await invalidateLegalTerms({
    refetchType: "all"
  });
}

export function useLegalTerms(
  options?: UseQueryOptions<ILegalTermsInfo, unknown, ILegalTermsInfo, string[]>
) {
  return useQuery([legalTermsQueryKey], getLegalTerms, {
    ...options,
    staleTime: legalTermsStaleTime
  });
}

export function useUpdateLegalTerms() {
  return useMutation((legalTermsInfo: ILegalTermsInfo) =>
    updateLegalTerms(legalTermsInfo)
  );
}
