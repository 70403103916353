import {
  NavigationProp,
  useFocusEffect,
  useNavigation
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { default as React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, View } from "react-native";
import { Text } from "react-native-elements";
import AccountListItemComponent from "../components/AccountListItemComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { AccountsSectionSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import TransactionsListSummaryComponent from "../components/TransactionsListSummaryComponent";
import { useAction } from "../hooks/ActionHook";
import usePreselectedAccount from "../hooks/PreselectedAccountHook";
import {
  AccountDetailsScreenProps,
  HomeStackParamsList
} from "../navigation/HomeStackNavigation";
import {
  AccountDetail,
  fetchAccounts,
  useAccounts
} from "../services/AccountsService";
import {
  invalidateAccounts,
  invalidateTransactions
} from "../services/QueryService";
import { AffiliationStatus } from "../services/clients/PlatformClient";
import { spacings, typographies } from "../styles/Styles";

export default function AccountListScreen() {
  const refreshAction = useAction(refresh);
  const { t } = useTranslation();
  const navigation =
    useNavigation<StackNavigationProp<HomeStackParamsList, "home">>();

  const accountsQuery = useAccounts();
  const { preselectAccount } = usePreselectedAccount();

  useEffect(() => {
    async function skipScreen() {
      const accounts = await fetchAccounts();
      if (!navigation.isFocused()) {
        return;
      }

      const account = accounts.length === 1 ? accounts[0] : undefined;

      if (account?.person && account.account) {
        const params: AccountDetailsScreenProps = {
          identifierId: account.identifier.identifierId,
          affiliationId: account.affiliation.affiliationId,
          personId: account.person!.personId,
          accountId: account.account!.accountId
        };
        navigation.replace("accountDetails", params);
      }
    }

    skipScreen();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      preselectAccount(undefined);
    }, [])
  );

  async function refresh(): Promise<void> {
    await Promise.all([invalidateAccounts(), invalidateTransactions()]);
  }

  if (accountsQuery.data?.length === 0) {
    return (
      <EmptyContentComponent
        message={t(
          "AccountListScreenNoAccounts",
          "You don't have any account."
        )}
        refreshControl={
          <RefreshControl
            refreshing={refreshAction.isBusy}
            onRefresh={refreshAction.execute}
          />
        }></EmptyContentComponent>
    );
  }

  return (
    <ScrollViewComponent
      refreshControl={
        <RefreshControl
          refreshing={refreshAction.isBusy}
          onRefresh={refreshAction.execute}
        />
      }>
      <View style={{ width: "100%", flex: 1 }}>
        {accountsQuery.isInitialLoading ? (
          <AccountsSectionSkeleton />
        ) : (
          <>
            <Text
              style={[
                typographies.title,
                { textAlign: "left", marginBottom: spacings.sm }
              ]}>
              {t("AccountListNoAccountTitle", "Accounts")}
            </Text>

            {accountsQuery.data?.map((item, index) => {
              return <RenderItem key={index} accountDetail={item}></RenderItem>;
            })}
          </>
        )}
      </View>

      <View style={{ marginTop: spacings.md }}>
        <TransactionsListSummaryComponent
          take={4}></TransactionsListSummaryComponent>
      </View>
    </ScrollViewComponent>
  );
}

function RenderItem({ accountDetail }: { accountDetail: AccountDetail }) {
  const navigation = useNavigation<NavigationProp<HomeStackParamsList>>();

  return (
    <AccountListItemComponent
      account={accountDetail}
      disabled={accountDetail.affiliation.status === AffiliationStatus.Offline}
      onPress={() => {
        navigation.navigate("accountDetails", {
          identifierId: accountDetail.identifier.identifierId,
          affiliationId: accountDetail.affiliation.affiliationId,
          personId: accountDetail.person!.personId,
          accountId: accountDetail.account!.accountId
        });
      }}></AccountListItemComponent>
  );
}
