import { useWindowDimensions } from "react-native";

interface ScreenSize {
  small: boolean;
  medium: boolean;
  large: boolean;
}

interface BreakPoints {
  sm: number;
  md: number;
  lg: number;
}

const breakPoints: BreakPoints = {
  sm: 0,
  md: 600,
  lg: 960
};

export default function useScreenSize(): ScreenSize {
  const { width } = useWindowDimensions();

  if (width < breakPoints.md) {
    return { small: true, medium: false, large: false };
  } else if (width >= breakPoints.md && width < breakPoints.lg) {
    return { small: false, medium: true, large: false };
  }

  return { small: false, medium: false, large: true };
}
