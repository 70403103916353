import { StyleSheet } from "react-native";
import { colors } from "./Colors";
import { cornerRadius, spacings } from "./Constants";

export const listItemStyle = StyleSheet.create({
  container: {
    borderRadius: cornerRadius.md,
    height: 100,
    borderWidth: cornerRadius.none
  },
  listItem: {
    marginBottom: spacings.md,
    borderRadius: cornerRadius.md,
    borderWidth: cornerRadius.none
  }
});

export const virtualizedListItemStyle = StyleSheet.create({
  baseItem: {
    overflow: "hidden",
    backgroundColor: colors.background.light
  },
  firstItem: {
    borderTopLeftRadius: cornerRadius.md,
    borderTopRightRadius: cornerRadius.md
  },
  middleItem: {
    borderBottomColor: colors.background.neutral,
    borderBottomWidth: cornerRadius.xxs
  },
  lastItem: {
    borderBottomLeftRadius: cornerRadius.md,
    borderBottomRightRadius: cornerRadius.md
  }
});
