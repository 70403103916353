import { AxiosInstance } from "axios";
import Constants from "expo-constants";
import { PlatformClient } from "./PlatformClient";
import { createAxios } from "./PlatformClientExtensions";

export const platformClientAxiosInstance: AxiosInstance = createAxios();

const platformClient = new PlatformClient(
  Constants.expoConfig?.extra?.platformAddress,
  platformClientAxiosInstance
);

class PlatformClientFactory {
  create(): PlatformClient {
    return platformClient;
  }
}

export const platformClientFactory: PlatformClientFactory =
  new PlatformClientFactory();
