import * as Localization from "expo-localization";
import * as SecureStorage from "../services/SecureStorage";
import { setLanguageCode } from "./AnalyticsService";

const CULTURE_CODE = "culture_code";
const supportedLanguages: string[] = [
  "de-DE",
  "de-CH",
  "fr-CH",
  "fr-FR",
  "es-ES",
  "en-GB",
  "en-US"
];
export const neutralDecimalSeparator = ".";

export async function setLanguage(selectedLanguage: string): Promise<void> {
  selectedLanguage = await getValidLanguage(selectedLanguage);
  await SecureStorage.setItemAsync(CULTURE_CODE, selectedLanguage);
  await setLanguageCode(selectedLanguage);
}

export async function getLanguage(): Promise<string> {
  const currentLanguage = await SecureStorage.getItemAsync(CULTURE_CODE);
  return getValidLanguage(currentLanguage);
}

async function getValidLanguage(language: string | null): Promise<string> {
  if (language && supportedLanguages.some((sl) => sl === language)) {
    return language;
  }
  const languageCode = Localization.locale;
  if (supportedLanguages.some((p) => p === languageCode)) {
    return languageCode;
  }
  const languageCodes = Localization.locales;
  const supportedLanguageCode = languageCodes.find((l) =>
    supportedLanguages.some((sl) => sl === l)
  );
  if (supportedLanguageCode) {
    return supportedLanguageCode;
  }
  return "en-GB";
}
