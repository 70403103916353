import { useEffect, useState } from "react";
import { IAccountKey } from "../services/AccountsService";

let selectedAccount: IAccountKey | undefined;

export default function usePreselectedAccount() {
  const [preselectedAccount, setPreselectedAccount] = useState(selectedAccount);

  useEffect(() => {
    setPreselectedAccount(selectedAccount);
  }, [selectedAccount]);

  function preselectAccount(account: IAccountKey | undefined) {
    selectedAccount = account;
    setPreselectedAccount(account);
  }

  return {
    preselectedAccount,
    preselectAccount
  };
}
