import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { faExchange } from "@fortawesome/pro-light-svg-icons/faExchange";
import { faHome } from "@fortawesome/pro-light-svg-icons/faHome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons/faUserCircle";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
  createDrawerNavigator
} from "@react-navigation/drawer";
import { CommonActions, NavigatorScreenParams } from "@react-navigation/native";
import { Image } from "expo-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  colors,
  fontFamilies,
  fontSizes,
  headerStyle,
  iconSizes,
  pressableStyle,
  spacings
} from "../styles/Styles";
import HomeStackScreen, { HomeStackParamsList } from "./HomeStackNavigation";
import LoadStackScreen, { LoadStackParamsList } from "./LoadStackNavigation";
import ProfileStackScreen, {
  ProfileStackParamsList
} from "./ProfileStackNavigation";
import SendStackScreen, { SendStackParamsList } from "./SendStackNavigation";

export type DrawerNavigatorParamsList = {
  homeStack: NavigatorScreenParams<HomeStackParamsList>;
  loadStack: NavigatorScreenParams<LoadStackParamsList>;
  sendStack: NavigatorScreenParams<SendStackParamsList>;
  profileStack: NavigatorScreenParams<ProfileStackParamsList>;
};

function SidebarMenuContent(props: DrawerContentComponentProps) {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Pressable
        style={({ pressed }) => pressed && pressableStyle.pressed}
        onPress={() => {
          const resetAction = CommonActions.reset({
            index: 0,
            routes: [{ name: "homeStack" }]
          });
          props.navigation.dispatch(resetAction);
        }}>
        <View style={{ padding: spacings.lg }}>
          <Image
            style={{ alignSelf: "center", width: 230, height: 100 }}
            source={require("../assets/img/secanda-branding.svg")}
            contentFit="contain"
          />
        </View>
      </Pressable>
      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </SafeAreaView>
  );
}

const SidebarMenu = createDrawerNavigator<DrawerNavigatorParamsList>();
export default function SidebarMenuNavigator() {
  const { t } = useTranslation();
  const sideBarWidth = 320;

  return (
    <SidebarMenu.Navigator
      initialRouteName="homeStack"
      drawerContent={(props) => <SidebarMenuContent {...props} />}
      screenOptions={{
        drawerStyle: { width: sideBarWidth },
        drawerActiveTintColor: colors.primary,
        drawerInactiveTintColor: colors.primary50,
        drawerLabelStyle: {
          fontFamily: fontFamilies.default,
          fontSize: fontSizes.md,
          marginLeft: -spacings.lg
        },
        drawerItemStyle: {
          paddingLeft: spacings.md
        },
        headerTitleStyle: headerStyle.title,
        headerStyle: headerStyle.container,
        headerShown: false,
        drawerType: "permanent"
      }}>
      <SidebarMenu.Screen
        name="homeStack"
        component={HomeStackScreen}
        options={{
          title: t("MenuHomeTitle", "Home"),
          drawerIcon: ({ color }) => (
            <FontAwesomeIcon
              icon={faHome}
              color={color}
              size={iconSizes.xl}></FontAwesomeIcon>
          )
        }}
      />
      <SidebarMenu.Screen
        name="loadStack"
        component={LoadStackScreen}
        options={{
          title: t("MenuLoadTitle", "Load"),
          drawerIcon: ({ color }) => (
            <FontAwesomeIcon
              icon={faCreditCard}
              color={color}
              size={iconSizes.xl}></FontAwesomeIcon>
          ),
          unmountOnBlur: true
        }}
      />
      <SidebarMenu.Screen
        name="sendStack"
        component={SendStackScreen}
        options={{
          title: t("MenuSendTitle", "Send"),
          drawerIcon: ({ color }) => (
            <FontAwesomeIcon
              icon={faExchange}
              color={color}
              size={iconSizes.xl}></FontAwesomeIcon>
          ),
          unmountOnBlur: true
        }}
      />
      <SidebarMenu.Screen
        name="profileStack"
        component={ProfileStackScreen}
        options={{
          title: t("MenuProfileTitle", "Profile"),
          drawerIcon: ({ color }) => (
            <FontAwesomeIcon
              icon={faUserCircle}
              color={color}
              size={iconSizes.xl}></FontAwesomeIcon>
          ),
          unmountOnBlur: true
        }}
      />
    </SidebarMenu.Navigator>
  );
}
