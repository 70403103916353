import { RouteProp, useRoute } from "@react-navigation/native";
import { Asset } from "expo-asset";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import WebViewComponent from "../components/WebViewComponent";
import { RootStackParamsList } from "../navigation";
import i18n from "../providers/i18n";
import {
  IAffiliationKey,
  fetchAffiliationTermsAndConditions
} from "../services/AffiliationsService";
import { colors, containerStyle } from "../styles/Styles";

export default function TermsAndConditionsScreen() {
  const route =
    useRoute<RouteProp<RootStackParamsList, "termsAndConditions">>();
  const [path, setPath] = useState("");

  const affiliationKey: IAffiliationKey | undefined =
    route.params?.identifierId && route.params?.affiliationId
      ? {
          identifierId: route.params.identifierId,
          affiliationId: route.params.affiliationId
        }
      : undefined;

  useEffect(() => {
    async function loadFileAsync() {
      let fileData = affiliationKey
        ? await fetchAffiliationTermsAndConditions(
            affiliationKey,
            i18n.language
          )
        : null;

      if (!fileData) {
        const languageCode = i18n.language;
        const assetsFiles = Asset.fromModule(
          languageCode.includes("fr")
            ? require("../assets/files/TermsOfUse-fr.pdf")
            : languageCode.includes("de")
            ? require("../assets/files/TermsOfUse-de.pdf")
            : require("../assets/files/TermsOfUse-en.pdf")
        );

        fileData = assetsFiles.uri;

        if (Platform.OS === "android") {
          fileData = (await assetsFiles.downloadAsync()).localUri!;
        }
      }

      setPath(fileData);
    }

    loadFileAsync();
  }, []);

  if (!path) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return <WebViewComponent path={path}></WebViewComponent>;
}
