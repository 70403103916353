import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { t } from "i18next";
import React from "react";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import { RootStackParamsList } from "../navigation";

export default function RefundBalanceResultScreen() {
  const navigation = useNavigation<StackNavigationProp<RootStackParamsList>>();
  const homeNavigation = useResetHomeNavigation();
  const route =
    useRoute<RouteProp<RootStackParamsList, "refundBalanceResult">>();

  let title = "";
  let description = "";
  let buttonTitle = "";
  let buttonCallback = () => {};
  let buttonRetryCallback = undefined;

  switch (route.params.type) {
    case ResultType.Success: {
      title = t("RefundBalanceScreenSuccessTitle", "Refund done successfully");
      description = t(
        "RefundBalanceScreenSuccess",
        "All the balance has been refunded"
      );
      buttonTitle = t("HomeScreenGoHomeTitle", "Go to Home");
      buttonCallback = () => homeNavigation.reset();
      break;
    }
    case ResultType.Info: {
      title = t("RefundBalanceScreenPartialRefundTitle", "Partial refund done");
      description = t(
        "RefundBalanceScreenPartialRefund",
        "Please get in touch with your affiliation to get a refund of the pending amount. \nAmount refunded: {{refundedAmount}} \nPending amount to refund: {{pendingAmount}}",
        {
          refundedAmount: route.params.amountRefunded,
          pendingAmount: route.params.pendingAmount
        }
      );
      buttonTitle = t("HomeScreenGoHomeTitle", "Go to Home");
      buttonCallback = () => homeNavigation.reset();
      break;
    }
    default: {
      title = t("RefundBalanceScreenErrorTitle", "Oops...");
      description = t(
        "RefundBalanceScreenError",
        "Something went wrong while refunding. \nPlease try again."
      );
      buttonTitle = t("HomeScreenGoHomeTitle", "Go to Home");
      buttonCallback = () => homeNavigation.reset();
      buttonRetryCallback = () => navigation.goBack();
      break;
    }
  }

  return (
    <ActionResultComponent
      title={title}
      description={description}
      resultType={route.params.type}
      buttonTitle={buttonTitle}
      buttonCallback={buttonCallback}
      retryCallback={buttonRetryCallback}
    />
  );
}
