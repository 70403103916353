import { faClone } from "@fortawesome/pro-light-svg-icons/faClone";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { RouteProp, useRoute } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import { Image } from "expo-image";
import { useTranslation } from "react-i18next";
import {
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  View
} from "react-native";
import { Divider, Text } from "react-native-elements";
import {
  PaymentSlipSkeleton,
  QRCodeSkeleton
} from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { LoadAmountSelectionStackParamsList } from "../navigation/LoadStackNavigation";
import {
  IPaymentSlipKey,
  usePaymentSlip,
  useQrBillQrCode
} from "../services/PaymentSlipsService";
import { IPaymentSlip } from "../services/clients/PlatformClient";
import { pressableStyle } from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { cornerRadius, iconSizes, spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";

export default function PaymentSlipPaymentScreen() {
  const route =
    useRoute<
      RouteProp<LoadAmountSelectionStackParamsList, "paymentSlipPayment">
    >();

  const paymentSlipKey: IPaymentSlipKey = {
    identifierId: route.params.identifierId,
    affiliationId: route.params.affiliationId,
    personId: route.params.personId,
    accountId: route.params.accountId,
    paymentSlipId: route.params.paymentSlipId
  };

  const paymentSlipQuery = usePaymentSlip(paymentSlipKey);

  if (paymentSlipQuery.isInitialLoading) {
    return <PaymentSlipSkeleton />;
  }

  if (!paymentSlipQuery.data) {
    return null;
  }

  return (
    <SafeAreaView
      style={{
        flex: 1
      }}>
      <ScrollViewComponent>
        <InformationHeader />

        <View style={{ marginTop: spacings.md, marginBottom: spacings.xxl }}>
          <InformationTable paymentSlip={paymentSlipQuery.data} />
        </View>

        <QrBillPreview paymentSlipKey={paymentSlipKey} />
      </ScrollViewComponent>
    </SafeAreaView>
  );
}

function InformationHeader() {
  const { t } = useTranslation();

  return (
    <View>
      <Text style={typographies.body}>
        {t(
          "PaymentSlipPaymentScreenCopyInformation",
          "Please copy the following informations into your e-banking."
        )}
      </Text>
      <Text style={{ marginTop: spacings.md }}>
        <Text style={[typographies.body, { color: colors.text.dark }]}>
          {t("PaymentSlipPaymentScreenWarningTitle", "Warning: ")}
        </Text>
        <Text style={typographies.body}>
          {t(
            "PaymentSlipPaymentScreenWarningMessage",
            "The reference number is mandatory, since it is used to determine which account should be loaded."
          )}
        </Text>
      </Text>
    </View>
  );
}

function InformationTable({ paymentSlip }: { paymentSlip: IPaymentSlip }) {
  const { t } = useTranslation();
  const address = `${paymentSlip.recipientName}\n${paymentSlip.recipientStreet} ${paymentSlip.recipientHouseNo}\n${paymentSlip.recipientPostalCode} ${paymentSlip.recipientTown}`;
  const account = paymentSlip.recipientQrIban;
  const referenceNumber = formatSlipReference(paymentSlip.slipReference);

  return (
    <View
      style={{
        borderWidth: cornerRadius.xxs,
        borderColor: colors.background.disabled,
        borderRadius: cornerRadius.md,
        backgroundColor: colors.background.light
      }}>
      <View
        style={[
          styles.bvrRow,
          {
            borderTopWidth: cornerRadius.none
          }
        ]}>
        <View style={styles.leftColumn}>
          <Text style={typographies.body}>
            {t("PaymentSlipPaymentScreenPaymentFor", "Payment for")}
          </Text>
        </View>
        <Divider
          orientation="vertical"
          color={colors.background.neutral}></Divider>
        <View style={[styles.rightColumn, { flexDirection: "row" }]}>
          <Text
            style={[typographies.body, { flexWrap: "wrap", flex: 1 }]}
            selectable={true}>
            {address}
          </Text>
          <Pressable
            style={({ pressed }) => [
              pressed && pressableStyle.pressed,
              {
                marginLeft: spacings.sm,
                justifyContent: "center"
              }
            ]}
            onPress={async () => await copyToClipboard(address)}>
            <FontAwesomeIcon
              icon={faClone}
              size={iconSizes.md}
              color={colors.text.primary}
            />
          </Pressable>
        </View>
      </View>

      <View style={styles.bvrRow}>
        <View style={styles.leftColumn}>
          <Text style={typographies.body}>
            {t("PaymentSlipPaymentScreenAccount", "Account")}
          </Text>
        </View>
        <Divider
          orientation="vertical"
          color={colors.background.neutral}></Divider>
        <View style={[styles.rightColumn, { flexDirection: "row" }]}>
          <Text style={[typographies.body, { flex: 1 }]} selectable={true}>
            {account}
          </Text>
          <Pressable
            style={({ pressed }) => [
              pressed && pressableStyle.pressed,
              {
                marginLeft: spacings.sm,
                justifyContent: "center"
              }
            ]}
            onPress={async () => await copyToClipboard(account)}>
            <FontAwesomeIcon
              icon={faClone}
              size={iconSizes.md}
              color={colors.text.primary}
            />
          </Pressable>
        </View>
      </View>

      <View style={styles.bvrRow}>
        <View style={styles.leftColumn}>
          <Text style={typographies.body}>
            {t("PaymentSlipPaymentScreenReferenceNumber", "Reference number")}
          </Text>
        </View>
        <Divider
          orientation="vertical"
          color={colors.background.neutral}></Divider>
        <View style={[styles.rightColumn, { flexDirection: "row" }]}>
          <Text style={[typographies.body, { flex: 1 }]} selectable={true}>
            {referenceNumber}
          </Text>
          <Pressable
            style={({ pressed }) => [
              pressed && pressableStyle.pressed,
              {
                marginLeft: spacings.sm,
                justifyContent: "center"
              }
            ]}
            onPress={async () => await copyToClipboard(referenceNumber)}>
            <FontAwesomeIcon
              icon={faClone}
              size={iconSizes.md}
              color={colors.text.primary}
            />
          </Pressable>
        </View>
      </View>

      <View style={styles.bvrRow}>
        <View style={styles.leftColumn}>
          <Text style={typographies.body}>
            {t("PaymentSlipPaymentScreenAmount", "Amount")}
          </Text>
        </View>
        <Divider
          orientation="vertical"
          color={colors.background.neutral}></Divider>
        <View style={styles.rightColumn}>
          <Text style={typographies.body} selectable={true}>
            {paymentSlip.amount?.toFixed(2)}
          </Text>
        </View>
      </View>
    </View>
  );

  async function copyToClipboard(text: string | undefined) {
    if (text) await Clipboard.setStringAsync(text);
  }
}

function QrBillPreview({
  paymentSlipKey
}: {
  paymentSlipKey: IPaymentSlipKey;
}) {
  const { t } = useTranslation();
  const qrBillQrCodeQuery = useQrBillQrCode(paymentSlipKey);

  return (
    <View>
      <Text style={typographies.body}>
        {t(
          "PaymentSlipPaymentScreenExampleForm",
          "Or scan the QR-Code with your mobile e-banking:"
        )}
      </Text>
      <View
        style={{
          width: "100%",
          marginTop: spacings.lg,
          alignItems: "center"
        }}>
        <View
          style={{
            borderRadius: cornerRadius.md,
            backgroundColor: colors.background.light,
            height: 300,
            width: 300,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}>
          {qrBillQrCodeQuery.data ? (
            <Image
              style={{
                width: 220,
                height: 220,
                alignSelf: "center",
                ...(Platform.OS === "web" && { imageRendering: "pixelated" })
              }}
              transition={1000}
              contentFit="contain"
              source={{ uri: qrBillQrCodeQuery.data }}
            />
          ) : (
            <QRCodeSkeleton />
          )}
        </View>
      </View>
    </View>
  );
}

function formatSlipReference(slipReference?: string): string | undefined {
  if (!slipReference) {
    return slipReference;
  }

  for (let i = slipReference.length; i > 0; i -= 5) {
    slipReference =
      slipReference?.substring(0, i) + " " + slipReference.substring(i);
  }

  return slipReference;
}

const styles = StyleSheet.create({
  bvrRow: {
    flexDirection: "row",
    borderTopWidth: cornerRadius.xxs,
    borderColor: colors.background.neutral,
    alignItems: "center"
  },
  rightColumn: {
    marginHorizontal: spacings.md,
    padding: spacings.sm,
    flex: 2
  },
  leftColumn: {
    flex: 1,
    paddingVertical: spacings.md,
    marginHorizontal: spacings.md
  }
});
