import { faCircleCheck } from "@fortawesome/pro-light-svg-icons/faCircleCheck";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons/faCircleExclamation";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons/faCircleInfo";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import { Text } from "react-native-elements";
import { ToastConfig, ToastConfigParams } from "react-native-toast-message";
import { colors } from "../styles/Colors";
import { cornerRadius, iconSizes, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { elevations } from "../styles/Elevations";
import { typographies } from "../styles/Fonts";

export enum ToastType {
  Success = "success",
  Error = "error",
  Info = "info"
}

export declare type ToastProps = {
  title: string;
  message: string;
  toastType: ToastType;
};

export const toastConfig: ToastConfig = {
  customToast: ({ props }: ToastConfigParams<ToastProps>) => (
    <ToastLayoutComponent props={props}></ToastLayoutComponent>
  )
};

export default function ToastLayoutComponent({ props }: { props: ToastProps }) {
  const { width } = useWindowDimensions();
  const minWidth = Math.min(420, width) - spacings.xl * 2;

  return (
    <View
      style={[
        containerStyle.containerCenter,
        elevations.md,
        {
          backgroundColor: colors.background.light,
          borderRadius: cornerRadius.xxl,
          minHeight: spacings.xxl * 2,
          minWidth: minWidth,
          maxWidth: minWidth,
          flexDirection: "row"
        }
      ]}>
      <FontAwesomeIcon
        icon={
          props.toastType == ToastType.Success
            ? faCircleCheck
            : props.toastType == ToastType.Error
            ? faCircleExclamation
            : faCircleInfo
        }
        size={iconSizes.lg}
        color={
          props.toastType == ToastType.Success
            ? colors.success
            : props.toastType == ToastType.Error
            ? colors.danger
            : colors.info
        }
      />
      <View
        style={{
          marginLeft: spacings.md,
          marginVertical: spacings.md,
          flex: 1
        }}>
        <Text
          numberOfLines={1}
          style={[
            typographies.small,
            {
              color: colors.text.tertiary
            }
          ]}>
          {props.title}
        </Text>
        <Text numberOfLines={3} style={typographies.small}>
          {props.message}
        </Text>
      </View>
    </View>
  );
}
