import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function useReCaptchaToken(action?: string): {
  executeRecaptcha: () => Promise<string | undefined>;
} {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = executeRecaptcha(action);
    return await token;
  }, [executeRecaptcha, action]);

  return {
    executeRecaptcha: handleReCaptchaVerify
  };
}
