import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, TextInput, View } from "react-native";
import { Button, Text } from "react-native-elements";
import RNPickerSelect, { Item } from "react-native-picker-select";
import { ResultType } from "../components/ActionResultComponent";
import AddressDataComponent from "../components/AddressDataComponent";
import AffiliationListItemComponent from "../components/AffiliationListItemComponent";
import CountrySelectionComponent from "../components/CountrySelectionComponent";
import HyperlinkedTextComponent from "../components/HyperlinkedTextComponent";
import { EnrollPersonalDataSkeleton } from "../components/LoadingSkeletonComponent";
import NameDataComponent from "../components/NameDataComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useReCaptchaToken from "../hooks/ReCaptchaHook";
import {
  EnrollSelectAffiliationStackParamsList,
  RootStackParamsList
} from "../navigation";
import { BottomTabNavigatorParamsList } from "../navigation/BottomTabMenuNavigation";
import { DrawerNavigatorParamsList } from "../navigation/SidebarMenuNavigation";
import { useAuth } from "../providers/AuthenticationProvider";
import i18n from "../providers/i18n";
import {
  fetchAffiliation,
  fetchDependentAffiliations,
  useAffiliationById
} from "../services/AffiliationsService";
import { useIdentifiers } from "../services/IdentifiersService";
import { useCreatePerson } from "../services/PersonsService";
import { useProfile, useUpdateProfile } from "../services/ProfileService";
import {
  AffiliationStatus,
  IPersonInfo,
  IProfileInfo
} from "../services/clients/PlatformClient";
import { cornerRadius, iconSizes, spacings } from "../styles/Constants";
import { largePickerSelectStyle } from "../styles/Inputs";
import {
  colors,
  largePrimaryRoundedButtonStyle,
  typographies
} from "../styles/Styles";

export default function EnrollPersonalDataScreen() {
  const route =
    useRoute<
      RouteProp<EnrollSelectAffiliationStackParamsList, "enrollPersonalData">
    >();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<RootStackParamsList>,
        StackNavigationProp<
          BottomTabNavigatorParamsList | DrawerNavigatorParamsList
        >
      >
    >();
  const { t } = useTranslation();
  const { userIdentifier } = useAuth();
  const { executeRecaptcha } = useReCaptchaToken("self_registration");
  const profileQuery = useProfile();
  const updateProfileMutation = useUpdateProfile();
  const identifiersQuery = useIdentifiers();
  const createPersonMutation = useCreatePerson();
  const affiliationQuery = useAffiliationById(route.params.affiliationId!);
  const identifiers = useMemo(
    () =>
      identifiersQuery.data?.map((i): Item => {
        // TODO: Workaround to avoid render and first item selection when placeholder empty object. With string type it works well.
        return {
          label: i.value,
          value: i.identifierId,
          key: i.identifierId.toString()
        };
      }) ?? [],
    [identifiersQuery.data]
  );

  const [identifierId, setIdentifierId] = useState<number | undefined>();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [address, setAddress] = useState<string | undefined>();
  const [city, setCity] = useState<string | undefined>();
  const [postalCode, setPostalCode] = useState<string | undefined>();
  const [countryCode, setCountryCode] = useState<string | undefined>();
  const addressRef = useRef<TextInput>(null);
  const [displayCountrySelection, setDisplayCountrySelection] = useState(false);

  useEffect(() => {
    setFirstName(profileQuery.data?.firstName);
    setLastName(profileQuery.data?.lastName);
    setAddress(profileQuery.data?.address);
    setCity(profileQuery.data?.city);
    setPostalCode(profileQuery.data?.postalCode);
    setCountryCode(profileQuery.data?.countryCode);
  }, [profileQuery.data]);

  useEffect(() => {
    setIdentifierId(
      identifiersQuery.data?.find((i) => i.value == userIdentifier)
        ?.identifierId
    );
  }, [userIdentifier, identifiersQuery.data]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", (e) => {
      // If already has been prevented, we need to dispatch the blocked action
      if (e.defaultPrevented) {
        navigation.dispatch(e.data.action);
        return;
      }

      // If we are not in the language selection, we want to go back
      if (!displayCountrySelection) {
        return;
      }

      e.preventDefault();
      setDisplayCountrySelection(false);
    });

    return unsubscribe;
  }, [displayCountrySelection, navigation]);

  const isFormInvalid =
    !identifierId ||
    !firstName ||
    !lastName ||
    !address ||
    !postalCode ||
    !city ||
    !countryCode;

  if (
    profileQuery.isInitialLoading ||
    identifiersQuery.isInitialLoading ||
    affiliationQuery.isInitialLoading
  ) {
    return <EnrollPersonalDataSkeleton />;
  }

  if (displayCountrySelection) {
    return (
      <CountrySelectionComponent
        defaultCountryCode={countryCode}
        onCountrySelection={(value) => {
          setCountryCode(value);
          setDisplayCountrySelection(false);
        }}
      />
    );
  }

  return (
    <ScrollViewComponent>
      {affiliationQuery.data && (
        <View
          style={{
            borderRadius: cornerRadius.md,
            overflow: "hidden",
            marginBottom: spacings.lg
          }}>
          <AffiliationListItemComponent
            affiliation={affiliationQuery.data}
            disabled={
              affiliationQuery.data.status === AffiliationStatus.Offline
            }
          />
        </View>
      )}

      <Text style={[typographies.title, { marginBottom: spacings.sm }]}>
        {t("EnrollPersonalDataScreenIdentifierTitle", "Identifier")}
      </Text>

      <RNPickerSelect
        placeholder={{}}
        fixAndroidTouchableBug={true}
        useNativeAndroidPickerStyle={false}
        style={largePickerSelectStyle}
        onValueChange={(value) => {
          setIdentifierId(value);
        }}
        value={identifierId}
        items={identifiers}
        itemKey={identifierId?.toString()}
        Icon={() => {
          if (Platform.OS !== "web") {
            return (
              <FontAwesomeIcon
                size={iconSizes.md}
                color={colors.primary}
                icon={faChevronDown}></FontAwesomeIcon>
            );
          }
          return null;
        }}
      />

      <Text
        style={[
          typographies.title,
          {
            marginTop: spacings.lg,
            marginBottom: spacings.sm
          }
        ]}>
        {t("EnrollPersonalDataScreenPersonalDataTitle", "Personal data")}
      </Text>

      <NameDataComponent
        defaultFirstName={firstName ?? profileQuery.data?.firstName}
        defaultLastName={lastName ?? profileQuery.data?.lastName}
        onFirstNameChanged={setFirstName}
        onLastNameChanged={setLastName}
        onSubmitCallback={() => addressRef.current?.focus()}
      />

      <AddressDataComponent
        defaultAddress={address ?? profileQuery.data?.address}
        defaultCity={city ?? profileQuery.data?.city}
        defaultCountryCode={countryCode ?? profileQuery.data?.countryCode}
        defaultPostalCode={postalCode ?? profileQuery.data?.postalCode}
        onAddressChanged={setAddress}
        onCityChanged={setCity}
        onPostalCodeChanged={setPostalCode}
        onCountrySelection={() => setDisplayCountrySelection(true)}
        addressInputReference={addressRef}
      />

      <HyperlinkedTextComponent
        containerStyle={{
          marginTop: spacings.lg,
          marginRight: spacings.none,
          marginLeft: spacings.sm
        }}
        firstText={t(
          "EnrollPersonalDataScreenAgreementText",
          "By pressing Enroll, you accept the"
        )}
        firstHyperlinkText={t(
          "EnrollPersonalDataScreenTermsConditions",
          "Terms & Conditions"
        )}
        onFirstHyperlinkCallback={() =>
          navigation.navigate("termsAndConditions", {
            identifierId: identifierId,
            affiliationId: affiliationQuery.data?.affiliationId
          })
        }
        secondText={t(
          "EnrollPersonalDataScreenOfLabel",
          "of {{affiliationName}}",
          {
            affiliationName: affiliationQuery.data?.name
          }
        )}
      />

      <Button
        title={t("EnrollPersonalDataScreenButton", "Enroll")}
        onPress={createPersonAsync}
        disabled={isFormInvalid || createPersonMutation.isLoading}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        loading={createPersonMutation.isLoading}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.md
          }
        ]}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}></Button>
    </ScrollViewComponent>
  );

  async function createPersonAsync() {
    if (isFormInvalid) {
      return;
    }

    const personInfo: IPersonInfo = {
      firstName: firstName?.trim() || profileQuery.data?.firstName.trim() || "",
      lastName: lastName?.trim() || profileQuery.data?.lastName?.trim() || "",
      languageCode:
        profileQuery.data?.languageCode?.trim() || i18n.language || undefined,
      address:
        address?.trim() || profileQuery.data?.address?.trim() || undefined,
      city: city?.trim() || profileQuery.data?.city?.trim() || undefined,
      postalCode:
        postalCode?.trim() ||
        profileQuery.data?.postalCode?.trim() ||
        undefined,
      countryCode:
        countryCode?.trim() ||
        profileQuery.data?.countryCode?.trim() ||
        undefined,
      birthdate: profileQuery.data?.birthdate,
      phoneNumber: profileQuery.data?.phoneNumber?.trim() || undefined,
      gender: profileQuery.data?.gender
    };
    const profileInfo: IProfileInfo = {
      ...personInfo
    };

    try {
      const recaptchaToken = await executeRecaptcha();
      const person = await createPersonMutation.mutateAsync({
        affiliationKey: {
          identifierId: identifierId,
          affiliationId: route.params.affiliationId!
        },
        personInfo: personInfo,
        recaptchaToken: recaptchaToken
      });

      await updateProfileMutation.mutateAsync(profileInfo);

      const affiliation = await fetchAffiliation({
        identifierId: identifierId,
        affiliationId: route.params.affiliationId!
      });
      const affiliations = await fetchDependentAffiliations(
        route.params.affiliationId!
      );

      navigation.replace("enrollPersonalDataResult", {
        type: ResultType.Success,
        description: t(
          "EnrollPersonalDataScreenRegistrationSuccessMessage",
          "User registered successfully"
        ),
        identifierId: identifierId,
        affiliationId: route.params.affiliationId!,
        personId: person?.personId,
        selfActivationAllowed:
          affiliation.configuration.selfActivationAllowed ||
          affiliations.some((a) => a.configuration.selfActivationAllowed),
        skipVisible: true
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("enrollPersonalDataResult", {
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      let resultType: ResultType = ResultType.Error;

      switch (e?.status) {
        case 400:
          errorDescription = t(
            "EnrollPersonalDataScreenDataError",
            "An error occurred during the registration. Please check the data."
          );
          break;
        case 404:
          errorDescription = t(
            "EnrollPersonalDataScreenAffiliationNotFound",
            "The selected affiliation was not found."
          );
          break;
        case 409:
          errorDescription = t(
            "EnrollPersonalDataScreenPersonExists",
            "You can only register to an affiliation once."
          );
          break;
        case 504:
          errorDescription = t(
            "EnrollPersonalDataScreenAffiliationOffline",
            "The affiliation you are trying to register in is offline. Please try again later."
          );

          resultType = ResultType.Warning;
          break;
        default:
          errorDescription = t(
            "EnrollPersonalDataScreenUnknownError",
            "Internal server error"
          );
          break;
      }

      navigation.navigate("enrollPersonalDataResult", {
        type: resultType,
        description: errorDescription
      });
    }
  }
}
