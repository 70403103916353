import { faPaperPlane } from "@fortawesome/pro-light-svg-icons/faPaperPlane";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import { AuthenticationStackParamsList } from "../navigation/AuthenticationStackNavigation";
import { colors, fontSizes } from "../styles/Styles";

export default function RegisterResultScreen() {
  const route =
    useRoute<RouteProp<AuthenticationStackParamsList, "registerResult">>();
  const navigation =
    useNavigation<StackNavigationProp<AuthenticationStackParamsList>>();
  const { t } = useTranslation();
  const isSuccess = route.params.type === ResultType.Success;

  return (
    <ActionResultComponent
      icon={isSuccess ? faPaperPlane : undefined}
      iconStyle={isSuccess && { color: colors.text.primary }}
      title={
        isSuccess
          ? t(
              "RegisterResultScreenSuccessTitle",
              "Account successfully created"
            )
          : t("RegisterResultScreenErrorTitle", "Oops...")
      }
      titleStyle={{ fontSize: fontSizes.lg }}
      description={route.params.description}
      descriptionStyle={{ fontSize: fontSizes.sm }}
      resultType={route.params.type}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
      buttonTitle={t("RegisterResultScreenButtonTitle", "Go to login")}
      buttonCallback={() =>
        navigation.navigate("login")
      }></ActionResultComponent>
  );
}
