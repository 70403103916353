import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useRoute } from "@react-navigation/core";
import { RouteProp, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Pressable, SafeAreaView, View } from "react-native";
import { Button, Chip, ListItem, Text } from "react-native-elements";
import AffiliationListItemComponent from "../components/AffiliationListItemComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { IdentifierDetailSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { EnrollIdentifiersStackParamsList } from "../navigation";
import { ProfileStackParamsList } from "../navigation/ProfileStackNavigation";
import { useAuth } from "../providers/AuthenticationProvider";
import { useAffiliations } from "../services/AffiliationsService";
import { useUserInfo } from "../services/AuthenticationService";
import { alert } from "../services/DialogService";
import {
  useDefinePrimaryIdentifier,
  useDeleteIdentifier,
  useIdentifier,
  useResendValidationEmail
} from "../services/IdentifiersService";
import { toastError, toastSuccess } from "../services/ToastService";
import {
  AffiliationStatus,
  IAffiliation,
  IdentifierStatus
} from "../services/clients/PlatformClient";
import { listItemHeights } from "../styles/Constants";
import {
  colors,
  cornerRadius,
  iconSizes,
  largePrimaryRoundedButtonStyle,
  pressableStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function IdentifierDetailScreen() {
  const { t } = useTranslation();
  const { accessToken } = useAuth();
  const userInfoQuery = useUserInfo(accessToken!);
  const route =
    useRoute<RouteProp<EnrollIdentifiersStackParamsList, "identifierDetail">>();
  const navigation =
    useNavigation<StackNavigationProp<ProfileStackParamsList>>();

  const { identifierId } = route.params;

  const identifierQuery = useIdentifier(identifierId);
  const affiliationsQuery = useAffiliations(identifierId);
  const resendValidationEmailMutation = useResendValidationEmail();
  const deleteIdentifierMutation = useDeleteIdentifier();

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        deleteIdentifierMutation.isLoading ? (
          <ActivityIndicator
            color={colors.primary}
            style={{
              marginRight: spacings.md
            }}
          />
        ) : (
          <Pressable
            style={({ pressed }) => pressed && pressableStyle.pressed}
            onPress={async () => {
              alert(
                t(
                  "IdentifierDetailScreenDeleteIdentifierTitle",
                  "Delete identifier"
                ),
                t(
                  "IdentifierDetailScreenDeleteIdentifierMessage",
                  "Are you sure you want to delete the identifier?"
                ),
                [
                  {
                    text: t("AlertConfirm", "Confirm"),
                    onPress: async () => await deleteIdentifier(),
                    style: "destructive"
                  },
                  {
                    text: t("AlertCancel", "Cancel"),
                    style: "cancel"
                  }
                ]
              );
            }}>
            <FontAwesomeIcon
              icon={faTrash}
              color={colors.text.link}
              style={{
                marginRight: spacings.md
              }}
              size={iconSizes.lg}></FontAwesomeIcon>
          </Pressable>
        )
    });
  }, [deleteIdentifierMutation.isLoading]);

  const isIdentifierStatusValid =
    identifierQuery.data?.status === IdentifierStatus.Validated;
  const isPrimaryIdentifier =
    identifierQuery.data?.value.toUpperCase() ===
    userInfoQuery.data?.preferred_username.toUpperCase();

  if (
    identifierQuery.isInitialLoading ||
    userInfoQuery.isInitialLoading ||
    affiliationsQuery.isInitialLoading
  ) {
    return <IdentifierDetailSkeleton />;
  }

  if (!identifierQuery.data) {
    return (
      <Text
        style={[
          typographies.body,
          { textAlign: "center", marginTop: spacings.sm }
        ]}>
        {t(
          "IdentifierDetailScreenIdentifierNotFound",
          "Identifier data not found"
        )}
      </Text>
    );
  }

  async function deleteIdentifier() {
    try {
      await deleteIdentifierMutation.mutateAsync(identifierId);
      navigation.popToTop();
      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "DeleteIdentifierMessage",
          "The identifier has been deleted successfully"
        )
      );
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      if (e?.status === 423) {
        toastError(
          t("AlertErrorTitle", "Error"),
          t(
            "IdentifierDetailsScreenDeletePrincipalIdentifierErrorMessage",
            "An error occurred when deleting the identifier. This identifier cannot be deleted since it is the principal identifier"
          )
        );

        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "DeleteIdentifierErrorMessage",
          "An error occurred when deleting the identifier"
        )
      );
    }
  }

  async function resendValidationEmail(identifier: string) {
    try {
      await resendValidationEmailMutation.mutateAsync(identifier);
      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "IdentifierResendValidationEmailSuccessMessage",
          "The validation email has been sent successfully"
        )
      );
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "IdentifierResendValidationEmailErrorMessage",
          "An error occurred when sending the validation email"
        )
      );
    }
  }

  return (
    <SafeAreaView
      style={{
        flex: 1
      }}>
      <ScrollViewComponent>
        <ListItem
          style={{
            marginBottom: cornerRadius.xxs,
            borderWidth: cornerRadius.none
          }}
          containerStyle={{
            height: listItemHeights.md,
            borderTopLeftRadius: cornerRadius.md,
            borderTopRightRadius: cornerRadius.md
          }}>
          <ListItem.Content
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row"
            }}>
            <Text style={[typographies.body, { marginRight: spacings.sm }]}>
              {t("IdentifierDetailScreenEmail", "Email")}
            </Text>

            <View style={{ flexDirection: "row", flexShrink: 1 }}>
              {isPrimaryIdentifier && (
                <Chip
                  title={t("IdentifiersScreenPrimaryBadge", "Primary")}
                  buttonStyle={{
                    borderRadius: cornerRadius.sm,
                    backgroundColor: colors.chip.primary,
                    paddingHorizontal: spacings.lg,
                    paddingVertical: spacings.xs,
                    marginRight: spacings.md
                  }}
                  titleStyle={[
                    typographies.caption,
                    { color: colors.text.light }
                  ]}></Chip>
              )}

              <Text
                style={[typographies.body, { flexShrink: 1 }]}
                numberOfLines={1}>
                {identifierQuery.data.value}
              </Text>
            </View>
          </ListItem.Content>
        </ListItem>

        <ListItem
          style={{
            marginBottom: cornerRadius.xxs,
            borderWidth: cornerRadius.none
          }}
          containerStyle={{
            height: listItemHeights.md,
            borderBottomLeftRadius: cornerRadius.md,
            borderBottomRightRadius: cornerRadius.md
          }}>
          <ListItem.Content
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row"
            }}>
            <Text style={typographies.body}>
              {t("IdentifierDetailScreenStatus", "Status")}
            </Text>

            <View style={{ flexShrink: 1 }}>
              <Text style={typographies.body}>
                {isIdentifierStatusValid
                  ? t("IdentifierDetailScreenEmailValidated", "Validated")
                  : t(
                      "IdentifierDetailScreenEmailNotValidated",
                      "Not validated"
                    )}
              </Text>
            </View>
          </ListItem.Content>
        </ListItem>

        {isIdentifierStatusValid && affiliationsQuery.data && (
          <AffiliationsDetail affiliations={affiliationsQuery.data} />
        )}

        <View
          style={{ marginTop: spacings.xxxl, marginHorizontal: spacings.xxl }}>
          {isIdentifierStatusValid && !isPrimaryIdentifier && (
            <DefinePrimaryIdentifier
              identifierId={identifierId}
              value={identifierQuery.data.value}
              isDeleting={deleteIdentifierMutation.isLoading}
            />
          )}

          {!isIdentifierStatusValid && (
            <Button
              title={t(
                "IdentifierDetailScreenResendEmailButton",
                "Resend validation email"
              )}
              buttonStyle={largePrimaryRoundedButtonStyle.button}
              containerStyle={[
                largePrimaryRoundedButtonStyle.container,
                { marginTop: spacings.md }
              ]}
              titleStyle={[largePrimaryRoundedButtonStyle.title, { flex: 1 }]}
              onPress={async () =>
                await resendValidationEmail(identifierQuery.data.value)
              }
              loading={resendValidationEmailMutation.isLoading}
              disabled={resendValidationEmailMutation.isLoading}
              disabledStyle={largePrimaryRoundedButtonStyle.disabled}
            />
          )}
        </View>
      </ScrollViewComponent>
    </SafeAreaView>
  );
}

function DefinePrimaryIdentifier({
  identifierId,
  value,
  isDeleting
}: {
  identifierId: number;
  value: string;
  isDeleting: boolean;
}) {
  const { t } = useTranslation();
  const definePrimaryIdentifierMutation = useDefinePrimaryIdentifier();

  async function definePrimaryIdentifierAsync() {
    try {
      await definePrimaryIdentifierMutation.mutateAsync(identifierId);
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      if (e?.status === 409) {
        toastError(
          t("AlertErrorTitle", "Error"),
          t(
            "IdentifierDetailsScreenDefinePrimaryIdentifierAlreadyPrimaryErrorMessage",
            "An error occurred when defining the identifier as primary. This identifier is already the primary identifier"
          )
        );

        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "IdentifierDetailScreenDefinePrimaryError",
          "An error occurred when sending the validation email"
        )
      );
    }
  }

  return (
    <Button
      title={t(
        "IdentifierDetailScreenDefinePrimaryIdentifierButton",
        "Define as primary"
      )}
      disabled={definePrimaryIdentifierMutation.isLoading || isDeleting}
      buttonStyle={largePrimaryRoundedButtonStyle.button}
      titleStyle={largePrimaryRoundedButtonStyle.title}
      containerStyle={largePrimaryRoundedButtonStyle.container}
      onPress={definePrimaryIdentifierAsync}
      loading={definePrimaryIdentifierMutation.isLoading}
      loadingStyle={largePrimaryRoundedButtonStyle.loading}
      disabledStyle={largePrimaryRoundedButtonStyle.disabled}
    />
  );
}

function AffiliationsDetail({
  affiliations
}: {
  affiliations: IAffiliation[];
}) {
  const { t } = useTranslation();

  if (affiliations.length === 0) {
    return (
      <EmptyContentComponent
        style={{ marginTop: spacings.lg }}
        message={t(
          "IdentifierDetailScreenAffiliationsNotFound",
          "This identifier is not associated to any affiliation."
        )}></EmptyContentComponent>
    );
  }

  return (
    <View style={{ marginTop: spacings.xl }}>
      <Text style={[typographies.body, { color: colors.text.tertiary }]}>
        {t("IdentifierDetailScreenAffiliations", "Affiliations")}
      </Text>

      <View
        style={{
          marginTop: spacings.sm,
          borderWidth: spacings.none,
          borderRadius: cornerRadius.md,
          overflow: "hidden"
        }}>
        {affiliations.map((item, index) => {
          return (
            <View
              key={index}
              style={{ marginTop: index ? spacings.xxs : spacings.none }}>
              <AffiliationListItemComponent
                affiliation={item}
                disabled={item.status === AffiliationStatus.Offline}
              />
            </View>
          );
        })}
      </View>
    </View>
  );
}
