import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import { RootStackParamsList } from "../navigation";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";

export default function ActivationCodeResultScreen() {
  const route =
    useRoute<RouteProp<RootStackParamsList, "activationCodeResult">>();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<RootStackParamsList, "activationCodeResult">,
        StackNavigationProp<HomeStackParamsList>
      >
    >();
  const homeNavigation = useResetHomeNavigation();
  const { t } = useTranslation();
  const isSuccess = route.params.type === ResultType.Success;

  return (
    <ActionResultComponent
      title={
        isSuccess
          ? t("ActivationCodeResultScreenSuccessTitle", "Activation successful")
          : t("ActivationCodeResultScreenErrorTitle", "Oops...")
      }
      description={route.params.description}
      resultType={route.params.type}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
      buttonTitle={
        !route.params.accountId
          ? t("ActivationCodeResultScreenButtonHomeTitle", "Go home")
          : t("ActivationCodeResultScreenButtonAccountTitle", "Go to account")
      }
      buttonCallback={
        !route.params.accountId
          ? () => homeNavigation.reset()
          : () =>
              navigation.navigate("accountDetails", {
                identifierId: route.params.identifierId!,
                affiliationId: route.params.affiliationId!,
                personId: route.params.personId!,
                accountId: route.params.accountId!
              })
      }></ActionResultComponent>
  );
}
