import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  NavigatorScreenParams,
  useNavigation
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-native-elements";
import { useAuth } from "../providers/AuthenticationProvider";
import AddIdentifierScreen from "../screens/AddIdentifierScreen";
import BeneficiariesScreen from "../screens/BeneficiariesScreen";
import BeneficiaryScreen from "../screens/BeneficiaryScreen";
import ChangePasswordScreen from "../screens/ChangePasswordScreen";
import ConfirmDeleteUserScreen from "../screens/ConfirmDeleteUserScreen";
import ContactAffiliationScreen from "../screens/ContactAffiliationScreen";
import CreditCardScreen from "../screens/CreditCardScreen";
import CreditCardsScreen from "../screens/CreditCardsScreen";
import GeneralTermsScreen from "../screens/GeneralTermsScreen";
import HelpCenterScreen from "../screens/HelpCenterScreen";
import HelpSectionScreen from "../screens/HelpSectionScreen";
import IdentifierDetailScreen from "../screens/IdentifierDetailScreen";
import IdentifiersScreen from "../screens/IdentifiersScreen";
import LanguageSelectionScreen from "../screens/LanguageSelectionScreen";
import PersonalDataScreen from "../screens/PersonalDataScreen";
import ProfileScreen from "../screens/ProfileScreen";
import SecuritySettingsScreen from "../screens/SecuritySettingsScreen";
import SelectAccountContactScreen from "../screens/SelectAccountContactScreen";
import SelectAffiliationBeneficiaryScreen from "../screens/SelectAffiliationBeneficiaryScreen";
import {
  colors,
  fontSizes,
  headerStyle,
  iconSizes,
  primaryLinkButtonStyle
} from "../styles/Styles";

export type IdentifierDetailScreenProps = {
  identifierId: number;
};

export type ContactAffiliationScreenProps = {
  affiliationId: number;
};

export type CreditCardScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
  creditCardId: number;
};

export type BeneficiaryScreenProps = {
  identifierId: number;
  affiliationId: number;
  beneficiaryId?: number;
};

export type CreditCardsStackParamsList = {
  creditCards: undefined;
  creditCard: CreditCardScreenProps;
};

export type AddIdentifierScreenProps = {
  email?: string;
};

export type IdentifiersStackParamsList = {
  identifiers: undefined;
  addIdentifier: AddIdentifierScreenProps;
  identifierDetail: IdentifierDetailScreenProps;
};

export type BeneficiarySelectAffiliationStackParamsList = {
  selectAffiliationBeneficiary: undefined;
  addBeneficiary: BeneficiaryScreenProps;
};

export type BeneficiariesStackParamsList = {
  beneficiaries: undefined;
  beneficiary: BeneficiaryScreenProps;
  selectAffiliationStack: NavigatorScreenParams<BeneficiarySelectAffiliationStackParamsList>;
};

export type SecurityStackParamsList = {
  securitySettings: undefined;
  changePassword: undefined;
};

export type ContactSelectAffiliationStackParamsList = {
  selectAccountContact: undefined;
  contact: ContactAffiliationScreenProps | undefined;
};

export type HelpCenterProfileStackParamsList = {
  helpCenterProfile: undefined;
  help: undefined;
  contactSelectAffiliation: NavigatorScreenParams<ContactSelectAffiliationStackParamsList>;
};

export type ProfileStackParamsList = {
  profile: undefined;
  generalTerms: undefined;
  personalData: undefined;
  confirmDeleteUser: undefined;
  languageSelection: undefined;
  identifiersStack: NavigatorScreenParams<IdentifiersStackParamsList>;
  beneficiariesStack: NavigatorScreenParams<BeneficiariesStackParamsList>;
  creditCardsStack: NavigatorScreenParams<CreditCardsStackParamsList>;
  securityStack: NavigatorScreenParams<SecurityStackParamsList>;
  helpCenterProfileStack: NavigatorScreenParams<HelpCenterProfileStackParamsList>;
};

const ProfileStack = createStackNavigator<ProfileStackParamsList>();

export default function ProfileStackScreen() {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const logoutMutation = useMutation(signOut);

  async function logoutAsync() {
    await logoutMutation.mutateAsync();
  }

  return (
    <ProfileStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <ProfileStack.Screen
        name="profile"
        component={ProfileScreen}
        options={{
          title: t("ProfileScreenTitle", "Profile"),
          headerRight: () => (
            <Button
              title={t("ProfileScreenSignOutButton", "Sign out")}
              onPress={logoutAsync}
              type="clear"
              titleStyle={[
                primaryLinkButtonStyle.title,
                { fontSize: fontSizes.md }
              ]}></Button>
          )
        }}
      />
      <ProfileStack.Screen
        name="personalData"
        component={PersonalDataScreen}
        options={{
          title: t("PersonalDataScreenTitle", "Personal data")
        }}></ProfileStack.Screen>
      <ProfileStack.Screen
        name="generalTerms"
        component={GeneralTermsScreen}
        options={{
          title: t("GeneralTermsScreenTitle", "General terms")
        }}></ProfileStack.Screen>
      <ProfileStack.Screen
        name="confirmDeleteUser"
        component={ConfirmDeleteUserScreen}
        options={{
          title: t("ConfirmDeleteUserScreenTitle", "Delete user")
        }}></ProfileStack.Screen>
      <ProfileStack.Screen
        name="languageSelection"
        component={LanguageSelectionScreen}
        options={{
          title: t("LanguageSelectionScreenTitle", "Language")
        }}></ProfileStack.Screen>

      <ProfileStack.Group screenOptions={{ headerShown: false }}>
        <ProfileStack.Screen
          name="identifiersStack"
          component={IdentifiersStackScreen}
        />
        <ProfileStack.Screen
          name="creditCardsStack"
          component={CreditCardsStackScreen}
        />
        <ProfileStack.Screen
          name="beneficiariesStack"
          component={BeneficiariesStackScreen}
        />
        <ProfileStack.Screen
          name="securityStack"
          component={SecurityStackScreen}
        />
        <ProfileStack.Screen
          name="helpCenterProfileStack"
          component={HelpCenterStackScreen}
        />
      </ProfileStack.Group>
    </ProfileStack.Navigator>
  );
}

const IdentifiersStack = createStackNavigator<IdentifiersStackParamsList>();
const CreditCardsStack = createStackNavigator<CreditCardsStackParamsList>();
const BeneficiariesStack = createStackNavigator<BeneficiariesStackParamsList>();
const BeneficiarySelectAffiliationStack =
  createStackNavigator<BeneficiarySelectAffiliationStackParamsList>();
const SecurityStack = createStackNavigator<SecurityStackParamsList>();
const ContactSelectAffiliationStack =
  createStackNavigator<ContactSelectAffiliationStackParamsList>();
const HelpCenterProfileStack =
  createStackNavigator<HelpCenterProfileStackParamsList>();

function IdentifiersStackScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<NavigationProp<IdentifiersStackParamsList>>();

  return (
    <IdentifiersStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <IdentifiersStack.Screen
        name="identifiers"
        component={IdentifiersScreen}
        options={{
          title: t("IdentifiersScreenTitle", "Identifiers"),
          headerRight: () => (
            <Button
              title={t("IdentifiersScreenAddButton", "Add")}
              onPress={() => navigation.navigate("addIdentifier", {})}
              icon={
                <FontAwesomeIcon
                  icon={faPlus}
                  color={colors.text.link}
                  size={iconSizes.md}
                />
              }
              type="clear"
              titleStyle={[
                primaryLinkButtonStyle.title,
                { fontSize: fontSizes.md }
              ]}></Button>
          )
        }}></IdentifiersStack.Screen>
      <IdentifiersStack.Screen
        name="addIdentifier"
        component={AddIdentifierScreen}
        options={{
          title: t("AddIdentifierScreenTitle", "Add email")
        }}></IdentifiersStack.Screen>
      <IdentifiersStack.Screen
        name="identifierDetail"
        component={IdentifierDetailScreen}
        options={{
          title: t("IdentifierDetailsScreenTitle", "Identifier")
        }}></IdentifiersStack.Screen>
    </IdentifiersStack.Navigator>
  );
}

function CreditCardsStackScreen() {
  const { t } = useTranslation();

  return (
    <CreditCardsStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <CreditCardsStack.Screen
        name="creditCards"
        component={CreditCardsScreen}
        options={{
          title: t("CreditCardsScreenTitle", "Credit cards")
        }}></CreditCardsStack.Screen>
      <CreditCardsStack.Screen
        name="creditCard"
        component={CreditCardScreen}
        options={{
          title: t("CreditCardScreenTitle", "Credit card")
        }}></CreditCardsStack.Screen>
    </CreditCardsStack.Navigator>
  );
}

function BeneficiariesStackScreen() {
  const { t } = useTranslation();

  return (
    <BeneficiariesStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <BeneficiariesStack.Screen
        name="beneficiaries"
        component={BeneficiariesScreen}
        options={{
          title: t("BeneficiariesListScreenTitle", "Beneficiaries")
        }}></BeneficiariesStack.Screen>
      <BeneficiariesStack.Screen
        name="beneficiary"
        component={BeneficiaryScreen}
        options={{
          title: t("BeneficiaryScreenTitle", "Beneficiary")
        }}></BeneficiariesStack.Screen>
      <BeneficiariesStack.Screen
        name="selectAffiliationStack"
        component={BeneficiarySelectAffiliationStackScreen}
        options={{
          headerShown: false
        }}></BeneficiariesStack.Screen>
    </BeneficiariesStack.Navigator>
  );
}

function BeneficiarySelectAffiliationStackScreen() {
  const { t } = useTranslation();

  return (
    <BeneficiarySelectAffiliationStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <BeneficiarySelectAffiliationStack.Screen
        name="selectAffiliationBeneficiary"
        component={SelectAffiliationBeneficiaryScreen}
        options={{
          title: t("SelectAffiliationBeneficiaryScreenTitle", "Add beneficiary")
        }}></BeneficiarySelectAffiliationStack.Screen>
      <BeneficiarySelectAffiliationStack.Screen
        name="addBeneficiary"
        component={BeneficiaryScreen}
        options={{
          title: t("AddBeneficiaryScreenTitle", "New beneficiary")
        }}></BeneficiarySelectAffiliationStack.Screen>
    </BeneficiarySelectAffiliationStack.Navigator>
  );
}

function SecurityStackScreen() {
  const { t } = useTranslation();

  return (
    <SecurityStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <SecurityStack.Screen
        name="securitySettings"
        component={SecuritySettingsScreen}
        options={{
          title: t("SecuritySettingsScreenTitle", "Security")
        }}></SecurityStack.Screen>
      <SecurityStack.Screen
        name="changePassword"
        component={ChangePasswordScreen}
        options={{
          title: t("ChangePasswordScreenTitle", "Change password")
        }}
      />
    </SecurityStack.Navigator>
  );
}

function ContactSelectAffiliationStackScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<NavigationProp<ContactSelectAffiliationStackParamsList>>();

  return (
    <ContactSelectAffiliationStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <ContactSelectAffiliationStack.Screen
        name="selectAccountContact"
        component={SelectAccountContactScreen}
        options={{
          title: t("SelectAccountContactScreenTitle", "Select account"),
          headerRight: () => (
            <Button
              title={t("SelectAccountContactScreenSkipButton", "Skip")}
              onPress={() => navigation.navigate("contact")}
              type="clear"
              titleStyle={[
                primaryLinkButtonStyle.title,
                { fontSize: fontSizes.md }
              ]}></Button>
          )
        }}
      />
      <ContactSelectAffiliationStack.Screen
        name="contact"
        component={ContactAffiliationScreen}
        options={{ title: t("ContactAffiliationScreenTitle", "Contact") }}
      />
    </ContactSelectAffiliationStack.Navigator>
  );
}

function HelpCenterStackScreen() {
  const { t } = useTranslation();

  return (
    <HelpCenterProfileStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <HelpCenterProfileStack.Screen
        name="helpCenterProfile"
        component={HelpCenterScreen}
        options={{
          title: t("HelpCenterScreenTitle", "Help Center")
        }}></HelpCenterProfileStack.Screen>
      <HelpCenterProfileStack.Screen
        name="help"
        component={HelpSectionScreen}
        options={{
          title: t("HelpSectionScreenTitle", "Help & Support")
        }}
      />
      <HelpCenterProfileStack.Screen
        name="contactSelectAffiliation"
        component={ContactSelectAffiliationStackScreen}
        options={{
          headerShown: false
        }}
      />
    </HelpCenterProfileStack.Navigator>
  );
}
