import axios, { AxiosInstance, AxiosResponseTransformer } from "axios";
import i18n from "../../providers/i18n";
import { jsonReviver } from "../ParserService";
import { BeneficiaryType, IdentifierType } from "./PlatformClient";

export function convertBeneficiaryTypeToIdentifierType(
  type: BeneficiaryType
): IdentifierType {
  switch (type) {
    case BeneficiaryType.Email:
      return IdentifierType.Email;
    default:
      throw new Error("Unsupported beneficiary type.");
  }
}

export function createAxios(): AxiosInstance {
  const axiosInstance = axios.create({
    transformResponse: [
      function transformResponse(data, headers) {
        const isJson =
          data &&
          typeof data === "string" &&
          headers?.["content-type"]?.includes("json");

        if (!isJson) {
          return data;
        }

        try {
          return JSON.parse(data, jsonReviver);
        } catch (error) {
          return data;
        }
      },
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[])
    ]
  });
  useAcceptLanguage(axiosInstance);
  return axiosInstance;
}

export function useAcceptLanguage(axiosInstance: AxiosInstance): void {
  i18n.on("languageChanged", () => {
    updateAcceptLanguage(axiosInstance);
  });

  updateAcceptLanguage(axiosInstance);
}

function updateAcceptLanguage(axiosInstance: AxiosInstance): void {
  const language = i18n.language ?? "en-US";
  axiosInstance.defaults.headers.common[
    "Accept-Language"
  ] = `${language}, ${language.slice(0, 2)}`;
}
