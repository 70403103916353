import { StyleSheet } from "react-native";
import { spacings } from "./Constants";

export const containerMaxWidth = 640;

export const containerStyle = StyleSheet.create({
  container: {
    padding: spacings.lg,
    alignSelf: "center",
    maxWidth: containerMaxWidth,
    width: "100%"
  },
  containerCenter: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    paddingHorizontal: spacings.lg
  },
  containerStretch: {
    paddingTop: spacings.xl,
    paddingHorizontal: spacings.lg,
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column"
  }
});
