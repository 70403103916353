import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { CompositeNavigationProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { Button, ListItem, Text } from "react-native-elements";
import AffiliationListItemComponent from "../components/AffiliationListItemComponent";
import HyperlinkedTextComponent from "../components/HyperlinkedTextComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import {
  EnrollIdentifiersStackParamsList,
  RootStackParamsList
} from "../navigation";
import { useAffiliationById } from "../services/AffiliationsService";
import { useIdentifiers } from "../services/IdentifiersService";
import {
  AffiliationStatus,
  IIdentifier,
  IdentifierStatus
} from "../services/clients/PlatformClient";
import { largePrimaryRoundedButtonStyle } from "../styles/Buttons";
import { iconSizes, listItemHeights } from "../styles/Constants";
import {
  colors,
  containerStyle,
  cornerRadius,
  spacings,
  typographies
} from "../styles/Styles";
import { IdentifierItem } from "./IdentifiersScreen";

export default function IdentifierNotFoundScreen() {
  const { t } = useTranslation();
  const identifiersQuery = useIdentifiers();
  const route =
    useRoute<
      RouteProp<EnrollIdentifiersStackParamsList, "identifierNotFound">
    >();

  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<
          EnrollIdentifiersStackParamsList,
          "identifierNotFound"
        >,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const affiliationQuery = useAffiliationById(route.params?.affiliationId!, {
    enabled: !!route.params?.affiliationId
  });

  if (identifiersQuery.isInitialLoading || affiliationQuery.isInitialLoading) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return (
    <ScrollViewComponent>
      {affiliationQuery.data && (
        <View
          style={{
            borderRadius: cornerRadius.md,
            overflow: "hidden",
            marginBottom: spacings.lg
          }}>
          <AffiliationListItemComponent
            affiliation={affiliationQuery.data}
            disabled={
              affiliationQuery.data.status === AffiliationStatus.Offline
            }
          />
        </View>
      )}

      <ErrorInformationList
        affiliationId={route.params?.affiliationId}
        identifiers={identifiersQuery.data ?? []}
      />

      <Text
        style={[
          typographies.title,
          { marginTop: spacings.md, marginBottom: spacings.sm }
        ]}>
        {t(
          "IdentifierNotFoundScreenRegisteredEmailsTitle",
          "Registered emails"
        )}
      </Text>

      <IdentifiersList identifiers={identifiersQuery.data ?? []} />

      <Text
        style={[
          typographies.title,
          { marginTop: spacings.xl, marginBottom: spacings.sm }
        ]}>
        {t("IdentifierNotFoundScreenRecommendationsTitle", "Recommendations")}
      </Text>

      <RecommendationsInfo identifiers={identifiersQuery.data ?? []} />

      <Button
        title={t("IdentifierNotFoundScreenAddEmailButton", "Add a new email")}
        onPress={() => navigation.navigate("addIdentifier", {})}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.xl
          }
        ]}
      />

      <HyperlinkedTextComponent
        containerStyle={{
          marginTop: spacings.xl,
          marginRight: spacings.lg,
          marginLeft: spacings.lg
        }}
        firstText={t(
          "IdentifierNotFoundScreenStillIssuesText",
          "If you still encounter issues, you may find solutions in our"
        )}
        firstHyperlinkText={t(
          "IdentifierNotFoundScreenHelpCenterText",
          "help center"
        )}
        onFirstHyperlinkCallback={() =>
          navigation.navigate("root", {
            screen: "profileStack",
            params: {
              screen: "helpCenterProfileStack",
              initial: false,
              params: {
                screen: "helpCenterProfile"
              }
            }
          })
        }
      />
    </ScrollViewComponent>
  );
}

function ErrorInformationList({
  affiliationId,
  identifiers
}: {
  affiliationId?: number;
  identifiers: IIdentifier[];
}) {
  const { t } = useTranslation();
  const emailNoValidated = useMemo(
    () => identifiers.some((i) => i.status === IdentifierStatus.NotValidated),
    [identifiers]
  );

  return (
    <View
      style={{
        borderWidth: cornerRadius.none,
        borderRadius: cornerRadius.md,
        overflow: "hidden",
        marginBottom: spacings.md
      }}>
      <ListItem
        style={{
          marginBottom: spacings.xxs
        }}
        containerStyle={{
          minHeight: listItemHeights.md,
          flexDirection: "row",
          alignItems: "center"
        }}>
        <Text style={[typographies.small, { flex: 1 }]}>
          {!!affiliationId
            ? t(
                "IdentifierNotFoundScreenNoAssociatedEmail",
                "We couldn't find a registered email address associated to this affiliation in our system."
              )
            : t(
                "IdentifierNotFoundScreenNoAssociatedEmailNotListed",
                "We couldn't find a registered email address associated to the affiliation your a looking for in our system."
              )}
        </Text>

        <FontAwesomeIcon
          style={{
            color: colors.warning
          }}
          icon={faExclamationCircle}
          size={iconSizes.lg}></FontAwesomeIcon>
      </ListItem>

      {emailNoValidated && (
        <ListItem
          style={{
            marginBottom: spacings.xxs
          }}
          containerStyle={{
            minHeight: listItemHeights.md,
            flexDirection: "row",
            alignItems: "center"
          }}>
          <Text style={[typographies.small, { flex: 1 }]}>
            {t(
              "IdentifierNotFoundScreenAwaitingEmailValidation",
              "Some registered emails are awaiting validation."
            )}
          </Text>

          <FontAwesomeIcon
            style={{
              color: colors.warning
            }}
            icon={faExclamationCircle}
            size={iconSizes.lg}></FontAwesomeIcon>
        </ListItem>
      )}
    </View>
  );
}

function IdentifiersList({ identifiers }: { identifiers: IIdentifier[] }) {
  return (
    <View
      style={{
        borderWidth: cornerRadius.none,
        borderRadius: cornerRadius.md,
        overflow: "hidden"
      }}>
      {identifiers.map((item, i) => (
        <IdentifierItem
          identifier={item}
          key={i}
          isPrimary={false}
          showStatusIcon={item.status !== IdentifierStatus.Validated}
        />
      ))}
    </View>
  );
}

function RecommendationsInfo({ identifiers }: { identifiers: IIdentifier[] }) {
  const { t } = useTranslation();
  const emailNoValidated = useMemo(
    () => identifiers.some((i) => i.status === IdentifierStatus.NotValidated),
    [identifiers]
  );

  return (
    <View
      style={{
        borderWidth: cornerRadius.none,
        borderRadius: cornerRadius.md,
        overflow: "hidden"
      }}>
      <ListItem
        style={{
          marginBottom: spacings.xxs,
          borderWidth: spacings.none
        }}
        containerStyle={{
          minHeight: listItemHeights.md,
          flexDirection: "row",
          alignItems: "center"
        }}>
        <Text style={[typographies.small, { flexShrink: 1 }]}>
          {emailNoValidated
            ? t(
                "IdentifierNotFoundScreenRecommendationsEmailNotValidated",
                "Please try to register a new email address, either personal or profesional, that is associated with this affiliation and validate pending mails."
              )
            : t(
                "IdentifierNotFoundScreenRecommendations",
                "Please try to register a new email address, either personal or profesional, that is associated with this affiliation."
              )}
        </Text>
      </ListItem>
    </View>
  );
}
