import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { faExchange } from "@fortawesome/pro-light-svg-icons/faExchange";
import { faHome } from "@fortawesome/pro-light-svg-icons/faHome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons/faUserCircle";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigatorScreenParams } from "@react-navigation/native";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import {
  colors,
  cornerRadius,
  fontFamilies,
  fontSizes,
  iconSizes,
  spacings,
  tabBarStyle
} from "../styles/Styles";
import HomeStackScreen, { HomeStackParamsList } from "./HomeStackNavigation";
import LoadStackScreen, { LoadStackParamsList } from "./LoadStackNavigation";
import ProfileStackScreen, {
  ProfileStackParamsList
} from "./ProfileStackNavigation";
import SendStackScreen, { SendStackParamsList } from "./SendStackNavigation";

export type BottomTabNavigatorParamsList = {
  homeStack: NavigatorScreenParams<HomeStackParamsList>;
  loadStack: NavigatorScreenParams<LoadStackParamsList>;
  sendStack: NavigatorScreenParams<SendStackParamsList>;
  profileStack: NavigatorScreenParams<ProfileStackParamsList>;
};
const BottomTab = createBottomTabNavigator<BottomTabNavigatorParamsList>();
export default function BottomTabNavigator() {
  const { t } = useTranslation();

  return (
    <BottomTab.Navigator
      initialRouteName="homeStack"
      screenOptions={{
        headerShown: false,
        tabBarStyle: tabBarStyle.container,
        tabBarHideOnKeyboard: Platform.OS === "ios" ? false : true,
        tabBarActiveTintColor: colors.primary,
        tabBarInactiveTintColor: colors.primary30,
        tabBarLabelPosition: "below-icon",
        tabBarLabelStyle: {
          fontFamily: fontFamilies.default,
          fontSize: fontSizes.xs
        },
        tabBarItemStyle: {
          paddingTop: spacings.xxs,
          paddingBottom: spacings.xs
        }
      }}>
      <BottomTab.Screen
        name="homeStack"
        component={HomeStackScreen}
        options={{
          title: t("MenuHomeTitle", "Home"),
          tabBarIcon: ({ color, focused }) => (
            <TabBarIcon color={color} focused={focused} icon={faHome} />
          )
        }}
      />
      <BottomTab.Screen
        name="loadStack"
        component={LoadStackScreen}
        options={{
          title: t("MenuLoadTitle", "Load"),
          tabBarIcon: ({ color, focused }) => (
            <TabBarIcon color={color} focused={focused} icon={faCreditCard} />
          ),
          unmountOnBlur: true
        }}
      />
      <BottomTab.Screen
        name="sendStack"
        component={SendStackScreen}
        options={{
          title: t("MenuSendTitle", "Send"),
          tabBarIcon: ({ color, focused }) => (
            <TabBarIcon color={color} focused={focused} icon={faExchange} />
          ),
          unmountOnBlur: true
        }}
      />
      <BottomTab.Screen
        name="profileStack"
        component={ProfileStackScreen}
        options={{
          title: t("MenuProfileTitle", "Profile"),
          tabBarIcon: ({ color, focused }) => (
            <TabBarIcon color={color} focused={focused} icon={faUserCircle} />
          ),
          unmountOnBlur: true
        }}
      />
    </BottomTab.Navigator>
  );
}

function TabBarIcon({
  focused,
  color,
  icon
}: {
  focused: boolean;
  color: string;
  icon: IconDefinition;
}) {
  return (
    <>
      <View
        style={{
          width: 30,
          height: spacings.xs,
          borderRadius: cornerRadius.xs,
          backgroundColor: focused ? color : colors.background.transparent,
          marginBottom: 2
        }}></View>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        size={iconSizes.lg}></FontAwesomeIcon>
    </>
  );
}
