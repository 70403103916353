import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import Constants from "expo-constants";
import { logSignUp } from "./AnalyticsService";
import { TokenResponse, UserInfoResponse } from "./clients/IdentityClient";
import { identityClientFactory } from "./clients/IdentityClientFactory";
import {
  IRegisterInfo,
  IResetPassword,
  RecoverPassword,
  RegisterInfo,
  ResetPassword
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";
import { userInfoQueryKey } from "./QueryService";

export * from "./clients/IdentityClient";

async function clientCredentials(): Promise<TokenResponse> {
  const identityClient = identityClientFactory.create();
  const response = await identityClient.RequestClientCredentials(
    Constants.expoConfig?.extra?.identityAppScopes
  );

  return response;
}

async function registerAsync(
  registerInfo: IRegisterInfo,
  recaptchaToken?: string
): Promise<void> {
  const platformClient = platformClientFactory.create();
  await platformClient.register(
    new RegisterInfo(registerInfo),
    null,
    recaptchaToken
  );
  await logSignUp();
}

async function recoverPasswordAsync(
  email: string,
  recaptchaToken?: string
): Promise<void> {
  const platformClient = platformClientFactory.create();
  const recoverPassword = new RecoverPassword({
    email: email,
    callbackUrl: Constants.expoConfig?.extra?.resetPasswordCallback
  });
  await platformClient.recoverPassword(recoverPassword, recaptchaToken);
}

async function resetPasswordAsync(resetPasswordModel: IResetPassword) {
  const platformClient = platformClientFactory.create();
  await platformClient.resetPassword(new ResetPassword(resetPasswordModel));
}

async function requestUserInfoAsync(
  accessToken: string
): Promise<UserInfoResponse> {
  const identityClient = identityClientFactory.create();
  const response = await identityClient.RequestUserInfoAsync(accessToken);

  return response;
}

export async function loginAsync(
  email: string,
  password: string
): Promise<TokenResponse> {
  const identityClient = identityClientFactory.create();
  const response = await identityClient.RequestResourceOwnerPasswordAsync(
    email,
    password,
    Constants.expoConfig?.extra?.identityUserScopes
  );

  return response;
}

export async function logoutAsync(token: string): Promise<void> {
  const identityClient = identityClientFactory.create();
  await identityClient.RequestTokenRevocationAsync(token);
}

export async function refreshTokenAsync(
  refreshToken: string
): Promise<TokenResponse> {
  const identityClient = identityClientFactory.create();
  const response = await identityClient.RequestRefreshTokenAsync(refreshToken);

  return response;
}

export function useClientCredentials() {
  return useMutation(clientCredentials);
}

export function useRegisterAsync() {
  return useMutation(
    ({
      registerInfo,
      recaptchaToken
    }: {
      registerInfo: IRegisterInfo;
      recaptchaToken?: string;
    }) => registerAsync(registerInfo, recaptchaToken)
  );
}

export function useRecoverPassword() {
  return useMutation(
    ({ email, recaptchaToken }: { email: string; recaptchaToken?: string }) =>
      recoverPasswordAsync(email, recaptchaToken)
  );
}

export function useResetPassword() {
  return useMutation((resetPasswordModel: IResetPassword) =>
    resetPasswordAsync(resetPasswordModel)
  );
}

export function useUserInfo(
  accessToken: string,
  options?: UseQueryOptions<
    UserInfoResponse,
    unknown,
    UserInfoResponse,
    string[]
  >
) {
  return useQuery([userInfoQueryKey], () => requestUserInfoAsync(accessToken), {
    ...options
  });
}
