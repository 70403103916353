import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { default as React, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { Button, Text } from "react-native-elements";
import AffiliationListItemComponent from "../components/AffiliationListItemComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import { ActivationSelectAffiliationStackParamsList } from "../navigation";
import { BottomTabNavigatorParamsList } from "../navigation/BottomTabMenuNavigation";
import { DrawerNavigatorParamsList } from "../navigation/SidebarMenuNavigation";
import {
  useAllAffiliations,
  useDependentAffiliations
} from "../services/AffiliationsService";
import { AffiliationStatus } from "../services/clients/PlatformClient";
import { largeActionRoundedButtonStyle } from "../styles/Buttons";
import { containerStyle } from "../styles/Containers";
import { colors, cornerRadius, spacings, typographies } from "../styles/Styles";

export default function SelectAffiliationActivationScreen() {
  const { t } = useTranslation();
  const route =
    useRoute<
      RouteProp<
        ActivationSelectAffiliationStackParamsList,
        "selectAffiliationActivation"
      >
    >();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<
          ActivationSelectAffiliationStackParamsList,
          "selectAffiliationActivation"
        >,
        StackNavigationProp<
          BottomTabNavigatorParamsList | DrawerNavigatorParamsList
        >
      >
    >();
  const homeNavigation = useResetHomeNavigation();

  const allAffiliationsQuery = useAllAffiliations();
  const dependentAffiliationsQuery = useDependentAffiliations(
    route.params.affiliationId
  );

  const affiliations = useMemo(() => {
    const dependentAffiliations =
      dependentAffiliationsQuery.data?.filter(
        (a) => a.configuration.selfActivationAllowed
      ) ?? [];

    const allAffiliations =
      allAffiliationsQuery.data?.filter(
        (a) =>
          a.affiliationId === route.params.affiliationId &&
          a.configuration.selfActivationAllowed
      ) ?? [];

    return [...dependentAffiliations, ...allAffiliations];
  }, [dependentAffiliationsQuery.data, allAffiliationsQuery.data]);

  useEffect(() => {
    if (!route.params.skipVisible) {
      navigation.setOptions({ headerRight: undefined });
    }
  }, []);

  useEffect(() => {
    if (affiliations.length === 1) {
      navigation.replace("activationCode", {
        identifierId: route.params.identifierId,
        affiliationId: route.params.affiliationId,
        personId: route.params.personId,
        skipVisible: route.params.skipVisible
      });
    }
  }, [affiliations.length]);

  if (
    allAffiliationsQuery.isInitialLoading ||
    dependentAffiliationsQuery.isInitialLoading
  ) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          size="large"
          color={colors.primary}></ActivityIndicator>
      </View>
    );
  }

  if (!affiliations.length) {
    if (route.params.skipVisible) {
      homeNavigation.reset();
      return null;
    }

    return (
      <EmptyContentComponent
        message={t(
          "SelectAffiliationActivationScreenEmptyList",
          "There are no affiliations to activate."
        )}></EmptyContentComponent>
    );
  }

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            marginHorizontal: spacings.xxl,
            textAlign: "center",
            marginBottom: spacings.md
          }
        ]}>
        {t(
          "SelectAffiliationActivationScreenHeader",
          "Please select the affiliation where to activate your account"
        )}
      </Text>

      <View
        style={{
          marginTop: spacings.md,
          marginBottom: spacings.xl,
          overflow: "hidden",
          borderRadius: cornerRadius.md,
          backgroundColor: colors.background.light
        }}>
        {affiliations.map((item, index) => {
          return (
            <View
              key={index}
              style={{
                overflow: "hidden",
                backgroundColor: colors.background.light,
                ...(index !== 0 && {
                  borderTopColor: colors.background.neutral,
                  borderTopWidth: cornerRadius.xxs
                })
              }}>
              <AffiliationListItemComponent
                affiliation={item}
                onPress={() => {
                  navigation.navigate("activationCode", {
                    identifierId: route.params.identifierId,
                    affiliationId: item.affiliationId,
                    personId: route.params.personId,
                    skipVisible: route.params.skipVisible
                  });
                }}
                disabled={item.status === AffiliationStatus.Offline}
              />
            </View>
          );
        })}
      </View>

      {route.params.skipVisible && (
        <Button
          buttonStyle={largeActionRoundedButtonStyle.button}
          containerStyle={{ borderRadius: cornerRadius.md }}
          titleStyle={largeActionRoundedButtonStyle.title}
          title={t(
            "SelectAffiliationActivationScreenEnrollAsGuest",
            "Enroll as guest"
          )}
          onPress={() => {
            homeNavigation.reset();
          }}></Button>
      )}
    </ScrollViewComponent>
  );
}
