import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { logDeleteAccount, setUserProfile } from "./AnalyticsService";
import {
  invalidateProfile,
  profileQueryKey,
  queryClient
} from "./QueryService";
import {
  ChangePassword,
  IChangePassword,
  IProfileInfo,
  ProfileInfo
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

export const profileStaleTime: number = Infinity;

export function useProfile(
  options?: UseQueryOptions<IProfileInfo, unknown, IProfileInfo, string[]>
) {
  return useQuery([profileQueryKey], getProfile, {
    ...options,
    staleTime: profileStaleTime
  });
}

export async function fetchProfile(): Promise<IProfileInfo> {
  const profile = await queryClient.fetchQuery<IProfileInfo>(
    [profileQueryKey],
    getProfile,
    {
      staleTime: profileStaleTime
    }
  );
  return profile;
}

export function useDeleteProfile() {
  return useMutation(deleteProfile);
}

async function deleteProfile(): Promise<void> {
  const platformClient = platformClientFactory.create();
  await platformClient.deleteProfile();
  await logDeleteAccount();
}

async function getProfile(): Promise<IProfileInfo> {
  const platformClient = platformClientFactory.create();

  const profileInfo = await platformClient.getProfile();
  await setUserProfile(profileInfo);
  return profileInfo;
}

async function updateProfile(profileInfo: IProfileInfo): Promise<void> {
  const platformClient = platformClientFactory.create();

  profileInfo.firstName = profileInfo.firstName?.trim();
  profileInfo.lastName = profileInfo.lastName?.trim();
  profileInfo.languageCode = profileInfo.languageCode?.trim() || undefined;
  profileInfo.address = profileInfo.address?.trim() || undefined;
  profileInfo.city = profileInfo.city?.trim() || undefined;
  profileInfo.postalCode = profileInfo.postalCode?.trim() || undefined;
  profileInfo.countryCode = profileInfo.countryCode?.trim() || undefined;
  profileInfo.birthdate = profileInfo.birthdate;
  profileInfo.phoneNumber = profileInfo.phoneNumber?.trim() || undefined;

  const profileData = new ProfileInfo(profileInfo);

  await platformClient.updateProfile(profileData);

  await invalidateProfile({
    refetchType: "all"
  });
}

export function useUpdateProfile() {
  return useMutation((profileInfo: IProfileInfo) => updateProfile(profileInfo));
}

async function changePassword(
  changePasswordModel: IChangePassword
): Promise<void> {
  const platformClient = platformClientFactory.create();
  const changePassword = new ChangePassword(changePasswordModel);

  await platformClient.changePassword(changePassword);
}

export function useChangePassword() {
  return useMutation(
    async ({ changePasswordModel }: { changePasswordModel: IChangePassword }) =>
      await changePassword(changePasswordModel)
  );
}
