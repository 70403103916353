import { Image } from "expo-image";
import { default as React } from "react";
import { useAffiliationLogo } from "../services/AffiliationsService";
import { LogoSkeleton } from "./LoadingSkeletonComponent";

export default function AffiliationLogoComponent({
  affiliationId,
  logoHeight = 70,
  logoWidth = 100
}: {
  affiliationId: number;
  logoHeight?: number;
  logoWidth?: number;
}) {
  const logoQuery = useAffiliationLogo(affiliationId);

  if (logoQuery.isInitialLoading) {
    return <LogoSkeleton height={logoHeight} width={logoWidth} />;
  }

  if (!logoQuery.data) {
    return null;
  }

  return (
    <Image
      style={{
        height: logoHeight,
        width: logoWidth,
        maxWidth: logoWidth
      }}
      transition={1000}
      contentFit="contain"
      source={{
        uri: logoQuery.data
      }}
    />
  );
}
