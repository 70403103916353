import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "react-native-elements";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { useAuth } from "../providers/AuthenticationProvider";
import { useDeleteProfile } from "../services/ProfileService";
import { toastError, toastSuccess } from "../services/ToastService";
import { colors } from "../styles/Colors";
import {
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function ConfirmDeleteUserScreen() {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const deleteProfileMutation = useDeleteProfile();

  async function deleteUser() {
    try {
      await deleteProfileMutation.mutateAsync();

      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t("ProfileScreenUserDeletedSuccess", "User successfully deleted.")
      );
      await signOut();
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "ProfileScreenUserDeletedError",
          "An error occurred during the user deletion."
        )
      );
    }
  }

  return (
    <ScrollViewComponent
      scrollViewContentContainerStyle={{
        marginTop: spacings.xxl,
        alignItems: "center"
      }}>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        size={128}
        color={colors.text.primary}
      />

      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.lg,
            textAlign: "center"
          }
        ]}>
        {t(
          "ConfirmDeleteUserScreenHeader",
          "This operation can not be reverted\n\n Are you sure you want to delete your user account and all related data?"
        )}
      </Text>

      <Button
        title={t("ConfirmDeleteUserScreenDeleteButton", "Delete user")}
        disabled={deleteProfileMutation.isLoading}
        onPress={deleteUser}
        loading={deleteProfileMutation.isLoading}
        buttonStyle={[
          largePrimaryRoundedButtonStyle.button,
          { backgroundColor: colors.danger }
        ]}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.xl
          }
        ]}
      />
    </ScrollViewComponent>
  );
}
