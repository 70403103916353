import { DeviceType, getDeviceTypeAsync } from "expo-device";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

export default function useHasCameraFeature(): boolean {
  const [device, setDevice] = useState(DeviceType.UNKNOWN);

  useEffect(() => {
    async function initDeviceType() {
      setDevice(await getDeviceTypeAsync());
    }

    initDeviceType();
  }, []);

  if (device === DeviceType.PHONE && Platform.OS !== "web") return true;
  if (__DEV__) return true;

  return false;
}
