import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import Constants from "expo-constants";
import { t } from "i18next";
import React from "react";
import { Platform } from "react-native";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import { RootStackParamsList } from "../navigation";
import { LoadAmountSelectionScreenProps } from "../navigation/LoadStackNavigation";
import { PaymentMode } from "../services/AffiliationsService";
import { humanizeCurrency } from "../services/HumanizerService";

export default function LoadAmountResultScreen() {
  const navigation =
    useNavigation<
      StackNavigationProp<RootStackParamsList, "creditCardPayment">
    >();
  const homeNavigation = useResetHomeNavigation();
  const route = useRoute<RouteProp<RootStackParamsList, "loadAmountResult">>();
  let title = "";
  let description = "";
  let buttonTitle = "";
  let buttonCallback = () => {};
  let buttonRetryCallback = undefined;

  function redirectToLoadAmountSelection() {
    if (Platform.OS !== "web") {
      navigation.goBack();
      return;
    }

    const base =
      window.location.href ?? Constants.expoConfig?.extra?.appAddress;
    const params: LoadAmountSelectionScreenProps = {
      identifierId: route.params.identifierId!,
      affiliationId: route.params.affiliationId!,
      personId: route.params!.personId!,
      accountId: route.params!.accountId!
    };
    window.location.replace(
      new URL(
        `/load/accounts/${params.accountId}?identifierId=${params.identifierId}&affiliationId=${params.affiliationId}&personId=${params.personId}`,
        base
      ).href
    );
  }

  switch (route.params.type) {
    case ResultType.Success: {
      title = humanizeCurrency(route.params.amount!, route.params.currency!);
      description = t(
        "CreditCardPaymentScreenTransactionSuccess",
        "Wallet successfully loaded"
      );
      buttonTitle = t("HomeScreenGoHomeTitle", "Go to Home");
      buttonCallback = () => homeNavigation.reset();
      break;
    }
    case ResultType.Info: {
      title = t("CreditCardPaymentScreenTransactionCancelTitle", "Cancelled");
      description = t(
        "CreditCardPaymentScreenTransactionCancelled",
        "Operation Cancelled"
      );
      buttonTitle = t("LoadAccountScreenGoBack", "Back to load");
      buttonCallback = () => redirectToLoadAmountSelection();
      break;
    }
    default: {
      title = t("CreditCardPaymentScreenTransactionErrorTitle", "Oops...");
      description = getPaymentModeDescription();
      buttonTitle = t("HomeScreenGoHomeTitle", "Go to Home");
      buttonCallback = () => homeNavigation.reset();
      buttonRetryCallback = () => redirectToLoadAmountSelection();
      break;
    }
  }

  function getPaymentModeDescription() {
    if (route.params.paymentMode == PaymentMode.Twint) {
      return t(
        "CreditCardPaymentScreenTransactionErrorTwint",
        "Something went wrong. Please check your Twint app"
      );
    } else if (route.params.paymentMode == PaymentMode.PaymentSlip) {
      return t(
        "CreditCardPaymentScreenTransactionErrorPaymentSlip",
        "Something went wrong. Please try with another QR-Bill"
      );
    }
    return t(
      "CreditCardPaymentScreenTransactionError",
      "Something went wrong. Please try with a different card"
    );
  }

  return (
    <ActionResultComponent
      title={title}
      description={description}
      resultType={route.params.type}
      buttonTitle={buttonTitle}
      buttonCallback={buttonCallback}
      retryCallback={buttonRetryCallback}
    />
  );
}
