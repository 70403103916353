import { ColorValue, StyleProp, TextStyle } from "react-native";
import { Text } from "react-native-elements";
import { FontAwesomeCustomKitIcon } from "../assets/fonts/FontAwesomeCustomKitIcons";

export default function FontAwesomeCustomKitIconComponent({
  icon,
  color,
  size,
  style
}: {
  icon: FontAwesomeCustomKitIcon;
  color?: ColorValue;
  size?: number;
  style?: StyleProp<TextStyle>;
}) {
  return (
    <Text
      style={[
        {
          fontFamily: "FontAwesome-CustomKit",
          fontSize: size,
          color: color
        },
        style
      ]}>
      {icon}
    </Text>
  );
}
