import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import { RootStackParamsList } from "../navigation";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";

export default function BlockCardResultScreen() {
  const route = useRoute<RouteProp<RootStackParamsList, "blockCardResult">>();
  const navigation = useNavigation<NavigationProp<HomeStackParamsList>>();
  const { t } = useTranslation();
  const isSuccess = route.params.type === ResultType.Success;

  return (
    <ActionResultComponent
      title={
        isSuccess
          ? t("BlockCardResultScreenSuccessTitle", "Card blocked")
          : t("BlockCardResultScreenErrorTitle", "Oops...")
      }
      description={route.params.description}
      resultType={route.params.type}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
      buttonTitle={
        !route.params.accountId
          ? t("BlockCardResultScreenButtonBackTitle", "Go back")
          : t("BlockCardResultScreenButtonAccountTitle", "Go to account")
      }
      buttonCallback={
        !route.params.accountId
          ? () =>
              navigation.navigate("accountDetails", {
                identifierId: route.params.identifierId!,
                affiliationId: route.params.affiliationId!,
                personId: route.params.personId!,
                accountId: route.params.accountId!
              })
          : () => navigation.goBack()
      }></ActionResultComponent>
  );
}
