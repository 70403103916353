import React, { JSXElementConstructor, ReactElement } from "react";
import { RefreshControlProps, StyleProp, ViewStyle } from "react-native";
import { Text } from "react-native-elements";
import { containerStyle } from "../styles/Containers";
import { typographies } from "../styles/Fonts";
import ScrollViewComponent from "./ScrollViewComponent";

export function EmptyContentComponent({
  message,
  style,
  refreshControl
}: {
  message: string;
  style?: StyleProp<ViewStyle>;
  refreshControl?:
    | ReactElement<RefreshControlProps, string | JSXElementConstructor<any>>
    | undefined;
}) {
  return (
    <ScrollViewComponent
      refreshControl={refreshControl}
      scrollViewContentContainerStyle={[containerStyle.containerCenter, style]}>
      <Text style={typographies.body}>{message}</Text>
    </ScrollViewComponent>
  );
}
