import { NavigationProp, useNavigation } from "@react-navigation/native";
import { first, groupBy, map, orderBy, uniqBy } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, SectionList, View } from "react-native";
import { Text } from "react-native-elements";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { CreditCardsListSkeleton } from "../components/LoadingSkeletonComponent";
import { CreditCardListItemComponent } from "../components/PaymentModesListItemComponent";
import { useAction } from "../hooks/ActionHook";
import {
  CreditCardScreenProps,
  CreditCardsStackParamsList
} from "../navigation/ProfileStackNavigation";
import {
  CreditCardDetails,
  useCreditCards
} from "../services/CreditCardsService";
import { invalidateCreditCards } from "../services/QueryService";
import { virtualizedListItemStyle } from "../styles/Lists";
import {
  colors,
  containerStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function CreditCardsScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<NavigationProp<CreditCardsStackParamsList>>();
  const creditCardsQuery = useCreditCards();
  const refreshAction = useAction(invalidateCreditCards);

  const creditCards = useMemo(() => {
    const groupedCreditCards = groupBy(
      creditCardsQuery.data ?? [],
      (a) => a.accountKey.affiliationId
    );
    const data = map(groupedCreditCards, (value, _) => ({
      title: first(value)?.affiliation.name,
      data: uniqBy(value, (c) => c.creditCard.creditCardId)
    }));

    return orderBy(data, (d) => d.title?.toLowerCase());
  }, [creditCardsQuery.data]);

  function RenderSectionHeader(title: string | undefined) {
    return (
      <View
        style={{
          marginBottom: spacings.sm
        }}>
        <Text style={[typographies.body, { color: colors.text.tertiary }]}>
          {title}
        </Text>
      </View>
    );
  }

  if (creditCardsQuery.isInitialLoading) {
    return <CreditCardsListSkeleton />;
  }

  if (creditCards.length === 0) {
    return (
      <EmptyContentComponent
        message={t(
          "CreditCardsScreenNoCards",
          "You don't have any credit card."
        )}
        refreshControl={
          <RefreshControl
            refreshing={refreshAction.isBusy}
            onRefresh={refreshAction.execute}
          />
        }></EmptyContentComponent>
    );
  }

  return (
    <SectionList
      refreshing={refreshAction.isBusy}
      onRefresh={refreshAction.execute}
      stickySectionHeadersEnabled={false}
      sections={creditCards}
      renderSectionHeader={({ section: { title } }) =>
        RenderSectionHeader(title)
      }
      contentContainerStyle={containerStyle.container}
      renderItem={({ item, index, section }) => (
        <View
          key={index}
          style={[
            virtualizedListItemStyle.baseItem,
            index === 0 ? virtualizedListItemStyle.firstItem : null,
            index === section.data.length - 1
              ? [
                  virtualizedListItemStyle.lastItem,
                  { marginBottom: spacings.lg }
                ]
              : virtualizedListItemStyle.middleItem
          ]}>
          <CreditCardListItemComponent
            item={item.creditCard}
            onPress={() => creditCardSelected(item)}
          />
        </View>
      )}
      keyExtractor={(_, index) => index.toString()}></SectionList>
  );

  function creditCardSelected(creditCard: CreditCardDetails): void {
    if (!creditCard.creditCard?.creditCardId || !creditCard.accountKey) {
      return;
    }

    const params: CreditCardScreenProps = {
      identifierId: creditCard.accountKey.identifierId,
      affiliationId: creditCard.accountKey.affiliationId,
      personId: creditCard.accountKey.personId,
      accountId: creditCard.accountKey.accountId,
      creditCardId: creditCard.creditCard.creditCardId
    };

    navigation.navigate("creditCard", params);
  }
}
