import { useMutation, useQuery } from "@tanstack/react-query";
import Constants from "expo-constants";
import { orderBy } from "lodash";
import {
  identifierQueryKey,
  identifiersQueryKey,
  invalidateAccounts,
  invalidateIdentifiers,
  invalidateUserInfo
} from "./QueryService";
import {
  AddIdentifier,
  IIdentifier,
  IdentifierType,
  ValidateIdentifier
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

export async function getIdentifiers(): Promise<IIdentifier[]> {
  const platformClient = platformClientFactory.create();

  let identifiers = await platformClient.getIdentifiers();

  identifiers = orderBy(
    identifiers,
    [(id) => id.type, (id) => id.status, (id) => id.value],
    ["asc", "desc", "asc"]
  );

  return identifiers;
}

async function getIdentifier(identifierId: number): Promise<IIdentifier> {
  const platformClient = platformClientFactory.create();
  const identifier = await platformClient.getIdentifier(identifierId);

  return identifier;
}

async function validateEmailAsync(
  userId: number,
  identifierId: number,
  token: string
): Promise<void> {
  const platformClient = platformClientFactory.create();
  const validateIdentifier = {
    userId: userId,
    identifierId: identifierId,
    token: token
  } as ValidateIdentifier;

  await platformClient.validateIdentifier(validateIdentifier);

  await Promise.all([
    invalidateIdentifiers({
      refetchType: "all"
    }),
    invalidateAccounts({
      refetchType: "all"
    })
  ]);
}

async function resendValidationEmail(identifier: string): Promise<void> {
  const platformClient = platformClientFactory.create();

  const addIdentifier: AddIdentifier = new AddIdentifier({
    value: identifier,
    type: IdentifierType.Email,
    callbackUrl: Constants.expoConfig?.extra?.validateEmailCallback
  });

  await platformClient.resendIdentifierValidation(addIdentifier);
}

async function addIdentifier(
  identifier: string,
  recaptchaToken?: string
): Promise<IIdentifier> {
  const platformClient = platformClientFactory.create();

  const addIdentifier: AddIdentifier = new AddIdentifier({
    value: identifier,
    type: IdentifierType.Email,
    callbackUrl: Constants.expoConfig?.extra?.validateEmailCallback
  });

  var addedIdentifier = await platformClient.addIdentifier(
    addIdentifier,
    recaptchaToken
  );

  await Promise.all([
    invalidateIdentifiers({
      refetchType: "all"
    }),
    invalidateAccounts({
      refetchType: "all"
    })
  ]);

  return addedIdentifier;
}

async function deleteIdentifier(identifierId: number): Promise<void> {
  const platformClient = platformClientFactory.create();

  await platformClient.deleteIdentifier(identifierId);

  await Promise.all([
    invalidateIdentifiers({
      refetchType: "all"
    }),
    invalidateAccounts({
      refetchType: "all"
    })
  ]);
}

async function definePrimaryIdentifier(identifierId: number): Promise<void> {
  const platformClient = platformClientFactory.create();
  await platformClient.changePrimaryIdentifier(identifierId);

  await invalidateUserInfo({
    refetchType: "all"
  });
}

export function useDefinePrimaryIdentifier() {
  return useMutation((identifierId: number) =>
    definePrimaryIdentifier(identifierId)
  );
}

export function useDeleteIdentifier() {
  return useMutation((identifierId: number) => deleteIdentifier(identifierId));
}

export function useIdentifiers() {
  return useQuery([identifiersQueryKey], getIdentifiers);
}

export function useIdentifier(identifierId: number) {
  return useQuery([identifierQueryKey, identifierId], () =>
    getIdentifier(identifierId)
  );
}

export function useValidateEmail() {
  return useMutation(
    ({
      userId,
      identifierId,
      token
    }: {
      userId: number;
      identifierId: number;
      token: string;
    }) => validateEmailAsync(userId, identifierId, token)
  );
}

export function useResendValidationEmail() {
  return useMutation((identifier: string) => resendValidationEmail(identifier));
}

export function useAddIdentifier() {
  return useMutation(
    ({
      identifier,
      recaptchaToken
    }: {
      identifier: string;
      recaptchaToken?: string;
    }) => addIdentifier(identifier, recaptchaToken)
  );
}
