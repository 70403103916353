import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionResultComponent, {
  ResultType
} from "../components/ActionResultComponent";
import { RootStackParamsList } from "../navigation";
import { ProfileStackParamsList } from "../navigation/ProfileStackNavigation";

export default function ChangePasswordResultScreen() {
  const route =
    useRoute<RouteProp<RootStackParamsList, "changePasswordResult">>();
  const navigation =
    useNavigation<StackNavigationProp<ProfileStackParamsList>>();
  const { t } = useTranslation();
  const isSuccess = route.params.type === ResultType.Success;

  return (
    <ActionResultComponent
      title={
        isSuccess
          ? t("ChangePasswordResultScreenSuccessTitle", "Password changed")
          : t("ChangePasswordResultScreenErrorTitle", "Oops...")
      }
      description={route.params.description}
      resultType={route.params.type}
      retryCallback={!isSuccess ? () => navigation.goBack() : undefined}
      buttonTitle={t("ChangePasswordResultScreenButtonTitle", "Go back")}
      buttonCallback={() =>
        navigation.navigate("profile")
      }></ActionResultComponent>
  );
}
