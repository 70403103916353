import { setDefaultOptions } from "date-fns";
import { de, enGB, enUS, es, fr, frCH } from "date-fns/locale";
import { InitOptions, Module, Services } from "i18next";
import { getLanguage, setLanguage } from "../services/CultureService";
import { forceUpdateQueries } from "./../services/QueryService";

export interface LanguageDetectorAsyncModule extends Module {
  type: "languageDetector";
  /** Set to true to enable async detection */
  async: true;
  init(
    services: Services,
    detectorOptions: object,
    i18nextOptions: InitOptions
  ): void;
  /** Must call callback passing detected language */
  detect(callback: (lng: string | readonly string[] | undefined) => void): void;
  cacheUserLanguage(lng: string): void;
}

export const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: (cb) => {
    return getLanguage().then((lng) => {
      cb(lng ?? undefined);
    });
  },
  init: () => {},
  cacheUserLanguage: async (lng: string): Promise<void> => {
    await setLanguage(lng);
    setDefaultOptions({ locale: langToFnsLocale(lng) });
    forceUpdateQueries();
  }
};

function langToFnsLocale(lng: string): Locale {
  switch (lng) {
    case "de-DE":
      return de;
    case "de-CH":
      return de;
    case "fr-CH":
      return frCH;
    case "fr-FR":
      return fr;
    case "es-ES":
      return es;
    case "en-GB":
      return enGB;
    case "en-US":
      return enUS;
    default:
      return enGB;
  }
}
