import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, SafeAreaView, TextInput, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import validator from "validator";
import { ResultType } from "../components/ActionResultComponent";
import PasswordToggleEyeComponent from "../components/PasswordToggleEyeComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { UserStackParamsList } from "../navigation/UserStackNavigation";
import { useResetPassword } from "../services/AuthenticationService";
import { IResetPassword } from "../services/clients/PlatformClient";
import { containerStyle } from "../styles/Containers";
import {
  colors,
  cornerRadius,
  largeInputStyle,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function ResetPasswordScreen() {
  const { t } = useTranslation();
  const route = useRoute<RouteProp<UserStackParamsList, "resetPassword">>();
  const navigation = useNavigation<StackNavigationProp<UserStackParamsList>>();
  const resetPasswordMutation = useResetPassword();

  const { userId, token } = route.params ?? {
    userId: 0,
    token: ""
  };
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState({
    passwordMatchLength: true,
    passwordMatchAlphanumeric: true,
    confirmPasswordMatch: true
  });
  const [newPasswordSecureTextEntry, setNewPasswordSecureTextEntry] =
    useState(true);
  const [
    confirmNewPasswordSecureTextEntry,
    setConfirmNewPasswordSecureTextEntry
  ] = useState(true);
  const isValid =
    password &&
    confirmPassword &&
    passwordValidation.passwordMatchLength &&
    passwordValidation.passwordMatchAlphanumeric &&
    passwordValidation.confirmPasswordMatch;

  const newPasswordRef = useRef<TextInput>(null);
  const confirmNewPasswordRef = useRef<TextInput>(null);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={[
          {
            paddingVertical: spacings.xl
          },
          containerStyle.container
        ]}>
        <Text
          style={[
            typographies.body,
            {
              marginHorizontal: spacings.xl,
              marginTop: spacings.xl,
              marginBottom: spacings.md,
              textAlign: "center"
            }
          ]}>
          {t(
            "ResetPasswordScreenEnterNewPasswordMessage",
            "Enter a new password for your account"
          )}
        </Text>

        <Input
          ref={newPasswordRef}
          autoFocus={Platform.OS !== "web"}
          inputContainerStyle={largeInputStyle.inputContainer}
          containerStyle={[
            largeInputStyle.container,
            {
              marginBottom: spacings.lg,
              borderColor:
                !passwordValidation.passwordMatchLength ||
                !passwordValidation.passwordMatchAlphanumeric
                  ? colors.danger
                  : undefined,
              borderWidth:
                !passwordValidation.passwordMatchLength ||
                !passwordValidation.passwordMatchAlphanumeric
                  ? cornerRadius.xxs
                  : undefined
            }
          ]}
          inputStyle={largeInputStyle.input}
          placeholder={t(
            "ResetPasswordScreenNewPasswordPlaceholder",
            "New password"
          )}
          returnKeyType="next"
          onSubmitEditing={() => {
            confirmNewPasswordRef.current?.focus();
          }}
          autoCapitalize="none"
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          rightIcon={
            <PasswordToggleEyeComponent
              isSecure={newPasswordSecureTextEntry}
              onPress={() =>
                setNewPasswordSecureTextEntry(!newPasswordSecureTextEntry)
              }
            />
          }
          secureTextEntry={newPasswordSecureTextEntry}
          errorMessage={
            !passwordValidation.passwordMatchLength
              ? t(
                  "ResetPasswordScreenPasswordRequiredLenghtErrorMessage",
                  "Minimum length 6 characters."
                )
              : !passwordValidation.passwordMatchAlphanumeric
              ? t(
                  "ResetPasswordScreenPasswordAlphanumericCharactersErrorMessage",
                  "Alphanumeric characters required."
                )
              : undefined
          }
          onBlur={validate}
          onChangeText={setPassword}
          value={password}></Input>

        <Input
          inputContainerStyle={largeInputStyle.inputContainer}
          containerStyle={[
            largeInputStyle.container,
            {
              marginBottom: spacings.lg,
              borderColor: !passwordValidation.confirmPasswordMatch
                ? colors.danger
                : undefined,
              borderWidth: !passwordValidation.confirmPasswordMatch
                ? cornerRadius.xxs
                : undefined
            }
          ]}
          ref={confirmNewPasswordRef}
          inputStyle={largeInputStyle.input}
          placeholder={t(
            "ResetPasswordScreenConfirmPasswordPlaceholder",
            "Confirm new password"
          )}
          autoCapitalize="none"
          autoCorrect={false}
          placeholderTextColor={colors.text.placeholder}
          rightIcon={
            <PasswordToggleEyeComponent
              isSecure={confirmNewPasswordSecureTextEntry}
              onPress={() =>
                setConfirmNewPasswordSecureTextEntry(
                  !confirmNewPasswordSecureTextEntry
                )
              }
            />
          }
          secureTextEntry={confirmNewPasswordSecureTextEntry}
          errorMessage={
            !passwordValidation.confirmPasswordMatch
              ? t(
                  "ResetPasswordScreenPasswordsNotMatchingErrorMessage",
                  "Passwords do not match."
                )
              : undefined
          }
          onBlur={validate}
          onSubmitEditing={resetPasswordAsync}
          onChangeText={setConfirmPassword}
          value={confirmPassword}></Input>

        <Button
          title={t(
            "ResetPasswordScreenResetPasswordButtonTitle",
            "Reset password"
          )}
          disabled={!isValid || resetPasswordMutation.isLoading}
          titleStyle={largePrimaryRoundedButtonStyle.title}
          containerStyle={[
            largePrimaryRoundedButtonStyle.container,
            { marginTop: spacings.xl }
          ]}
          loading={resetPasswordMutation.isLoading}
          loadingStyle={largePrimaryRoundedButtonStyle.loading}
          disabledStyle={largePrimaryRoundedButtonStyle.disabled}
          onPress={resetPasswordAsync}
          buttonStyle={largePrimaryRoundedButtonStyle.button}></Button>
      </View>
    </SafeAreaView>
  );

  function validate() {
    setPasswordValidation({
      ...passwordValidation,
      passwordMatchLength:
        !password || validator.isLength(password, { min: 6 }),
      passwordMatchAlphanumeric:
        !password ||
        validator.matches(password, "^(?=.*\\d)(?=.*[a-zA-Z]).{0,}$"),
      confirmPasswordMatch:
        !confirmPassword || validator.equals(confirmPassword, password)
    });
  }

  async function resetPasswordAsync() {
    if (!isValid) {
      return;
    }

    try {
      const resetPasswordModel: IResetPassword = {
        newPassword: password,
        token: token,
        userId: userId
      };
      await resetPasswordMutation.mutateAsync(resetPasswordModel);
      navigation.navigate("resetPasswordResult", {
        type: ResultType.Success,
        description: t(
          "ResetPasswordScreenPasswordRecoveredMessage",
          "Password successfully changed."
        )
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("resetPasswordResult", {
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      switch (e?.status) {
        case 404:
          errorDescription = t(
            "ResetPasswordScreenNoAccountFoundErrorMessage",
            "No account found with the specified email address."
          );
          break;

        default:
          errorDescription = t(
            "ResetPasswordScreenInternalServerErrorMessage",
            "Internal server error"
          );
          break;
      }

      navigation.navigate("resetPasswordResult", {
        type: ResultType.Error,
        description: errorDescription
      });
    }
  }
}
