import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { Platform } from "expo-modules-core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, TextInput, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import validator from "validator";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { BeneficiariesStackParamsList } from "../navigation/ProfileStackNavigation";
import {
  IAffiliationKey,
  useAffiliation
} from "../services/AffiliationsService";
import {
  IBeneficiaryKey,
  useAddBeneficiary,
  useBeneficiary,
  useDeleteBeneficiary,
  useUpdateBeneficiary
} from "../services/BeneficiariesService";
import { alert } from "../services/DialogService";
import { toastError, toastSuccess } from "../services/ToastService";
import {
  BeneficiaryType,
  IBeneficiary
} from "../services/clients/PlatformClient";
import {
  largePrimaryRoundedButtonStyle,
  primaryLinkButtonStyle
} from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { cornerRadius, iconSizes, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { fontSizes, typographies } from "../styles/Fonts";
import { largeInputStyle } from "../styles/Inputs";

export default function BeneficiaryScreen() {
  const route =
    useRoute<RouteProp<BeneficiariesStackParamsList, "beneficiary">>();
  const navigation =
    useNavigation<NavigationProp<BeneficiariesStackParamsList>>();
  const { t } = useTranslation();

  const { identifierId, beneficiaryId, affiliationId } = route.params ?? [];

  const isCreationMode = !beneficiaryId;

  const affiliationQuery = useAffiliation({ affiliationId, identifierId });
  const beneficiaryQuery = useBeneficiary(
    { identifierId, affiliationId, beneficiaryId: beneficiaryId! },
    {
      enabled: !isCreationMode && beneficiaryId !== undefined
    }
  );
  const addBeneficiaryMutation = useAddBeneficiary();
  const updateBeneficiaryMutation = useUpdateBeneficiary();
  const deleteBeneficiaryMutation = useDeleteBeneficiary();

  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const emailRef = useRef<TextInput>(null);
  const nameRef = useRef<TextInput>(null);

  const isFormValid =
    email !== undefined &&
    validator.isEmail(email) &&
    !validator.isEmpty(alias);

  useEffect(() => {
    if (!isCreationMode && beneficiaryQuery.data) {
      setAlias(beneficiaryQuery.data.name);
      setEmail(beneficiaryQuery.data.value);
    }
  }, [beneficiaryQuery.data]);

  useEffect(() => {
    if (
      isCreationMode ||
      affiliationQuery.isInitialLoading ||
      beneficiaryQuery.isInitialLoading
    ) {
      return;
    }

    navigation.setOptions({
      headerRight: () => (
        <Button
          onPress={deleteBeneficiary}
          type="clear"
          icon={
            <FontAwesomeIcon
              icon={faTrash}
              color={colors.text.link}
              size={iconSizes.lg}></FontAwesomeIcon>
          }
          titleStyle={[
            primaryLinkButtonStyle.title,
            { fontSize: fontSizes.md }
          ]}></Button>
      )
    });
  }, [affiliationQuery.isInitialLoading, beneficiaryQuery.isInitialLoading]);

  if (
    affiliationQuery.isInitialLoading ||
    (!isCreationMode && beneficiaryQuery.isInitialLoading)
  ) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  if (!isCreationMode && (!affiliationQuery.data || !beneficiaryQuery.data)) {
    return (
      <EmptyContentComponent
        message={t(
          "BeneficiaryScreenNoData",
          "Beneficiary data not found."
        )}></EmptyContentComponent>
    );
  }

  function deleteBeneficiary() {
    alert(
      t("BeneficiaryScreenDeleteBeneficiaryTitle", "Delete beneficiary"),
      t(
        "BeneficiaryScreenDeleteBeneficiaryMessage",
        "Are you sure you want to delete the beneficiary from the list?"
      ),
      [
        {
          text: t("AlertConfirm", "Confirm"),
          onPress: async () => await deleteBeneficiaryAsync(),
          style: "destructive"
        },
        { text: t("AlertCancel", "Cancel"), style: "cancel" }
      ]
    );
  }

  async function deleteBeneficiaryAsync() {
    try {
      await deleteBeneficiaryMutation.mutateAsync({
        beneficiaryKey: {
          affiliationId,
          identifierId,
          beneficiaryId: beneficiaryId!
        }
      });
      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "BeneficiaryScreenBeneficiaryDeletedSuccessful",
          "The beneficiary was deleted successfully"
        )
      );
      navigation.navigate("beneficiaries");
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "BeneficiaryScreenDeleteBeneficiaryError",
          "An error ocurred when deleting the beneficiary"
        )
      );
    }
  }

  async function onSaveChangesAsync() {
    try {
      if (!isFormValid) {
        return;
      }

      const affiliationKey: IAffiliationKey = {
        identifierId,
        affiliationId
      };
      const beneficiary: IBeneficiary = {
        name: alias,
        value: email,
        type: BeneficiaryType.Email
      };

      if (isCreationMode) {
        await addBeneficiaryMutation.mutateAsync({
          affiliationKey,
          beneficiary
        });
      } else {
        const beneficiaryKey: IBeneficiaryKey = {
          ...affiliationKey,
          beneficiaryId
        };
        await updateBeneficiaryMutation.mutateAsync({
          beneficiaryKey,
          beneficiary
        });
      }

      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t("BeneficiaryScreenSuccessMessage", "Beneficiary saved successfully")
      );
      navigation.navigate("beneficiaries");
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      switch (e?.status) {
        case 404:
          toastError(
            t("AlertErrorTitle", "Error"),
            t("BeneficiaryScreenNotFound", "The beneficiary was not found.")
          );
          break;
        default:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "BeneficiaryScreenUnknownError",
              "An error occurred when saving the beneficiary. Please, try again later."
            )
          );
          break;
      }
    }
  }

  return (
    <View style={[containerStyle.container]}>
      <Text
        style={[
          typographies.title,
          {
            textAlign: "left",
            marginBottom: spacings.md
          }
        ]}>
        {affiliationQuery.data?.name}
      </Text>

      <Input
        ref={nameRef}
        autoFocus={Platform.OS !== "web" && isCreationMode}
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          largeInputStyle.container,
          {
            borderColor: !isNameValid ? colors.danger : undefined,
            borderWidth: !isNameValid ? cornerRadius.xxs : undefined
          }
        ]}
        inputStyle={largeInputStyle.input}
        placeholder={t("BeneficiaryScreenNamePlaceholder", "Name")}
        onSubmitEditing={() => emailRef.current?.focus()}
        value={alias}
        returnKeyType="next"
        onChangeText={(value) => {
          if (!isDirty) {
            setIsDirty(true);
          }
          setAlias(value);
        }}
        onBlur={() => setIsNameValid(!validator.isEmpty(alias))}
        autoCorrect={false}
        errorMessage={
          !isNameValid
            ? t("BeneficiaryScreenNameNotValid", "Name cannot be empty")
            : undefined
        }
        placeholderTextColor={colors.text.placeholder}></Input>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          largeInputStyle.container,
          {
            marginTop: spacings.xl,
            borderColor: !isEmailValid ? colors.danger : undefined,
            borderWidth: !isEmailValid ? cornerRadius.xxs : undefined,
            marginBottom: spacings.xxxl
          }
        ]}
        inputStyle={largeInputStyle.input}
        ref={emailRef}
        returnKeyType="next"
        inputMode="email"
        placeholder={t("BeneficiaryScreenEmailPlaceholder", "Email")}
        onSubmitEditing={onSaveChangesAsync}
        onBlur={() => setIsEmailValid(validator.isEmail(email))}
        value={email}
        onChangeText={(value) => {
          if (!isDirty) {
            setIsDirty(true);
          }
          setEmail(value);
        }}
        autoCorrect={false}
        errorMessage={
          !isEmailValid
            ? t("BeneficiaryScreenEmailNotValid", "Email format is not valid")
            : undefined
        }
        placeholderTextColor={colors.text.placeholder}
        disabled={false}></Input>

      <Button
        title={
          isCreationMode
            ? t("BeneficiaryScreenCreateButton", "Add")
            : t("BeneficiaryScreenSaveButton", "Save")
        }
        disabled={
          !isDirty ||
          !isFormValid ||
          updateBeneficiaryMutation.isLoading ||
          addBeneficiaryMutation.isLoading
        }
        loading={
          updateBeneficiaryMutation.isLoading ||
          addBeneficiaryMutation.isLoading
        }
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        containerStyle={largePrimaryRoundedButtonStyle.container}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        onPress={onSaveChangesAsync}></Button>
    </View>
  );
}
