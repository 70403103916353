import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons/faCircleInfo";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle
} from "@fortawesome/react-native-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  SafeAreaView,
  StyleProp,
  StyleSheet,
  TextStyle,
  View
} from "react-native";
import { Button, Text } from "react-native-elements";
import {
  colors,
  containerStyle,
  fontSizes,
  iconSizes,
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export enum ResultType {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info"
}

export default function ActionResultComponent({
  resultType,
  title,
  titleStyle,
  description,
  descriptionStyle,
  icon,
  iconStyle,
  buttonTitle,
  retryTitle,
  buttonCallback,
  retryCallback
}: {
  resultType: ResultType;
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  description: string;
  descriptionStyle?: StyleProp<TextStyle>;
  icon?: IconProp;
  iconStyle?: FontAwesomeIconStyle;
  buttonTitle: string;
  retryTitle?: string;
  buttonCallback: () => Promise<void> | void;
  retryCallback?: () => Promise<void> | void;
}) {
  const { t } = useTranslation();

  let defaultIcon: IconProp;
  let defaultIconStyle: FontAwesomeIconStyle;

  switch (resultType) {
    case ResultType.Error:
      defaultIcon = faTimesCircle;
      defaultIconStyle = { color: colors.danger };
      break;
    case ResultType.Warning:
      defaultIcon = faExclamationCircle;
      defaultIconStyle = { color: colors.warning };
      break;
    case ResultType.Info:
      defaultIcon = faCircleInfo;
      defaultIconStyle = { color: colors.info };
      break;
    default:
      defaultIcon = faCheckCircle;
      defaultIconStyle = { color: colors.success };
      break;
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={[
          {
            paddingVertical: spacings.xl,
            flex: 1
          },
          containerStyle.container
        ]}>
        <View
          style={{
            alignItems: "center",
            paddingTop: 150,
            paddingHorizontal: spacings.xxxl
          }}>
          <FontAwesomeIcon
            icon={icon ?? defaultIcon}
            size={iconSizes.xxxxl}
            style={[defaultIconStyle, iconStyle]}
          />

          <Text style={[typographies.heading, styles.title, titleStyle]}>
            {title}
          </Text>
          <Text
            style={[
              typographies.subtitle,
              styles.description,
              descriptionStyle
            ]}>
            {description}
          </Text>
        </View>

        <View
          style={{
            marginHorizontal: spacings.lg,
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end"
          }}>
          {retryCallback && (
            <Button
              titleStyle={largePrimaryRoundedButtonStyle.title}
              containerStyle={[
                largePrimaryRoundedButtonStyle.container,
                {
                  marginBottom: spacings.lg
                }
              ]}
              buttonStyle={largePrimaryRoundedButtonStyle.button}
              title={
                retryTitle ?? t("ActionResultComponentRetryButton", "Retry")
              }
              onPress={retryCallback}
            />
          )}

          <Button
            titleStyle={
              retryCallback
                ? largePrimaryOutlineRoundedButtonStyle.title
                : largePrimaryRoundedButtonStyle.title
            }
            containerStyle={[
              !retryCallback
                ? largePrimaryRoundedButtonStyle.container
                : undefined
            ]}
            buttonStyle={
              retryCallback
                ? largePrimaryOutlineRoundedButtonStyle.button
                : largePrimaryRoundedButtonStyle.button
            }
            title={buttonTitle}
            onPress={buttonCallback}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: fontSizes.xxxl,
    marginTop: spacings.lg,
    marginBottom: spacings.lg,
    textAlign: "center"
  },
  description: {
    fontSize: fontSizes.lg,
    textAlign: "center"
  }
});
