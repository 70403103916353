import {
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";
import i18n from "../providers/i18n";
import { IAccountKey } from "./AccountsService";
import { PaymentMode } from "./AffiliationsService";
import {
  CreditCardFees,
  CreditCardType,
  Fees,
  ICreditCardFees
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";
import { feeQueryKey, feesQueryKey, queryClient } from "./QueryService";

async function getFees(
  accountKey: IAccountKey,
  amount?: number
): Promise<ICreditCardFees[]> {
  let fees: ICreditCardFees[] = [];

  const paymentSlipFees = await getPaymentSlipFees(accountKey, amount);

  const paymentSlipCreditCardFees: ICreditCardFees = {
    name: i18n.t("PaymentMode.PaymentSlip", "QR-bill"),
    type: CreditCardType.Others,
    fees: paymentSlipFees
  };
  fees.push(paymentSlipCreditCardFees);

  const creditCardFees = await getAllCreditCardsFees(accountKey, amount);
  fees.push(...creditCardFees);

  if (
    fees.every(
      (f) =>
        !f.fees?.amountFee &&
        !f.fees?.minimumFee &&
        !f.fees?.percentFee &&
        !f.fees?.totalFee
    )
  ) {
    return [];
  }

  fees.forEach((fee) => {
    fee.name = getFeeDisplayName(fee);
  });

  return fees;
}

async function getFee(
  accountKey: IAccountKey,
  paymentMode: PaymentMode,
  amount?: number
): Promise<ICreditCardFees> {
  if (paymentMode === PaymentMode.PaymentSlip) {
    const paymentSlipFees = await getPaymentSlipFees(accountKey, amount);

    const paymentSlipCreditCardFees: ICreditCardFees = {
      name: i18n.t("PaymentMode.PaymentSlip", "QR-bill"),
      type: CreditCardType.Others,
      fees: paymentSlipFees
    };
    return paymentSlipCreditCardFees;
  } else {
    const creditCardFees = await getAllCreditCardsFees(accountKey, amount);
    switch (paymentMode) {
      case PaymentMode.Twint:
        return creditCardFees.find((f) => f.type == CreditCardType.Twint) ?? {};
      case PaymentMode.CreditCard:
        return (
          creditCardFees.find((f) => f.type == CreditCardType.Others) ?? {}
        );
      default:
        break;
    }

    return {};
  }
}

async function getAllCreditCardsFees(
  accountKey: IAccountKey,
  amount?: number
): Promise<CreditCardFees[]> {
  const platformClient = platformClientFactory.create();
  return await platformClient.getAllCreditCardsFees(
    accountKey.identifierId,
    accountKey.affiliationId,
    accountKey.personId,
    accountKey.accountId,
    amount,
    false
  );
}

async function getPaymentSlipFees(
  accountKey: IAccountKey,
  amount?: number
): Promise<Fees> {
  const platformClient = platformClientFactory.create();
  return await platformClient.getPaymentSlipFees(
    accountKey.identifierId,
    accountKey.affiliationId,
    accountKey.personId,
    accountKey.accountId,
    amount,
    false
  );
}

export function useFee(
  accountKey: IAccountKey,
  paymentMode: PaymentMode,
  amount?: number,
  options?: UseQueryOptions<
    ICreditCardFees,
    unknown,
    ICreditCardFees,
    (string | IAccountKey | number | undefined)[]
  >
): UseQueryResult<ICreditCardFees> {
  return useQuery(
    [feeQueryKey, accountKey, paymentMode, amount],
    () => getFee(accountKey, paymentMode, amount),
    {
      ...options
    }
  );
}

export function useFees(
  accountKey: IAccountKey,
  amount?: number,
  options?: UseQueryOptions<
    ICreditCardFees[],
    unknown,
    ICreditCardFees[],
    (string | number | IAccountKey | undefined)[]
  >
): UseQueryResult<ICreditCardFees[]> {
  return useQuery(
    [feesQueryKey, accountKey, amount],
    () => getFees(accountKey, amount),
    {
      ...options
    }
  );
}

export async function fetchFees(
  accountKey: IAccountKey,
  amount?: number
): Promise<ICreditCardFees[]> {
  const fees = await queryClient.fetchQuery<ICreditCardFees[]>(
    [feesQueryKey, accountKey, amount],
    () => getFees(accountKey, amount)
  );
  return fees;
}

function getFeeDisplayName(creditCardFees: ICreditCardFees) {
  switch (creditCardFees.type) {
    case CreditCardType.Twint:
      return i18n.t("PaymentMode.Twint", "Twint");
    case CreditCardType.MasterCard:
      return i18n.t("PaymentMode.MasterCard", "Mastercard");
    case CreditCardType.AmericanExpress:
      return i18n.t("PaymentMode.AmericanExpress", "American Express");
    case CreditCardType.PostCard:
      return i18n.t("PaymentMode.PostCard", "PostFinance");
    case CreditCardType.Visa:
      return i18n.t("PaymentMode.Visa", "Visa");
    case CreditCardType.Paypal:
      return i18n.t("PaymentMode.Paypal", "Paypal");
    default:
      return creditCardFees.name ?? i18n.t("PaymentMode.Others", "Others");
  }
}
