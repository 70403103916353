export enum FontAwesomeCustomKitIcon {
  AliPay = "\ue000",
  AmexPay = "\ue001",
  ApplePay = "\ue002",
  BancontactPay = "\ue003",
  BizumPay = "\ue004",
  BluecodePay = "\ue005",
  DinersClubPay = "\ue006",
  DirektPay = "\ue007",
  DiscoverPay = "\ue008",
  EpsPay = "\ue009",
  GenericNfcPay = "\ue00a",
  GenericRfidPay = "\ue00b",
  GenericQrcodeScanPay = "\ue00c",
  GooglePay = "\ue00d",
  GiroPay = "\ue00e",
  IdealPay = "\ue00f",
  JcbPay = "\ue010",
  KlarnaPay = "\ue011",
  MaestroInternationalPay = "\ue012",
  MastercardPay = "\ue013",
  MyOnePay = "\ue014",
  PaypalPay = "\ue015",
  PolyrightPay = "\ue016",
  PostfinanceCardPay = "\ue017",
  SecandaPay = "\ue018",
  PostfinanceEFinancePay = "\ue019",
  SepaDirectDebitPay = "\ue01a",
  SofortPay = "\ue01b",
  TwintPay = "\ue01c",
  UnionPay = "\ue01d",
  VisaPay = "\ue01e",
  VPay = "\ue01f",
  WlCryptoPay = "\ue020",
  CcPostfinanceCardPay = "\ue021",
  CcPostfinanceEFinancePay = "\ue022",
  CcSecandaPay = "\ue023",
  CcSepaDirectDebitPay = "\ue024",
  CcSofort = "\ue025",
  CcTwintPay = "\ue026",
  CcUnionPay = "\ue027",
  CcVisaPay = "\ue028",
  CcVPay = "\ue029",
  CcWlCryptoPay = "\ue02a",
  CcAliPay = "\ue02b",
  CcAmexPay = "\ue02c",
  CcApplePay = "\ue02d",
  CcBancontactPay = "\ue02e",
  CcBizumPay = "\ue02f",
  CcBluecodePay = "\ue030",
  CcDinersClubPay = "\ue031",
  CcDirektPay = "\ue032",
  CcDiscoverPay = "\ue033",
  CcEpsPay = "\ue034",
  CcGenericNfcPay = "\ue035",
  CcGenericQrcodeScanPay = "\ue036",
  CcGenericRfidPay = "\ue037",
  CcGiroPay = "\ue038",
  CcGooglePay = "\ue039",
  CcJcbPay = "\ue03a",
  CcIdealPay = "\ue03b",
  CcKlarnaPay = "\ue03c",
  CcMaestroInternationalPay2 = "\ue03d",
  CcMaestroInternationalPay = "\ue03e",
  CcMastercardPay2 = "\ue03f",
  CcMastercardPay = "\ue040",
  CcMyonePay = "\ue041",
  CcPolyrightPay = "\ue042",
  CcPaypalPay = "\ue043",
  CcQrBillPay = "\ue044",
  QrBillPay = "\ue045",
  CreditCard = "\ue046",
  CcBankTransferPay = "\ue048",
  BankTransferPay = "\ue049",
  BizumIconPay = "\ue04a",
  BluecodeIconPay = "\ue04b",
  PostfinanceEIconFinancePay = "\ue04c",
  SecandaIconPay = "\ue04d",
  TwintIconPay = "\ue04e",
  PostfinanceIconCardPay = "\ue04f",
  QrBillIconPay = "\ue050"
}
