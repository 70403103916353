import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import {
  AffiliationStatus,
  IAffiliation
} from "../services/clients/PlatformClient";
import { listItemHeights } from "../styles/Constants";
import { colors, typographies } from "../styles/Styles";
import AffiliationLogoComponent from "./AffiliationLogoComponent";

export default function AffiliationListItemComponent({
  affiliation,
  disabled,
  onPress
}: {
  affiliation: IAffiliation;
  disabled?: boolean;
  onPress?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <ListItem
      containerStyle={{
        height: listItemHeights.md,
        justifyContent: "space-between"
      }}
      onPress={onPress}
      disabledStyle={{
        opacity: 0.7
      }}
      disabled={disabled}>
      <View
        style={{
          flexShrink: 1
        }}>
        <Text style={typographies.body} numberOfLines={1}>
          {affiliation.name}
        </Text>

        {affiliation.status === AffiliationStatus.Offline && (
          <Text
            style={[typographies.caption, { color: colors.danger }]}
            numberOfLines={1}>
            {t(
              "AffiliationListItemTemporarilyUnavailable",
              "Temporarily unavailable"
            )}
          </Text>
        )}
      </View>
      <AffiliationLogoComponent
        affiliationId={affiliation.affiliationId}
        logoHeight={42}
        logoWidth={80}
      />
    </ListItem>
  );
}
