import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { t } from "i18next";
import React, { useState } from "react";
import { View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { colors } from "../styles/Colors";
import { iconSizes, listItemHeights, spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";
import { virtualizedListItemStyle } from "../styles/Lists";

export const languages: { label: string; value: string }[] = [
  {
    label: t("LanguageSelection.EnglishGB", "English - Great Britain"),
    value: "en-GB"
  },
  {
    label: t("LanguageSelection.EnglishUS", "English - United States"),
    value: "en-US"
  },
  {
    label: t("LanguageSelection.SpanishESP", "Español - España"),
    value: "es-ES"
  },
  {
    label: t("LanguageSelection.GermanDE", "Deutsch - Deutschland"),
    value: "de-DE"
  },
  {
    label: t("LanguageSelection.GermanCH", "Deutsch - Schweiz"),
    value: "de-CH"
  },
  {
    label: t("LanguageSelection.FrenchCH", "Français - Suisse"),
    value: "fr-CH"
  },
  {
    label: t("LanguageSelection.FrenchFR", "Français - France "),
    value: "fr-FR"
  }
];

export default function LanguageSelectionComponent({
  defaultLanguage,
  onLanguageSelectedCallback
}: {
  defaultLanguage?: string;
  onLanguageSelectedCallback: (languageCode?: string) => Promise<void> | void;
}) {
  const [language, setLanguage] = useState(defaultLanguage ?? "");

  async function onLanguageValueChange(value: string) {
    if (value === language) {
      return;
    }

    setLanguage(value);
    onLanguageSelectedCallback?.(value);
  }

  return (
    <ScrollViewComponent>
      {languages.map((item, index) => {
        return (
          <View
            key={index}
            style={[
              virtualizedListItemStyle.baseItem,
              index === 0 ? virtualizedListItemStyle.firstItem : null,
              index === languages.length - 1
                ? virtualizedListItemStyle.lastItem
                : virtualizedListItemStyle.middleItem
            ]}>
            <ListItem
              containerStyle={{
                height: listItemHeights.md,
                paddingHorizontal: spacings.md,
                paddingVertical: spacings.lg,
                flexDirection: "row",
                alignItems: "center"
              }}
              onPress={() => onLanguageValueChange(item.value)}>
              <Text style={[typographies.body, { flex: 1 }]}>{item.label}</Text>

              {language === item.value && (
                <FontAwesomeIcon
                  style={{
                    color: colors.text.primary
                  }}
                  icon={faCheck}
                  size={iconSizes.lg}></FontAwesomeIcon>
              )}
            </ListItem>
          </View>
        );
      })}
    </ScrollViewComponent>
  );
}
