import React from "react";
import { ListItem } from "react-native-elements";
import { AccountDetailSummary } from "../screens/AccountDetailsScreen";
import { AccountDetail } from "../services/AccountsService";
import { listItemStyle } from "../styles/Lists";
import AffiliationLogoComponent from "./AffiliationLogoComponent";

export default function AccountListItemComponent({
  account,
  disabled,
  onPress
}: {
  account: AccountDetail;
  disabled?: boolean;
  onPress?: () => void;
}) {
  const key = `${account.identifier.identifierId}/${account.affiliation.affiliationId}/${account.person?.personId}/${account.account?.accountId}`;

  return (
    <ListItem
      key={key}
      disabled={disabled}
      disabledStyle={{ opacity: 0.7 }}
      style={listItemStyle.listItem}
      containerStyle={listItemStyle.container}
      onPress={onPress}>
      <ListItem.Content
        style={{
          flexDirection: "row",
          alignItems: "center"
        }}>
        <AccountDetailSummary accountDetail={account} />
        <AffiliationLogoComponent
          affiliationId={account.affiliation.affiliationId}
        />
      </ListItem.Content>
    </ListItem>
  );
}
