import { Locale, getLocales } from "expo-localization";
import {
  default as React,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TextInput, View } from "react-native";
import { Input, Text } from "react-native-elements";
import { neutralDecimalSeparator } from "../services/CultureService";
import { humanizeCurrency } from "../services/HumanizerService";
import {
  getFractionalDigitCount,
  roundNumber
} from "../services/RoundingService";
import { colors } from "../styles/Colors";
import { listItemHeights, spacings } from "../styles/Constants";
import { fontSizes, typographies } from "../styles/Fonts";
import { largeInputStyle } from "../styles/Inputs";

export default function AmountSelectionComponent({
  minimumAmount,
  maximumAmount,
  roundingFactor,
  currency,
  amountHint,
  amountInputRef,
  amountInputOnLayout,
  onAmountChange,
  onSubmitCallback
}: {
  minimumAmount?: number;
  maximumAmount?: number;
  roundingFactor?: number;
  amountHint?: string;
  currency?: string;
  amountInputRef?: RefObject<TextInput>;
  amountInputOnLayout?: () => void;
  onAmountChange?: (amount?: number) => void;
  onSubmitCallback?: () => void;
}) {
  const { t } = useTranslation();

  const [amount, setAmount] = useState("");
  const [formValidation, setFormValidation] = useState({
    amountHigherThanMaximum: false,
    amountLowerThanMinimum: false
  });

  const locale = useMemo(() => getLocales()[0], []);

  useEffect(() => {
    onAmountChangeText(amount);
  }, [minimumAmount, maximumAmount, roundingFactor]);

  const onAmountChangeText = useCallback(
    (value: string) => {
      if (value === "") {
        setAmount("");

        if (onAmountChange) {
          onAmountChange(undefined);
        }

        return;
      }

      const sanitized = sanitizeNumber(value, roundingFactor, locale);
      const formValidation = {
        amountHigherThanMaximum:
          sanitized !== undefined &&
          maximumAmount !== undefined &&
          maximumAmount < sanitized.number,
        amountLowerThanMinimum:
          sanitized !== undefined &&
          minimumAmount !== undefined &&
          minimumAmount > sanitized.number
      };

      if (sanitized) {
        setAmount(sanitized.value);
      }

      if (sanitized && onAmountChange) {
        onAmountChange(
          !formValidation.amountHigherThanMaximum &&
            !formValidation.amountLowerThanMinimum
            ? sanitized.number
            : undefined
        );
      }

      setFormValidation(formValidation);
    },
    [locale, roundingFactor, maximumAmount, minimumAmount]
  );

  return (
    <View>
      <View
        style={[
          largeInputStyle.container,
          {
            height: listItemHeights.lg,
            width: "100%",
            flexDirection: "row",
            backgroundColor: colors.background.light,
            paddingHorizontal: spacings.xl
          }
        ]}>
        <View
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center"
          }}>
          <Text
            style={[
              typographies.body,
              styles.inputAffix,
              { alignSelf: "flex-start" }
            ]}>
            {t("AmountSelectionComponentAmountPlaceholder", "Amount")}
          </Text>
          {maximumAmount !== undefined || minimumAmount !== undefined ? (
            <Text
              style={[
                typographies.caption,
                {
                  color:
                    formValidation.amountHigherThanMaximum ||
                    formValidation.amountLowerThanMinimum
                      ? colors.danger
                      : colors.text.disabled
                }
              ]}>
              {minimumAmount !== undefined &&
              formValidation.amountLowerThanMinimum
                ? t(
                    "AmountSelectionComponentAmountMinimumHint",
                    "Minimum: {{amount}}",
                    {
                      amount: currency
                        ? humanizeCurrency(minimumAmount, currency)
                        : minimumAmount
                    }
                  )
                : maximumAmount !== undefined
                ? t(
                    "AmountSelectionComponentAmountMaximumHint",
                    "Maximum: {{amount}}",
                    {
                      amount: currency
                        ? humanizeCurrency(maximumAmount, currency)
                        : maximumAmount
                    }
                  )
                : null}
            </Text>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
            overflow: "hidden",
            flex: 1
          }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-end"
            }}>
            <Input
              ref={amountInputRef}
              onLayout={amountInputOnLayout}
              containerStyle={[
                largeInputStyle.container,
                {
                  flex: 1,
                  height: undefined,
                  paddingVertical: spacings.none,
                  paddingHorizontal: spacings.md
                }
              ]}
              inputContainerStyle={[
                largeInputStyle.inputContainer,
                styles.inputContainer,
                {
                  height: undefined
                }
              ]}
              inputStyle={[
                largeInputStyle.input,
                {
                  textAlign: "right",
                  minHeight: undefined
                }
              ]}
              inputMode="decimal"
              placeholder={
                roundingFactor
                  ? `0${locale.decimalSeparator}${"0".repeat(
                      getFractionalDigitCount(roundingFactor)
                    )}`
                  : "0"
              }
              placeholderTextColor={colors.text.placeholder}
              autoCorrect={false}
              renderErrorMessage={false}
              onSubmitEditing={onSubmitCallback}
              onChangeText={onAmountChangeText}
              value={amount}></Input>
            <Text style={[typographies.body, styles.inputAffix]}>
              {currency}
            </Text>
          </View>
          {amountHint && (
            <Text
              style={[
                typographies.caption,
                {
                  color: colors.text.disabled
                }
              ]}>
              {amountHint}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  inputAffix: {
    alignSelf: "center",
    fontSize: fontSizes.lg,
    justifyContent: "center"
  },
  inputContainer: {
    justifyContent: "center",
    paddingHorizontal: spacings.none,
    paddingVertical: spacings.none
  }
});

function sanitizeNumber(
  value: string,
  roundingFactor: number | undefined,
  locale: Locale
): { value: string; number: number } | undefined {
  let neutralValue = value.replace(",", neutralDecimalSeparator);

  if (neutralValue.startsWith(neutralDecimalSeparator)) {
    neutralValue = `0${neutralValue}`;
  }

  let number = Number(neutralValue);
  if (isNaN(number)) {
    return undefined;
  }

  if (roundingFactor) {
    const roundingFactorFractionDigits =
      getFractionalDigitCount(roundingFactor);
    const roundedNumber = roundNumber(number, roundingFactor);

    if (number !== roundedNumber) {
      number = roundedNumber;
      neutralValue = roundedNumber.toFixed(roundingFactorFractionDigits);
    }

    const neutralParts = neutralValue.split(neutralDecimalSeparator);
    if (
      neutralParts.length > 1 &&
      neutralParts[1].length > roundingFactorFractionDigits
    ) {
      return undefined;
    }
  }

  const result = locale.decimalSeparator
    ? neutralValue.replace(neutralDecimalSeparator, locale.decimalSeparator)
    : neutralValue;

  return { value: result, number: number };
}
