import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { RouteProp, useRoute } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Button, CheckBox, ListItem, Text } from "react-native-elements";
import AccountListItemComponent from "../components/AccountListItemComponent";
import { DeleteAccountConfirmationSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";
import {
  AccountDetail,
  IPersonKey,
  useAccounts
} from "../services/AccountsService";
import { humanizeCurrency } from "../services/HumanizerService";
import { useDeletePerson } from "../services/PersonsService";
import { toastError, toastSuccess } from "../services/ToastService";
import {
  AffiliationStatus,
  IDeletePerson
} from "../services/clients/PlatformClient";
import {
  colors,
  cornerRadius,
  iconSizes,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function DeleteAccountConfirmationScreen() {
  const route =
    useRoute<RouteProp<HomeStackParamsList, "deleteAccountConfirmation">>();
  const { t } = useTranslation();
  const { identifierId, affiliationId, personId, token } = route.params;
  const deletePersonMutation = useDeletePerson();
  const homeNavigation = useResetHomeNavigation();
  const [agreeFields, setAgreeFields] = useState({
    accountDeleted: false,
    cardBlocked: false,
    personalDataDeleted: false,
    moneyLost: false
  });
  const accountsQuery = useAccounts({
    select: (accounts) =>
      accounts.filter(
        (a) =>
          a.identifier.identifierId === route.params.identifierId &&
          a.affiliation.affiliationId === route.params.affiliationId &&
          a.person?.personId === route.params.personId
      )
  });

  const availableBalance = useMemo(() => {
    const balance =
      accountsQuery.data?.reduce(
        (balance: number, account: AccountDetail) =>
          balance + (account.account?.balance ?? 0),
        0
      ) ?? 0;

    return balance;
  }, [accountsQuery.data]);

  if (accountsQuery.isInitialLoading) {
    return <DeleteAccountConfirmationSkeleton />;
  }

  return (
    <ScrollViewComponent>
      {accountsQuery.data?.map((account) => (
        <AccountListItemComponent
          account={account}
          disabled={account.affiliation.status === AffiliationStatus.Offline}
        />
      ))}

      <View
        style={{
          borderRadius: cornerRadius.md,
          marginBottom: spacings.xl,
          marginTop: spacings.md,
          overflow: "hidden"
        }}>
        <ListItem
          style={{
            marginBottom: spacings.xxs
          }}
          containerStyle={{
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: spacings.lg
          }}>
          <Text style={[typographies.caption, { flex: 1 }]}>
            {t(
              "DeleteAccountConfirmationScreenHeader",
              "You are about to delete your account from the system. Your personal data will be permanently removed and this action cannot be reverted. Please, confirm the action by accepting all the fields."
            )}
          </Text>
          <FontAwesomeIcon
            style={{
              color: colors.warning
            }}
            icon={faExclamationCircle}
            size={iconSizes.lg}></FontAwesomeIcon>
        </ListItem>

        <CheckBox
          containerStyle={checkboxStyle.container}
          checked={agreeFields.accountDeleted}
          onPress={() =>
            setAgreeFields({
              ...agreeFields,
              accountDeleted: !agreeFields.accountDeleted
            })
          }
          checkedIcon="check-square"
          checkedColor={colors.primary}
          uncheckedColor={colors.secondary}
          title={t(
            "DeleteAccountConfirmationScreenAccountDeleted",
            "The selected account will be deleted"
          )}
          textStyle={checkboxStyle.text}
        />

        <CheckBox
          containerStyle={checkboxStyle.container}
          checked={agreeFields.cardBlocked}
          onPress={() =>
            setAgreeFields({
              ...agreeFields,
              cardBlocked: !agreeFields.cardBlocked
            })
          }
          checkedIcon="check-square"
          checkedColor={colors.primary}
          uncheckedColor={colors.secondary}
          title={t(
            "DeleteAccountConfirmationScreenCardBlocked",
            "The related card will be blocked"
          )}
          textStyle={checkboxStyle.text}
        />
        <CheckBox
          containerStyle={checkboxStyle.container}
          checked={agreeFields.personalDataDeleted}
          onPress={() =>
            setAgreeFields({
              ...agreeFields,
              personalDataDeleted: !agreeFields.personalDataDeleted
            })
          }
          checkedIcon="check-square"
          checkedColor={colors.primary}
          uncheckedColor={colors.secondary}
          title={t(
            "DeleteAccountConfirmationScreenPersonalData",
            "All your personal data will be deleted"
          )}
          textStyle={checkboxStyle.text}
        />

        {!!availableBalance && accountsQuery.data?.[0].account && (
          <CheckBox
            containerStyle={checkboxStyle.container}
            checked={agreeFields.moneyLost}
            onPress={() =>
              setAgreeFields({
                ...agreeFields,
                moneyLost: !agreeFields.moneyLost
              })
            }
            checkedIcon="check-square"
            checkedColor={colors.primary}
            uncheckedColor={colors.secondary}
            title={t(
              "DeleteAccountConfirmationScreenMoneyLost",
              "Your account balance of {{amount}} will be definitely lost!",
              {
                amount: humanizeCurrency(
                  availableBalance,
                  accountsQuery.data[0].account.currency
                )
              }
            )}
            textStyle={checkboxStyle.text}
          />
        )}
      </View>

      <Button
        title={t(
          "DeleteAccountConfirmationScreenDeleteButton",
          "Delete account"
        )}
        disabled={
          deletePersonMutation.isLoading ||
          !agreeFields.accountDeleted ||
          !agreeFields.cardBlocked ||
          !agreeFields.personalDataDeleted ||
          (!agreeFields.moneyLost && !!availableBalance)
        }
        onPress={deleteAccountAsync}
        loading={deletePersonMutation.isLoading}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        containerStyle={largePrimaryRoundedButtonStyle.container}
      />
    </ScrollViewComponent>
  );

  async function deleteAccountAsync() {
    try {
      const personKey: IPersonKey = {
        identifierId,
        affiliationId,
        personId
      };
      const model: IDeletePerson = {
        token: token
      };

      await deletePersonMutation.mutateAsync({ personKey, model });

      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "DeleteAccountConfirmationScreenDeleteSuccessMessage",
          "Account successfully deleted."
        )
      );
      homeNavigation.reset();
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      switch (e?.status) {
        case 400:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "DeleteAccountConfirmationScreenDataError",
              "An error occurred during the deletion. Please check the data."
            )
          );
          break;
        case 404:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "DeleteAccountConfirmationScreenNotFound",
              "The account was not found."
            )
          );
          break;
        default:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "DeleteAccountConfirmationScreenUnknownError",
              "Internal server error"
            )
          );
          break;
      }
    }
  }
}

const checkboxStyle = StyleSheet.create({
  container: {
    margin: spacings.none,
    marginLeft: spacings.none,
    marginRight: spacings.none,
    marginBottom: spacings.xxs,
    padding: spacings.none,
    paddingHorizontal: spacings.lg,
    paddingVertical: spacings.lg,
    borderWidth: cornerRadius.none,
    borderRadius: cornerRadius.none,
    backgroundColor: colors.background.light
  },
  text: {
    ...typographies.small,
    marginLeft: spacings.sm,
    fontWeight: "normal"
  }
});
