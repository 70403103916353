import { NavigatorScreenParams } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { BaseResultScreenProps } from ".";
import ForgotPasswordResultScreen from "../screens/ForgotPasswordResultScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import LoginScreen from "../screens/LoginScreen";
import RegisterResultScreen from "../screens/RegisterResultScreen";
import RegisterScreen from "../screens/RegisterScreen";
import { headerStyle } from "../styles/Styles";

export type RegisterResultScreenProps = BaseResultScreenProps & {
  description: string;
};

export type ForgotPasswordResultScreenProps = BaseResultScreenProps & {
  description: string;
};

export type ForgotPasswordScreenProps = {
  email?: string;
};

export type LoginScreenProps = {
  email?: string;
};

export type AuthenticationStackParamsList = {
  login: NavigatorScreenParams<LoginScreenProps>;
  register: undefined;
  registerResult: RegisterResultScreenProps;
  forgotPassword: ForgotPasswordScreenProps;
  forgotPasswordResult: ForgotPasswordResultScreenProps;
};

const AuthenticationStack =
  createStackNavigator<AuthenticationStackParamsList>();

export default function AuthenticationStackScreen() {
  const { t } = useTranslation();

  return (
    <AuthenticationStack.Navigator
      initialRouteName="login"
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <AuthenticationStack.Screen
        name="login"
        component={LoginScreen}
        options={{
          headerShown: false,
          title: t("LoginScreenTitle", "Login")
        }}></AuthenticationStack.Screen>
      <AuthenticationStack.Screen
        name="register"
        component={RegisterScreen}
        options={{
          title: t("RegisterScreenTitle", "Sign up on SECANDA")
        }}></AuthenticationStack.Screen>
      <AuthenticationStack.Screen
        name="forgotPassword"
        component={ForgotPasswordScreen}
        options={{
          title: t("ForgotPasswordScreenTitle", "Reset password")
        }}></AuthenticationStack.Screen>
      <AuthenticationStack.Group
        screenOptions={{
          headerShown: false
        }}>
        <AuthenticationStack.Screen
          name="registerResult"
          component={RegisterResultScreen}
          options={{
            title: t("RegisterScreenTitle", "Sign up on SECANDA")
          }}></AuthenticationStack.Screen>
        <AuthenticationStack.Screen
          name="forgotPasswordResult"
          component={ForgotPasswordResultScreen}
          options={{
            title: t("ForgotPasswordScreenTitle", "Reset password")
          }}></AuthenticationStack.Screen>
      </AuthenticationStack.Group>
    </AuthenticationStack.Navigator>
  );
}
