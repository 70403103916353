import Constants from "expo-constants";
import {
  setUserId as analyticsSetUserId,
  getAnalytics,
  logEvent,
  setUserProperties
} from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { IProfileInfo } from "./clients/PlatformClient";

const firebaseConfig: FirebaseOptions =
  Constants.expoConfig?.extra?.googleServicesConfig;

const app = initializeApp(firebaseConfig);
const webAnalytics = getAnalytics(app);

export function setUserId(id: string | null): Promise<void> {
  return new Promise((resolve, _) => {
    {
      resolve(analyticsSetUserId(webAnalytics, id));
    }
  });
}

export function setApplicationVersion(version: string | null): Promise<void> {
  return new Promise((resolve) => {
    resolve(
      setUserProperties(webAnalytics, {
        app_version: version
      })
    );
  });
}

export function setLanguageCode(languageCode: string): Promise<void> {
  return new Promise((resolve) => {
    resolve(
      setUserProperties(webAnalytics, {
        app_language_code: languageCode
      })
    );
  });
}

export function setUserProfile(profile: IProfileInfo | null): Promise<void> {
  return new Promise((resolve) => {
    resolve(
      setUserProperties(webAnalytics, {
        address_country_code: profile?.countryCode ?? null,
        address_postal_code: profile?.postalCode ?? null,
        user_birth_year: profile?.birthdate?.getFullYear()?.toString() ?? null
      })
    );
  });
}

export function logScreenView(screenName: string): Promise<void> {
  return new Promise((resolve) => {
    resolve(
      logEvent(webAnalytics, "screen_view", {
        firebase_screen: screenName,
        firebase_screen_class: screenName
      })
    );
  });
}

export function logLogin(): Promise<void> {
  return new Promise((resolve) => {
    resolve(logEvent(webAnalytics, "login"));
  });
}

export function logLogout(): Promise<void> {
  return new Promise((resolve) => {
    resolve(logEvent(webAnalytics, "logout"));
  });
}

export function logSignUp(): Promise<void> {
  return new Promise((resolve) => {
    resolve(logEvent(webAnalytics, "sign_up"));
  });
}

export function logDeleteAccount(): Promise<void> {
  return new Promise((resolve) => {
    resolve(logEvent(webAnalytics, "delete"));
  });
}
