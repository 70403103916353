import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { flatMap, orderBy } from "lodash";
import { IAffiliationKey } from "./AffiliationsService";
import { IMediumKey, prefetchAffiliationCardLayout } from "./MediaService";
import {
  accountsQueryKey,
  invalidateAccounts,
  invalidateAllBeneficiaries,
  invalidateBeneficiaries,
  invalidateTransactions,
  queryClient
} from "./QueryService";
import {
  AffiliationStatus,
  IAccount,
  IAffiliation,
  IIdentifier,
  IMedium,
  IPerson,
  ITransferToAccountInfo,
  ITransferToIdentifierInfo,
  LayoutFace,
  LayoutType,
  TransferToAccountInfo,
  TransferToIdentifierInfo
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";

export interface IPersonKey extends IAffiliationKey {
  personId: number;
}

export interface IAccountKey extends IPersonKey {
  accountId: number;
}

export class AccountDetail {
  constructor(
    identifier: IIdentifier,
    affiliation: IAffiliation,
    person: IPerson | null,
    account: IAccount | null,
    media: IMedium[] | null
  ) {
    this.identifier = identifier;
    this.affiliation = affiliation;
    this.person = person;
    this.account = account;
    this.media = media ?? [];
  }

  readonly identifier: IIdentifier;
  readonly affiliation: IAffiliation;
  readonly person: IPerson | null = null;
  readonly account: IAccount | null = null;
  readonly media: IMedium[];
}

export function useAccounts<TResult = AccountDetail[]>(
  options?: UseQueryOptions<AccountDetail[], unknown, TResult, any[]>
) {
  return useQuery([accountsQueryKey], getAccounts, options);
}

export async function fetchAccounts(): Promise<AccountDetail[]> {
  const accounts = await queryClient.fetchQuery<AccountDetail[]>(
    [accountsQueryKey],
    getAccounts
  );
  return accounts;
}

export function useAccount(
  accountKey: IAccountKey,
  options?: UseQueryOptions<AccountDetail, unknown, AccountDetail, any[]>
) {
  return useQuery(
    [accountsQueryKey, accountKey],
    () => fetchAccount(accountKey),
    options
  );
}

export async function fetchAccount(
  accountKey: IAccountKey
): Promise<AccountDetail> {
  const accounts = await fetchAccounts();
  return getAccount(accounts, accountKey);
}

export function useTransferToIdentifier() {
  return useMutation(
    ({
      accountKey,
      model
    }: {
      accountKey: IAccountKey;
      model: ITransferToIdentifierInfo;
    }) => transferToIdentifier(accountKey, model)
  );
}

export function useTransferToAccount() {
  return useMutation(
    ({
      accountKey,
      model
    }: {
      accountKey: IAccountKey;
      model: ITransferToAccountInfo;
    }) => transferToAccount(accountKey, model)
  );
}

async function getAccounts(): Promise<AccountDetail[]> {
  const platformClient = platformClientFactory.create();
  const identifiers = await platformClient.getIdentifiersExpand();

  let accountDetails = flatMap(identifiers, (i) => {
    return flatMap(i.affiliations, (a) => {
      if (a.status == AffiliationStatus.Offline && !a.persons?.length) {
        return new AccountDetail(i, a, null, null, null);
      }

      return flatMap(a.persons, (p) => {
        if (!p.accounts?.length) {
          return new AccountDetail(i, a, p, null, p.media ?? null);
        }

        return flatMap(p.accounts, (acc) => {
          return new AccountDetail(i, a, p, acc, p.media ?? null);
        });
      });
    });
  });

  accountDetails.forEach((acc) => {
    if (acc.affiliation.configuration.isVirtualCardAllowed) {
      acc.media.forEach((m) => {
        const key: IMediumKey = {
          affiliationId: acc.affiliation.affiliationId,
          identifierId: acc.identifier.identifierId,
          personId: acc.person?.personId ?? 0,
          mediumId: m.mediumId
        };
        prefetchAffiliationCardLayout(
          key,
          LayoutFace.Front,
          LayoutType.Preview
        );
      });
    }
  });

  accountDetails = orderBy(
    accountDetails,
    [
      (ad) => ad.affiliation.name?.toLowerCase(),
      (ad) => ad.person?.type,
      (ad) => ad.person?.formattedName?.toLowerCase(),
      (ad) => ad.account?.accountId
    ],
    ["asc", "asc", "asc", "asc"]
  );

  return accountDetails;
}

async function transferToIdentifier(
  accountKey: IAccountKey,
  model: ITransferToIdentifierInfo
) {
  const platformClient = platformClientFactory.create();
  await platformClient.transferToIdentifier(
    accountKey.identifierId,
    accountKey.affiliationId,
    accountKey.personId,
    accountKey.accountId,
    new TransferToIdentifierInfo(model)
  );

  await Promise.all([
    invalidateAccounts({
      refetchType: "all"
    }),
    invalidateTransactions(undefined, {
      refetchType: "all"
    }),
    model.remember
      ? invalidateBeneficiaries(accountKey.affiliationId, {
          refetchType: "all"
        })
      : null,
    model.remember ? invalidateAllBeneficiaries({ refetchType: "all" }) : null
  ]);
}

async function transferToAccount(
  accountKey: IAccountKey,
  model: ITransferToAccountInfo
) {
  const platformClient = platformClientFactory.create();
  await platformClient.transferToAccount(
    accountKey.identifierId,
    accountKey.affiliationId,
    accountKey.personId,
    accountKey.accountId,
    new TransferToAccountInfo(model)
  );

  await Promise.all([
    invalidateAccounts({
      refetchType: "all"
    }),
    invalidateTransactions(undefined, {
      refetchType: "all"
    })
  ]);
}

function getAccount(
  accounts: AccountDetail[],
  accountKey: IAccountKey
): AccountDetail {
  const account = accounts.find(
    (a) =>
      a.identifier?.identifierId == accountKey.identifierId &&
      a.affiliation?.affiliationId == accountKey.affiliationId &&
      a.person?.personId == accountKey.personId &&
      a.account?.accountId == accountKey.accountId
  );

  if (!account?.account) {
    throw new Error("Unavailable or invalid account");
  }

  return account;
}
