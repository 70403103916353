import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { default as React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-elements";
import { default as AccountListItemComponent } from "../components/AccountListItemComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { AccountListSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import usePreselectedAccount from "../hooks/PreselectedAccountHook";
import {
  SendAmountSelectionScreenProps,
  SendSelectAccountStackParamsList
} from "../navigation/SendStackNavigation";
import { fetchAccounts, useAccounts } from "../services/AccountsService";
import { AffiliationStatus } from "../services/clients/PlatformClient";
import { spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";

export default function SendScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<StackNavigationProp<SendSelectAccountStackParamsList>>();
  const accountsQuery = useAccounts();
  const { preselectedAccount } = usePreselectedAccount();

  useEffect(() => {
    async function skipScreen() {
      const accounts = await fetchAccounts();
      if (!navigation.isFocused()) {
        return;
      }

      const account =
        accounts.length === 1
          ? accounts[0]
          : accounts.find(
              (a) =>
                a.identifier.identifierId ===
                  preselectedAccount?.identifierId &&
                a.affiliation.affiliationId ===
                  preselectedAccount?.affiliationId &&
                a.person?.personId === preselectedAccount?.personId &&
                a.account?.accountId === preselectedAccount?.accountId
            );

      if (account && account.affiliation.status !== AffiliationStatus.Offline) {
        const params: SendAmountSelectionScreenProps = {
          identifierId: account.identifier.identifierId,
          affiliationId: account.affiliation.affiliationId,
          personId: account.person!.personId,
          accountId: account.account!.accountId
        };
        if (accounts.length === 1) {
          navigation.replace("amountSelection", params);
        } else {
          navigation.navigate("amountSelection", params);
        }
      }
    }

    skipScreen();
  }, [preselectedAccount]);

  if (accountsQuery.isInitialLoading) {
    return <AccountListSkeleton />;
  }

  if (accountsQuery.data?.length === 0) {
    return (
      <EmptyContentComponent
        message={t(
          "SendScreenNoAccounts",
          "You don't have any account."
        )}></EmptyContentComponent>
    );
  }

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.sm,
            marginBottom: spacings.md,
            alignSelf: "center"
          }
        ]}>
        {t(
          "SendScreenSelectSourceAccountTitle",
          "Select the account to send from"
        )}
      </Text>
      {accountsQuery.data?.map((item, index) => {
        return (
          <AccountListItemComponent
            key={index}
            account={item}
            disabled={item.affiliation.status === AffiliationStatus.Offline}
            onPress={() => {
              const params: SendAmountSelectionScreenProps = {
                identifierId: item.identifier.identifierId,
                affiliationId: item.affiliation.affiliationId,
                personId: item.person!.personId,
                accountId: item.account!.accountId
              };
              navigation.navigate("amountSelection", params);
            }}></AccountListItemComponent>
        );
      })}
    </ScrollViewComponent>
  );
}
