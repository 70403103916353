import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { DeviceType, getDeviceTypeAsync } from "expo-device";
import { useFonts } from "expo-font";
import * as ScreenOrientation from "expo-screen-orientation";
import * as SplashScreen from "expo-splash-screen";
import countries from "i18n-iso-countries";
import deLocale from "i18n-iso-countries/langs/de.json";
import enLocale from "i18n-iso-countries/langs/en.json";
import esLocale from "i18n-iso-countries/langs/es.json";
import frLocale from "i18n-iso-countries/langs/fr.json";
import "intl";
import "intl-pluralrules";
import "intl/locale-data/jsonp/de-CH";
import "intl/locale-data/jsonp/de-DE";
import "intl/locale-data/jsonp/en-GB";
import "intl/locale-data/jsonp/en-US";
import "intl/locale-data/jsonp/es-ES";
import "intl/locale-data/jsonp/fr-CH";
import "intl/locale-data/jsonp/fr-FR";
import { default as React, useCallback, useEffect, useState } from "react";
import { Platform } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Toast from "react-native-toast-message";
import BusyIndicatoryOverlayComponent from "./src/components/BusyIndicatoryOverlayComponent";
import OfflineBannerComponent from "./src/components/OfflineBannerComponent";
import SmartBannerComponent from "./src/components/SmartBannerComponent";
import { toastConfig } from "./src/components/ToastLayoutComponent";
import UpdateAppComponent from "./src/components/UpdateAppComponent";
import useApplicationState from "./src/hooks/ApplicationStateHook";
import useRemoteServerState from "./src/hooks/RemoteServerStateHook";
import AuthenticationInterceptor from "./src/interceptors/AuthenticationInterceptor";
import Navigation from "./src/navigation";
import AuthProvider from "./src/providers/AuthenticationProvider";
import ReCaptchaProvider from "./src/providers/ReCaptchaProvider";
import "./src/providers/i18n";
import NoConnectionScreen, {
  ErrorType
} from "./src/screens/NoConnectionScreen";
import { getApplicationInfo } from "./src/services/AboutService";
import { setApplicationVersion } from "./src/services/AnalyticsService";
import { queryClient, queryClientPersister } from "./src/services/QueryService";

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);
  const [fontsLoaded] = useFonts({
    "Roboto-Regular": require("./src/assets/fonts/Roboto-Regular.ttf"),
    "RobotoMono-Regular": require("./src/assets/fonts/RobotoMono-Regular.ttf"),
    "FontAwesome-CustomKit": require("./src/assets/fonts/custom-icons.ttf")
  });
  const remoteServerState = useRemoteServerState();

  useApplicationState();

  useEffect(() => {
    async function prepare() {
      try {
        await SplashScreen.preventAutoHideAsync();

        const deviceType = await getDeviceTypeAsync();

        if (deviceType === DeviceType.PHONE && Platform.OS !== "web") {
          ScreenOrientation.lockAsync(
            ScreenOrientation.OrientationLock.PORTRAIT_UP
          );
        }

        await setApplicationVersion(getApplicationInfo().version ?? null);

        countries.registerLocale(frLocale);
        countries.registerLocale(enLocale);
        countries.registerLocale(esLocale);
        countries.registerLocale(deLocale);
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }
    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady && fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady, fontsLoaded]);

  if (!appIsReady || !fontsLoaded) {
    return null;
  }

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: queryClientPersister }}>
      <AuthProvider>
        <AuthenticationInterceptor>
          <ReCaptchaProvider>
            <SafeAreaProvider onLayout={onLayoutRootView}>
              <OfflineBannerComponent />

              {remoteServerState.maintenanceMode ? (
                <NoConnectionScreen errorType={ErrorType.ServerInMaintenance} />
              ) : (
                <GestureHandlerRootView style={{ flex: 1 }}>
                  <Navigation />
                </GestureHandlerRootView>
              )}
              <UpdateAppComponent />
            </SafeAreaProvider>

            {Platform.OS === "web" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}

            <SmartBannerComponent />
            <Toast config={toastConfig} />
            <BusyIndicatoryOverlayComponent />
          </ReCaptchaProvider>
        </AuthenticationInterceptor>
      </AuthProvider>
    </PersistQueryClientProvider>
  );
}
