import { addEventListener, getInitialURL } from "expo-linking";
import { DependencyList, useCallback, useEffect } from "react";
import { Platform } from "react-native";

export default function useDeepLinkHandlerHook(
  handler: (deepLinkUrl: string | null) => Promise<void>,
  deps: DependencyList
) {
  useEffect(() => {
    async function getInitialUrlAsync() {
      const initialUrl = await getInitialURL();
      await handler(initialUrl);
    }

    getInitialUrlAsync();
  }, []);

  useEffect(() => {
    if (Platform.OS === "web") {
      window.addEventListener("popstate", urlHandler);

      return () => window.removeEventListener("popstate", urlHandler);
    } else {
      const unsubscribe = addEventListener(
        "url",
        async (urlListener) => await handler(urlListener.url)
      );

      return () => unsubscribe.remove();
    }
  }, deps);

  const urlHandler = useCallback(async () => {
    await handler(window.location.href);
  }, [handler]);
}
