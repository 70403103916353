import { useMutation } from "@tanstack/react-query";
import Constants from "expo-constants";
import { fetchAccounts, IPersonKey } from "./AccountsService";
import { IAffiliationKey } from "./AffiliationsService";
import {
  ActivatePersonRequest,
  DeletePerson,
  IActivatePersonRequest,
  IDeletePerson,
  IPersonInfo,
  PersonInfo,
  RequestDeletePerson
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";
import {
  invalidateAccounts,
  invalidateAffiliationCardLayout,
  invalidateTransactions
} from "./QueryService";

async function createPerson(
  affiliationKey: IAffiliationKey,
  personInfo: IPersonInfo,
  recaptchaToken?: string
) {
  const platformClient = platformClientFactory.create();

  await platformClient.createPerson(
    affiliationKey.identifierId,
    affiliationKey.affiliationId,
    recaptchaToken,
    new PersonInfo(personInfo)
  );

  await Promise.all([
    invalidateAccounts({
      refetchType: "all"
    }),
    invalidateTransactions(undefined, {
      refetchType: "all"
    })
  ]);

  const accounts = await fetchAccounts();
  const newlyCreatedAccount = accounts.find(
    (a) => a.affiliation.affiliationId === affiliationKey.affiliationId
  );

  return newlyCreatedAccount?.person;
}

async function activatePerson(
  personKey: IPersonKey,
  activatePersonRequest: IActivatePersonRequest
) {
  const platformClient = platformClientFactory.create();

  await platformClient.activatePerson(
    personKey.identifierId,
    personKey.affiliationId,
    personKey.personId,
    new ActivatePersonRequest(activatePersonRequest)
  );

  await Promise.all([
    invalidateAccounts({
      refetchType: "all"
    }),
    invalidateAffiliationCardLayout({
      refetchType: "all"
    })
  ]);
}

async function deletePerson(personKey: IPersonKey, model: IDeletePerson) {
  const platformClient = platformClientFactory.create();

  await platformClient.deletePerson(
    personKey.identifierId,
    personKey.affiliationId,
    personKey.personId,
    new DeletePerson(model)
  );

  await Promise.all([
    invalidateAccounts({
      refetchType: "all"
    }),
    invalidateTransactions(undefined, {
      refetchType: "all"
    })
  ]);
}

async function requestDeletePerson(personKey: IPersonKey) {
  const platformClient = platformClientFactory.create();
  const model: RequestDeletePerson = new RequestDeletePerson({
    callbackUrl: Constants.expoConfig?.extra?.deletePersonCallback
  });

  await platformClient.requestDeletePerson(
    personKey.identifierId,
    personKey.affiliationId,
    personKey.personId,
    model
  );
}

export function useCreatePerson() {
  return useMutation(
    ({
      affiliationKey,
      personInfo,
      recaptchaToken
    }: {
      affiliationKey: IAffiliationKey;
      personInfo: IPersonInfo;
      recaptchaToken?: string;
    }) => createPerson(affiliationKey, personInfo, recaptchaToken)
  );
}

export function useActivatePerson() {
  return useMutation(
    ({
      personKey,
      activatePersonRequest
    }: {
      personKey: IPersonKey;
      activatePersonRequest: IActivatePersonRequest;
    }) => activatePerson(personKey, activatePersonRequest)
  );
}

export function useDeletePerson() {
  return useMutation(
    ({ personKey, model }: { personKey: IPersonKey; model: IDeletePerson }) =>
      deletePerson(personKey, model)
  );
}

export function useRequestDeletePerson() {
  return useMutation(({ personKey }: { personKey: IPersonKey }) =>
    requestDeletePerson(personKey)
  );
}
