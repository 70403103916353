import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { fetchAccount, fetchAccounts, IAccountKey } from "./AccountsService";
import { getDataUrl } from "./BlobService";
import {
  ApiException,
  IAffiliation,
  IAffiliationContact,
  ICreditCard
} from "./clients/PlatformClient";
import { platformClientFactory } from "./clients/PlatformClientFactory";
import {
  accountsQueryKey,
  affiliationContactDataQueryKey,
  affiliationLogoQueryKey,
  affiliationQueryKey,
  affiliationsQueryKey,
  dependentAffiliationsQueryKey,
  paymentModesQueryKey,
  queryClient,
  termsAndConditionsQueryKey
} from "./QueryService";

export type PaymentModeDetails = {
  paymentMode: PaymentMode;
  minimumAmount?: number;
  registeredCreditCard?: ICreditCard;
};
export enum PaymentMode {
  CreditCard,
  Twint,
  PaymentSlip
}

export const affiliationLogoStaleTime: number = 60 * 60 * 1000;

export interface IAffiliationKey {
  identifierId: number;
  affiliationId: number;
}

async function getAffiliationLogo(
  affiliationId: number
): Promise<string | null> {
  const platformClient = platformClientFactory.create();

  try {
    const fileResponse = await platformClient.getAffiliationLogoById(
      affiliationId
    );
    return getDataUrl(fileResponse.data);
  } catch (e) {
    if (e instanceof ApiException && e.status <= 400) {
      // No logo defined
      return null;
    }

    throw e;
  }
}

async function getPaymentModes(
  accountKey: IAccountKey
): Promise<PaymentModeDetails[]> {
  const accountDetail = await fetchAccount(accountKey);

  const affiliationConfiguration = accountDetail.affiliation.configuration;
  const acceptedLoadModes: PaymentModeDetails[] = [];
  if (affiliationConfiguration.loadByCreditCard) {
    const platformClient = platformClientFactory.create();
    const creditCards = await platformClient.getRegisteredCreditCards(
      accountKey.identifierId,
      accountKey.affiliationId,
      accountKey.personId,
      accountKey.accountId
    );

    creditCards.forEach((a) => {
      acceptedLoadModes.push({
        paymentMode: PaymentMode.CreditCard,
        minimumAmount: affiliationConfiguration.loadByCreditCardMinimumAmount,
        registeredCreditCard: a
      });
    });
    acceptedLoadModes.push({
      paymentMode: PaymentMode.CreditCard,
      minimumAmount: affiliationConfiguration.loadByCreditCardMinimumAmount
    });
  }
  if (affiliationConfiguration.loadByTwint) {
    acceptedLoadModes.push({
      paymentMode: PaymentMode.Twint,
      minimumAmount: affiliationConfiguration.loadByCreditCardMinimumAmount
    });
  }
  if (affiliationConfiguration.requestPaymentSlip) {
    acceptedLoadModes.push({
      paymentMode: PaymentMode.PaymentSlip,
      minimumAmount: affiliationConfiguration.requestPaymentSlipMinimumAmount
    });
  }

  return acceptedLoadModes;
}

async function getAllAffiliations(): Promise<IAffiliation[]> {
  const platformClient = platformClientFactory.create();
  let affiliations = await platformClient.getAllAffiliations();

  affiliations = orderBy(affiliations, [(a) => a.name?.toLowerCase()], ["asc"]);
  return affiliations;
}

async function getAffiliationContact(
  affiliationId: number
): Promise<IAffiliationContact> {
  const platformClient = platformClientFactory.create();
  const contactData = await platformClient.getAffiliationContact(affiliationId);

  return contactData;
}

async function getAffiliationById(
  affiliationId: number
): Promise<IAffiliation> {
  const platformClient = platformClientFactory.create();
  const affiliation = await platformClient.getAffiliationById(affiliationId);

  return affiliation;
}

export function useAffiliationById(
  affiliationId: number,
  options?: UseQueryOptions<
    IAffiliation,
    unknown,
    IAffiliation,
    (string | number)[]
  >
) {
  return useQuery(
    [affiliationQueryKey, affiliationId],
    () => getAffiliationById(affiliationId),
    {
      ...options
    }
  );
}

async function getDependentAffiliations(
  affiliationId: number
): Promise<IAffiliation[]> {
  const platformClient = platformClientFactory.create();
  let dependentAffiliations = await platformClient.getAffiliationDependents(
    affiliationId
  );

  dependentAffiliations = orderBy(
    dependentAffiliations,
    [(a) => a.name?.toLowerCase()],
    ["asc"]
  );
  return dependentAffiliations;
}

async function getAffiliationTermsAndConditions(
  affiliationKey: IAffiliationKey,
  languageCode: string
): Promise<string | null> {
  const platformClient = platformClientFactory.create();

  try {
    var fileResponse = await platformClient.getTermsAndConditions(
      affiliationKey.identifierId,
      affiliationKey.affiliationId,
      languageCode
    );
  } catch (e) {
    if (e instanceof ApiException && e.status <= 400) {
      // No terms and conditions defined
      return null;
    }

    throw e;
  }

  const pdfType = "application/pdf";
  if (fileResponse.data.type !== pdfType) {
    fileResponse.data = new Blob([fileResponse.data], {
      type: pdfType
    });
  }

  return await getDataUrl(fileResponse.data);
}

export function usePaymentModes(accountKey: IAccountKey) {
  return useQuery([paymentModesQueryKey, accountKey.affiliationId], () =>
    getPaymentModes(accountKey)
  );
}

export function useAffiliationLogo(affiliationId: number) {
  return useQuery(
    [affiliationLogoQueryKey, affiliationId],
    () => getAffiliationLogo(affiliationId),
    { staleTime: affiliationLogoStaleTime }
  );
}

export function fetchAffiliationTermsAndConditions(
  affiliationKey: IAffiliationKey,
  languageCode: string
): Promise<string | null> {
  return queryClient.fetchQuery<string | null>(
    [termsAndConditionsQueryKey, affiliationKey, languageCode],
    () => getAffiliationTermsAndConditions(affiliationKey, languageCode)
  );
}

export function useAffiliations(identifierId: number) {
  return useQuery(
    [accountsQueryKey, { identifierId: identifierId }],
    async () => {
      const accounts = await fetchAccounts();
      return (
        accounts.find((a) => a.identifier.identifierId === identifierId)
          ?.identifier.affiliations ?? []
      );
    }
  );
}

export function useAllAffiliations() {
  return useQuery([affiliationsQueryKey], getAllAffiliations);
}

export function useAffiliationContact(
  affiliationId: number,
  options?: UseQueryOptions<
    IAffiliationContact,
    unknown,
    IAffiliationContact,
    (string | number)[]
  >
) {
  return useQuery(
    [affiliationContactDataQueryKey, affiliationId],
    () => getAffiliationContact(affiliationId),
    options
  );
}

export function useDependentAffiliations(affiliationId: number) {
  return useQuery([dependentAffiliationsQueryKey, affiliationId], () =>
    getDependentAffiliations(affiliationId)
  );
}

export async function fetchDependentAffiliations(affiliationId: number) {
  const affiliations = await queryClient.fetchQuery<IAffiliation[]>(
    [dependentAffiliationsQueryKey, affiliationId],
    () => getDependentAffiliations(affiliationId)
  );
  return affiliations;
}

export function useAffiliation(affiliationKey: IAffiliationKey) {
  return useQuery([accountsQueryKey, affiliationKey], () =>
    fetchAffiliation(affiliationKey)
  );
}

export async function fetchAffiliation(
  affiliationKey: IAffiliationKey
): Promise<IAffiliation> {
  const accounts = await fetchAccounts();
  const account = accounts.find(
    (a) =>
      a.identifier.identifierId === affiliationKey.identifierId &&
      a.affiliation.affiliationId === affiliationKey.affiliationId
  );

  if (!account?.affiliation) {
    throw new Error("Unavailable or invalid affiliation");
  }

  return account.affiliation;
}
