import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { View } from "react-native";
import { ListItem, Text } from "react-native-elements";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";
import { humanizeCurrency } from "../services/HumanizerService";
import { ITransactionAccount } from "../services/TransactionsService";
import { colors } from "../styles/Colors";
import { cornerRadius, spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";

export default function TransactionsListItemComponent({
  item,
  dateFormat
}: {
  item: ITransactionAccount;
  dateFormat: (dates: Date) => string;
}) {
  const navigation = useNavigation<StackNavigationProp<HomeStackParamsList>>();

  const listItemKey = `${item.identifierId}/${item.affiliationId}/${item.personId}/${item.accountId}/${item.transactionId}`;

  return (
    <ListItem
      containerStyle={{ paddingRight: spacings.md }}
      onPress={() =>
        navigation.navigate("transactionsStack", {
          screen: "transactionDetail",
          params: {
            identifierId: item.identifierId,
            affiliationId: item.affiliationId,
            personId: item.personId,
            accountId: item.accountId,
            transactionId: item.transactionId
          }
        })
      }
      key={listItemKey}>
      <ListItem.Content>
        <View
          style={{
            alignSelf: "stretch",
            flexDirection: "row",
            justifyContent: "space-between"
          }}>
          <View
            style={{
              flexShrink: 1,
              paddingRight: spacings.md
            }}>
            <Text
              style={[typographies.body, { color: colors.primary }]}
              numberOfLines={1}>
              {item.element}
              {item.description && (
                <Text style={typographies.small}> {item.description}</Text>
              )}
            </Text>
            <Text numberOfLines={1} style={typographies.small}>
              {dateFormat(item.date)}
            </Text>
          </View>
          <View style={{ alignSelf: "center" }}>
            <View
              style={{
                backgroundColor:
                  item.amount > 0
                    ? colors.success
                    : colors.background.transparent,
                borderRadius: cornerRadius.sm,
                position: "absolute",
                opacity: 0.1,
                left: 0,
                top: 0,
                right: 0,
                bottom: 0
              }}></View>
            <Text
              style={[
                typographies.body,
                {
                  color: item.amount > 0 ? colors.success : colors.text.dark,
                  padding: spacings.sm,
                  flexShrink: 0
                }
              ]}
              numberOfLines={1}>
              {humanizeCurrency(item.amount, item.currency)}
            </Text>
          </View>
        </View>
      </ListItem.Content>
    </ListItem>
  );
}
