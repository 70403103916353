import { faArrowUpFromBracket } from "@fortawesome/pro-light-svg-icons/faArrowUpFromBracket";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useRoute } from "@react-navigation/core";
import {
  NavigationProp,
  RouteProp,
  useNavigation
} from "@react-navigation/native";
import { format } from "date-fns";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, StyleProp, TextStyle, View } from "react-native";
import { Button, Text } from "react-native-elements";
import AffiliationLogoComponent from "../components/AffiliationLogoComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { TransactionDetailSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useScreenSize from "../hooks/ScreenSizeHook";
import { TransactionsStackParamsList } from "../navigation/HomeStackNavigation";
import i18n from "../providers/i18n";
import { useAccount } from "../services/AccountsService";
import { useAffiliationContact } from "../services/AffiliationsService";
import { humanizeCurrency } from "../services/HumanizerService";
import { toastError } from "../services/ToastService";
import {
  ITransactionKey,
  useExportTransaction,
  useTransaction
} from "../services/TransactionsService";
import { TransactionType } from "../services/clients/PlatformClient";
import { colors } from "../styles/Colors";
import {
  cornerRadius,
  fontFamilies,
  fontSizes,
  iconSizes,
  primaryLinkButtonStyle,
  spacings
} from "../styles/Styles";

export default function TransactionDetailScreen() {
  const route =
    useRoute<RouteProp<TransactionsStackParamsList, "transactionDetail">>();
  const horizontalLine = "-".repeat(42);
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const navigation =
    useNavigation<NavigationProp<TransactionsStackParamsList>>();
  const textStyle: StyleProp<TextStyle> = {
    fontFamily: fontFamilies.mono,
    fontSize: screenSize.small ? 10 : fontSizes.md
  };

  const transactionQuery = useTransaction({
    identifierId: route.params.identifierId,
    affiliationId: route.params.affiliationId,
    personId: route.params.personId,
    accountId: route.params.accountId,
    transactionId: route.params.transactionId
  });
  const exportTransaction = useExportTransaction();

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        exportTransaction.isLoading ? (
          <ActivityIndicator
            color={colors.primary}
            style={{
              marginRight: spacings.md
            }}
          />
        ) : (
          <Button
            onPress={exportTransactionAsync}
            type="clear"
            icon={
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                color={colors.text.link}
                size={iconSizes.lg}></FontAwesomeIcon>
            }
            titleStyle={primaryLinkButtonStyle.title}></Button>
        )
    });
  }, [exportTransaction.isLoading]);

  const affiliationContactQuery = useAffiliationContact(
    route.params.affiliationId
  );

  const accountQuery = useAccount({
    identifierId: route.params.identifierId,
    affiliationId: route.params.affiliationId,
    personId: route.params.personId,
    accountId: route.params.accountId
  });

  if (
    transactionQuery.isInitialLoading ||
    accountQuery.isInitialLoading ||
    affiliationContactQuery.isInitialLoading
  ) {
    return <TransactionDetailSkeleton />;
  }

  if (!transactionQuery.data) {
    return (
      <EmptyContentComponent
        message={t(
          "TransactionDetailScreenTransactionNotAvailable",
          "The selected transaction is currently unavailable."
        )}></EmptyContentComponent>
    );
  }

  return (
    <ScrollViewComponent
      scrollViewContentContainerStyle={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: spacings.md
      }}>
      {transactionQuery.data.receipt ? (
        <View
          style={{
            borderRadius: cornerRadius.md,
            backgroundColor: colors.background.light
          }}>
          <Text
            allowFontScaling={false}
            style={[
              textStyle,
              {
                margin: screenSize.small ? spacings.lg : spacings.xl
              }
            ]}>
            {transactionQuery.data.receipt}
          </Text>
        </View>
      ) : (
        <View
          style={{
            borderRadius: cornerRadius.md,
            backgroundColor: colors.background.light,
            padding: spacings.xl,
            maxWidth: screenSize.small ? 293 : 444
          }}>
          <View style={{ alignItems: "center" }}>
            <AffiliationLogoComponent
              affiliationId={route.params.affiliationId}
            />
          </View>
          <Text
            allowFontScaling={false}
            style={[
              textStyle,
              {
                marginTop: spacings.xl,
                textAlign: "center"
              }
            ]}>
            {accountQuery.data?.affiliation.name}
            {"\n"}
            {affiliationContactQuery.data?.address}
            {"\n"}
            {affiliationContactQuery.data?.countryCode.toUpperCase()}
            {"-"}
            {affiliationContactQuery.data?.postalCode}{" "}
            {affiliationContactQuery.data?.city}
          </Text>

          <Text
            allowFontScaling={false}
            style={[textStyle, { marginTop: spacings.xxxl }]}>
            #{transactionQuery.data.transactionId}
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <Text
              allowFontScaling={false}
              style={[textStyle, { flexShrink: 1 }]}>
              {transactionQuery.data.element}
            </Text>
            <Text
              allowFontScaling={false}
              numberOfLines={1}
              style={[textStyle, { alignSelf: "flex-end" }]}>
              {format(transactionQuery.data.date, "Pp")}
            </Text>
          </View>

          <Text
            allowFontScaling={false}
            ellipsizeMode="clip"
            numberOfLines={1}
            style={textStyle}>
            {horizontalLine}
          </Text>

          <Text allowFontScaling={false} style={textStyle}>
            {transactionQuery.data.description}
          </Text>

          <Text
            allowFontScaling={false}
            ellipsizeMode="clip"
            numberOfLines={1}
            style={textStyle}>
            {horizontalLine}
          </Text>

          <Text
            allowFontScaling={false}
            style={[
              textStyle,
              {
                textAlign: "right"
              }
            ]}>
            {getTransactionTypeName(transactionQuery.data.type)}{" "}
            {humanizeCurrency(
              transactionQuery.data.amount,
              transactionQuery.data.currency
            )}
          </Text>

          <Text
            allowFontScaling={false}
            style={[
              textStyle,
              {
                marginTop: spacings.xxxl,
                marginBottom: spacings.xl,
                textAlign: "center"
              }
            ]}>
            {t(
              "TransactionDetailScreenReceiptNotValid",
              "*** Receipt not valid ***\n*** Information purpose only ***"
            )}
          </Text>
        </View>
      )}
    </ScrollViewComponent>
  );

  async function exportTransactionAsync() {
    try {
      let transactionKey: ITransactionKey = {
        accountId: route.params.accountId,
        affiliationId: route.params.affiliationId,
        identifierId: route.params.identifierId,
        personId: route.params.personId,
        transactionId: route.params.transactionId
      };

      return await exportTransaction.mutateAsync({
        transactionKey: transactionKey,
        languageCode: i18n.language
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "TransactionDetailScreenExportError",
          "An error ocurred when exporting the transaction."
        )
      );
    }
  }
}

function getTransactionTypeName(type: TransactionType) {
  switch (type) {
    case TransactionType.AmericanExpress:
      return t("TransactionType.AmericanExpress", "AmericanExpress");
    case TransactionType.Cash:
      return t("TransactionType.Cash", "Cash");
    case TransactionType.CashCard:
      return t("TransactionType.CashCard", "CashCard");
    case TransactionType.Cheque:
      return t("TransactionType.Cheque", "Cheque");
    case TransactionType.Diners:
      return t("TransactionType.Diners", "Diners Club");
    case TransactionType.Epurse:
      return t("TransactionType.Epurse", "polyright");
    case TransactionType.Invoice:
      return t("TransactionType.Invoice", "Invoice");
    case TransactionType.Maestro:
      return t("TransactionType.Maestro", "Maestro");
    case TransactionType.MasterCard:
      return t("TransactionType.MasterCard", "Mastercard");
    case TransactionType.Ocas:
      return t("TransactionType.Ocas", "ocas");
    case TransactionType.Others:
      return t("TransactionType.Others", "Others");
    case TransactionType.Otra:
      return t("TransactionType.Otra", "otra");
    case TransactionType.PaymentSlip:
      return t("TransactionType.PaymentSlip", "QR-Bill");
    case TransactionType.Paypal:
      return t("TransactionType.Paypal", "Paypal");
    case TransactionType.PostCard:
      return t("TransactionType.PostCard", "PostFinance");
    case TransactionType.Transfer:
      return t("TransactionType.Transfer", "Transfer");
    case TransactionType.Twint:
      return t("TransactionType.Twint", "Twint");
    case TransactionType.VPay:
      return t("TransactionType.VPay", "VPay");
    case TransactionType.Visa:
      return t("TransactionType.Visa", "Visa");
    case TransactionType.Voucher:
      return t("TransactionType.Voucher", "Voucher");
    default:
      return t("TransactionType.Others", "Others");
  }
}
