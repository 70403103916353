import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, TextInput, View } from "react-native";
import { Button } from "react-native-elements";
import AddressDataComponent from "../components/AddressDataComponent";
import CountrySelectionComponent from "../components/CountrySelectionComponent";
import NameDataComponent from "../components/NameDataComponent";
import OptionalPersonalDataComponent from "../components/OptionalPersonalDataComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { ProfileStackParamsList } from "../navigation/ProfileStackNavigation";
import i18n from "../providers/i18n";
import { useProfile, useUpdateProfile } from "../services/ProfileService";
import { toastError, toastSuccess } from "../services/ToastService";
import { IProfileInfo } from "../services/clients/PlatformClient";
import { largePrimaryRoundedButtonStyle } from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { isMobilePhone } from "../utils/PhoneValidator";

export default function PersonalDataScreen() {
  const profileQuery = useProfile();
  const updateProfileInformationMutation = useUpdateProfile();
  const navigation = useNavigation<NavigationProp<ProfileStackParamsList>>();

  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [address, setAddress] = useState<string | undefined>();
  const [city, setCity] = useState<string | undefined>();
  const [postalCode, setPostalCode] = useState<string | undefined>();
  const [countryCode, setCountryCode] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [birthdate, setBirthdate] = useState<Date | undefined>();

  const [displayCountrySelection, setDisplayCountrySelection] = useState(false);

  const isFormInvalid =
    !firstName ||
    !lastName ||
    !address ||
    !city ||
    !postalCode ||
    !countryCode ||
    (phoneNumber && !isMobilePhone(phoneNumber));

  const addressRef = useRef<TextInput>(null);
  const phoneRef = useRef<TextInput>(null);

  useEffect(() => {
    setFirstName(profileQuery.data?.firstName);
    setLastName(profileQuery.data?.lastName);
    setAddress(profileQuery.data?.address);
    setCity(profileQuery.data?.city);
    setPostalCode(profileQuery.data?.postalCode);
    setCountryCode(profileQuery.data?.countryCode);
    setPhoneNumber(profileQuery.data?.phoneNumber);
    setBirthdate(profileQuery.data?.birthdate);
  }, [profileQuery.data]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", (e) => {
      // If already has been prevented, we need to dispatch the blocked action
      if (e.defaultPrevented) {
        navigation.dispatch(e.data.action);
        return;
      }

      // If we are not in the language selection, we want to go back
      if (!displayCountrySelection) {
        return;
      }

      e.preventDefault();
      setDisplayCountrySelection(false);
    });
    return unsubscribe;
  }, [displayCountrySelection, navigation]);

  if (profileQuery.isInitialLoading) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  if (displayCountrySelection) {
    return (
      <CountrySelectionComponent
        defaultCountryCode={countryCode}
        onCountrySelection={(value) => {
          setCountryCode(value);
          setDisplayCountrySelection(false);
        }}
      />
    );
  }

  return (
    <ScrollViewComponent>
      <NameDataComponent
        defaultFirstName={firstName ?? profileQuery.data?.firstName}
        defaultLastName={lastName ?? profileQuery.data?.lastName}
        onFirstNameChanged={setFirstName}
        onLastNameChanged={setLastName}
        onSubmitCallback={() => addressRef.current?.focus()}
      />

      <AddressDataComponent
        defaultAddress={address ?? profileQuery.data?.address}
        defaultCity={city ?? profileQuery.data?.city}
        defaultPostalCode={postalCode ?? profileQuery.data?.postalCode}
        defaultCountryCode={countryCode ?? profileQuery.data?.countryCode}
        onAddressChanged={setAddress}
        onCityChanged={setCity}
        onPostalCodeChanged={setPostalCode}
        onCountrySelection={() => setDisplayCountrySelection(true)}
        addressInputReference={addressRef}
      />

      <OptionalPersonalDataComponent
        defaultBirthdate={birthdate ?? profileQuery.data?.birthdate}
        defaultPhoneNumber={phoneNumber ?? profileQuery.data?.phoneNumber}
        onBirthdateChanged={setBirthdate}
        onPhoneNumberChanged={setPhoneNumber}
        phoneRef={phoneRef}
        onSubmitCallback={saveProfile}
      />
      <Button
        title={t("PersonalDataScreenButtonSave", "Save")}
        disabled={isFormInvalid || updateProfileInformationMutation.isLoading}
        onPress={saveProfile}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        loading={updateProfileInformationMutation.isLoading}
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.xxl
          }
        ]}></Button>
    </ScrollViewComponent>
  );

  async function saveProfile() {
    if (isFormInvalid) {
      return;
    }

    const profileInfo: IProfileInfo = {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      languageCode: i18n.language?.trim() || undefined,
      address: address?.trim() || undefined,
      city: city?.trim() || undefined,
      postalCode: postalCode?.trim() || undefined,
      countryCode: countryCode?.trim() || undefined,
      birthdate: birthdate,
      phoneNumber: phoneNumber?.trim() || undefined
    };

    try {
      await updateProfileInformationMutation.mutateAsync(profileInfo);

      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "PersonalDataScreenProfileUpdatedMessage",
          "Profile updated successfully"
        )
      );
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "PersonalDataScreenProfileUpdateErrorMessage",
          "An error occurred when updating the profile information"
        )
      );
    }
  }
}
