import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-elements";
import AccountListItemComponent from "../components/AccountListItemComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { AccountListSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import usePreselectedAccount from "../hooks/PreselectedAccountHook";
import {
  LoadAmountSelectionScreenProps,
  LoadSelectAccountStackParamsList
} from "../navigation/LoadStackNavigation";
import {
  AccountDetail,
  fetchAccounts,
  useAccounts
} from "../services/AccountsService";
import { AffiliationStatus } from "../services/clients/PlatformClient";
import { spacings } from "../styles/Constants";
import { typographies } from "../styles/Fonts";

export default function LoadScreen() {
  const accountsQuery = useAccounts();
  const { preselectedAccount } = usePreselectedAccount();
  const { t } = useTranslation();
  const navigation =
    useNavigation<StackNavigationProp<LoadSelectAccountStackParamsList>>();

  useEffect(() => {
    async function skipScreen() {
      const accounts = await fetchAccounts();
      if (!navigation.isFocused()) {
        return;
      }

      const account =
        accounts.length === 1
          ? accounts[0]
          : preselectedAccount
          ? accounts.find(
              (a) =>
                a.identifier.identifierId === preselectedAccount.identifierId &&
                a.affiliation.affiliationId ===
                  preselectedAccount.affiliationId &&
                a.person?.personId === preselectedAccount.personId &&
                a.account?.accountId === preselectedAccount.accountId
            )
          : undefined;

      if (account && account.affiliation.status !== AffiliationStatus.Offline) {
        const params: LoadAmountSelectionScreenProps = {
          identifierId: account.identifier.identifierId,
          affiliationId: account.affiliation.affiliationId,
          personId: account.person!.personId,
          accountId: account.account!.accountId
        };
        if (accounts.length === 1) {
          navigation.replace("loadAmountStack", {
            screen: "amountSelection",
            params
          });
        } else {
          navigation.navigate("loadAmountStack", {
            screen: "amountSelection",
            params
          });
        }
      }
    }

    skipScreen();
  }, [preselectedAccount]);

  if (accountsQuery.isInitialLoading) {
    return <AccountListSkeleton />;
  }

  if (accountsQuery.data?.length === 0) {
    return (
      <EmptyContentComponent
        message={t(
          "LoadScreenNoAccounts",
          "You don't have any account."
        )}></EmptyContentComponent>
    );
  }

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.sm,
            marginBottom: spacings.md,
            alignSelf: "center"
          }
        ]}>
        {t("LoadScreenSelectAccountTitle", "Select wallet to load")}
      </Text>

      <View style={{ width: "100%", flex: 1 }}>
        <DisplayAccounts
          accounts={accountsQuery.data ?? []}
          onPress={accountSelected}></DisplayAccounts>
      </View>
    </ScrollViewComponent>
  );

  function accountSelected(account: AccountDetail): void {
    const params: LoadAmountSelectionScreenProps = {
      identifierId: account.identifier.identifierId,
      affiliationId: account.affiliation.affiliationId,
      personId: account.person!.personId,
      accountId: account.account!.accountId
    };
    navigation.navigate("loadAmountStack", {
      screen: "amountSelection",
      params
    });
  }
}

function DisplayAccounts({
  accounts,
  onPress
}: {
  accounts: AccountDetail[];
  onPress: (account: AccountDetail) => void;
}) {
  if (!accounts.length) {
    return null;
  }

  return (
    <View style={{ width: "100%", flex: 1 }}>
      {accounts.map((item, index) => {
        return (
          <AccountListItemComponent
            key={index}
            account={item}
            disabled={item.affiliation.status === AffiliationStatus.Offline}
            onPress={() => onPress(item)}></AccountListItemComponent>
        );
      })}
    </View>
  );
}
