import { faAddressBook } from "@fortawesome/pro-light-svg-icons/faAddressBook";
import { faAt } from "@fortawesome/pro-light-svg-icons/faAt";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { isEqual } from "lodash";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, TextInput, View } from "react-native";
import { Button, Input, Switch, Text } from "react-native-elements";
import validator from "validator";
import AccountListItemComponent from "../components/AccountListItemComponent";
import { ResultType } from "../components/ActionResultComponent";
import AmountSelectionComponent from "../components/AmountSelectionComponent";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import { SendAmountSelectionSkeleton } from "../components/LoadingSkeletonComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { RootStackParamsList } from "../navigation";
import { SendSelectAccountStackParamsList } from "../navigation/SendStackNavigation";
import {
  AccountDetail,
  IAccountKey,
  useAccount,
  useAccounts,
  useTransferToAccount,
  useTransferToIdentifier
} from "../services/AccountsService";
import { useBeneficiaries } from "../services/BeneficiariesService";
import { humanizeCurrency } from "../services/HumanizerService";
import {
  AffiliationStatus,
  IBeneficiary,
  ITransferToAccountInfo,
  ITransferToIdentifierInfo,
  IdentifierType
} from "../services/clients/PlatformClient";
import { virtualizedListItemStyle } from "../styles/Lists";
import {
  colors,
  cornerRadius,
  largeInputStyle,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";
import { BeneficiaryListItem } from "./BeneficiariesScreen";

export default function SendAmountSelectionScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  const route =
    useRoute<RouteProp<SendSelectAccountStackParamsList, "amountSelection">>();
  const { identifierId, affiliationId, personId, accountId } = route.params;

  const [destinationAccount, setDestinationAccount] = useState<AccountDetail>();
  const [destinationBeneficiary, setDestinationBeneficiary] =
    useState<IBeneficiary>();
  const [newDestinationBeneficiary, setNewDestinationBeneficiary] =
    useState<NewBeneficiaryDetail>();
  const [amount, setAmount] = useState<number>();
  const [purpose, setPurpose] = useState("");
  const amountSelectionRef = useRef<TextInput>(null);

  const accountsQuery = useAccounts({
    select: (accounts) =>
      accounts.filter(
        (a) =>
          a.affiliation.affiliationId === affiliationId &&
          a.account?.accountId !== accountId
      )
  });
  const beneficiariesQuery = useBeneficiaries({ identifierId, affiliationId });

  const sourceAccountKey: IAccountKey = {
    identifierId: identifierId,
    affiliationId: affiliationId,
    personId: personId,
    accountId: accountId
  };
  const sourceAccountQuery = useAccount(sourceAccountKey);

  const transferToIdentifierMutation = useTransferToIdentifier();
  const transferToAccountMutation = useTransferToAccount();

  const availableBalance = Math.min(
    sourceAccountQuery.data?.account?.balance ?? 0,
    sourceAccountQuery.data?.account?.maximumUnloadAmount ?? 0
  );

  const isNewBeneficiaryValid =
    newDestinationBeneficiary?.email !== undefined &&
    validator.isEmail(newDestinationBeneficiary?.email) &&
    (!newDestinationBeneficiary.remember ||
      !validator.isEmpty(newDestinationBeneficiary.alias));

  const isFormInvalid =
    !amount ||
    transferToAccountMutation.isLoading ||
    transferToIdentifierMutation.isLoading ||
    (!destinationAccount &&
      !destinationBeneficiary &&
      !newDestinationBeneficiary) ||
    (newDestinationBeneficiary && !isNewBeneficiaryValid);

  useEffect(() => {
    const source = sourceAccountQuery.data?.person?.formattedName;
    const destination =
      destinationAccount?.person?.formattedName ||
      destinationBeneficiary?.name ||
      newDestinationBeneficiary?.alias ||
      newDestinationBeneficiary?.email;

    if (source && destination) {
      setPurpose(
        t(
          "SendAmountSelectionScreenDefaultPurpose",
          "Transfer from {{source}} to {{destination}}",
          {
            source: source,
            destination: destination
          }
        )
      );
    }
  }, [
    sourceAccountQuery.data,
    destinationAccount,
    destinationBeneficiary,
    newDestinationBeneficiary
  ]);

  useEffect(() => {
    if (destinationBeneficiary || destinationAccount)
      amountSelectionRef.current?.focus();
  }, [destinationBeneficiary, destinationAccount]);

  if (
    sourceAccountQuery.isInitialLoading ||
    accountsQuery.isInitialLoading ||
    beneficiariesQuery.isInitialLoading
  ) {
    return <SendAmountSelectionSkeleton />;
  }

  if (
    !sourceAccountQuery.data?.affiliation.configuration.transferBetweenAccounts
  ) {
    return (
      <EmptyContentComponent
        message={t(
          "SendAmountSelectionScreenNotAllowed",
          "This affiliation does not accept the transfer through SECANDA."
        )}></EmptyContentComponent>
    );
  }

  async function transferMoneyAsync() {
    if (isFormInvalid) {
      return;
    }

    try {
      if (destinationAccount) {
        if (
          destinationAccount.person?.personId &&
          destinationAccount.account?.accountId
        ) {
          const model: ITransferToAccountInfo = {
            accountId: destinationAccount.account?.accountId,
            affiliationId: destinationAccount.affiliation.affiliationId,
            identifierId: destinationAccount.identifier.identifierId,
            personId: destinationAccount.person?.personId,
            amount: amount,
            purpose: purpose
          };

          await transferToAccountMutation.mutateAsync({
            accountKey: sourceAccountKey,
            model
          });
        }
      } else if (destinationBeneficiary) {
        const model: ITransferToIdentifierInfo = {
          value: destinationBeneficiary.value,
          type: IdentifierType.Email,
          amount: amount,
          name: destinationBeneficiary.name,
          remember: undefined,
          purpose: purpose
        };
        await transferToIdentifierMutation.mutateAsync({
          accountKey: sourceAccountKey,
          model
        });
      } else if (newDestinationBeneficiary) {
        const model: ITransferToIdentifierInfo = {
          value: newDestinationBeneficiary.email,
          type: IdentifierType.Email,
          amount: amount,
          name: newDestinationBeneficiary?.remember
            ? newDestinationBeneficiary.alias
            : undefined,
          remember: newDestinationBeneficiary?.remember,
          purpose: purpose
        };
        await transferToIdentifierMutation.mutateAsync({
          accountKey: sourceAccountKey,
          model
        });
      } else {
        return;
      }

      navigation.navigate("sendAmountResult", {
        accountId: accountId,
        affiliationId: affiliationId,
        identifierId: identifierId,
        personId: personId,
        amount: amount,
        currency: sourceAccountQuery.data!.account!.currency,
        type: ResultType.Success,
        description: t(
          "SendAmountResultSuccessDescription",
          "Amount successfully transferred"
        )
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("sendAmountResult", {
          accountId: accountId,
          affiliationId: affiliationId,
          identifierId: identifierId,
          personId: personId,
          amount: amount,
          currency: sourceAccountQuery.data!.account!.currency,
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      switch (e?.status) {
        case 400:
          errorDescription = t(
            "SendAmountSelectionScreenDataError",
            "An error occurred during the amount transfer. Please check the data."
          );
          break;
        case 404:
          errorDescription = t(
            "SendAmountSelectionScreenAccountNotFoundError",
            "The destination account has not been found. Enter the email address of a person belonging to your affiliation."
          );
          break;
        case 422:
          errorDescription = t(
            "SendAmountSelectionScreenInvalidAmountOrRecipientError",
            "Can not transfer the amount to the target account. Please verify the amount and the recipient."
          );
          break;
        default:
          errorDescription = t(
            "SendAmountSelectionScreenUnknownError",
            "Internal server error"
          );
          break;
      }

      navigation.navigate("sendAmountResult", {
        accountId: accountId,
        affiliationId: affiliationId,
        identifierId: identifierId,
        personId: personId,
        amount: amount,
        currency: sourceAccountQuery.data!.account!.currency,
        type: ResultType.Error,
        description: errorDescription
      });
    }
  }

  return (
    <ScrollViewComponent>
      {sourceAccountQuery.data && (
        <AccountListItemComponent
          account={sourceAccountQuery.data}
          disabled={
            sourceAccountQuery.data.affiliation.status ===
            AffiliationStatus.Offline
          }></AccountListItemComponent>
      )}

      <DisplayBeneficiaries
        accounts={accountsQuery.data ?? []}
        beneficiaries={beneficiariesQuery.data ?? []}
        onAccountPress={setDestinationAccount}
        onBeneficiaryPress={setDestinationBeneficiary}
        onNewBeneficiaryPress={setNewDestinationBeneficiary}
      />

      <AmountSelectionComponent
        currency={sourceAccountQuery.data?.account?.currency}
        minimumAmount={sourceAccountQuery.data?.account?.roundingFactor}
        maximumAmount={availableBalance}
        roundingFactor={sourceAccountQuery.data?.account?.roundingFactor}
        amountInputRef={amountSelectionRef}
        onAmountChange={setAmount}
        onSubmitCallback={transferMoneyAsync}></AmountSelectionComponent>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        inputStyle={largeInputStyle.input}
        containerStyle={[
          largeInputStyle.container,
          {
            height: undefined,
            flex: 1,
            marginTop: spacings.lg
          }
        ]}
        value={purpose}
        onChangeText={setPurpose}
        placeholder={t(
          "AmountSelectionComponentPurposePlaceholder",
          "Add a note"
        )}
        autoCorrect={false}
        multiline={true}
        numberOfLines={4}
        placeholderTextColor={colors.text.placeholder}></Input>

      <Button
        title={t("SendAmountSelectionScreenSendButton", "Transfer {{amount}}", {
          amount: humanizeCurrency(
            amount ?? 0,
            sourceAccountQuery.data!.account!.currency
          )
        })}
        onPress={transferMoneyAsync}
        disabled={isFormInvalid}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        titleStyle={largePrimaryRoundedButtonStyle.title}
        loading={
          transferToAccountMutation.isLoading ||
          transferToIdentifierMutation.isLoading
        }
        loadingStyle={largePrimaryRoundedButtonStyle.loading}
        disabledStyle={largePrimaryRoundedButtonStyle.disabled}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          {
            marginTop: spacings.xl
          }
        ]}></Button>
    </ScrollViewComponent>
  );
}

function DisplayBeneficiaries({
  beneficiaries,
  accounts,
  onAccountPress,
  onBeneficiaryPress,
  onNewBeneficiaryPress
}: {
  beneficiaries: IBeneficiary[];
  accounts: AccountDetail[];
  onAccountPress: (accountDetail: AccountDetail | undefined) => void;
  onBeneficiaryPress: (beneficiary: IBeneficiary | undefined) => void;
  onNewBeneficiaryPress: (
    newBeneficiaryDetail: NewBeneficiaryDetail | undefined
  ) => void;
}) {
  const { t } = useTranslation();
  const isEmptyList = beneficiaries.length === 0 && accounts.length === 0;
  const [currentSelectedItem, setCurrentSelectedItem] = useState<
    AccountDetail | IBeneficiary | "NewBeneficiary" | undefined
  >(isEmptyList ? "NewBeneficiary" : undefined);
  const newBeneficiaryRef = useRef<TextInput>(null);

  useEffect(() => {
    if (currentSelectedItem === "NewBeneficiary")
      newBeneficiaryRef.current?.focus();
  }, [currentSelectedItem]);

  return (
    <>
      <Text
        style={[
          typographies.title,
          { marginTop: spacings.md, marginBottom: spacings.sm }
        ]}>
        {t("SendAmountSelectionScreenBeneficiariesListTitle", "Beneficiary")}
      </Text>
      <View
        style={[
          {
            backgroundColor: colors.background.light,
            borderRadius: cornerRadius.md,
            overflow: "hidden"
          },
          !isEmptyList && { marginBottom: spacings.lg }
        ]}>
        {accounts.map((account, index) => (
          <View
            style={[
              virtualizedListItemStyle.baseItem,
              virtualizedListItemStyle.middleItem
            ]}
            key={index}>
            <BeneficiaryListItem
              icon={faUser}
              selected={isEqual(currentSelectedItem, account)}
              onPress={() => {
                if (account.account?.accountId && account.person?.personId) {
                  onAccountPress(account);
                  onBeneficiaryPress(undefined);
                  onNewBeneficiaryPress(undefined);
                  setCurrentSelectedItem(account);
                }
              }}
              topText={account.person?.formattedName}
              bottomText={
                account.account?.balance
                  ? humanizeCurrency(
                      account.account?.balance,
                      account.account?.currency
                    )
                  : undefined
              }
            />
          </View>
        ))}

        {beneficiaries.map((beneficiary, index) => (
          <View
            style={[
              virtualizedListItemStyle.baseItem,
              virtualizedListItemStyle.middleItem
            ]}
            key={index}>
            <BeneficiaryListItem
              selected={isEqual(currentSelectedItem, beneficiary)}
              icon={faAddressBook}
              onPress={() => {
                onBeneficiaryPress(beneficiary);
                onAccountPress(undefined);
                onNewBeneficiaryPress(undefined);
                setCurrentSelectedItem(beneficiary);
              }}
              topText={beneficiary.name}
              bottomText={beneficiary.value}
            />
          </View>
        ))}

        {!isEmptyList && (
          <BeneficiaryListItem
            icon={faAt}
            selected={currentSelectedItem === "NewBeneficiary"}
            onPress={() => {
              onAccountPress(undefined);
              onBeneficiaryPress(undefined);
              onNewBeneficiaryPress(undefined);
              setCurrentSelectedItem("NewBeneficiary");
            }}
            topText={t(
              "SendAmountSelectionScreenNewBeneficiaryEmailTitle",
              "Email"
            )}
          />
        )}
      </View>

      {currentSelectedItem === "NewBeneficiary" && (
        <DisplayNewBeneficiaryDetail
          nameInputRef={newBeneficiaryRef}
          onNewBeneficiaryChanged={onNewBeneficiaryPress}
        />
      )}
    </>
  );
}

function DisplayNewBeneficiaryDetail({
  nameInputRef,
  onNewBeneficiaryChanged
}: {
  nameInputRef?: RefObject<TextInput>;
  onNewBeneficiaryChanged: (newBeneficiaryDetail: NewBeneficiaryDetail) => void;
}) {
  const { t } = useTranslation();
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [saveBeneficiary, setSaveBeneficiary] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [aliasIsValid, setAliasIsValid] = useState(true);

  const emailRef = useRef<TextInput>(null);

  return (
    <View style={{ marginBottom: spacings.lg }}>
      <Input
        ref={nameInputRef}
        inputContainerStyle={[largeInputStyle.inputContainer]}
        containerStyle={[
          styles.newBeneficiaryInputContainerStyle,
          {
            borderRadius: undefined,
            borderTopLeftRadius: cornerRadius.md,
            borderTopRightRadius: cornerRadius.md
          },
          !aliasIsValid && {
            borderColor: colors.danger,
            borderWidth: spacings.xxs
          }
        ]}
        onBlur={() =>
          setAliasIsValid(!saveBeneficiary || !validator.isEmpty(alias))
        }
        returnKeyType="next"
        onSubmitEditing={() => emailRef.current?.focus()}
        inputStyle={largeInputStyle.input}
        placeholder={t(
          "SendAmountSelectionScreenNewBeneficiaryAlias",
          "Name *"
        )}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        onChangeText={(value) => {
          setAlias(value);
          onNewBeneficiaryChanged({
            alias: value,
            email,
            remember: saveBeneficiary
          });
        }}
        value={alias}
        autoComplete="off"></Input>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          styles.newBeneficiaryInputContainerStyle,
          {
            borderRadius: undefined
          },
          !emailIsValid && {
            borderColor: colors.danger,
            borderWidth: spacings.xxs
          }
        ]}
        ref={emailRef}
        returnKeyType="next"
        inputStyle={largeInputStyle.input}
        placeholder={t(
          "SendAmountSelectionScreenNewBeneficiaryEmail",
          "Email *"
        )}
        autoCorrect={false}
        autoCapitalize="none"
        onBlur={() => setEmailIsValid(validator.isEmail(email))}
        placeholderTextColor={colors.text.placeholder}
        onChangeText={(value) => {
          setEmail(value);
          onNewBeneficiaryChanged({
            alias: alias,
            email: value,
            remember: saveBeneficiary
          });
        }}
        value={email}></Input>

      <View
        style={{
          backgroundColor: colors.background.light,
          flexDirection: "row",
          paddingHorizontal: spacings.lg,
          height: 48,
          alignItems: "center",
          borderBottomLeftRadius: cornerRadius.md,
          borderBottomRightRadius: cornerRadius.md
        }}>
        <Switch
          color={Platform.OS === "android" ? colors.primary50 : colors.primary}
          thumbColor={Platform.OS === "android" ? colors.primary : undefined}
          {...(Platform.OS === "web" && {
            activeThumbColor: colors.primary,
            activeTrackColor: colors.primary50
          })}
          value={saveBeneficiary}
          onValueChange={(value) => {
            setSaveBeneficiary(value);
            onNewBeneficiaryChanged({
              alias: alias,
              email: email,
              remember: value
            });
          }}
          style={
            Platform.OS === "android" && {
              marginVertical: -spacings.lg
            }
          }
        />

        <View style={{ flexShrink: 1, marginLeft: spacings.md }}>
          <Text style={typographies.body}>
            {t("SendAmountSelectionScreenSaveBeneficiaryTitle", "Save it")}
          </Text>
          <Text style={typographies.caption}>
            {t(
              "SendAmountSelectionScreenSaveBeneficiaryDescription",
              "Save the beneficiary for future usages"
            )}
          </Text>
        </View>
      </View>
    </View>
  );
}

type NewBeneficiaryDetail = {
  alias: string;
  email: string;
  remember: boolean;
};

const styles = StyleSheet.create({
  newBeneficiaryInputContainerStyle: {
    backgroundColor: colors.background.light,
    height: 48,
    paddingHorizontal: spacings.lg,
    marginBottom: spacings.xxs
  }
});
