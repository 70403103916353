import { faArrowUpFromBracket } from "@fortawesome/pro-light-svg-icons/faArrowUpFromBracket";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { format, startOfDay } from "date-fns";
import { flatten, groupBy, map, orderBy } from "lodash";
import { default as React, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, SectionList, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { EmptyContentComponent } from "../components/EmptyContentComponent";
import {
  TransactionListSummarySkeleton,
  TransactionsListSkeleton
} from "../components/LoadingSkeletonComponent";
import TransactionsListItemComponent from "../components/TransactionsListItemComponent";
import { useAction } from "../hooks/ActionHook";
import {
  ExportTransactionsScreenProps,
  TransactionsStackParamsList
} from "../navigation/HomeStackNavigation";
import { IAccountKey } from "../services/AccountsService";
import { humanizeDay } from "../services/HumanizerService";
import { invalidateTransactions } from "../services/QueryService";
import { useInfiniteTransactions } from "../services/TransactionsService";
import { typographies } from "../styles/Fonts";
import { virtualizedListItemStyle } from "../styles/Lists";
import {
  colors,
  containerStyle,
  iconSizes,
  primaryLinkButtonStyle,
  spacings
} from "../styles/Styles";

export default function TransactionsListScreen() {
  const route =
    useRoute<RouteProp<TransactionsStackParamsList, "transactions">>();
  const navigation =
    useNavigation<NavigationProp<TransactionsStackParamsList>>();
  const { t } = useTranslation();

  const accountKey: IAccountKey | undefined = route.params
    ? {
        identifierId: route.params.identifierId,
        affiliationId: route.params.affiliationId,
        personId: route.params.personId,
        accountId: route.params.accountId
      }
    : undefined;

  const transactionsQuery = useInfiniteTransactions(accountKey);
  const refreshAction = useAction(refresh);

  useEffect(() => {
    const exportTransactionsScreenProps:
      | ExportTransactionsScreenProps
      | undefined = accountKey;

    navigation.setOptions({
      headerRight: () => (
        <Button
          icon={
            <FontAwesomeIcon
              icon={faArrowUpFromBracket}
              color={colors.text.link}
              size={iconSizes.lg}></FontAwesomeIcon>
          }
          onPress={() =>
            navigation.navigate(
              "exportTransactions",
              exportTransactionsScreenProps
            )
          }
          type="clear"
          titleStyle={primaryLinkButtonStyle.title}></Button>
      )
    });
  }, []);

  const groupTransactionsByDate = useMemo(() => {
    const groupedTransactions = groupBy(
      flatten(transactionsQuery.data?.pages ?? []),
      (item) => startOfDay(item.date)
    );

    const data = map(groupedTransactions, (value, key) => ({
      title: humanizeDay(new Date(key)),
      data: orderBy(value, [(t) => t.date, (t) => t.amount], ["desc", "asc"])
    }));

    return data;
  }, [transactionsQuery.data]);

  function RenderSectionHeader(title: string) {
    return (
      <View
        style={{
          marginBottom: spacings.sm
        }}>
        <Text style={[typographies.body, { color: colors.text.tertiary }]}>
          {title}
        </Text>
      </View>
    );
  }

  function RenderFooter() {
    return transactionsQuery.hasNextPage ||
      transactionsQuery.isFetchingNextPage ? (
      <View style={{ paddingTop: spacings.lg }}>
        <TransactionListSummarySkeleton />
      </View>
    ) : null;
  }

  if (transactionsQuery.isInitialLoading) {
    return <TransactionsListSkeleton />;
  }

  if (transactionsQuery.data?.pages.length === 0) {
    return (
      <EmptyContentComponent
        message={t(
          "TransactionListScreenNoTransactions",
          "You don't have any transaction."
        )}
        refreshControl={
          <RefreshControl
            refreshing={refreshAction.isBusy}
            onRefresh={refreshAction.execute}
          />
        }></EmptyContentComponent>
    );
  }

  function refresh() {
    return invalidateTransactions(accountKey);
  }

  return (
    <SectionList
      refreshing={refreshAction.isBusy}
      onRefresh={refreshAction.execute}
      stickySectionHeadersEnabled={false}
      sections={groupTransactionsByDate}
      onEndReachedThreshold={0.3}
      onEndReached={() => transactionsQuery.fetchNextPage()}
      ListFooterComponent={RenderFooter}
      renderSectionHeader={({ section: { title } }) =>
        RenderSectionHeader(title)
      }
      contentContainerStyle={containerStyle.container}
      renderItem={({ item, index, section }) => (
        <View
          key={index}
          style={[
            virtualizedListItemStyle.baseItem,
            index === 0 ? virtualizedListItemStyle.firstItem : null,
            index === section.data.length - 1
              ? [
                  virtualizedListItemStyle.lastItem,
                  { marginBottom: spacings.lg }
                ]
              : virtualizedListItemStyle.middleItem
          ]}>
          <TransactionsListItemComponent
            item={item}
            dateFormat={(date) =>
              format(date, "p")
            }></TransactionsListItemComponent>
        </View>
      )}
      keyExtractor={(_, index) => index.toString()}></SectionList>
  );
}
