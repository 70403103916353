import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { updateAppInfoQueryKey } from "./QueryService";

export const UPDATE_APP_REMINDER = "update_app_reminder";

async function getUpdateAppInfo(): Promise<UpdateAppInfo> {
  const response = await axios.get(
    `${Constants.expoConfig?.extra?.appAddress}update.config.json`,
    {
      responseType: "json"
    }
  );
  const updateAppInfo: UpdateAppInfo = response.data;
  return updateAppInfo;
}

export function getApplicationInfo(): ApplicationInfo {
  const appInfo: ApplicationInfo = {
    version:
      Platform.OS !== "ios"
        ? Constants.expoConfig?.version
        : Constants.expoConfig?.ios?.buildNumber
  };

  return appInfo;
}

export function useUpdateAppInfo() {
  return useQuery([updateAppInfoQueryKey], getUpdateAppInfo, {
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    cacheTime: 0
  });
}

export type UpdateAppInfo = {
  minimumVersion: string;
  targetVersion: string;
  androidInstaller: string;
  iosInstaller: string;
  webInstaller: string;
};

export type ApplicationInfo = {
  version: string | undefined;
};
